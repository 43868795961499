<template>
  <!-- Add User -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New User</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="#">
            <div class="accordion-lists" id="list-accord">
              <!-- Basic Info -->
              <div class="manage-user-modal">
                <div class="manage-user-modals">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="profile-pic-upload">
                        <div class="profile-pic">
                          <span><i class="ti ti-photo"></i></span>
                        </div>
                        <div class="upload-content">
                          <div class="upload-btn">
                            <input type="file" />
                            <span> <i class="ti ti-file-broken"></i>Upload File </span>
                          </div>
                          <p>JPG, GIF or PNG. Max size of 800K</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Name <span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >User Name <span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Email <span class="text-danger">*</span></label
                          >
                          <div
                            class="status-toggle small-toggle-btn d-flex align-items-center"
                          >
                            <span class="me-2 label-text">Email Opt Out</span>
                            <input type="checkbox" id="user1" class="check" checked="" />
                            <label for="user1" class="checktoggle"></label>
                          </div>
                        </div>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Role <span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Phone 1 <span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">Phone 2</label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Password <span class="text-danger">*</span></label
                        >
                        <div class="icon-form-end">
                          <span class="form-icon"><i class="ti ti-eye-off"></i></span>
                          <input type="password" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Repeat Password <span class="text-danger">*</span></label
                        >
                        <div class="icon-form-end">
                          <span class="form-icon"><i class="ti ti-eye-off"></i></span>
                          <input type="password" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Location <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Cansel" id="cansel" placeholder="Choose" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="radio-wrap">
                        <label class="col-form-label">Status</label>
                        <div class="d-flex flex-wrap">
                          <div class="radio-btn">
                            <input
                              type="radio"
                              class="status-radio"
                              id="active1"
                              name="status"
                              checked=""
                            />
                            <label for="active1">Active</label>
                          </div>
                          <div class="radio-btn">
                            <input
                              type="radio"
                              class="status-radio"
                              id="inactive1"
                              name="status"
                            />
                            <label for="inactive1">Inactive</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->
            </div>
            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->

  <!-- Edit User -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit User</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="#">
            <div class="accordion-lists" id="list-accords">
              <!-- Basic Info -->
              <div class="manage-user-modal">
                <div class="manage-user-modals">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="profile-pic-upload">
                        <div class="profile-pic">
                          <span><i class="ti ti-photo"></i></span>
                        </div>
                        <div class="upload-content">
                          <div class="upload-btn">
                            <input type="file" />
                            <span> <i class="ti ti-file-broken"></i>Upload File </span>
                          </div>
                          <p>JPG, GIF or PNG. Max size of 800K</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          value="Darlee Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >User Name <span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" value="Darlee_47" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="col-form-label"
                            >Email <span class="text-danger">*</span></label
                          >
                          <div
                            class="status-toggle small-toggle-btn d-flex align-items-center"
                          >
                            <span class="me-2 label-text">Email Opt Out</span>
                            <input type="checkbox" id="user" class="check" checked="" />
                            <label for="user" class="checktoggle"></label>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          value="robertson@example.com"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Role <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          value="Facility Manager"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Phone 1 <span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" value="+1 875455453" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">Phone 2</label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Password <span class="text-danger">*</span></label
                        >
                        <div class="icon-form-end">
                          <span class="form-icon"><i class="ti ti-eye-off"></i></span>
                          <input type="password" class="form-control" value="********" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Repeat Password <span class="text-danger">*</span></label
                        >
                        <div class="icon-form-end">
                          <span class="form-icon"><i class="ti ti-eye-off"></i></span>
                          <input type="password" class="form-control" value="********" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Location <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="EditCansel"
                          id="editcansel"
                          placeholder="Germany"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="radio-wrap">
                        <label class="col-form-label">Status</label>
                        <div class="d-flex flex-wrap">
                          <div class="radio-btn">
                            <input
                              type="radio"
                              class="status-radio"
                              id="active"
                              name="status"
                              checked=""
                            />
                            <label for="active">Active</label>
                          </div>
                          <div class="radio-btn">
                            <input
                              type="radio"
                              class="status-radio"
                              id="inactive"
                              name="status"
                            />
                            <label for="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->
            </div>

            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit User -->

  <!-- Delete User -->
  <div class="modal custom-modal fade" id="delete_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove User?</h3>
            <p class="del-info">Are you sure you want to remove it.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/user/manage-users" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete User -->
</template>
<script>
export default {
  data() {
    return {
      EditCansel: ["Germany", "USA", "Canada", "India", "China"],
      Cansel: ["Choose", "Germany", "USA", "Canada", "India", "China"],
    };
  },
};
</script>
