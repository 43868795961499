<template>
    <div class="card analytics-card">
        <div class="card-header d-flex justify-content-between align-items-center flex-wrap">
            <h3>
                <i class="ti ti-grip-vertical"></i>Leads By Stage
            </h3>
            <div class="card-select">
                <ul>
                    <li>
                        <vue-select 
                            :options="Sales"
                            id="pipelinessale"
                            placeholder="Sales Pipeline"
                        />
                    </li>
                    <li class="form-wrap">
                        <vue-select 
                            :options="Days"
                            id="salescomplete"
                            placeholder="Last 30 days"
                        />
                    </li>
                </ul>
            </div>

        </div>
        <div class="card-body">
            <div id="leads-chart">
                <apexchart
                    type="bar"
                    height="250"
                    :options="leadsChart.chart"
                    :series="leadsChart.series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import { leadsChart } from "./data";

export default {
    data() {
        return {
            leadsChart: leadsChart,
            Days: ["Last 30 days", "Last 15 days", "Last 7 days"],
            Sales: ["Sales Pipeline", "Marketing Pipeline"]
        }
    },
}
</script>