<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="contact-head">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <ul class="contact-breadcrumb">
                <li>
                  <router-link to="/crm/organisation-list"><i class="ti ti-arrow-narrow-left"></i>Organisations</router-link>
                </li>
                <li>{{ isEditing ? 'Edit Organisation' : 'Create New Organisation' }}</li>
              </ul>
            </div>
            <div class="col-sm-6 text-end">
              <ul class="contact-breadcrumb">
                <li>
                  <router-link :to="{ path: '/crm/person-list', query: { org_id: org_id } }">Persons<i class="ti ti-arrow-narrow-right ms-2"></i></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <!-- Company Settings -->
            <div class="card">
              <div class="card-body settings-form">
                <!-- <form @submit.prevent="handleSubmit" class="toggle-height"> -->
                <Form @submit="handleSubmit" :validation-schema="schema" class="toggle-height">
                  <div class="settings-sub-header">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <h4>{{ isEditing ? 'Edit Organisation' : 'Create Organisation' }}</h4>
                      </div>
                      <div class="col-6 text-end" v-if="isEditing">
                        <router-link class="btn btn-primary" :to="{ path: '/crm/create-person', query: { org_id: org_id } }">
                          <i class="ti ti-square-rounded-plus me-1"></i>Add
                          Person
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="pro-create">
                    <div class="row">
                      <div class="col-md-12" v-if="isEditing">
                        <div class="form-wrap">
                          <div class="profile-upload">
                            <div class="profile-upload-img">
                              <span v-if="!imagePreview"><i class="ti ti-photo"></i></span>
                              <img v-if="imagePreview" :src="imagePreview" class="img-fluid" />
                              <button v-if="imagePreview" type="button" class="profile-remove" @click="removeImage">
                                <i class="feather-x"></i>
                              </button>
                            </div>
                            <div class="profile-upload-content">
                              <label class="profile-upload-btn">
                                <i class="ti ti-file-broken"></i> Upload File
                                <input type="file" class="input-img" @change="handleImageUpload" />
                              </label>
                              <p>
                                Upload Logo of your organisation (In jpeg, png, jpg).
                              </p>
                            </div>
                          </div>
                          <span class="text-danger" v-if="apiErrors && apiErrors['logo'] && apiErrors['logo'].length">
                            {{ apiErrors['logo'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Organisation name <span class="text-danger">*</span></label>
                          <!-- <input type="text" class="form-control" v-model="formData.name" /> -->
                          <Field name="name" as="input" type="text" class="form-control" v-model="formData.name" />
                          <ErrorMessage name="name" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['name'] && apiErrors['name'].length">
                            {{ apiErrors['name'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Alert text</label>
                          <!-- <input type="text" class="form-control" v-model="formData.alert_text" /> -->
                          <Field name="alert_text" as="input" type="text" class="form-control" v-model="formData.alert_text" />
                          <ErrorMessage name="alert_text" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Postcode</label>
                          <input type="text" class="form-control" v-model="formData.postcode" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['postcode'] && apiErrors['postcode'].length">
                            {{ apiErrors['postcode'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">www</label>
                          <input type="text" class="form-control" v-model="formData.www" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Telephone</label>
                          <Field name="telephone" as="input" type="text" class="form-control" v-model="formattedTelephone" />
                          <!-- <ErrorMessage name="telephone" class="text-danger" /> -->
                          <span class="text-danger" v-if="apiErrors && apiErrors['telephone'] && apiErrors['telephone'].length">
                            {{ apiErrors['telephone'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Fax</label>
                          <input type="text" class="form-control" v-model="formData.fax" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">VAT no.</label>
                          <input type="text" class="form-control" v-model="formData.vat_no" />
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Comment </label>
                          <textarea class="form-control" rows="3" v-model="formData.comment"></textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">ERP ID </label>
                          <input type="text" class="form-control" v-model="formData.erp_id" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Org URN</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.org_urn" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Original Lead Source</label>
                          <!-- {{ leadSourceOptions }} {{ options }} -->
                          <select id="leadSource" v-model="formData.original_lead_source" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="source in options.org_lead_source" :key="source" :value="source.value">
                              {{ source.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">No of Employees</label>
                          <select id="leadSource" v-model="formData.no_of_employees" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="emp in options.org_no_of_emp" :key="emp" :value="emp.value">
                              {{ emp.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Territory
                          </label>
                          <select id="territory" v-model="formData.territory_id" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="ter in Territories" :key="ter.id" :value="ter.id">
                              {{ ter.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Responsible
                          </label>
                          <select id="responsible" v-model="formData.responsible" class="form-select">
                            <option value="">
                              -----None-----
                            </option>
                            <option v-for="res in Responsibles" :key="res" :value="res.id">
                              {{ res.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Assign To
                          </label>
                          <select id="assignedTo" v-model="formData.assign_to" class="form-select">
                            <option value="">
                              -----None-----
                            </option>
                            <option v-for="res in assignedToOptions" :key="res" :value="res.id">
                              {{ res.name }}
                            </option>
                          </select>
                        </div>
                      </div> -->
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">SIC code
                          </label>
                          <input type="text" class="form-control" v-model="formData.sic_code" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">SIC Description</label>
                          <input type="text" class="form-control" v-model="formData.sic_description" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">VAT group
                          </label>
                          <select id="vatgroup" v-model="formData.vat_group" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in VatGroup" :key="res" :value="res">
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Type </label>
                          <select id="type" v-model="formData.type" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in options.org_type" :key="res" :value="res.value">
                              {{ res.value }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Status </label>
                          <select id="status" v-model="formData.status" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in options.org_account_status" :key="res" :value="res.value">
                              {{ res.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Industry
                          </label>
                          <select id="industry" v-model="formData.industry" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in options.org_industry" :key="res" :value="res.value">
                              {{ res.text }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Company Reg No:
                          </label>
                          <input type="text" class="form-control" v-model="formData.company_reg_no" />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Reg No: </label>
                          <input type="text" class="form-control" v-model="formData.charity_reg_no" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Import ID
                          </label>
                          <input type="text" class="form-control" v-model="formData.charity_import_id" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Lead Source
                          </label>
                          <input type="text" class="form-control" v-model="formData.charity_lead_source" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Duplicate Batch
                          </label>
                          <select id="duplicateBatch" v-model="formData.charity_duplicate_batch" class="form-select">
                            <option value="">--</option>
                            <option v-for="res in DuplicateBatch" :key="res" :value="res">
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4 mb-3">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Address</label>
                          <textarea class="form-control" rows="3" v-model="formData.address"></textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">City</label>
                          <input type="text" class="form-control" v-model="formData.city" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">County / State
                          </label>
                          <input type="text" class="form-control" v-model="formData.state" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Post code/ZIP</label>
                          <input type="text" class="form-control" v-model="formData.zip" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap mb-0 mt-4" style="border: 1px solid #e1e1e1;">
                          <h4 class="p-2">Logs</h4>
                          <p class="col-form-label p-2" style="background-color: #eff1f0;">Add log entry</p>
                          <textarea class="form-control" rows="3" v-model="formData.content"></textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Country</label>
                          <!-- {{ options.org_type }} -->
                          <select id="country_id" v-model="formData.country_id" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in Countries" :key="res" :value='res.id'>
                              {{ res.country_name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-3" v-if="isEditing">
                    <div class="col-md-4">
                      <div class="form-wrap mb-0" style="border: 1px solid #e1e1e1;">
                        <p class="col-form-label p-2" style="background-color: #eff1f0;">Logs</p>
                        <div class="row" v-for="log in logs">
                          <div class="col-12">
                            <p class="p-2">{{ log.content }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-button text-end">
                    <router-link class="btn btn-light" to="/crm/organisation-list">Cancel</router-link>
                    <button type="submit" class="btn btn-primary">
                      {{ isEditing ? 'Update' : 'Create' }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed
} from "vue";
import {
  mapActions,
  mapGetters
} from 'vuex';
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "dd-MM-yyyy",
      DuplicateBatch: ["Yes"],
      VatGroup: ["A", "B"],
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isEditing = ref(false);
    const org_id = ref(null);
    const imagePreview = ref('');
    const apiErrors = ref(null);

    const options = computed(() => store.getters['organisation/getorgDropdowns']);
    const Territories = computed(() => store.getters['organisation/getTerritories']);
    const Responsibles = computed(() => store.getters['organisation/getresUsersOptions'])
    const assignedToOptions = computed(() => store.getters['organisation/getfetchAssignedTo']);
    const Countries = computed(() => store.getters['organisation/getCountries']);

    const schema = Yup.object().shape({
      name: Yup.string()
        .required("Organisation name is required"),
      // .min(3, "Organisation name must be at least 3 characters"),
    });

    if (route.query.orgId) {
      org_id.value = route.query.orgId;
      isEditing.value = true;
    }
    const formData = ref({
      id: null,
      name: "",
      postcode: "",
      telephone: "",
      fax: "",
      responsible: null,
      assign_to: null,
      logo: null,
      comment: "",
      territory_id: "",
      vat_group: "",
      type: "",
      status: "",
      industry: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country_id: null,
      alert_text: "",
      www: "",
      vat_no: "",
      erp_id: "",
      org_urn: "",
      original_lead_source: "",
      no_of_employees: "",
      sic_code: "",
      sic_description: "",
      company_reg_no: "",
      charity_reg_no: "",
      charity_import_id: "",
      charity_lead_source: "",
      charity_duplicate_batch: "",
      content: "",
    });
    const logs = ref([]);

    // Assuming formData is a reactive object
    const formattedTelephone = computed({
      get() {
        // Ensure formData and formData.value.telephone are defined
        if (!formData.value || !formData.value.telephone) {
          return ''; // Return an empty string if there's no telephone number
        }

        const number = formData.value.telephone.replace(/\D/g, '');

        // Check if the number is valid (e.g., 10 digits)
        if (number.length === 10) {
          // Format the number
          return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
        } else {
          // Return the original value if the number is not valid
          return formData.value.telephone;
        }
      },
      set(value) {
        // Clean the input value to store only digits
        const cleanedValue = value.replace(/\D/g, '');

        // If the cleaned value is empty, set formData.telephone to an empty string
        formData.value.telephone = cleanedValue.length ? cleanedValue : '';
      }
    });

    const fetchOrgData = async (id) => {
      try {
        const data = await store.dispatch('organisation/fetchOrgData', id);
        logs.value = data.logs;
        Object.assign(formData.value, data);
        formData.value.content = ''
        imagePreview.value = data.logo;
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    const handleSubmit = async () => {
      const response = await store.dispatch('organisation/saveOrgData', formData.value);
      if (response?.status) {
        router.push("/crm/organisation-list");
      } else {
        apiErrors.value = response.errors
      }
    };
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        formData.value.logo = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result; // Show the image preview
        };
        reader.readAsDataURL(file); // Convert the file to a data URL
      }
    };

    const removeImage = () => {
      formData.value.logo = ''; // Clear the image URL in the form data
      imagePreview.value = ''; // Clear the image preview
    };
    // Watch for changes in the selected territory
    watch(
      () => formData.value.territory_id,
      async (newTerritory) => {
        if (newTerritory) {
          store.dispatch('organisation/fetchTerritoryCompanyUsers', [newTerritory])
          // store.dispatch('organisation/fetchAssignedToUsers', [newTerritory]);
        } else {
          Responsibles.value = []; // Clear Responsible if no territory selected
          assignedToOptions.value = [];
        }
      }, {
        immediate: true
      } // Immediate to handle initial load if needed
    );

    onMounted(async () => {
      const orgId = route.query.orgId;
      if (orgId) {
        fetchOrgData(orgId);
      }
      store.dispatch('organisation/fetchDropdownOptions');
      store.dispatch('organisation/fetchTerritories');
      store.dispatch('organisation/fetchCountries');
    });

    return {
      schema,
      formData,
      org_id,
      handleSubmit,
      isEditing,
      Territories,
      Responsibles,
      Countries,
      handleImageUpload,
      removeImage,
      imagePreview,
      assignedToOptions,
      options,
      apiErrors,
      logs,
      formattedTelephone
    };
  },
};
</script>
