<template>
  <!-- Add Company -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Company</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="#">
            <div class="accordion-lists" id="list-accord">
              <!-- Basic Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap"
                  data-bs-toggle="collapse"
                  data-bs-target="#basic"
                  ><span><i class="ti ti-user-plus"></i></span>Basic Info</a
                >
                <div
                  class="accordion-collapse collapse show"
                  id="basic"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="profile-pic-upload">
                          <div class="profile-pic">
                            <span><i class="ti ti-photo"></i></span>
                          </div>
                          <div class="upload-content">
                            <div class="upload-btn">
                              <input type="file" />
                              <span> <i class="ti ti-file-broken"></i>Upload File </span>
                            </div>
                            <p>JPG, GIF or PNG. Max size of 800K</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Company Name</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="d-flex justify-content-between align-items-center">
                            <label class="col-form-label"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div
                              class="status-toggle small-toggle-btn d-flex align-items-center"
                            >
                              <span class="me-2 label-text">Email Opt Out</span>
                              <input type="checkbox" id="user" class="check" checked="" />
                              <label for="user" class="checktoggle"></label>
                            </div>
                          </div>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Phone 1 <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Phone 2</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Fax <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Website <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Ratings </label>
                          <div class="icon-form-end">
                            <span class="form-icon"><i class="ti ti-star"></i></span>
                            <input type="text" class="form-control" placeholder="4.2" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap img-select">
                          <label class="col-form-label">Owner</label>
                          <vue-select :options="SelOwner" placeholder="Jerald" />

                          <span class="select-box-img avatar"
                            ><img src="@/assets/img/profiles/avatar-14.jpg" alt=""
                          /></span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Tags </label>
                          <vue3-tags-input
                            class="input-tags form-control"
                            type="text"
                            data-role="tagsinput"
                            name="Label"
                            value="Tag1"
                            :tags="tags"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Deals <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="SelChoAn"
                            id="selchoan"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Source <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="SourceSel"
                            id="sourcesel"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Industry <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="RetailSel"
                            id="retailsel"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Contact <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="DarSel"
                            id="darsel"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Currency <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Language <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="LanSel"
                            id="Choose"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label"
                            >Description <span class="text-danger">*</span></label
                          >
                          <textarea class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->

              <!-- Address Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#address"
                  ><span><i class="ti ti-map-pin-cog"></i></span>Address Info</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="address"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Street Address </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">City </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">State / Province </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Country</label>
                          <vue-select
                            :options="CounSel"
                            id="counsel"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Zipcode </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Address Info -->

              <!-- Social Profile -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#social"
                  ><span><i class="ti ti-social"></i></span>Social Profile</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="social"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Facebook</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Skype </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Linkedin </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Twitter</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Whatsapp</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Instagram</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Social Profile -->

              <!-- Access -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#access"
                  ><span><i class="ti ti-accessible"></i></span>Access</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="access"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="radio-wrap form-wrap">
                          <label class="col-form-label">Visibility</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="public"
                                name="visible"
                              />
                              <label for="public">Public</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="private"
                                name="visible"
                              />
                              <label for="private">Private</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="people"
                                name="visible"
                              />
                              <label for="people">Select People</label>
                            </div>
                          </div>
                        </div>
                        <div class="radio-wrap">
                          <label class="col-form-label">Status</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="active"
                                name="status"
                                checked=""
                              />
                              <label for="active">Active</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="inactive"
                                name="status"
                              />
                              <label for="inactive">Inactive</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Access -->
            </div>

            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
              <button
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#create_contact"
                class="btn btn-primary"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Company -->

  <!-- Add New Pipeline -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Pipeline</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <div class="toggle-height">
          <div class="pro-create">
            <div class="form-wrap">
              <label class="col-form-label"
                >Pipeline Name <span class="text-danger">*</span></label
              >
              <input class="form-control" type="text" />
            </div>
            <div class="form-wrap">
              <h5 class="form-title">Pipeline Stages</h5>
              <div class="pipeline-listing">
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Inpipeline</p>
                  <div class="action-pipeline">
                    <a href="javascript:;"><i class="ti ti-edit text-blue"></i>Edit</a>
                    <a href="javascript:;"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Follow Up</p>
                  <div class="action-pipeline">
                    <a href="javascript:;"><i class="ti ti-edit text-blue"></i>Edit</a>
                    <a href="javascript:;"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Schedule Service</p>
                  <div class="action-pipeline">
                    <a href="javascript:;"><i class="ti ti-edit text-blue"></i>Edit</a>
                    <a href="javascript:;"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-wrap">
              <h5 class="form-title">Access</h5>
              <div class="d-flex flex-wrap access-item nav">
                <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#all">
                  <input
                    type="radio"
                    class="status-radio"
                    id="all"
                    name="status"
                    checked=""
                  />
                  <label for="all">All</label>
                </div>
                <div
                  class="radio-btn"
                  data-bs-toggle="tab"
                  data-bs-target="#select-person"
                >
                  <input type="radio" class="status-radio" id="select" name="status" />
                  <label for="select">Select Person</label>
                </div>
              </div>
              <div class="tab-content">
                <div class="tab-pane fade" id="select-person">
                  <div class="access-wrapper">
                    <div class="access-view">
                      <div class="access-img">
                        <img
                          src="@/assets/img/profiles/avatar-21.jpg"
                          alt="Image"
                        />Vaughan
                      </div>
                      <a href="javascript:;">Remove</a>
                    </div>
                    <div class="access-view">
                      <div class="access-img">
                        <img
                          src="@/assets/img/profiles/avatar-01.jpg"
                          alt="Image"
                        />Jessica
                      </div>
                      <a href="javascript:;">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
            <router-link to="/crm/analytics" class="btn btn-primary">Create</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Pipeline -->

  <!-- Add New Activity -->
  <div class="toggle-popup2">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Activity</h4>
        <a href="javascript:;" class="sidebar-close2 toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <form action="#" class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Title <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Activity Type <span class="text-danger">*</span></label
                  >
                  <ul class="radio-activity">
                    <li>
                      <div class="active-type">
                        <input type="radio" id="call" name="status" checked="" />
                        <label for="call"><i class="ti ti-phone"></i>Calls</label>
                      </div>
                    </li>
                    <li>
                      <div class="active-type">
                        <input type="radio" id="mail" name="status" />
                        <label for="mail"><i class="ti ti-mail"></i>Email</label>
                      </div>
                    </li>
                    <li>
                      <div class="active-type">
                        <input type="radio" id="task" name="status" />
                        <label for="task"><i class="ti ti-subtask"></i>Task</label>
                      </div>
                    </li>
                    <li>
                      <div class="active-type">
                        <input type="radio" id="shares" name="status" />
                        <label for="shares"
                          ><i class="ti ti-user-share"></i>Meeting</label
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label"
                  >Due Date <span class="text-danger">*</span></label
                >
                <div class="form-wrap icon-form">
                  <date-picker
                    v-model="startdate"
                    value="29-02-2020"
                    class="datetimepicker form-control"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                  />
                  <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label"
                  >Time <span class="text-danger">*</span></label
                >
                <div class="form-wrap icon-form">
                  <span class="form-icon"><i class="ti ti-clock-hour-10"></i></span>
                  <input type="text" class="form-control datetimepicker-time" />
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label"
                  >Reminder <span class="text-danger">*</span></label
                >
                <div class="form-wrap icon-form">
                  <span class="form-icon"><i class="ti ti-bell-ringing"></i></span>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center">
                  <div class="form-wrap w-100">
                    <label class="col-form-label">&nbsp;</label>
                    <vue-select :options="SelMin" id="selin" placeholder="Select" />
                  </div>
                  <div class="form-wrap time-text">
                    <label class="col-form-label">&nbsp;</label>
                    <p>Before Due</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Owner <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="SelectBrad"
                    id="selectbrad"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Guests <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="CarSel"
                    id="carsel"
                    placeholder="Darlee Robertson"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >

                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label">Deals</label>
                    <a href="javascript:;" class="label-add"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select
                    :options="SelectAdms"
                    id="selectadms"
                    placeholder="Select"
                  />
                </div>
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label">Contacts</label>
                    <a href="javascript:;" class="label-add"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select
                    :options="SelectNewAdd"
                    id="selectnewadd"
                    placeholder="Select"
                  />
                </div>
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label">Companies</label>
                    <a href="javascript:;" class="label-add"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select
                    :options="SelectComp"
                    id="selectcomp"
                    placeholder="Select"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close2">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add New Activity -->
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Vue3TagsInput from "vue3-tags-input";
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "Description",
      editorConfig: {},
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      SelOwner: ["Jerald", "Guillory", "Jami", "Theresa", "Espinosa"],
      SelChoAn: ["Choose", "Collins", "Konopelsko", "Adams", "Schumm", "Wisozk"],
      SourceSel: [
        "Choose",
        "Phone Calls",
        "Social Media",
        "Refeeral Sites",
        "Web Analytics",
        "Previous Puruchases",
      ],
      RetailSel: [
        "Choose",
        "Retail Industry",
        "Banking",
        "Hotels",
        "Financial Services",
        "Insurance",
      ],
      DarSel: ["Choose", "Darlee Robertson", "Sharon Roy", "Vaughan", "Jessica"],
      LanSel: ["Choose", "English", "Arabic", "Chinese", "Hindi"],
      CounSel: ["Choose", "India", "USA", "France", "UK", "UAE"],
      SelMin: ["Select", "Minutes", "Hours"],
      SelectBrad: ["Select", "Hendry", "Bradtke", "Sally"],
      SelectAdms: ["Select", "Collins", "Konopelski", "Adams"],
      SelectNewAdd: ["Select", "Collins", "Konopelski", "Adams"],
      SelectComp: ["Select", "NovaWave", "SilverHawk", "HarborView"],
      CarSel: ["Darlee Robertson", "Sharon Roy", "Vaughan", "Jessica", "Carol Thomas"],
      tags: ["Tag1"],
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popups").length > 0) {
      var addPopups = document.getElementsByClassName("add-popups");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close2");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
