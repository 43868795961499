<template>
  <!-- Add Printer -->
  <div class="modal custom-modal fade" id="add_printer" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Printer</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/app-settings/printers">
            <div class="form-wrap">
              <label class="col-form-label"
                >Printer Company <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Printer Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Connection Type <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >IP Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Port <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Currency -->

  <!-- Edit Printer -->
  <div class="modal custom-modal fade" id="edit_printer" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Printer</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/app-settings/printers">
            <div class="form-wrap">
              <label class="col-form-label"
                >Printer Company <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Hp" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Printer Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Hp printer" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Connection Type <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Network" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >IP Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="198.162.0.1" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Port <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="900" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Printer -->

  <!-- Delete Printer -->
  <div class="modal custom-modal fade" id="delete_printer" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/app-settings/printers">
            <div class="success-message text-center">
              <div class="success-popup-icon">
                <i class="ti ti-trash-x"></i>
              </div>
              <h3>Remove Printer?</h3>
              <p class="del-info">Are you sure you want to remove it.</p>
              <div class="col-lg-12 text-center modal-btn">
                <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-danger">Yes, Delete it</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Printer -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
