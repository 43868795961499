<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Activity"
                      />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <a href="javascript:void(0);" class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add New Activity</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <div class="filter-section filter-flex">
                <div class="activity-title">
                  <h4>All Activity</h4>
                  <div class="active-list">
                    <activities-tabset></activities-tabset>
                  </div>
                </div>
                <div class="filter-list">
                  <ul>
                    <li>
                      <div class="sort-dropdown drop-down">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          ><i class="ti ti-sort-ascending-2"></i>Sort
                        </a>
                        <div class="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Ascending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Descending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Recently Added
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="manage-dropdwon">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-purple-light"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          ><i class="ti ti-columns-3"></i>Manage Columns</a
                        >
                        <div class="dropdown-menu dropdown-menu-xl-end">
                          <h4>Want to manage datatables?</h4>
                          <p>
                            Please drag and drop your column to reorder your table and
                            enable see option as you want.
                          </p>
                          <ul>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Title</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-name" class="check" />
                                <label for="col-name" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Activity Type</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-phone" class="check" />
                                <label for="col-phone" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Due Date</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-email" class="check" />
                                <label for="col-email" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Owner</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-tag" class="check" />
                                <label for="col-tag" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Created at</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-loc" class="check" />
                                <label for="col-loc" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Action</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-action" class="check" />
                                <label for="col-action" class="checktoggle"></label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form-sorts dropdown">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          ><i class="ti ti-filter-share"></i>Filter</a
                        >
                        <div
                          class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end"
                        >
                          <div class="filter-set-view">
                            <div class="filter-set-head">
                              <h4><i class="ti ti-filter-share"></i>Filter</h4>
                            </div>
                            <div class="accordion" id="accordionExample">
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    >Title</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse show"
                                  id="collapseTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Name"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>We scheduled a meeting</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Store and manage contact</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Built landing pages</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Discussed budget proposal</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Discussed about team</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#owner"
                                    aria-expanded="false"
                                    aria-controls="owner"
                                    >Activity Type</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="owner"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Meeting</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Calls</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Task</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Email</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#Status"
                                    aria-expanded="false"
                                    aria-controls="Status"
                                    >Due Date
                                  </a>
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="Status"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Date"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>25 Sep 2023, 12:12 pm</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>29 Sep 2023, 04:12 pm</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>11 Oct 2023, 05:00 pm</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>19 Oct 2023, 02:35 pm</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                    >Owner</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapseThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Hendry</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Monty Beer</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Bradtke</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Swaniawski</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsestatus"
                                    aria-expanded="false"
                                    aria-controls="collapsestatus"
                                    >Created Date</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapsestatus"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>22 Sep 2023, 10:14 am</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>27 Sep 2023, 03:26 pm</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>03 Oct 2023, 03:53 pm</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="filter-reset-btns">
                              <div class="row">
                                <div class="col-6">
                                  <a href="javascript:;" class="btn btn-light">Reset</a>
                                </div>
                                <div class="col-6">
                                  <router-link
                                    to="/crm/activity-calls"
                                    class="btn btn-primary"
                                    >Filter</router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Filter -->

              <!-- Activity List -->
              <div class="table-responsive custom-table">
                <calls-table></calls-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Activity List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <activities-list-modal></activities-list-modal>
</template>

<script>
export default {
  data() {
    return {
      title: "Activities",
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("edit-popup").length > 0) {
      var editPopups = document.getElementsByClassName("edit-popup");
      for (var k = 0; k < editPopups.length; k++) {
        editPopups[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses1 = document.getElementsByClassName("sidebar-close1");
      for (var l = 0; l < sidebarCloses1.length; l++) {
        sidebarCloses1[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
