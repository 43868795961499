<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>

<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->

        <div class="card main-card">
          <div class="card-body">
            <!-- Search -->
            <div class="search-section">
              <div class="row">
                <div class="col-md-5 col-sm-4">
                  <div class="form-wrap icon-form">
                    <span class="form-icon">
                      <i class="ti ti-search"></i>
                    </span>
                    <input type="text" class="form-control" placeholder="Search your organisation name" v-model="searchQuery" @input="searchCompanies" />
                  </div>
                </div>
                <div class="col-md-7 col-sm-8">
                  <div class="export-list text-sm-end">
                    <ul>
                      <li>
                        <div class="export-dropdwon">
                          <a href="javascript:void(0);" class="dropdown-toggle" data-bs-toggle="dropdown">
                            <i class="ti ti-package-export"></i>Export
                          </a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <ul>
                              <li>
                                <a href="javascript:void(0);">
                                  <i class="ti ti-file-type-pdf text-danger"></i>
                                  Export as PDF
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <i class="ti ti-file-type-xls text-green"></i>
                                  Export as Excel
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <router-link class="btn btn-primary" to="/crm/create-organisation">
                          <i class="ti ti-square-rounded-plus"></i>Add
                          Organisation
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Search -->

            <!-- Filter -->
            <div class="filter-section filter-flex">
              <div class="sortby-list">
                <ul>
                  <li>
                    <div class="sort-dropdown drop-down">
                      <a href="javascript:void(0);" class="dropdown-toggle" data-bs-toggle="dropdown">
                        <i class="ti ti-sort-ascending-2"></i>Sort
                      </a>
                      <div class="dropdown-menu dropdown-menu-start">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Ascending
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Descending
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Recently Added
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="filter-list">
                <ul>
                  <li>
                    <div class="form-sorts dropdown">
                      <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="true">
                        <i class="ti ti-filter-share"></i>Filter
                      </a>
                      <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
                        <div class="filter-set-view">
                          <div class="filter-set-head">
                            <h4><i class="ti ti-filter-share"></i>Filter</h4>
                          </div>
                          <div class="filter-content-list">
                            <label for="search-status" class="mb-2">Organisation: Status</label>
                            <div class="form-wrap icon-form">
                              <span class="form-icon"><i class="ti ti-search"></i></span>
                              <input v-model="filters.status" type="text" id="search-status" class="form-control" placeholder="Enter Value" />
                            </div>
                          </div>
                          <div class="filter-content-list">
                            <label for="search-vat-group" class="mb-2">Organisation: Vat Group</label>
                            <div class="form-wrap icon-form">
                              <span class="form-icon"><i class="ti ti-search"></i></span>
                              <input v-model="filters.vatGroup" type="text" id="search-vat-group" class="form-control" placeholder="Enter Value" />
                            </div>
                          </div>
                          <div class="filter-content-list">
                            <label for="search-www" class="mb-2">Organisation: www</label>
                            <div class="form-wrap icon-form">
                              <span class="form-icon"><i class="ti ti-search"></i></span>
                              <input v-model="filters.www" type="text" id="search-www" class="form-control" placeholder="Enter Value" />
                            </div>
                          </div>
                          <div class="filter-content-list">
                            <label for="search-fax" class="mb-2">Organisation: Fax</label>
                            <div class="form-wrap icon-form">
                              <span class="form-icon"><i class="ti ti-search"></i></span>
                              <input v-model="filters.fax" type="text" id="search-fax" class="form-control" placeholder="Enter Value" />
                            </div>
                          </div>
                          <div class="filter-content-list">
                            <label for="search-telephone" class="mb-2">Organisation: Telephone</label>
                            <div class="form-wrap icon-form">
                              <span class="form-icon"><i class="ti ti-search"></i></span>
                              <input v-model="filters.telephone" type="text" id="search-telephone" class="form-control" placeholder="Enter Value" />
                            </div>
                          </div>
                          <div class="filter-reset-btns">
                            <div class="row">
                              <div class="col-6">
                                <a href="javascript:void(0);" class="btn btn-light" @click="resetFilters">Reset</a>
                              </div>
                              <div class="col-6">
                                <a href="javascript:void(0);" class="btn btn-primary" @click="applyFilters">Filter</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="view-icons">
                      <router-link to="/crm/organisation-list" class="active">
                        <i class="ti ti-list-tree"></i>
                      </router-link>
                      <router-link to="/crm/companies-grid">
                        <i class="ti ti-grid-dots"></i>
                      </router-link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->

            <!-- Contact List -->
            <div class="table-responsive custom-table">
              <organisations-table :search-query="searchQuery" :filters="appliedFilters" ref="companiesTable" />
            </div>
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="datatable-length"></div>
              </div>
              <div class="col-md-6">
                <div class="datatable-paginate"></div>
              </div>
            </div>
            <!-- /Contact List -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Page Wrapper -->
</template>

<script>
import {
  ref,
  reactive
} from "vue";

export default {
  data() {
    return {
      title: "Organisations",
      searchQuery: "",
      filters: {
        status: "",
        vatGroup: "",
        www: "",
        fax: "",
        telephone: ""
      },
      appliedFilters: {} // New object to store the applied filters
    };
  },
  methods: {
    async searchCompanies() {
      this.$refs.companiesTable.fetchOrganisations();
    },
    resetFilters() {
      this.filters = {
        status: "",
        vatGroup: "",
        www: "",
        fax: "",
        telephone: "",
      };
      this.applyFilters();
    },
    applyFilters() {
      this.appliedFilters = {
        ...this.filters
      };
      this.searchCompanies();
    }
  }
};
</script>
