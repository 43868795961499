<template>
  <!-- Add Contact -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Contact</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="#">
            <div class="accordion-lists" id="list-accord">
              <!-- Basic Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap"
                  data-bs-toggle="collapse"
                  data-bs-target="#basic"
                  ><span><i class="ti ti-user-plus"></i></span>Basic Info</a
                >
                <div
                  class="accordion-collapse collapse show"
                  id="basic"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="profile-upload">
                            <div class="profile-upload-img">
                              <span><i class="ti ti-photo"></i></span>
                              <img src="" class="preview1" />
                              <button type="button" class="profile-remove">
                                <i class="ti ti-x"></i>
                              </button>
                            </div>
                            <div class="profile-upload-content">
                              <label class="profile-upload-btn">
                                <i class="ti ti-file-broken"></i> Upload File
                                <input type="file" class="input-img" />
                              </label>
                              <p>JPG, GIF or PNG. Max size of 800K</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >First Name <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Last Name <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Job Title <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Company Name</label>
                          <vue-select
                            :options="Company"
                            placeholder="Choose"
                            id="companyname"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="d-flex justify-content-between align-items-center">
                            <label class="col-form-label"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div
                              class="status-toggle small-toggle-btn d-flex align-items-center"
                            >
                              <span class="me-2 label-text">Email Opt Out</span>
                              <input type="checkbox" id="user" class="check" checked="" />
                              <label for="user" class="checktoggle"></label>
                            </div>
                          </div>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Phone 1 <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Phone 2</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Fax <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <div class="d-flex align-items-center justify-content-between">
                            <label class="col-form-label">Deals</label>
                            <a href="javascript:;" class="label-add add-popups"
                              ><i class="ti ti-square-rounded-plus"></i>Add New</a
                            >
                          </div>
                          <vue-select
                            :options="Choose"
                            id="collins"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Date of Birth</label>
                          <div class="icon-form-end">
                            <date-picker
                              v-model="startdate"
                              placeholder=""
                              class="datetimepicker form-control"
                              :editable="true"
                              :clearable="false"
                              :input-format="dateFormat"
                            />
                            <span class="form-icon"
                              ><i class="ti ti-calendar-event"></i
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Reviews </label>
                          <div class="icon-form-end">
                            <span class="form-icon"><i class="ti ti-star"></i></span>
                            <input type="text" class="form-control" placeholder="4.2" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Owner</label>
                          <vue-select
                            :options="EspinosaSel"
                            id="espinosasel"
                            placeholder="Jerald"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Tags </label>
                          <vue3-tags-input
                            class="input-tags form-control"
                            type="text"
                            data-role="tagsinput"
                            name="Label"
                            value="Collab"
                            :tags="tags"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Source <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Social"
                            id="social"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Industry <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Retail"
                            id="retail"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Currency <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Language <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Language"
                            id="langenglish"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label"
                            >Description <span class="text-danger">*</span></label
                          >
                          <textarea class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->

              <!-- Address Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#address"
                  ><span><i class="ti ti-map-pin-cog"></i></span>Address Info</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="address"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Street Address </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">City </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">State / Province </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Country</label>
                          <vue-select
                            :options="Country"
                            id="india"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Zipcode </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Address Info -->

              <!-- Social Profile -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#social"
                  ><span><i class="ti ti-social"></i></span>Social Profile</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="social"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Facebook</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Skype </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Linkedin </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Twitter</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Whatsapp</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Instagram</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Social Profile -->

              <!-- Access -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#access"
                  ><span><i class="ti ti-accessible"></i></span>Access</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="access"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="radio-wrap form-wrap">
                          <label class="col-form-label">Visibility</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="public"
                                name="visible"
                              />
                              <label for="public">Public</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="private"
                                name="visible"
                              />
                              <label for="private">Private</label>
                            </div>
                            <div
                              class="radio-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#access_view"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="people"
                                name="visible"
                              />
                              <label for="people">Select People</label>
                            </div>
                          </div>
                        </div>
                        <div class="radio-wrap">
                          <label class="col-form-label">Status</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="active"
                                name="status"
                                checked=""
                              />
                              <label for="active">Active</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="inactive"
                                name="status"
                              />
                              <label for="inactive">Inactive</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Access -->
            </div>
            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
              <a
                href="javascript:;"
                data-bs-toggle="modal"
                data-bs-target="#create_contact"
                class="btn btn-primary"
                >Create</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Contact -->

  <!-- Edit Contact -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Contact</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form @submit.prevent="submitForm">
            <div class="accordion-lists" id="list-accords">
              <!-- Basic Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-basic"
                  ><span><i class="ti ti-user-plus"></i></span>Basic Info</a
                >
                <div
                  class="accordion-collapse collapse show"
                  id="edit-basic"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="profile-upload">
                            <div class="profile-upload-img">
                              <span><i class="ti ti-photo"></i></span>
                              <img src="" class="preview1" />
                              <button type="button" class="profile-remove">
                                <i class="ti ti-x"></i>
                              </button>
                            </div>
                            <div class="profile-upload-content">
                              <label class="profile-upload-btn">
                                <i class="ti ti-file-broken"></i> Upload File
                                <input type="file" class="input-img" />
                              </label>
                              <p>JPG, GIF or PNG. Max size of 800K</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >First Name <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" value="Darlee" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Last Name <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" value="Robertson" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Job Title <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            value="Facility Manager"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Company Name</label>
                          <vue-select
                            :options="Novawave"
                            id="nove"
                            placeholder="NovaWave"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="d-flex justify-content-between align-items-center">
                            <label class="col-form-label"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div
                              class="status-toggle small-toggle-btn d-flex align-items-center"
                            >
                              <span class="me-2 label-text">Email Opt Out</span>
                              <input
                                type="checkbox"
                                id="user2"
                                class="check"
                                checked=""
                              />
                              <label for="user2" class="checktoggle"></label>
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            value="robertson@example.com"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Phone 1 <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" value="1234567890" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Phone 2</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Fax <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <div class="d-flex align-items-center justify-content-between">
                            <label class="col-form-label">Deals</label>
                            <a href="javascript:;" class="label-add add-popups"
                              ><i class="ti ti-square-rounded-plus"></i>Add New</a
                            >
                          </div>
                          <vue-select
                            :options="Choose"
                            id="schum"
                            placeholder="Collins"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Date of Birth</label>
                          <div class="icon-form-end">
                            <date-picker
                              v-model="startdateOne"
                              value="29-02-2020"
                              class="datetimepicker form-control"
                              :editable="true"
                              :clearable="false"
                              :input-format="dateFormat"
                            />
                            <span class="form-icon"
                              ><i class="ti ti-calendar-event"></i
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Reviews </label>
                          <div class="icon-form-end">
                            <span class="form-icon"><i class="ti ti-star"></i></span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="4.2"
                              value="4.2"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Owner</label>
                          <vue-select
                            :options="TheresaSel"
                            id="Theresasel"
                            placeholder="Jerald"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Tags </label>
                          <vue3-tags-input
                            class="input-tags form-control"
                            type="text"
                            id="inputBox"
                            data-role="tagsinput"
                            name="Label"
                            value="Collab"
                            :tags1="tags1"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Source <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Social"
                            id="phonecall"
                            placeholder="Phone Call"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Industry <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Retail"
                            id="insurance"
                            placeholder="Banking"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Currency <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Language <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Language"
                            id="choosehindi"
                            placeholder="English"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label"
                            >Description <span class="text-danger">*</span></label
                          >
                          <textarea class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->

              <!-- Address Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-address"
                  ><span><i class="ti ti-map-pin-cog"></i></span>Address Info</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="edit-address"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Street Address </label>
                          <input
                            type="text"
                            class="form-control"
                            value="22, Ave Street"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">City </label>
                          <input type="text" class="form-control" value="Denver" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">State / Province </label>
                          <input type="text" class="form-control" value="Colorado" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Country</label>
                          <vue-select :options="Country" id="usa" placeholder="USA" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Zipcode </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Address Info -->

              <!-- Social Profile -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-social"
                  ><span><i class="ti ti-social"></i></span>Social Profile</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="edit-social"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Facebook</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Skype </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Linkedin </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Twitter</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Whatsapp</label>
                          <input type="text" class="form-control" value="1234567890" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Instagram</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Social Profile -->

              <!-- Access -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-access"
                  ><span><i class="ti ti-accessible"></i></span>Access</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="edit-access"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="radio-wrap form-wrap">
                          <label class="col-form-label">Visibility</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-public"
                                name="visible"
                              />
                              <label for="edit-public">Public</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-private"
                                name="visible"
                              />
                              <label for="edit-private">Private</label>
                            </div>
                            <div
                              class="radio-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#access_view"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-people"
                                name="visible"
                              />
                              <label for="edit-people">Select People</label>
                            </div>
                          </div>
                        </div>
                        <div class="radio-wrap">
                          <label class="col-form-label">Status</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-active"
                                name="status"
                                checked=""
                              />
                              <label for="edit-active">Active</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-inactive"
                                name="status"
                              />
                              <label for="edit-inactive">Inactive</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Access -->
            </div>

            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
              <a href="javascript:;" class="btn btn-primary">Save Changes</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Contact -->

  <!-- Add New Deals -->
  <div class="toggle-popup2">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Deals</h4>
        <a href="javascript:;" class="sidebar-close2 toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <form @submit.prevent="submitForm" class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Deal Name <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label"
                      >Pipeine <span class="text-danger">*</span></label
                    >
                  </div>
                  <vue-select :options="Pipeline" id="sales" placeholder="Choose" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Status <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Status" id="statusone" placeholder="Choose" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Deal Value<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Currency <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Currency" id="currency" placeholder="Select" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Period" id="chooseperiod" placeholder="Choose" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period Value <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Contact <span class="text-danger">*</span></label
                  ><vue-select
                    :options="ContSelM"
                    id="contselm"
                    placeholder="Darlee Robertson"
                  />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Project <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="DevopsSel"
                    id="devopssel"
                    placeholder="Devops Design"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Due Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateTwo"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Expected Closing Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateThree"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Assignee <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="GuesonSel"
                    id="guesonsel"
                    placeholder="Darlee Robertson"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Follow Up Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateFour"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Source <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Source" id="sourceone" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Tags <span class="text-danger">*</span></label
                  >
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Collab, Rated"
                    :tags2="tags2"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Priority <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Priority" id="priority" placeholder="Select" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                </div>
              </div>
            </div>
            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close2">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add New Deals -->

  <!-- Delete Contact -->
  <div class="modal custom-modal fade" id="delete_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Contacts?</h3>
            <p class="del-info">Are you sure you want to remove contact you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <router-link to="/crm/contacts-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Contact -->

  <!-- Create Contact -->
  <div class="modal custom-modal fade" id="create_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-user-plus"></i>
            </div>
            <h3>Contact Created Successfully!!!</h3>
            <p>View the details of contact, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <router-link to="/crm/contact-details" class="btn btn-primary"
                >View Details</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Contact -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New View</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-wrap">
              <label class="col-form-label">View Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->

  <!-- Access -->
  <div class="modal custom-modal fade" id="access_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Access For</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-wrap icon-form">
              <span class="form-icon"><i class="ti ti-search"></i></span>
              <input type="text" class="form-control" placeholder="Search" />
            </div>
            <div class="access-wrap">
              <ul>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-19.jpg" alt="" />
                      <a href="javascript:;">Darlee Robertson</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-20.jpg" alt="" />
                      <a href="javascript:;">Sharon Roy</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-21.jpg" alt="" />
                      <a href="javascript:;">Vaughan</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-01.jpg" alt="" />
                      <a href="javascript:;">Jessica</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-16.jpg" alt="" />
                      <a href="javascript:;">Carol Thomas</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-22.jpg" alt="" />
                      <a href="javascript:;">Dawn Mercha</a>
                    </span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Access -->
</template>

<script>
import { ref } from "vue";
import Vue3TagsInput from "vue3-tags-input";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
const currentDateThree = ref(new Date());
const currentDateFour = ref(new Date());
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      startdateThree: currentDateThree,
      startdateFour: currentDateFour,
      dateFormat: "dd-MM-yyyy",
      Company: [
        "Choose",
        "NovaWave LLC",
        "BlueSky Industries",
        "SilverHawk",
        "SummitPeak",
        "RiverStone Ventur",
      ],
      Choose: ["Choose", "Collins", "Konopelski", "Adams", "Schumm", "Wisozk"],
      Retail: [
        "Choose",
        "Retail Industry",
        "Banking",
        "Hotels",
        "Financial Services",
        "Insurance",
      ],
      Social: [
        "Choose",
        "Phone Calls",
        "Social Media",
        "Referral Sites",
        "Web Analytics",
        "Previous Purchases",
      ],
      Language: ["Choose", "English", "Arabic", "Chinese", "Hindi"],
      Country: ["Choose", "India", "USA", "France", "UK", "UAE"],
      Novawave: [
        "Choose",
        "NovaWave LLC",
        "BlueSky Industries",
        "SilverHawk",
        "SummitPeak",
        "RiverStone Ventur",
      ],
      Pipeline: ["Choose", "Sales", "Marketing", "Calls"],
      Status: ["Choose", "Open", "Lost", "Won"],
      Currency: ["Select", "$", "€"],
      Period: ["Choose", "Days", "Month"],
      Source: ["Select", "Google", "Social Media"],
      Priority: ["Select", "Highy", "Low", "Medium"],
      EspinosaSel: ["Jerald", "Guillory", "Jami", "Theresa", "Espinosa"],
      TheresaSel: ["Jerald", "Guillory", "Jami", "Theresa", "Espinosa"],
      ContSelM: ["Darlee Robertson", "Sharon Roy", "Vaughan", "Jessica", "Carol Thomas"],
      DevopsSel: ["Devops Design", "MargrateDesign", "UI for Chat", "Web Chat"],
      GuesonSel: ["Darlee Robertson", "Sharon Roy", "Vaughan", "Jessica", "Carol Thomas"],
      tags: ["Collab"],
      tags1: ["Collab"],
      tags2: ["Collab", "Rated"],
      editor: ClassicEditor,
      editorData: 'Description',
      editorConfig: {},
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/crm/contacts-list");
    },
  },
  mounted() {
    if (document.getElementsByClassName("add-popups").length > 0) {
      var addPopups = document.getElementsByClassName("add-popups");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close2");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
