<template>
<index-loader></index-loader>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>

<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <dashboard-header :title="title" />
        <div class="row">
          <div class="col-md-6 d-flex">
            <div class="card flex-fill">
              <div class="card-body p-0">
                <div class="statistic-header p-3 mb-0 border-bottom">
                  <div class="col-md-4 px-2">
                    <div class="form-wrap icon-form mb-0">
                      <span class="form-icon">
                        <i class="ti ti-search"></i>
                      </span>
                      <input type="text" class="form-control" placeholder="Search" v-model="searchQuery" />
                    </div>
                  </div>
                  <div class="col-md-4 px-2">
                    <select class="form-select" v-model="selectedOption">
                      <option v-for="option in searchOptions" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 px-2">
                    <button type="submit" @click="onSearch" class="btn btn-primary p-2">
                      <i class="ti ti-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 d-flex">
            <div class="card flex-fill">
              <div class="card-body p-0">
                <div class="statistic-header p-3 mb-3 border-bottom">
                  <div class="col-6">
                    <h4 class="mb-0">
                      <i class="ion-search me-2"></i> Last search result
                    </h4>
                  </div>
                  <div class="col-6 text-end">
                  </div>
                </div>
                <deals-dashboard-table :searchResults="searchResults"></deals-dashboard-table>
              </div>
            </div>
          </div>
         
          <div class="col-md-6 d-flex" v-if="user.roles[0] === 'company_admin'">
            <div class="card flex-fill">
              <div class="card-body p-0 pb-3">
                <div class="statistic-header p-3 border-bottom mb-3">
                  <div class="col-6">
                    <h4 class="mb-0">
                      <router-link to="/crm/meter-opportunities-list"><i class="ti ti-chart-arcs me-1"></i>
                        Overdue Meter Opportunities
                      </router-link>
                    </h4>
                  </div>
                  <div class="col-6 text-end">
                  </div>
                </div>
                <deals-dashboard-table :overDueOpportunities="overDueOpportunities"></deals-dashboard-table>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex" v-if="user.roles[0] === 'company_admin'">
            <div class="card flex-fill">
              <div class="card-body p-0">
                <div class="statistic-header p-3 mb-0 border-bottom">
                  <div class="col-6">
                    <h4 class="mb-0">
                      <router-link to="/crm/organisation-list">
                        <i class="ti ti-building-community me-1"></i> Organisations
                      </router-link>
                    </h4>
                  </div>
                  <div class="col-6 text-end">
                    <router-link class="btn btn-primary p-1" to="/crm/create-organisation">
                      <i class="ti ti-plus"></i>
                    </router-link>
                  </div>
                </div>
                <deals-dashboard-table :organisations="organisations"></deals-dashboard-table>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex" v-if="user.roles[0] !== 'company_admin'">
            <div class="card flex-fill">
              <div class="card-body p-0 pb-3">
                <div class="statistic-header p-3 mb-3 border-bottom">
                  <div class="col-6">
                    <h4 class="mb-0">
                      <router-link to="/crm/organisation-list">
                        <i class="ti ti-bounce-right me-1"></i> Activities
                      </router-link>
                    </h4>
                  </div>
                  <div class="col-6 text-end">
                    <router-link class="btn btn-primary p-1" to="/crm/create-activity">
                      <i class="ti ti-plus"></i>
                    </router-link>
                  </div>
                </div>
                <deals-dashboard-table :userActivities="userActivities"></deals-dashboard-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Page Wrapper -->
</template>

<script>
import {
  ref
} from "vue";
import {
  onMounted,
  computed
} from "vue";
import {
  useStore, mapGetters
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import { message } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Dashboard",
    };
  },
  computed: {
    ...mapGetters('auth', ['user']), // 'auth' is the namespace of your Vuex module
    
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const searchOptions = ref([{
      value: 'organization',
      text: 'Organisation'
    }, {
      value: 'person',
      text: 'Person'
    }]);

    const organisations = ref([]);
    const overDueOpportunities = ref([]);
    const searchResults = ref([]);
    const userActivities = ref([]);

    const searchQuery = ref('');
    const selectedOption = ref(searchOptions.value[0].value);

    const onSearch = async () => {
        if(searchQuery.value === ''){
            message.error('Please enter a text to search for');
            return;
        }
        fetchData(searchQuery.value, selectedOption.value)
    }
    const fetchData = async (searchQuery, selectedOption) => {
      try {
        // const resData = await store.dispatch("dashboard/getDashboardData");
        const resData = await store.dispatch('dashboard/getDashboardData', {
            search: searchQuery,
            type: selectedOption,
        })
        if (resData.data && resData.data.organizations) {
          organisations.value = resData.data.organizations;
        }
        if (resData.data && resData.data.organizations) {
          overDueOpportunities.value = resData.data.overdue_opportunities;
        }
        if(resData.data && resData.data.search_results){
            searchResults.value = resData.data.search_results.data;
        }
        if(resData.data && resData.data.activities){
            userActivities.value = resData.data.activities;
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
   


    onMounted(async () => {
      fetchData();
    });

    return {
        onSearch,
        userActivities,
      searchQuery,
      selectedOption,
      organisations,
      overDueOpportunities,
      searchResults,
      searchOptions,
      fetchData,
    };
  },
};
</script>
