<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="contact-head">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <ul class="contact-breadcrumb">
                <li>
                  <!-- <router-link to="/crm/meter-opportunities-list"><i class="ti ti-arrow-narrow-left"></i>Meter Opportunities
                    </router-link> -->
                  <router-link to='/crm/contracts-list'>
                    <i class="ti ti-arrow-narrow-left"></i>
                    Contracts
                  </router-link>
                </li>
                <li>Edit Contract</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-body settings-form">
                <Form @submit="updateOpportunity">
                  <div class="settings-sub-header">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <h4> Contract - {{ formData.contract_number }} | Edit</h4>
                      </div>
                      <div class="col-6 text-end" v-if="isEditing">
                        <router-link class="btn btn-primary" :to="{ path: '/crm/create-person', query: { org_id: org_id } }">
                          <i class="ti ti-square-rounded-plus me-1"></i>Add
                          Person
                        </router-link>
                      </div>
                    </div>
                    <div class="row organisation-details-section mt-2" v-if="Object.keys(org_details).length">
                      <div class="col-md-12">
                        <h4 class="d-flex align-items-center my-2"><a class="website me-2" :href="org_details.www">www</a>{{ org_details.name }}</h4>
                        <p title="Postcode">{{ org_details.postcode }}</p>
                        <p title="Telephone">T: {{ org_details.telephone }}</p>
                        <p class="text-danger" title="Alert text"><i class="typcn typcn-info me-1"></i>{{ org_details.alert_text }}</p>
                        <p title="Address">{{ org_details.city +', ' + org_details.state +', ' + country.country_name  }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="pro-create">
                    <div class="row contract-won-row mx-1 py-2">
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-wrap">
                              <label class="col-form-label">Date of Sale</label>
                              <div class="icon-form">
                                <date-picker placeholder="" class="datetimepicker form-control" v-model="formData.opportunity.date_of_sale" :editable="true" :clearable="false" />
                                <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">SC Forecast Total Commission</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.sc_forecast_total_commission" @keypress="$utils.allowOnlyNumbers" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['sc_forecast_total_commission'] && apiErrors['sc_forecast_total_commission'].length">
                            {{ apiErrors['sc_forecast_total_commission'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Quoted Annual Consumption</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.quoted_annual_consumption" @keypress="$utils.allowOnlyNumbers" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['quoted_annual_consumption'] && apiErrors['quoted_annual_consumption'].length">
                            {{ apiErrors['quoted_annual_consumption'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Quotation</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.quotation" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Meter Opportunity Number [Auto]</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.meter_opportunity_nr_auto" @keypress="$utils.allowOnlyNumbers" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['meter_opportunity_nr_auto'] && apiErrors['meter_opportunity_nr_auto'].length">
                            {{ apiErrors['meter_opportunity_nr_auto'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Main product/service</label>
                          <Field as="select" name="main_production_service" class="form-select" v-model="formData.opportunity.main_production_service">
                            <option value="">-----Select-----</option>
                            <option v-for="res in options.op_product" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Sales responsible </label>
                          <Field as="select" name="sales_responsible" class="form-select" v-model="formData.opportunity.sales_responsible">
                            <option value="">-----Select-----</option>
                            <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Referred By</label>
                          <Field as="select" name="referred_by" class="form-select" v-model="formData.opportunity.referred_by">
                            <option value="">-----Select-----</option>
                            <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <!-- <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Pipeline level <span class="text-danger">*</span></label>
                          <Field as="select" name="revert_to_meter_opportunity" class="form-select" v-model="formData.revert_to_meter_opportunity" @change="fetchWinProb">
                            <option value="">-----Select-----</option>
                            <option v-for="res in pipelineLevels" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div> -->
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Contract description </label>
                          <Field name="contract_description" as="input" type="text" class="form-control" v-model="formData.contract_description" />
                          <ErrorMessage name="contract_description" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Overall status</label>
                          <Field as="select" name="overall_status" class="form-select" v-model="formData.overall_status">
                            <option value="">-----Select-----</option>
                            <option v-for="res in options.contract_overall_status" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Overall risk</label>
                          <Field as="select" name="overall_risk" class="form-select" v-model="formData.overall_risk">
                            <option value="">-----Select-----</option>
                            <option v-for="res in options.contract_overall_risk" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Sales contact</label>
                          <Field as="select" name="sales_contact" class="form-select" v-model="formData.opportunity.sales_contact">
                            <option value="">-----Select-----</option>
                            <option v-for="res in orgPersons" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Contract contact</label>
                          <Field as="select" name="contract_contact" class="form-select" v-model="formData.contract_contact">
                            <option value="">-----Select-----</option>
                            <option v-for="res in orgPersons" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Contract responsible </label>
                          <Field as="select" name="contract_responsible" class="form-select" v-model="formData.contract_responsible">
                            <option value="">-----Select-----</option>
                            <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Contract number [Auto]</label>
                          <input type="text" class="form-control" v-model="formData.contract_number" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['contract_number'] && apiErrors['contract_number'].length">
                            {{ apiErrors['contract_number'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*MPAN / MPR N</label>
                          <Field name="mpan_mpr_no" as="input" type="text" class="form-control" v-model="formData.opportunity.mpan_mpr_no" />
                          <ErrorMessage name="mpan_mpr_no" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['name'] && apiErrors['name'].length">
                            {{ apiErrors['name'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Current Supplier </label>
                          <Field as="select" name="supplier_id" class="form-select" v-model="formData.opportunity.supplier_id">
                            <option value="">-----Select-----</option>
                            <option v-for="res in supplierOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">SPID</label>
                          <!-- <input type="text" class="form-control" v-model="formData.name" /> -->
                          <Field name="spid" as="input" type="text" class="form-control" v-model="formData.opportunity.spid" />
                          <ErrorMessage name="spid" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['name'] && apiErrors['name'].length">
                            {{ apiErrors['name'][0]}}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Contract End Date</label>
                          <div class="icon-form">
                            <date-picker placeholder="" class="datetimepicker form-control" v-model="formData.opportunity.contract_end_date" :editable="true" :clearable="false" :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>
                      <h4 class="mb-2 py-2 text-danger">New Supplier</h4>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">New Supplier </label>
                          <Field as="select" name="new_supplier_id" class="form-select" v-model="formData.opportunity.new_supplier_id">
                            <option value="">-----Select-----</option>
                            <option v-for="res in supplierOptions" :key="res.id" :value="res.id">
                              {{ res.name }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Contract Start Date</label>
                          <div class="icon-form">
                            <date-picker placeholder="" class="datetimepicker form-control" v-model="formData.opportunity.contract_start_date" :editable="true" :clearable="false" :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Sales Type</label>
                          <Field as="select" name="sale_type" class="form-select" v-mode="formData.opportunity.sale_type">
                            <option value="">-----Select-----</option>
                            <option v-for="res in options.op_sales_type" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">COT</label>
                          <Field as="select" name="cot" class="form-select" v-model="formData.opportunity.cot">
                            <option value="">-----Select-----</option>
                            <option v-for="res in yesNoOptions" :key="res" :value="res">
                              {{ res }}
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">COT Date</label>
                          <div class="icon-form">
                            <date-picker placeholder="" class="datetimepicker form-control" v-model="formData.opportunity.cot_date" :editable="true" :clearable="false" :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">NIC</label>
                          <Field as="select" name="nic" class="form-select" v-model="formData.opportunity.nic">
                            <option value="">-----Select-----</option>
                            <option v-for="res in yesNoOptions" :key="res" :value="res">
                              {{ res }}
                            </option>
                          </Field>
                        </div>
                      </div>

                      <h4 class="mb-2 py-2 text-indigo">*Supply Address</h4>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Building Name</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.supply_address.building_name" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Street </label>
                          <input type="text" class="form-control" v-model="formData.opportunity.supply_address.street" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*City</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.supply_address.city" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*County</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.supply_address.county" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">*Postcode</label>
                          <input type="text" class="form-control" v-model="formData.opportunity.supply_address.postcode" />
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Comment </label>
                          <textarea class="form-control" rows="1" v-model="formData.opportunity.comment"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row  mt-4">
                    <div class="col-md-4">
                      <div class="form-wrap mb-0" style="border: 1px solid #e1e1e1;">
                        <h4 class="p-2">Logs</h4>
                        <p class="col-form-label p-2" style="background-color: #eff1f0;">Add log entry</p>
                        <textarea class="form-control" rows="3" v-model="formData.opportunity.content"></textarea>
                      </div>
                      <div class="row my-3">
                        <div class="col-md-12">
                          <div class="form-wrap mb-0 shadow" style="border: 1px solid #e1e1e1;">
                            <p class="col-form-label p-2" style="background-color: #eff1f0;">Logs</p>
                            <div class="row" v-for="log in logs">
                              <div class="col-12">
                                <p class="p-2" style="border-bottom: 1px solid #e1e1e1;">{{ log.content }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="shadow p-3" style="border: 1px solid #e1e1e1;">
                        <h4 class="mb-3 text-indigo">SC Forecast Total Commission - Details</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">SC Forecast or Amended Annual Consumption</label>
                              <input name="sc_forecast_or_amended_annual_consumption" type="text" class="form-control" v-model="formattedData.sc_forecast_or_amended_annual_consumption" @keypress="$utils.allowOnlyNumbers" @blur="updateFormData('sc_forecast_or_amended_annual_consumption', formattedData.sc_forecast_or_amended_annual_consumption)" @focus="formattedData.sc_forecast_or_amended_annual_consumption = formatNumber(formData.opportunity.forecast_commission.sc_forecast_or_amended_annual_consumption)" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Cancelled Commission</label>
                              <input name="cancelled_commission" type="text" class="form-control" v-model="formattedData.cancelled_commission" @blur="updateFormData('cancelled_commission', formattedData.cancelled_commission)" @focus="formattedData.cancelled_commission = formatNumber(formData.opportunity.forecast_commission.cancelled_commission)" @keypress="$utils.allowOnlyNumbers" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">*SC Forecast or Amended Total Commission</label>
                              <input name="sc_corecast_or_amended_total_commission" type="text" class="form-control" v-model="formattedData.sc_corecast_or_amended_total_commission" @blur="updateFormData('sc_corecast_or_amended_total_commission', formattedData.sc_corecast_or_amended_total_commission)" @focus="formattedData.sc_corecast_or_amended_total_commission = formatNumber(formData.opportunity.forecast_commission.sc_corecast_or_amended_total_commission)" @keypress="$utils.allowOnlyNumbers" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">SC Forecast or Amended First Payment</label>
                              <input name="sc_forecast_or_amended_first_payment" type="text" class="form-control" v-model="formattedData.sc_forecast_or_amended_first_payment" @blur="updateFormData('sc_forecast_or_amended_first_payment', formattedData.sc_forecast_or_amended_first_payment)" @focus="formattedData.sc_forecast_or_amended_first_payment = formatNumber(formData.opportunity.forecast_commission.sc_forecast_or_amended_first_payment)" @keypress="$utils.allowOnlyNumbers" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">SC Forecast or Amended Residual Payment</label>
                              <input name="sc_forecast_or_amended_residual_payment" type="text" class="form-control" v-model="formattedData.sc_forecast_or_amended_residual_payment" @blur="updateFormData('sc_forecast_or_amended_residual_payment', formattedData.sc_forecast_or_amended_residual_payment)" @focus="formattedData.sc_forecast_or_amended_residual_payment = formatNumber(formData.opportunity.forecast_commission.sc_forecast_or_amended_residual_payment)" @keypress="$utils.allowOnlyNumbers" />

                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">*U4B Uplift</label>
                              <input name="u4b_uplift" type="text" class="form-control" v-model="formattedData.u4b_uplift" @blur="updateFormData('u4b_uplift', formattedData.u4b_uplift)" @focus="formattedData.u4b_uplift = formatNumber(formData.opportunity.forecast_commission.u4b_uplift)" @keypress="$utils.allowOnlyNumbers" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Final Contract Consumption</label>
                              <input name="final_contract_consumption" type="text" class="form-control" v-model="formattedData.final_contract_consumption" @blur="updateFormData('final_contract_consumption', formattedData.final_contract_consumption)" @focus="formattedData.final_contract_consumption = formatNumber(formData.opportunity.forecast_commission.final_contract_consumption)" @keypress="$utils.allowOnlyNumbers" />

                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Failed Credit</label>
                              <input name="failed_credit" type="text" class="form-control" v-model="formattedData.failed_credit" @blur="updateFormData('failed_credit', formattedData.failed_credit)" @focus="formattedData.failed_credit = formatNumber(formData.opportunity.forecast_commission.failed_credit)" @keypress="$utils.allowOnlyNumbers" />

                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Final Contract Commission</label>
                              <input name="final_contract_commission" type="text" class="form-control" v-model="formattedData.final_contract_commission" @blur="updateFormData('final_contract_commission', formattedData.final_contract_commission)" @focus="formattedData.final_contract_commission = formatNumber(formData.opportunity.forecast_commission.final_contract_commission)" @keypress="$utils.allowOnlyNumbers" />

                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Actual First Payment</label>

                              <input name="actual_first_payment" type="text" class="form-control" v-model="formattedData.actual_first_payment" @blur="updateFormData('actual_first_payment', formattedData.actual_first_payment)" @focus="formattedData.actual_first_payment = formatNumber(formData.opportunity.forecast_commission.actual_first_payment)" @keypress="$utils.allowOnlyNumbers" />

                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Actual Residual Payment</label>

                              <input name="actual_residual_payment" type="text" class="form-control" v-model="formattedData.actual_residual_payment" @blur="updateFormData('actual_residual_payment', formattedData.actual_residual_payment)" @focus="formattedData.actual_residual_payment = formatNumber(formData.opportunity.forecast_commission.actual_residual_payment)" @keypress="$utils.allowOnlyNumbers" />

                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">Forecast First Payment Amount</label>

                              <input name="forecast_first_payment_amount" type="text" class="form-control" v-model="formattedData.forecast_first_payment_amount" @blur="updateFormData('forecast_first_payment_amount', formattedData.forecast_first_payment_amount)" @focus="formattedData.forecast_first_payment_amount = formatNumber(formData.opportunity.forecast_commission.forecast_first_payment_amount)" @keypress="$utils.allowOnlyNumbers" />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-button text-end mt-4">
                    <router-link class="btn btn-light" to="/crm/contracts-list">Cancel</router-link>
                    <button type="submit" class="btn btn-primary">
                      Update
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "yyyy-MM-dd",
      yesNoOptions: ["YES", "NO"],
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const org_id = ref(null);
    const isEditable = ref(true);
    const isDateEditable = ref(true);
    const isShowWon = ref(false);
    const isLost = ref(false);

    const isKanban = ref(false);

    if (route.query.kanban) {
      isKanban.value = true;
    }

    const apiErrors = computed(() => store.getters['opportunity/getapiErrors']);
    const pipelineLevels = computed(() => store.getters['opportunity/getPipelineLevels']);
    const options = computed(() => store.getters['opportunity/getoppDropdowns']);
    const supplierOptions = computed(() => store.getters['opportunity/getoppSuppliers']);
    const opportunityData = computed(() => store.getters['opportunity/getoppViewData']);
    const orgPersons = computed(() => store.getters['opportunity/getPersonsData']);

    const saleResponsibleOptions = computed(() => store.getters['organisation/getfetchAssignedTo']);

    // Computed property to format the date
    const formattedNextUpdateLatest = computed(() => {
      const date = new Date(formData.value.next_update_latest);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    });

    const formattedData = computed(() => {
      const {
        forecast_commission
      } = formData.value.opportunity;
      return {
        sc_forecast_or_amended_annual_consumption: formatNumber(forecast_commission.sc_forecast_or_amended_annual_consumption),
        cancelled_commission: formatNumber(forecast_commission.cancelled_commission),
        sc_corecast_or_amended_total_commission: formatNumber(forecast_commission.sc_corecast_or_amended_total_commission),
        sc_forecast_or_amended_first_payment: formatNumber(forecast_commission.sc_forecast_or_amended_first_payment),
        sc_forecast_or_amended_residual_payment: formatNumber(forecast_commission.sc_forecast_or_amended_residual_payment),
        u4b_uplift: formatNumber(forecast_commission.u4b_uplift),
        final_contract_consumption: formatNumber(forecast_commission.final_contract_consumption),
        failed_credit: formatNumber(forecast_commission.failed_credit),
        final_contract_commission: formatNumber(forecast_commission.final_contract_commission),
        actual_first_payment: formatNumber(forecast_commission.actual_first_payment),
        actual_residual_payment: formatNumber(forecast_commission.actual_residual_payment),
        forecast_first_payment_amount: formatNumber(forecast_commission.forecast_first_payment_amount),
      };
    });
    // Watch for changes to formatted data and update the raw data
    const updateFormData = (key, value) => {
      formData.value.opportunity.forecast_commission[key] = removeFormatting(value);
    };

    const schema = Yup.object().shape({
      revert_to_meter_opportunity: Yup.string()
        .required("Pipeline level is required"),
    });
    const isEditing = ref(false);
    if (route.query.orgId) {
      org_id.value = route.query.orgId;
      isEditing.value = true;
    }
    const formData = ref({
      id: null,
      contract_contact: null,
      contract_description: "",
      contract_number: "",
      contract_responsible: null,
      opportunity: {
        contract_end_date: null,
        contract_start_date: null,
        cot: null,
        cot_date: null,
        date_of_sale: "",
        new_supplier_id: null,
        description: "",
        main_production_service: null,
        meter_opportunity_nr_auto: null,
        mpan_mpr_no: null,
        nic: null,
        organization_id: 2,
        quotation: "",
        quoted_annual_consumption: 0.00,
        referred_by: null,
        sale_type: null,
        sales_responsible: 2,
        sc_forecast_total_commission: 0.00,
        spid: null,
        supplier_id: null,
        forecast_commission: {
          sc_forecast_or_amended_annual_consumption: 0.00,
          final_contract_consumption: 0.00,
          cancelled_commission: 0.00,
          failed_credit: 0.00,
          sc_corecast_or_amended_total_commission: 0.00,
          final_contract_commission: 0.00,
          sc_forecast_or_amended_first_payment: 0.00,
          actual_first_payment: 0.00,
          sc_forecast_or_amended_residual_payment: 0.00,
          actual_residual_payment: 0.00,
          u4b_uplift: 0.00,
          forecast_first_payment_amount: 0.00,
        },
        supply_address: {
          building_name: '', //string input
          street: '', //string input
          city: '', //string input
          county: '', //string input
          postcode: '', //string input
        },
      },
      overall_risk: null,
      overall_status: "",
      revert_to_meter_opportunity: null,
    });
    const logs = ref([]);

    const org_details = ref({});
    const country = ref({});
    const addDays = ref(['0', '1', '4', '5', '6', '7', '14', '21']);
    const addMonths = ref(['1', '2', '3', '4', '5', '6', '9', '12']);
    const fetchContrat = async (id) => {
      try {
        const data = await store.dispatch('contract/fetchContractData', id);
        console.log(data, 'data');
        
        logs.value = data.opportunities_logs;
        // org_details.value = data.organization;
        // country.value = data.organization.country
        Object.assign(formData.value, data);
        Object.assign(formData.value.opportunity, data.opportunity);
        Object.assign(formData.value.opportunity.forecast_commission, data.opportunity.forecast_commission);
        Object.assign(formData.value.opportunity.supply_address, data.opportunity.supply_address);
        // Object.assign(formData.value.contracts, data.contracts);

        // if (data.probability_disabled === 1) {
        //   isEditable.value = false;
        // } else {
        //   isEditable.value = true;
        // }
        // if (data.date_disabled === 1) {
        //   isDateEditable.value = false;
        // } else {
        //   isDateEditable.value = true;
        // }
        // if (data.pipeline_level === 13) {
        //   isShowWon.value = true;
        // } else {
        //   isShowWon.value = false;
        // }
        // if (data.pipeline_level === 14) {
        //   isLost.value = true;
        // } else {
        //   isLost.value = false;
        // }

        // if (data.next_update_latest) {
        //   formData.value.next_update_latest = new Date(data.next_update_latest);
        // }
        if (data.opportunity.date_of_sale) {
          formData.value.opportunity.date_of_sale = new Date(data.opportunity.date_of_sale);
        }
        if (data.opportunity.contract_end_date) {
          formData.value.opportunity.contract_end_date = new Date(data.opportunity.contract_end_date);
        }
        if (data.opportunity.cot_date) {
          formData.value.opportunity.cot_date = new Date(data.opportunity.cot_date);
        }
        if (data.opportunity.contract_start_date) {
          formData.value.opportunity.contract_start_date = new Date(data.opportunity.contract_start_date);
        }
        formData.value.content = ''
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    // Utility functions for formatting and removing formatting
    const formatNumber = (value) => {
      const number = parseFloat(value);
      if (!isNaN(number)) {
        return number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return '0.00';
    };

    const removeFormatting = (value) => {
      const numberValue = value.replace(/,/g, ''); // Remove commas
      return parseFloat(numberValue) || 0;
    };
    // Function to handle change
    const fetchWinProb = async () => {
      const selectedPipelineLevelId = formData.value.pipeline_level;
      const selectedOppId = formData.value.id;

      const data = await store.dispatch('opportunity/fetchWinProb', {
        pipelineID: selectedPipelineLevelId,
        selectedOppId: selectedOppId
      });
      if (selectedPipelineLevelId === 13) {
        isShowWon.value = true;
      } else {
        isShowWon.value = false;
      }
      if (selectedPipelineLevelId === 14) {
        isLost.value = true;
      } else {
        isLost.value = false;
      }
      if (data && data.data) {
        formData.value.win_probability = data.data.win_prob_percentage;
        formData.value.next_update_latest = new Date(data.data.next_update_latest)
        if (data.data.probability_disabled === 1) {
          isEditable.value = false;
        } else {
          isEditable.value = true;
        }
        if (data.data.date_disabled === 1) {
          isDateEditable.value = false;
        } else {
          isDateEditable.value = true;
        }
      }
    };
    // Function to add days or months
    const addToDate = (field, type, value) => {
      // Check if the field is valid
      if (['date_of_sale', 'next_update_latest'].includes(field)) {
        const numericValue = parseInt(value); // Convert the string value to an integer

        // If value is 0, set the date to today's date
        if (numericValue === 0) {
          formData.value[field] = new Date(); // Set to today's date
        } else {
          // Get the current date for the field (or today's date if the field is not set)
          const currentDate = new Date(formData.value[field] || new Date());

          // Add days or months based on the type
          if (type === 'days') {
            currentDate.setDate(currentDate.getDate() + numericValue); // Add days
          } else if (type === 'months') {
            currentDate.setMonth(currentDate.getMonth() + numericValue); // Add months
          }

          // Update the corresponding field in formData
          formData.value[field] = new Date(currentDate);
        }
      }
    };

    const updateOpportunity = async () => {
      const response = await store.dispatch('contract/updateContract', formData.value);
      if (response) {
        router.push("/crm/contracts-list");
      }
    };
    onMounted(async () => {
      const contractId = route.query.contract_Id;
      const organisation_id = route.query.org_Id;
      const territory_id = route.query.territory_id;
      if (contractId) {
        fetchContrat(contractId);

      }
      if (organisation_id) {
        store.dispatch('opportunity/fetchPersons', organisation_id);
      }
      store.dispatch('organisation/fetchAssignedToUsers', territory_id)
      store.dispatch('opportunity/fetchPipelines');
      store.dispatch('opportunity/fetchOpportunityOptions');
    });

    return {
      schema,
      formData,
      org_id,
      updateOpportunity,
      pipelineLevels,
      options,
      supplierOptions,
      orgPersons,
      opportunityData,
      org_details,
      country,
      isEditing,
      saleResponsibleOptions,
      addDays,
      addMonths,
      addToDate,
      apiErrors,
      logs,
      formattedNextUpdateLatest,
      fetchWinProb,
      isEditable,
      isKanban,
      formattedData,
      formatNumber,
      updateFormData,
      removeFormatting,
      isDateEditable,
      isShowWon,
      isLost
    };
  },
};
</script>

<style lang="scss" scoped>
.contract-won-row {
  background-color: #5cb85c21;
}

.contract-lost-row {
  background-color: #ff00001a;
}

.website {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #00918e;
  color: #fff;
}

.add-cursor {
  cursor: pointer;
  font-weight: 400;
}

.disabled {
  background-color: #F6F6F6;
}
</style>
