<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Card -->
          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Proposals"
                      />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <div class="export-dropdwon">
                            <a
                              href="javascript:void(0);"
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              ><i class="ti ti-package-export"></i>Export</a
                            >
                            <div class="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-pdf text-danger"></i>Export
                                    as PDF</a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-xls text-green"></i>Export
                                    as Excel
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add Proposals</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <div class="filter-section filter-flex">
                <div class="sortby-list">
                  <ul>
                    <li>
                      <div class="sort-dropdown drop-down task-drops">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          >All Proposals
                        </a>
                        <div class="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-dots-vertical"></i>All Proposals
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-dots-vertical"></i>Accepted
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-dots-vertical"></i>Declined
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-dots-vertical"></i>Draft
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-dots-vertical"></i>Deleted
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="sort-dropdown drop-down">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          ><i class="ti ti-sort-ascending-2"></i>Sort
                        </a>
                        <div class="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Ascending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Descending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Recently Added
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form-wrap icon-form">
                        <span class="form-icon"><i class="ti ti-calendar"></i></span>
                        <input
                          type="text"
                          class="form-control bookingrange"
                          ref="dateRangeInput"
                          placeholder=""
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="filter-list">
                  <ul class="justify-content-md-end">
                    <li>
                      <div class="form-sorts dropdown">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          ><i class="ti ti-filter-share"></i>Filter</a
                        >
                        <div
                          class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end"
                        >
                          <div class="filter-set-view">
                            <div class="filter-set-head">
                              <h4><i class="ti ti-filter-share"></i>Filter</h4>
                            </div>
                            <div class="accordion" id="accordionExample">
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="#"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    >Subject</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse show"
                                  id="collapseTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Name"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>SEO Proposals</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Web Design</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Logo & Branding</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Development</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Logo</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="#"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseclient"
                                    aria-expanded="false"
                                    aria-controls="collapseclient"
                                    >Sent to</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapseclient"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Client"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>NovaWave LLC</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Redwood Inc</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>HarborVie w</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>CoastalStar Co.</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>RiverStone Ventur</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="#"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#owner"
                                    aria-expanded="false"
                                    aria-controls="owner"
                                    >Date of Proposals</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="owner"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 May 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>16 Jan 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>17 Sep 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>18 May 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>19 Aug 2024</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="#"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#Status"
                                    aria-expanded="false"
                                    aria-controls="Status"
                                    >Open till</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="Status"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Date"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Aug 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Sep 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Nov 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Jun 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Oct 2024</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="#"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                    >Project</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapseThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Truelysell</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Dreamsports</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Best@laundry</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Doccure</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="#"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsestage"
                                    aria-expanded="false"
                                    aria-controls="collapsestage"
                                    >Created Date</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapsestage"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>21 May 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Apr 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>12 Mar 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Feb 2024</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>15 Jan 2024</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="filter-reset-btns">
                              <div class="row">
                                <div class="col-6">
                                  <a href="#" class="btn btn-light">Reset</a>
                                </div>
                                <div class="col-6">
                                  <router-link
                                    to="/crm/proposals-grid"
                                    class="btn btn-primary"
                                    >Filter</router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="view-icons">
                        <router-link to="/crm/proposals-list"
                          ><i class="ti ti-list-tree"></i
                        ></router-link>
                        <router-link to="/crm/proposals-grid"
                          ><i class="ti ti-grid-dots"></i
                        ></router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Filter -->

              <!-- Contact Grid -->
              <div class="row">
                <div
                  class="col-xxl-3 col-xl-4 col-md-6"
                  v-for="item in ProposalGrid"
                  :key="item.id"
                >
                  <div class="contact-grid proposals-grid">
                    <div class="grid-head">
                      <div class="users-profile">
                        <span class="badge badge-tag badge-purple-light">#1493016</span>
                      </div>
                      <div class="dropdown table-action">
                        <a
                          href="#"
                          class="action-icon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item edit-popup" href="#"
                            ><i class="ti ti-edit text-blue"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_proposals"
                            ><i class="ti ti-trash text-danger"></i> Delete</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-clipboard-copy text-green"></i> View
                            Proposal</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-checks text-success"></i> Mark As Accepted</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-file text-tertiary"></i> Mark as Draft</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-sticker text-blue"></i> Mark ad Declined</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-subtask text-pink"></i> Convert to
                            estimate</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-file-invoice text-tertiary"></i> Convert to
                            Invoice</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-printer text-info"></i> Print</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="grid-body">
                      <div class="proposals-head">
                        <div class="proposals-title">
                          <h4>SEO Proposal</h4>
                          <p>Project : Truelysell</p>
                        </div>
                        <div class="proposals-badge">
                          <span :class="item.BadgeClass">{{ item.Badge }}</span>
                        </div>
                      </div>
                      <div class="address-info">
                        <p><i class="ti ti-moneybag"></i>Total Value : $2,04,214</p>
                        <p><i class="ti ti-calendar-event"></i>Date : 25 May 2024</p>
                        <p><i class="ti ti-calendar-stats"></i>Open till : 31 Jun 2024</p>
                      </div>
                    </div>
                    <div class="grid-footer d-flex justify-content-between">
                      <div class="star-user">
                        <a href="javascript:void(0);" class="avatar">
                          <img :src="require(`@/assets/img/icons/${item.Image}`)" alt="img" />
                        </a>
                        <div class="star-user-title">
                          <span>Sent to</span>
                          <a href="javascript:void(0);" class="start-title"
                            >NovaWave LLC</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Contact Grid -->

              <div class="load-btn text-center">
                <a href="javascript:void(0);" class="btn btn-primary"
                  >Load More Contacts<i class="ti ti-loader"></i
                ></a>
              </div>
            </div>
          </div>
          <!-- /Card -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <proposals-modal></proposals-modal>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
import ProposalGrid from "@/assets/json/proposals-grid.json";

export default {
  data() {
    return {
      ProposalGrid: ProposalGrid,
      title: "Proposals",
    };
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
    if (document.getElementsByClassName("add-popups").length > 0) {
      var addPopupsOne = document.getElementsByClassName("add-popups");
      for (var k = 0; k < addPopupsOne.length; k++) {
        addPopupsOne[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesOne = document.getElementsByClassName("sidebar-close2");
      for (var l = 0; l < sidebarClosesOne.length; l++) {
        sidebarClosesOne[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopupsTwo = document.getElementsByClassName("edit-popup");
      for (var m = 0; m < addPopupsTwo.length; m++) {
        addPopupsTwo[m].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesTwo = document.getElementsByClassName("sidebar-close1");
      for (var n = 0; n < sidebarClosesTwo.length; n++) {
        sidebarClosesTwo[n].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
