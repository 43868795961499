<template>
  <!-- Add New Proposal -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Proposal</h4>
        <a href="#" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Subject <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Date<span class="text-danger"> *</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdate"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-event"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Open Till <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateOne"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-event"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Client <span class="text-danger">*</span></label
                  >
                  <vue-select :options="NovaWaPro" id="novawapro" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label"
                      >Project<span class="text-danger">*</span></label
                    >
                    <a href="#" class="label-add add-popups"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select :options="TrueSel" id="truesel" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Related to <span class="text-danger">*</span></label
                  >
                  <vue-select :options="DreamSel" id="dreamssele" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label"
                      >Deals<span class="text-danger">*</span></label
                    >
                    <a href="#" class="label-add add-popups"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select :options="DoccSel" id="doccusele" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Currency</label>
                  <vue-select :options="MedSelect" id="medselect" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Status <span class="text-danger">*</span></label
                  >
                  <vue-select :options="ActivSel" id="activsel" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Assigned to <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="InActivSel"
                    id="inactivsel"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Attachment <span class="text-danger">*</span></label
                  >
                  <div class="drag-attach">
                    <input type="file" />
                    <div class="img-upload">
                      <i class="ti ti-file-broken"></i>Upload File
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label">Tags </label>
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Tag1"
                    :tags="tags"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="#" class="btn btn-light sidebar-close">Cancel</a>
            <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#create_proposals"
              >Create</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Proposal -->

  <!-- Edit Proposal -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Proposal</h4>
        <a href="#" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Subject <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" value="Web design Proposal" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Date<span class="text-danger"> *</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateTwo"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-event"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Open Till <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateThree"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-event"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Client <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="EditNovaWaPro"
                    id="editnovawapro"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label"
                      >Project<span class="text-danger">*</span></label
                    >
                    <a href="#" class="label-add add-popups"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select
                    :options="EditTrueSel"
                    id="edittruesel"
                    placeholder="Truelysell"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Related to <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="EditDreamSel"
                    id="editdreamssele"
                    placeholder="Deals"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label"
                      >Deals<span class="text-danger">*</span></label
                    >
                    <a href="#" class="label-add add-popups"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select
                    :options="EditDoccSel"
                    id="editdoccusele"
                    placeholder="Collins"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Currency</label>
                  <vue-select
                    :options="EditMedSelect"
                    id="editmedselect"
                    placeholder="$"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Status <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="EditActivSel"
                    id="editactivsel"
                    placeholder="Open"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Assigned to <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="EditInActivSel"
                    id="editinactivsel"
                    placeholder="Darlee Robertson"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Attachment <span class="text-danger">*</span></label
                  >
                  <div class="drag-attach">
                    <input type="file" />
                    <div class="img-upload">
                      <i class="ti ti-file-broken"></i>Upload File
                    </div>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Uploaded Files</label>
                  <div class="upload-file upload-list">
                    <div>
                      <h6>Web_Design_Contract.pdf</h6>
                      <p>1.2 MB</p>
                    </div>
                    <a href="javascript:void(0);" class="text-danger"
                      ><i class="ti ti-trash-x"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label">Tags </label>
                  <input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Tag1, Tag2, Tag3"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="#" class="btn btn-light sidebar-close1">Cancel</a>
            <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#create_project"
              >Create</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Proposal -->

  <!-- Delete Proposals -->
  <div class="modal custom-modal fade" id="delete_proposals" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Proposals?</h3>
            <p class="del-info">
              Are you sure you want to remove proposals you selected.
            </p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/proposals-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Proposals -->

  <!-- Create Project -->
  <div class="modal custom-modal fade" id="create_proposals" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-atom-2"></i>
            </div>
            <h3>Proposals Created Successfully!!!</h3>
            <p>View the details of proposals, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <a href="#" class="btn btn-primary">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Project -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New View</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="proposals-list">
            <div class="form-wrap">
              <label class="col-form-label">View Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->

  <div class="toggle-popup2">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Proposal <span class="badge badge-purple-light ms-2">#1493016</span></h4>
        <div class="d-flex align-items-center">
          <div class="toggle-header-popup">
            <div class="dropdown table-action">
              <a
                href="#"
                class="btn btn-dropdowns dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Convert
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#"> Estimate </a>
                <a class="dropdown-item" href="#"> Invoice </a>
              </div>
            </div>
          </div>
          <a href="#" class="sidebar-close2 toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="toggle-content-view">
          <h5>Web Design</h5>
          <p>
            Where's the other side. The further off from England the nearer is to find my
            way into a tree. By the use of a well--' 'What did they draw?' said Alice, in
            a sorrowful tone, 'I'm afraid I've offended it again
          </p>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="details-propsal">
              <h4>Proposal Details</h4>
              <ul>
                <li>
                  <h5>Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Related To</h5>
                  <h6>Deals</h6>
                </li>
                <li>
                  <h5>Name</h5>
                  <h6>Collins</h6>
                </li>
                <li>
                  <h5>Projects</h5>
                  <h6>Truelysell</h6>
                </li>
                <li>
                  <h5>Assigned to</h5>
                  <div class="d-flex align-items-center">
                    <img src="@/assets/img/profiles/avatar-19.jpg" alt="img" />
                    <h6 class="mb-0">Darlee Robertson</h6>
                  </div>
                </li>
                <li>
                  <h5>Created on</h5>
                  <h6>20 Jan 2024</h6>
                </li>
                <li>
                  <h5>Status</h5>
                  <a href="#" class="badge badge-secondary">Sent</a>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <h4>Proposal From & To</h4>
              <div class="row">
                <div class="col-lg-6">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="proposal-to">
                    <h6>To</h6>
                    <h3>Harbor View</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="attached-div">
          <h5>Attachment</h5>
          <div class="attachement-download">
            <div class="attachement-download-img">
              <div class="attachement-download-icon">
                <span><i class="ti ti-file-type-pdf"></i></span>
              </div>
              <div class="attachement-download-content">
                <h5>Proposal_webdesign.pdf</h5>
                <h6>15.2 MB</h6>
              </div>
            </div>
            <div class="attachement-download-btn">
              <a href="#"><i class="ti ti-download"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Vue3TagsInput from "vue3-tags-input";
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
const currentDateThree = ref(new Date());
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData:
        " Write a new comment, send your team notification by typing @ followed by their name",
      editorConfig: {},
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      startdateThree: currentDateThree,
      dateFormat: "dd-MM-yyyy",
      NovaWaPro: ["Select", "NovaWave LLC", "Silver Hawk", "Harbor View"],
      DoccSel: ["Select", "Truelysell", "Best@laundry", "Doccure", "Kofejob"],
      DreamSel: ["Select", "Truelysell", "Best@laundry", "Doccure", "Kofejob"],
      ActivSel: ["Select", "Active", "Inactive"],
      MedSelect: ["Select", "High", "Low", "Medium"],
      InActivSel: ["Select", "Active", "Inactive"],
      TrueSel: [
        "Select",
        "Truelysell",
        "Dreamsports",
        "Best@laundry",
        "Doccure",
        "Kofejob",
      ],
      EditNovaWaPro: ["Select", "NovaWave LLC", "Silver Hawk", "Harbor View"],
      EditDoccSel: ["Select", "Truelysell", "Best@laundry", "Doccure", "Kofejob"],
      EditDreamSel: ["Select", "Truelysell", "Best@laundry", "Doccure", "Kofejob"],
      EditActivSel: ["Select", "Active", "Inactive"],
      EditMedSelect: ["Select", "High", "Low", "Medium"],
      EditInActivSel: ["Select", "Active", "Inactive"],
      EditTrueSel: [
        "Select",
        "Truelysell",
        "Dreamsports",
        "Best@laundry",
        "Doccure",
        "Kofejob",
      ],
      tags: ["Tag1", "Tag2", "Tag3"],
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
    if (document.getElementsByClassName("add-popups").length > 0) {
      var addPopupsOne = document.getElementsByClassName("add-popups");
      for (var k = 0; k < addPopupsOne.length; k++) {
        addPopupsOne[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesOne = document.getElementsByClassName("sidebar-close2");
      for (var l = 0; l < sidebarClosesOne.length; l++) {
        sidebarClosesOne[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopupsTwo = document.getElementsByClassName("edit-popup");
      for (var m = 0; m < addPopupsTwo.length; m++) {
        addPopupsTwo[m].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesTwo = document.getElementsByClassName("sidebar-close1");
      for (var n = 0; n < sidebarClosesTwo.length; n++) {
        sidebarClosesTwo[n].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
