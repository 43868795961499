<template>
  <div
    class="col-xxl-3 col-xl-4 col-md-6"
    v-for="item in ContactGrid"
    :key="item.id"
  >
    <div class="contact-grid project">
      <div class="status-item">
        <div class="contact-status">
          <span class="priority badge badge-tag badge-danger-light me-2"
            ><i class="ti ti-square-rounded-filled"></i>High</span
          >
          <span class="badge badge-pill badge-status bg-success">Active</span>
        </div>
        <div class="rate-status">
          <span class="rate-icon">
            <i class="fa-solid fa-star"></i>
          </span>
        </div>
      </div>
      <div class="grid-head">
        <div class="users-profile">
          <router-link to="/crm/project-details">
            <span class="con-avatar">
              <img
                :src="require(`@/assets/img/priority/${item.pro_img}`)"
                alt="img"
              />
            </span>
          </router-link>
          <div class="name-user">
            <h6>
              <router-link to="/crm/project-details">{{
                item.name
              }}</router-link>
            </h6>
            <p>{{ item.type }}</p>
          </div>
        </div>
        <div class="dropdown table-action">
          <a
            href="javascript:;"
            class="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-v"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item edit-popup" href="javascript:;"
              ><i class="ti ti-edit text-blue"></i> Edit</a
            >
            <a
              class="dropdown-item"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#delete_project"
              ><i class="ti ti-trash text-danger"></i> Delete</a
            >
            <a class="dropdown-item" href="javascript:void(0);"
              ><i class="ti ti-clipboard-copy text-green"></i> Clone this
              Project</a
            >
            <a class="dropdown-item" href="javascript:void(0);"
              ><i class="ti ti-printer text-info"></i> Print</a
            >
            <a class="dropdown-item" href="javascript:void(0);"
              ><i class="ti ti-subtask"></i> Add New Task</a
            >
          </div>
        </div>
      </div>
      <div class="grid-body">
        <p>
          Kofejob is a freelancers marketplace where you can post projects & get
          instant help.
        </p>
        <div class="address-info">
          <p>
            <i class="ti ti-forbid-2"></i>Project ID :
            <span>{{ item.id }}</span>
          </p>
          <p>
            <i class="ti ti-report-money"></i>Value :
            <span>{{ item.value }}</span>
          </p>
          <p>
            <i class="ti ti-calendar-exclamation"></i>Due Date :
            <span>{{ item.end_date }}</span>
          </p>
        </div>
        <div class="client-wrap">
          <ul class="project-mem">
            <li>
              <a href="javascript:;">
                <img
                  :src="require(`@/assets/img/profiles/${item.mem_image1}`)"
                  alt="img"
                />
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <img
                  :src="require(`@/assets/img/profiles/${item.mem_image2}`)"
                  alt="img"
                />
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <img
                  :src="require(`@/assets/img/profiles/${item.mem_image3}`)"
                  alt="img"
                />
              </a>
            </li>
            <li class="more-set">
              <a href="javascript:;">+05</a>
            </li>
          </ul>
          <div class="company-item">
            <span>
              <img
                :src="require(`@/assets/img/icons/${item.client_img}`)"
                alt="img"
              />
            </span>
            <p>{{ item.client }}</p>
          </div>
        </div>
      </div>
      <div class="grid-footer d-flex justify-content-between">
        <span class="grid-timeline"
          ><i class="ti ti-clock-stop"></i>Total Hours : {{ item.hrs }}</span
        >
        <div class="msg-list">
          <ul>
            <li><i class="ti ti-brand-wechat"></i>02</li>
            <li><i class="ti ti-subtask"></i>04</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactGrid from "@/assets/json/projects.json";
export default {
  data() {
    return {
      ContactGrid: ContactGrid,
    };
  },
  mounted() {
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopups = document.getElementsByClassName("edit-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close1");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>