<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <general-sidebar></general-sidebar>

            <div class="col-xl-9 col-lg-12">
              <!-- Settings Info -->
              <div class="card">
                <div class="card-body pb-0">
                  <div class="settings-header">
                    <h4>Connected Apps</h4>
                  </div>
                  <div class="row">
                    <!-- App -->
                    <div class="col-md-4 col-sm-6">
                      <div class="integration-grid">
                        <div class="integration-calendar">
                          <img src="@/assets/img/icons/integration-01.svg" alt="Icon" />
                          <div class="connect-btn">
                            <a href="javascript:void(0);" class="connected">Connected</a>
                          </div>
                        </div>
                        <div class="integration-content">
                          <p>Google Calendar</p>
                          <div class="status-toggle">
                            <input
                              id="google_calendar"
                              class="check"
                              type="checkbox"
                              checked=""
                            />
                            <label for="google_calendar" class="checktoggle"
                              >checkbox</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /App -->

                    <!-- App -->
                    <div class="col-md-4 col-sm-6">
                      <div class="integration-grid">
                        <div class="integration-calendar">
                          <img src="@/assets/img/icons/integration-02.svg" alt="Icon" />
                          <div class="connect-btn">
                            <a href="javascript:void(0);">Connect</a>
                          </div>
                        </div>
                        <div class="integration-content">
                          <p>Figma</p>
                          <div class="status-toggle">
                            <input id="figma" class="check" type="checkbox" checked="" />
                            <label for="figma" class="checktoggle">checkbox</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /App -->

                    <!-- App -->
                    <div class="col-md-4 col-sm-6">
                      <div class="integration-grid">
                        <div class="integration-calendar">
                          <img src="@/assets/img/icons/integration-03.svg" alt="Icon" />
                          <div class="connect-btn">
                            <a href="javascript:void(0);" class="connected">Connected</a>
                          </div>
                        </div>
                        <div class="integration-content">
                          <p>Dropbox</p>
                          <div class="status-toggle">
                            <input
                              id="dropbox"
                              class="check"
                              type="checkbox"
                              checked=""
                            />
                            <label for="dropbox" class="checktoggle">checkbox</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /App -->

                    <!-- App -->
                    <div class="col-md-4 col-sm-6">
                      <div class="integration-grid">
                        <div class="integration-calendar">
                          <img src="@/assets/img/icons/integration-04.svg" alt="Icon" />
                          <div class="connect-btn">
                            <a href="javascript:void(0);">Connect</a>
                          </div>
                        </div>
                        <div class="integration-content">
                          <p>Slack</p>
                          <div class="status-toggle">
                            <input id="slack" class="check" type="checkbox" checked="" />
                            <label for="slack" class="checktoggle">checkbox</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /App -->

                    <!-- App -->
                    <div class="col-md-4 col-sm-6">
                      <div class="integration-grid">
                        <div class="integration-calendar">
                          <img src="@/assets/img/icons/integration-05.svg" alt="Icon" />
                          <div class="connect-btn">
                            <a href="javascript:void(0);" class="connected">Connected</a>
                          </div>
                        </div>
                        <div class="integration-content">
                          <p>Gmail</p>
                          <div class="status-toggle">
                            <input id="gmail" class="check" type="checkbox" checked="" />
                            <label for="gmail" class="checktoggle">checkbox</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /App -->

                    <!-- App -->
                    <div class="col-md-4 col-sm-6">
                      <div class="integration-grid">
                        <div class="integration-calendar">
                          <img src="@/assets/img/icons/integration-06.svg" alt="Icon" />
                          <div class="connect-btn">
                            <a href="javascript:void(0);">Connect</a>
                          </div>
                        </div>
                        <div class="integration-content">
                          <p>Github</p>
                          <div class="status-toggle">
                            <input id="github" class="check" type="checkbox" checked="" />
                            <label for="github" class="checktoggle">checkbox</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /App -->
                  </div>
                </div>
              </div>
              <!-- /Settings Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Settings",
    };
  },
};
</script>
