<template>
  <div class="filter-section filter-flex">
    <div class="sortby-list">
      <ul>
        <li>
          <div class="sort-dropdown drop-down">
            <a href="javascript:void(0);" class="dropdown-toggle" data-bs-toggle="dropdown"><i
                class="ti ti-sort-ascending-2"></i>Sort
            </a>
            <div class="dropdown-menu dropdown-menu-start">
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Ascending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Descending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Added
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div class="form-wrap icon-form">
            <span class="form-icon"><i class="ti ti-calendar"></i></span>
            <input type="text" class="form-control bookingrange" placeholder="" ref="dateRangeInput" />
          </div>
        </li>
      </ul>
    </div>
    <div class="filter-list">
      <ul>
        <li>
          <div class="manage-dropdwon">
            <a href="javascript:void(0);" class="btn btn-purple-light" data-bs-toggle="dropdown"
              data-bs-auto-close="false"><i class="ti ti-columns-3"></i>Manage Columns</a>
            <div class="dropdown-menu dropdown-menu-xl-end">
              <h4>Want to manage datatables?</h4>
              <p>
                Please drag and drop your column to reorder your table and enable see
                option as you want.
              </p>
              <ul>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Type</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-type" class="check" />
                    <label for="col-type" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Amount</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-amount" class="check" />
                    <label for="col-amount" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Date</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-date" class="check" />
                    <label for="col-date" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Payment Type</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-payment" class="check" />
                    <label for="col-payment" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Status</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-status" class="check" />
                    <label for="col-status" class="checktoggle"></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div class="form-sorts dropdown">
            <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="false"><i
                class="ti ti-filter-share"></i>Filter</a>
            <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
              <div class="filter-set-view">
                <div class="filter-set-head">
                  <h4><i class="ti ti-filter-share"></i>Filter</h4>
                </div>
                <div class="accordion" id="accordionExample">
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#Type"
                        aria-expanded="false" aria-controls="Type">Type</a>
                    </div>
                    <div class="filter-set-contents accordion-collapse collapse" id="Type"
                      data-bs-parent="#accordionExample">
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Wallet Topup</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Purchase</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#Status"
                        aria-expanded="false" aria-controls="Status">Status</a>
                    </div>
                    <div class="filter-set-contents accordion-collapse collapse" id="Status"
                      data-bs-parent="#accordionExample">
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Completed</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Cancel</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#Payment"
                        aria-expanded="false" aria-controls="Payment">Payment Type</a>
                    </div>
                    <div class="filter-set-contents accordion-collapse collapse" id="Payment"
                      data-bs-parent="#accordionExample">
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Paypal</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Cash</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#date"
                        aria-expanded="false" aria-controls="date">Created Date</a>
                    </div>
                    <div class="filter-set-contents accordion-collapse collapse" id="date"
                      data-bs-parent="#accordionExample">
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>25 Sep 2023, 01:22 pm</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>27 Sep 2023, 04:18 pm</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-reset-btns">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:;" class="btn btn-light">Reset</a>
                    </div>
                    <div class="col-6">
                      <router-link to="/membership/membership-transactions" class="btn btn-primary">Filter</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
export default {
  data() {
    return {
    };
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },

};
</script>
