<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"
                        ><i class="ti ti-search"></i
                      ></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Project"
                      />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <div class="export-dropdwon">
                            <a
                              href="javascript:void(0);"
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              ><i class="ti ti-package-export"></i>Export</a
                            >
                            <div class="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i
                                      class="ti ti-file-type-pdf text-danger"
                                    ></i
                                    >Export as PDF</a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i
                                      class="ti ti-file-type-xls text-green"
                                    ></i
                                    >Export as Excel
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add New
                            Project</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <div class="filter-section filter-flex">
                <div class="sortby-list">
                  <ul>
                    <li>
                      <div class="sort-dropdown drop-down">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          ><i class="ti ti-sort-ascending-2"></i>Sort
                        </a>
                        <div class="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i
                                >Ascending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i
                                >Descending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i
                                >Recently Viewed
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i
                                >Recently Added
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form-wrap icon-form">
                        <span class="form-icon"
                          ><i class="ti ti-calendar"></i
                        ></span>
                        <input
                          type="text"
                          class="form-control bookingrange"
                          ref="dateRangeInput"
                          placeholder=""
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="filter-list">
                  <ul>
                    <li>
                      <div class="manage-dropdwon">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-purple-light"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          ><i class="ti ti-columns-3"></i>Manage Columns</a
                        >
                        <div class="dropdown-menu dropdown-menu-xl-end">
                          <h4>Want to manage datatables?</h4>
                          <p>
                            Please drag and drop your column to reorder your
                            table and enable see option as you want.
                          </p>
                          <ul>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Name</p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-name"
                                  class="check"
                                />
                                <label
                                  for="col-name"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Client</p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-phone"
                                  class="check"
                                />
                                <label
                                  for="col-phone"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Priority</p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-email"
                                  class="check"
                                />
                                <label
                                  for="col-email"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p>
                                <i class="ti ti-grip-vertical"></i>Start Date
                              </p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-tag"
                                  class="check"
                                />
                                <label
                                  for="col-tag"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Due Date</p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-loc"
                                  class="check"
                                />
                                <label
                                  for="col-loc"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Type</p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-rate"
                                  class="check"
                                />
                                <label
                                  for="col-rate"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p>
                                <i class="ti ti-grip-vertical"></i>Pipeline
                                Stage
                              </p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-owner"
                                  class="check"
                                />
                                <label
                                  for="col-owner"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Status</p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-contact"
                                  class="check"
                                  checked=""
                                />
                                <label
                                  for="col-contact"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Action</p>
                              <div class="status-toggle">
                                <input
                                  type="checkbox"
                                  id="col-action"
                                  class="check"
                                />
                                <label
                                  for="col-action"
                                  class="checktoggle"
                                ></label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form-sorts dropdown">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          ><i class="ti ti-filter-share"></i>Filter</a
                        >
                        <div
                          class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end"
                        >
                          <div class="filter-set-view">
                            <div class="filter-set-head">
                              <h4><i class="ti ti-filter-share"></i>Filter</h4>
                            </div>
                            <div class="accordion" id="accordionExample">
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    >Project Name</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse show"
                                  id="collapseTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Name"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Truelysell</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Dreamschat</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Servbook</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Doccure</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Dreamsports</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseclient"
                                    aria-expanded="false"
                                    aria-controls="collapseclient"
                                    >Client Name</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapseclient"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Client"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>NovaWave LLC</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>BlueSky Industries</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>SilverHawk</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>SummitPeak</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>CoastalStar Co.</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#owner"
                                    aria-expanded="false"
                                    aria-controls="owner"
                                    >Type</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="owner"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Web App</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Meeting</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Mobile App</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#Status"
                                    aria-expanded="false"
                                    aria-controls="Status"
                                    >Start Date
                                  </a>
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="Status"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Date"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>25 Sep 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>29 Sep 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>05 Oct 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>14 Oct 2023</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                    >End Date</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapseThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>19 Oct 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>23 Nov 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>14 Dec 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>09 Dec 2023</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsestage"
                                    aria-expanded="false"
                                    aria-controls="collapsestage"
                                    >Pipeline Stage</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapsestage"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Plan</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Develop</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Completed</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapsestatus"
                                    aria-expanded="false"
                                    aria-controls="collapsestatus"
                                    >Status</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapsestatus"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Active</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Inactive</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="filter-reset-btns">
                              <div class="row">
                                <div class="col-6">
                                  <a href="javascript:;" class="btn btn-light">Reset</a>
                                </div>
                                <div class="col-6">
                                  <router-link
                                    to="/crm/projects-list"
                                    class="btn btn-primary"
                                    >Filter</router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="view-icons">
                        <router-link to="/crm/projects-list" class="active"
                          ><i class="ti ti-list-tree"></i
                        ></router-link>
                        <router-link to="/crm/project-grid"
                          ><i class="ti ti-grid-dots"></i
                        ></router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Filter -->

              <!-- Projects List -->
              <div class="table-responsive custom-table">
                <projects-table></projects-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Projects List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <projects-list-modal></projects-list-modal>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";

export default {
  data() {
    return {
      title: "Projects",
    };
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days"),
              ],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
