<template>
  <div class="account-page">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">
          <div class="login-content">
            <form action="/success-2">
              <div class="login-user-info">
                <div class="login-logo">
                  <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
                </div>
                <div class="login-heading">
                  <h4>Reset Password?</h4>
                  <p>Enter New Password & Confirm Password to get inside</p>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Password</label>
                  <div class="pass-group">
                    <input type="password" class="pass-input form-control" />
                    <span class="ti toggle-password ti-eye-off"></span>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Confirm Password</label>
                  <div class="pass-group">
                    <input type="password" class="pass-inputs form-control" />
                    <span class="ti toggle-passwords ti-eye-off"></span>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">New Confirm Password</label>
                  <div class="pass-group">
                    <input type="password" class="pass-input-new form-control" />
                    <span class="ti toggle-password-new ti-eye-off"></span>
                  </div>
                </div>
                <div class="form-wrap">
                  <button type="submit" class="btn btn-primary">Change Password</button>
                </div>
                <div class="login-form text-center">
                  <h6>
                    Return to
                    <router-link to="/login-2" class="hover-a">Log In</router-link>
                  </h6>
                </div>
                <div class="login-social-link">
                  <div class="copyright-text">
                    <p>Copyright &copy;2024 - CRMS</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="login-img">
            <img
              src="@/assets/img/authentication/reset-img.png"
              class="img-fluid"
              alt="Login"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
