<template>
  <div class="card settings-tab">
    <div class="card-body pb-0">
      <div class="settings-menu">
        <ul class="nav">
          <li>
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('security')
              }"
              to="/settings/profile"
            >
                   <!-- ||
                  currentPath.includes('notifications') ||
                  currentPath.includes('connected-apps'), -->
              <i class="ti ti-settings-cog"></i> General Settings
            </router-link>
          </li>
          <li>
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('import-data-status') ||
                  currentPath.includes('create-mode') ||
                  currentPath.includes('map-data') ||
                  currentPath.includes('upload-file') ||
                  currentPath.includes('validate') ||
                  currentPath.includes('error-logs'),
              }"
              to="/data-import/import-data-status"
            >
              <i class="ti ti-world-cog"></i> Data Import
            </router-link>
          </li>
          <li v-if="user.roles[0] !== 'sales_representative'">
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('users-list') ||
                  currentPath.includes('create-user'),
              }"
              to="/users/users-list"
            >
              <i class="ti ti-world-cog"></i> Users
            </router-link>
          </li>
           <li v-if="user.roles[0] !== 'sales_representative'">
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('create-territory') ||
                  currentPath.includes('custom-fields'),
              }"
              to="/territory/territory-list"
            >
              <i class="ti ti-apps"></i> Territories
            </router-link>
          </li>
          <!-- <li>
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('sms-gateways') ||
                  currentPath.includes('gdpr-cookies'),
              }"
              to="/system-settings/email-settings"
            >
              <i class="ti ti-device-laptop"></i> System Settings
            </router-link>
          </li> -->
          <!-- <li>
            <router-link
              v-bind:class="{
                active:
                  currentPath.includes('bank-accounts') ||
                  currentPath.includes('tax-rates') ||
                  currentPath.includes('currencies'),
              }"
              to="/financial-settings/payment-gateways"
            >
              <i class="ti ti-moneybag"></i> Financial Settings
            </router-link>
          </li> -->
          <!-- <li>
            <router-link
              v-bind:class="{
                active: currentPath.includes('ban-ip-address'),
              }"
              to="/other-settings/storage"
            >
              <i class="ti ti-flag-cog"></i> Other Settings
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      currentPath: this.$route.path,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']), // 'auth' is the namespace of your Vuex module
    
  },
};
</script>
