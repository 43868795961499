<template>
  <!-- Add New Estimation -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Estimation</h4>
        <a href="#" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Client</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_client"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select :options="Bluesky" id="bluesky" placeholder="Choose" />
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Bill To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Ship To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Project</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#save_view"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select :options="Servbook" id="bookserv" placeholder="Choose" />
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Estimate By <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="VaughanOne"
                    id="vaughanone"
                    placeholder="Choose"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Amount<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Currency</label>
                  <vue-select
                    :options="Currency"
                    id="currencynine"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Estimate Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdate"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Expiry Date<span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdateOne"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Status</label>
                  <vue-select :options="Status" id="statusnine" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Tags <span class="text-danger">*</span></label
                  >
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Tag1"
                    :tags="tags"
                  />
                </div>
              </div>
              <div class="form-wrap">
                <label class="col-form-label"
                  >Attachment <span class="text-danger">*</span></label
                >
                <div class="drag-attach">
                  <input type="file" />
                  <div class="img-upload">
                    <i class="ti ti-file-broken"></i>Upload File
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="#" class="btn btn-light sidebar-close">Cancel</a>
            <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#create_estimations"
              >Create</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Estimation -->

  <!-- Edit Estimation -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Estimation</h4>
        <a href="#" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Client</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select
                  :options="Bluesky"
                  id="editbluesky"
                  placeholder="NovaWave LLC"
                />
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Bill To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Ship To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Project</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select
                  :options="Servbook"
                  id="editservbook"
                  placeholder="Truelysell"
                />
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Estimate By <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="EditVaughan"
                    id="editvaughan"
                    placeholder="Truelysell"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Amount<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" value="2,15,000" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Currency</label>
                  <vue-select :options="Currency" id="editcurrency" placeholder="$" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Estimate Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdate"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Expiry Date<span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdateOne"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Status</label>
                  <vue-select :options="StatusOne" id="editstatus" placeholder="Sent" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Tags <span class="text-danger">*</span></label
                  >
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Tag1"
                    :tags="tags"
                  />
                </div>
              </div>
              <div class="form-wrap">
                <label class="col-form-label"
                  >Attachment <span class="text-danger">*</span></label
                >
                <div class="drag-attach">
                  <input type="file" />
                  <div class="img-upload">
                    <i class="ti ti-file-broken"></i>Upload File
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="#" class="btn btn-light sidebar-close">Cancel</a>
            <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#create_estimations"
              >Save Changes</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Estimation -->

  <!-- view Contracts -->
  <div class="toggle-popup2">
    <!-- estimation -->
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Estimation<span class="badge badge-purple-light ms-2">#274738</span></h4>
        <div class="d-flex align-items-center">
          <a href="#" class="btn btn-downloads">Download</a>
          <a href="#" class="sidebar-close toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="card">
          <div class="card-body">
            <div class="details-propsal details-propsals">
              <h4>
                Estimation Details
                <span class="badge badge-success ms-2 d-inline-flex">Accepted</span>
              </h4>
              <ul>
                <li>
                  <h5>Estimate Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Expiry Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Client</h5>
                  <h6>NovaWave LLC</h6>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <h4>Proposal From & To</h4>
              <div class="row">
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Bill To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Ship To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>1</td>
                <td>$200</td>
                <td>2%</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="subtotal-div">
          <ul>
            <li>
              <h5>Subtotal</h5>
              <h6>$196.00</h6>
            </li>
            <li>
              <h5>Discount 2%</h5>
              <h6>$4.00</h6>
            </li>
            <li>
              <h5>Extra Discount 0%</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Tax</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
          </ul>
        </div>
        <div class="terms-condition">
          <h2>Terms & Conditions</h2>
          <p>
            The products/services listed in this invoice will be delivered/provided as per
            the specifications and schedule detailed in the invoice or as agreed upon by
            both parties in previous communications.
          </p>
        </div>
      </div>
    </div>
    <!-- estimation -->
  </div>
  <!-- /view Contracts -->

  <!-- view Contracts -->
  <div class="toggle-popup-sent">
    <!-- estimation -->
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Estimation<span class="badge badge-purple-light ms-2">#274738</span></h4>
        <div class="d-flex align-items-center">
          <a href="#" class="btn btn-downloads">Download</a>
          <a href="#" class="btn btn-danger ms-2"><i class="ti ti-x me-2"></i> Decline</a>
          <a href="#" class="btn btn-success ms-2"
            ><i class="ti ti-check me-2"></i> Accept</a
          >
          <a href="#" class="sidebar-close toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="card">
          <div class="card-body">
            <div class="details-propsal details-propsals">
              <h4>
                Estimation Details
                <span class="badge badge-pending text-white ms-2 d-inline-flex"
                  >Sent</span
                >
              </h4>
              <ul>
                <li>
                  <h5>Estimate Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Expiry Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Client</h5>
                  <h6>NovaWave LLC</h6>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <h4>Proposal From & To</h4>
              <div class="row">
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Bill To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Ship To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>1</td>
                <td>$200</td>
                <td>2%</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="subtotal-div">
          <ul>
            <li>
              <h5>Subtotal</h5>
              <h6>$196.00</h6>
            </li>
            <li>
              <h5>Discount 2%</h5>
              <h6>$4.00</h6>
            </li>
            <li>
              <h5>Extra Discount 0%</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Tax</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
          </ul>
        </div>
        <div class="terms-condition">
          <h2>Terms & Conditions</h2>
          <p>
            The products/services listed in this invoice will be delivered/provided as per
            the specifications and schedule detailed in the invoice or as agreed upon by
            both parties in previous communications.
          </p>
        </div>
      </div>
    </div>
    <!-- estimation -->
  </div>
  <!-- /view Contracts -->

  <!-- view Contracts -->
  <div class="toggle-popup-draft">
    <!-- estimation -->
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Estimation<span class="badge badge-purple-light ms-2">#274738</span></h4>
        <div class="d-flex align-items-center">
          <a href="#" class="btn btn-downloads">Download</a>
          <a href="#" class="btn btn-danger ms-2"><i class="ti ti-x me-2"></i> Decline</a>
          <a href="#" class="btn btn-success ms-2"
            ><i class="ti ti-check me-2"></i> Accept</a
          >
          <a href="#" class="sidebar-close toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="card">
          <div class="card-body">
            <div class="details-propsal details-propsals">
              <h4>
                Estimation Details
                <span class="badge badge-warning ms-2 d-inline-flex">Draft</span>
              </h4>
              <ul>
                <li>
                  <h5>Estimate Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Expiry Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Client</h5>
                  <h6>NovaWave LLC</h6>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <h4>Proposal From & To</h4>
              <div class="row">
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Bill To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Ship To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>1</td>
                <td>$200</td>
                <td>2%</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="subtotal-div">
          <ul>
            <li>
              <h5>Subtotal</h5>
              <h6>$196.00</h6>
            </li>
            <li>
              <h5>Discount 2%</h5>
              <h6>$4.00</h6>
            </li>
            <li>
              <h5>Extra Discount 0%</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Tax</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
          </ul>
        </div>
        <div class="terms-condition">
          <h2>Terms & Conditions</h2>
          <p>
            The products/services listed in this invoice will be delivered/provided as per
            the specifications and schedule detailed in the invoice or as agreed upon by
            both parties in previous communications.
          </p>
        </div>
      </div>
    </div>
    <!-- estimation -->
  </div>
  <!-- /view Contracts -->

  <!-- view Contracts -->
  <div class="toggle-popup-declined">
    <!-- estimation -->
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Estimation<span class="badge badge-purple-light ms-2">#274738</span></h4>
        <div class="d-flex align-items-center">
          <a href="#" class="btn btn-downloads">Download</a>
          <a href="#" class="btn btn-success ms-2"
            ><i class="ti ti-check me-2"></i> Accept</a
          >
          <a href="#" class="sidebar-close toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="card">
          <div class="card-body">
            <div class="details-propsal details-propsals">
              <h4>
                Estimation Details
                <span class="badge badge-danger ms-2 d-inline-flex">Declined</span>
              </h4>
              <ul>
                <li>
                  <h5>Estimate Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Expiry Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Client</h5>
                  <h6>NovaWave LLC</h6>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <h4>Proposal From & To</h4>
              <div class="row">
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Bill To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Ship To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>1</td>
                <td>$200</td>
                <td>2%</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="subtotal-div">
          <ul>
            <li>
              <h5>Subtotal</h5>
              <h6>$196.00</h6>
            </li>
            <li>
              <h5>Discount 2%</h5>
              <h6>$4.00</h6>
            </li>
            <li>
              <h5>Extra Discount 0%</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Tax</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
          </ul>
        </div>
        <div class="terms-condition">
          <h2>Terms & Conditions</h2>
          <p>
            The products/services listed in this invoice will be delivered/provided as per
            the specifications and schedule detailed in the invoice or as agreed upon by
            both parties in previous communications.
          </p>
        </div>
      </div>
    </div>
    <!-- estimation -->
  </div>
  <!-- /view Contracts -->

  <!-- Delete Estimations -->
  <div class="modal custom-modal fade" id="delete_estimations" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Estimations?</h3>
            <p class="del-info">
              Are you sure you want to remove estimations you selected.
            </p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/estimations-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Estimations -->

  <!-- Create Estimation -->
  <div class="modal custom-modal fade" id="create_estimations" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-atom-2"></i>
            </div>
            <h3>Estimations Created Successfully!!!</h3>
            <p>View the details of estimations, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <a href="#" class="btn btn-primary">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Project -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Project</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-wrap">
              <label class="col-form-label">Project Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="add_client" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Client</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-wrap">
              <label class="col-form-label">Client Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->
</template>

<script>
import { ref } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Vue3TagsInput from "vue3-tags-input";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      Bluesky: ["Choose", "NovaWave LLC", "BlueSky Industries", "ASilver Hawkdams"],
      Servbook: ["Choose", "Truelysell", "Dreamschat", "Servbook"],
      VaughanOne: ["Choose", "Darlee Robertson", "Sharon Roy", "Vaughan"],
      EditVaughan: ["Choose", "Darlee Robertson", "Sharon Roy", "Vaughan"],
      Currency: ["Select", "$", "€"],
      Status: ["Select", "Active", "Inactive"],
      StatusOne: ["Select", "Active", "Inactive"],
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      tags: ["Tag1", "Tag2", "Tag3"],
      editor: ClassicEditor,
      editorData: "Description",
      editorConfig: {},
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/crm/estimations-list");
    },
  },
};
</script>
