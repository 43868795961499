<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <!-- Prefixes -->
              <div class="card">
                <div class="card-body">
                  <div class="settings-header">
                    <website-sidebar></website-sidebar>
                  </div>
                  <form @submit.prevent="validateData" v-if="validateOptions.length > 0">
                    <div class="row align-items-center py-4">
                      <div class="col"></div>
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-md-5">
                            <div
                              class="storage-wrap prefer-wrap"
                              v-for="val in validateOptions"
                              :key="val.id"
                            >
                              <div class="storage-icon">
                                <h6>{{ val.name }}</h6>
                              </div>
                              <div class="setting-gateway">
                                <div class="status-toggle">
                                  <input
                                    type="checkbox"
                                    :id="val.id"
                                    class="check"
                                    :value="val.id"
                                    v-model="formData.modules"
                                  />
                                  <label :for="val.id" class="checktoggle"> </label>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              class="storage-wrap prefer-wrap"
                              v-for="(val, index) in tooMany"
                              :key="index"
                            >
                              <div class="storage-icon">
                                <h6>{{ val }}</h6>
                              </div>
                              <div class="setting-gateway">
                                <div class="status-toggle">
                                  <input
                                    type="checkbox"
                                    :id="'few-' + index"
                                    class="check"
                                     :checked="formData[val] === 1"
                                    @change="updateTooMany(index, $event.target.checked)"
                                  />
                                  <label :for="'few-' + index" class="checktoggle"> </label>
                                </div>
                              </div>
                            </div> -->
                          </div>
                          <div class="col-md-7">
                            <textarea
                              class="form-control"
                              rows="5"
                              v-model="formData.import_description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col"></div>
                    </div>
                    <div class="submit-button">
                      <div class="row">
                      <div class="col-md-6">
                        <button type="submit" class="btn btn-primary">Validate</button>
                      </div>
                      <div class="col-md-6 text-end">
                        <router-link v-if="isValidated" class="btn btn-teal" :to="{ path: '/data-import/map-data', query: { fileId: file_id } }">
                          Ready to map</router-link>
                      </div>
                    </div>
                    </div>
                  </form>
                  <div class="row" v-else>
                    <div class="col"></div>
                    <div class="col-md-7 py-3 bg-light">
                     <p class="text-center "><i class="ion-information-circled"></i> Please upload a file</p>
                    </div>
                    <div class="col"></div>
                  </div>
                </div>
              </div>
              <!-- /Prefixes -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  data() {
    return {
      title: "Settings",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isValidated = ref(false);
    const validateOptions = ref([]);
    const tooMany = ref(["Too few data fields", "Too many data fields"]);
    const file_id = ref(null)
    if(route.query.fileId || localStorage.getItem('fileId')){
      file_id.value = route.query.fileId || localStorage.getItem('fileId');
    }

    const formData = ref({
      id: file_id,
      modules: [], // This will store the selected IDs
      import_relations: 0,
      only_create_new: 0,
      too_few_data_fields: 0,
      too_many_data_fields: 0,
      import_description: ""
    });
    
    // Fetch data on mount
    const fetchValidData = async (id) => {
      try {
        const data = await store.dispatch("dataimport/fetchValidateData", id);
        validateOptions.value = data.all_modules;
      } catch (error) {
        console.error("Error fetching dataimport data:", error);
      }
    };
   // Update formData based on "Too few" and "Too many" checkboxes
   const updateTooMany = (index, isChecked) => {
      if (index === 0) {
        formData.value.too_few_data_fields = isChecked ? 1 : 0;
      } else if (index === 1) {
        formData.value.too_many_data_fields = isChecked ? 1 : 0;
      }
    };
    // Submit form
    const validateData = async () => {
      try {
        const response = await store.dispatch("dataimport/validateFile", formData.value);
        if (response.status) {
          isValidated.value = true;
        }
      } catch (error) {
        console.error("Validation failed:", error);
      }
    };

    // When component is mounted
    onMounted(async () => {
      const fileId = route.query.fileId || localStorage.getItem('fileId');
      if (fileId) {
        fetchValidData(fileId);
      }
    });

    return {
      validateOptions,
      formData,
      tooMany,
      updateTooMany,
      validateData,
      isValidated,
      file_id
    };
  }
};
</script>

