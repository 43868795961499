<template>
  <div class="filter-section filter-flex">
    <div class="sortby-list">
      <ul>
        <li>
          <div class="sort-dropdown drop-down task-drops invoices">
            <a
              href="javascript:void(0);"
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              >All Invoices
            </a>
            <div class="dropdown-menu dropdown-menu-start">
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-dots-vertical"></i>All Invoices
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-dots-vertical"></i>Paid
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-dots-vertical"></i>Partially Paid
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-dots-vertical"></i>Overdue
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-dots-vertical"></i>Unpaid
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div class="sort-dropdown drop-down">
            <a
              href="javascript:void(0);"
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              ><i class="ti ti-sort-ascending-2"></i>Sort
            </a>
            <div class="dropdown-menu dropdown-menu-start">
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Ascending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Descending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Added
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div class="form-wrap icon-form">
            <span class="form-icon"><i class="ti ti-calendar"></i></span>
            <input
              type="text"
              class="form-control bookingrange"
              ref="dateRangeInput"
              placeholder=""
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="filter-list">
      <ul class="justify-content-md-end">
        <li>
          <div class="form-sorts dropdown">
            <a
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
              data-bs-auto-close="false"
              ><i class="ti ti-filter-share"></i>Filter</a
            >
            <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
              <div class="filter-set-view">
                <div class="filter-set-head">
                  <h4><i class="ti ti-filter-share"></i>Filter</h4>
                </div>
                <div class="accordion" id="accordionExample">
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="#"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseclient"
                        aria-expanded="false"
                        aria-controls="collapseclient"
                        >Client</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="collapseclient"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <div class="form-wrap icon-form">
                          <span class="form-icon"><i class="ti ti-search"></i></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Client"
                          />
                        </div>
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>NovaWave LLC</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Redwood Inc</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>HarborVie w</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>CoastalStar Co.</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>RiverStone Ventur</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="#"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#owner"
                        aria-expanded="false"
                        aria-controls="owner"
                        >Project</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="owner"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Truelysell</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Dreamschat</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Truelysell</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Servbook</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>DreamPOS</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="#"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#Status"
                        aria-expanded="false"
                        aria-controls="Status"
                        >Amount</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="Status"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <div class="form-wrap icon-form">
                          <span class="form-icon"><i class="ti ti-search"></i></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Date"
                          />
                        </div>
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>$2,15,000</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>$1,45,000</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>$2,15,000</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>$4,80,380</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>$2,12,000</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-reset-btns">
                  <div class="row">
                    <div class="col-6">
                      <a href="#" class="btn btn-light">Reset</a>
                    </div>
                    <div class="col-6">
                      <router-link to="/crm/invoices-list" class="btn btn-primary"
                        >Filter</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="view-icons">
            <router-link to="/crm/invoices-list"
              ><i class="ti ti-list-tree"></i
            ></router-link>
            <router-link to="/crm/invoice-grid"
              ><i class="ti ti-grid-dots"></i
            ></router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";

export default {
  data() {
    return {};
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },
};
</script>
