import axios from 'axios';
import router from './router';
import store from './store';
import { message } from 'ant-design-vue';

const apiClient = axios.create({
  baseURL: 'https://api.crm.anviam.co.uk/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Flag to prevent multiple error messages
let errorMessageShown = false;

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  store.dispatch('showLoader');
  
  return config;
}, error => {
  store.dispatch('hideLoader');
  return Promise.reject(error);
});

apiClient.interceptors.response.use(
  response => {
    store.dispatch('hideLoader');
    return response;
  },
  async error => {
    store.dispatch('hideLoader');
    
    if (error.response && error.response.status === 401) {
      if (!errorMessageShown) {
        errorMessageShown = true;
        message.error("Session expired. Please log in again");
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        router.push('/'); // Redirect to the login page
      }
    }

    return Promise.reject(error);
  }
);

// Reset the error message flag when a new API call starts
apiClient.interceptors.request.use(config => {
  errorMessageShown = false;
  return config;
});

export default apiClient;
