<template>
  <div class="modal custom-modal fade" id="add_translate" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <form action="/website-settings/language-web">
            <div class="translate-item">
              <div class="d-flex align-items-center">
                <div class="lang-flag">
                  <a href="javascript:void(0);">
                    <img
                      class="avatar-img"
                      src="@/assets/img/icons/flag-02.svg"
                      alt="Flag"
                    /><span>Arabic</span>
                  </a>
                </div>
                <div class="lang-progress">
                  <p class="mb-0">Progress</p>
                  <div class="d-flex align-items-center">
                    <div class="progress">
                      <div class="progress-bar bg-blue" role="progressbar"></div>
                    </div>
                    <span class="position-static ms-2">40%</span>
                  </div>
                </div>
              </div>
              <router-link to="/website-settings/create-mode" class="btn btn-primary"
                ><i class="ti ti-arrow-narrow-left me-2"></i>Back to
                Translation</router-link
              >
            </div>
            <div class="translate-wrap translate-title">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>English</h6>
                </div>
                <div class="col-md-4">
                  <h6>Arabic</h6>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bugs</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input type="text" class="form-control" value="البق" />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bugs Email</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      value="البق البريد الإلكتروني"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bug Assigned</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      value="البق البريد الإلكتروني"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bug Comments</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input type="text" class="form-control" value="علة تعليقات" />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bug Attachment</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input type="text" class="form-control" value="البقعلة مرفق" />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bug Updated</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      value="البق بمجلس تم الحفظ بنجاح"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bug Reported</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      value="توقيت بمجلس المحذوفة بنجاح"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bugs information successfully updated</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      value="البق بمجلس المحذوفة بنجاح"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bugs information successfully Saved</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input type="text" class="form-control" value="علة التحديث" />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Timer information successfully Deleted</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      value="آخر البق الجديدة التي تمت إضافتها"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bugs infromation successfully Deleted</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input type="text" class="form-control" value="جميع البق" />
                  </div>
                </div>
              </div>
            </div>
            <div class="translate-wrap">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <h6>Bug Updated</h6>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      value="البق بمجلس تم الحفظ بنجاح"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Reset</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
