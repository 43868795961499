<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <!-- Template for table body cells -->
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link to="/crm/company-details" class="profile-split d-flex flex-column">
          {{ record.full_name }}
        </router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'Status'">
      <div>
        <span :class="record.statusclass">{{ record.status }}</span>
      </div>
    </template>
    <template v-else-if="column.key === 'action'">
      <div class="dropdown table-action">
        <a href="javascript:;" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-ellipsis-v"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item edit-popup" href="javascript:void(0);" @click="openEditModal(record.key)">
            <i class="ti ti-edit text-blue"></i> Edit
          </a>
          <a class="dropdown-item" href="javascript:;" @click="confirmDelete(record.key)">
            <i class="ti ti-trash text-danger"></i> Delete
          </a>
        </div>
      </div>
    </template>
  </template>
</a-table>

<!-- Delete Confirmation Modal -->
<div class="modal-backdrop fade show" v-if="showDeleteModal"></div>
<div v-if="showDeleteModal" class="modal custom-modal fade show" style="display: block;" tabindex="-1" role="dialog" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0 m-0 justify-content-end">
        <button class="btn-close" @click="showDeleteModal = false" aria-label="Close">
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="success-message text-center">
          <div class="success-popup-icon">
            <i class="ti ti-trash-x"></i>
          </div>
          <h3>Delete Person</h3>
          <p class="del-info">Are you sure you want to delete?</p>
          <div class="col-lg-12 text-center modal-btn">
            <button class="btn btn-light" @click="showDeleteModal = false">Cancel</button>
            <button class="btn btn-danger" @click="deletePerson(selectedPerId)">Yes, Delete it</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watchEffect
} from 'vue';
import { message } from 'ant-design-vue';
import {
  useRoute, useRouter
} from 'vue-router';
import apiClient from '@/axios';

const columns = [{
    title: "Full Name",
    dataIndex: "name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. Name",
    dataIndex: "org_name",
    sorter: {
      compare: (a, b) => {
        a = a.org_name;
        b = b.org_name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. Postcode",
    dataIndex: "org_postcode",
    sorter: {
      compare: (a, b) => {
        a = a.org_postcode;
        b = b.org_postcode;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Job Title",
    dataIndex: "job_title",
    sorter: {
      compare: (a, b) => {
        a = a.job_title;
        b = b.job_title;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Direct Phone",
    dataIndex: "d_phone",
    sorter: {
      compare: (a, b) => {
        a = a.d_phone;
        b = b.d_phone;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Mobile Phone",
    dataIndex: "m_phone",
    sorter: {
      compare: (a, b) => {
        a = a.m_phone;
        b = b.m_phone;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  // props: {
  //   searchQuery: String,
  //   filters: Object,
  // },
  data() {
    return {
      columns,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const data = ref([]);
    const selectedPerId = ref(null);
    const showDeleteModal = ref(false);
    const organistoinId = ref(null);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    if (route.query.org_id) {
      organistoinId.value = route.query.org_id;
    }
    const fetchOrgPersons = async () => {
      try {
        const response = await apiClient.get(`/organisation/${organistoinId.value}/persons`);
        if (response.data.status) {
          data.value = response.data.data.map((per) => ({
            key: per.id,
            full_name: per.last_name ? `${per.first_name} ${per.last_name}` : per.first_name,
            org_name: per.organization.name,
            org_postcode: per.organization.postcode,
            job_title: per.job_title,
            d_phone: per.direct_telephone,
            m_phone: per.mobile_telephone
          }));
          pagination.value.total = response.data.data.total;
        } else {
          console.error("Failed to fetch organisations:", response);
        }
      } catch (error) {
        console.error("Error fetching organisations:", error);
      }
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchOrgPersons();
    };

    const openEditModal = (id) => {
      router.push({
        path: "/crm/create-person",
        query: {
          personId: id,
          org_id: route.query.org_id
        },
      });
    };
    const confirmDelete = (id) => {
      selectedPerId.value = id;
      showDeleteModal.value = true;
    };
    const deletePerson= async (id) =>  {
      try {
        const response = await apiClient.delete(`organisation/deletePerson/${id}`);

        if (response.data.status) {
          message.success("Person deleted successfully!");
          showDeleteModal.value = false;
          fetchOrgPersons(pagination.value.current, pagination.value.pageSize);
        } else {
          message.error("Failed to delete Person.");
        }
      } catch (error) {
        console.error("Error deleting Person:", error);
        message.error("Error deleting Person.");
      }
    };
    // Fetch data when component is mounted
    // onMounted(() => {
    //   fetchOrgPersons();
    // });

    // Watch for changes in searchQuery or filters
    watchEffect(() => {
      fetchOrgPersons();
    });

    return {
      data,
      selectedPerId,
      showDeleteModal,
      pagination,
      fetchOrgPersons,
      handleTableChange,
      openEditModal,
      confirmDelete,
      deletePerson
    };
  },
};
</script>
