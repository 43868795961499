import apiClient from '@/axios';
import { message } from 'ant-design-vue';
import router from '@/router';

const state = {
  authToken: localStorage.getItem('authToken') || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
};

const getters = {
  isAuthenticated: state => !!state.authToken,
  authToken: state => state.authToken,
  user: state => state.user,
};

const actions = {
  async login({ commit }, loginData) {
    try {
      const response = await apiClient.post('/login', loginData);
      if (response.status) {
        const token = response.data.data.token;
        const user = response.data.data;
        
        // Store token and user data in localStorage
        localStorage.setItem('authToken', token);
        localStorage.setItem('user', JSON.stringify(user));
        
        // Commit to Vuex store
        commit('setAuthToken', token);
        commit('setUser', user);
        
        return response.data;
      } else {
        return Promise.reject(response.data.error);
      }
    } catch (error) {
      return Promise.reject('An error occurred. Please try again.');
    }
  },

  async logout({ commit }) {
    try {
      const response = await apiClient.post('/logout');
      if (response.data.status) {
        localStorage.removeItem('authToken');
        commit('setAuthToken', '');
        localStorage.removeItem('user');
        commit('setUser', {});
        router.push('/')
      } else {
        console.error('Logout failed:', response.data.error);
      }
    } catch (error) {
      localStorage.removeItem('authToken');
      commit('setAuthToken', '');
      localStorage.removeItem('user');
      commit('setUser', {});
      router.push('/')
      console.error('Error during logout:', error);
    }
  },

  async forgotPassword({ commit }, email) {
    try {
      const response = await apiClient.post('/forgot-password', { email });
      if (response.data.status) {
        message.success('Password reset link sent successfully!');
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async resetPassword({ commit }, form) {
    try {
      const response = await apiClient.post('/reset-password', form);
      if (response.data.status) {
        message.success('Password reset successfully!');
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async updateProfile({ commit, state }, form) {
    try {
      const response = await apiClient.post('/auth/update-profile', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status) {
        const updatedUser = response.data.data; // Assuming the API returns the updated user data
  
        // Merge the updated user data with the current user state
        const mergedUser = { ...state.user, ...updatedUser };
  
        // Update user in Vuex store
        commit('setUser', mergedUser);
  
        // Update user in localStorage
        localStorage.setItem('user', JSON.stringify(mergedUser));
  
        message.success(response.data.message);
      }
  
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  loadUserFromLocalStorage({ commit }) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (Object.keys(user).length) {
      commit('setUser', user);
    }
  },
};

const mutations = {
  setAuthToken(state, token) {
    state.authToken = token;
  },

  setUser(state, user) {
    state.user = { ...state.user, ...user };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
