<template>
  <!-- Add Page -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add Page</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="#">
            <div class="accordion-lists" id="list-accord">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Slug <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >keywords <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-wrap">
                    <label class="col-form-label">Login Type</label>
                    <div class="radio-btn-items">
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="top-menu1"
                          name="export-type"
                          checked=""
                        />
                        <label for="top-menu1">Top Menu</label>
                      </div>
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="links1"
                          name="export-type"
                        />
                        <label for="links1">Quick Links</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-wrap">
                    <label class="col-form-label">Visibility</label>
                    <div class="radio-btn-items">
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="pdf"
                          name="export-type"
                          checked=""
                        />
                        <label for="pdf">Show</label>
                      </div>
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="share"
                          name="export-type"
                        />
                        <label for="share">Hide</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Content<span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
              <a
                href="javascript:;"
                data-bs-toggle="modal"
                data-bs-target="#create_page"
                class="btn btn-primary"
                >Create</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Contact -->

  <!-- Edit Contact -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Page</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <form action="/content/pages" class="toggle-height">
          <div class="pro-create">
            <div class="accordion-lists" id="edit-page">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Slug <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Description <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >keywords <span class="text-danger">*</span></label
                    >
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-wrap">
                    <label class="col-form-label">Login Type</label>
                    <div class="radio-btn-items">
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="top-menu"
                          name="export-type"
                          checked=""
                        />
                        <label for="top-menu">Top Menu</label>
                      </div>
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="links"
                          name="export-type"
                        />
                        <label for="links">Quick Links</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-wrap">
                    <label class="col-form-label">Visibility</label>
                    <div class="radio-btn-items">
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="showa"
                          name="export-type"
                          checked=""
                        />
                        <label for="showa">Show</label>
                      </div>
                      <div class="radio-btn">
                        <input
                          type="radio"
                          class="status-radio"
                          id="hides"
                          name="export-type"
                        />
                        <label for="hides">Hide</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-wrap">
                    <label class="col-form-label"
                      >Content<span class="text-danger">*</span></label
                    >
                    <textarea class="form-control" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit page -->

  <!-- Delete page -->
  <div class="modal custom-modal fade" id="delete_page" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Pages?</h3>
            <p class="del-info">Are you sure you want to remove page you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/content/pages" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete page -->

  <!-- Create Page -->
  <div class="modal custom-modal fade" id="create_page" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-user-plus"></i>
            </div>
            <h3>Page Created Successfully!!!</h3>
            <p>View the details of contact, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/content/pages" class="btn btn-primary"
                >View Details</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Contact -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New View</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/content/pages">
            <div class="form-wrap">
              <label class="col-form-label">View Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
