<template>
<div class="kanban-wrapper leads-kanban-wrapper">
  <div v-for="(pipe, index) in modifiedPipeName" :key="index" class="kanban-list-items" :data-pipe-id="pipe.id">
    <!-- Kanban List Head -->
    <div class="kanban-list-head">
      <div class="d-flex justify-content-between align-items-center">
        <div :class="pipe.DotClass">
          <h5>{{ pipe.name }}</h5>
          <span>{{ pipe.Count }}</span>
        </div>
        <div class="kanban-action-btns d-flex align-items-center">
          <router-link :to="{ path: '/crm/create-meter-opportunity', query: { kanban: true, pipe_line: pipe.id } }" class="btn btn-primary p-1">
            <i class="ti ti-plus"></i>
          </router-link>
        </div>
      </div>
    </div>
    <!-- Kanban Cards -->
    <ul class="kanban-drag-wrap ui-sortable">
      <draggable :list="getCardsByPipe(pipe.id)" :group="{ name: 'kanban', pull: true, put: true }" @end="onDragEnd($event)" :data-pipe-id="pipe.id">
        <!-- <li v-for="card in getCardsByPipe(pipe.id)" :key="card.id"> -->
        <li v-for="card in getCardsByPipe(pipe.id)" :key="card.id" :data-card-id="card.id" :data-card-info="JSON.stringify(card)">
          <div class="kanban-card ui-sortable-handle">
            <div class="kanban-card-head">
              <span :class="card.CardClass"></span>
              <div class="kanban-card-title d-block">
                <div class='row align-items-center'>
                  <div class="col-md-2">
                    <router-link :to="{ path: '/crm/meter-opportunities-edit', query: { kanban: true, territory_id: pipe.id, org_Id: card.organization.id, opp_Id: card.id } }"><span>{{ card.initials }}</span></router-link>

                  </div>
                  <div class="col-md-7 p-0">
                    <h6>
                      <router-link :to="{ path: '/crm/meter-opportunities-edit', query: {kanban: true, territory_id: pipe.id, org_Id: card.organization.id, opp_Id: card.id } }">{{ card.organization.name.toUpperCase() }}</router-link>
                      <p title="Description" class="fw-normal">{{ card.description }}</p>
                    </h6>
                  </div>
                  <div class="col-md-3">
                    <p class="text-end" title="Opportunity Nr number"># {{ card.meter_opportunity_nr_auto }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="kanban-card-body">
              <div class="row align-item-center card-price">
                <div class="col-4">
                  <p title="Date oF Sale">
                    <i class="ti ti-calendar-check"></i>
                    {{ card.date_of_sale }}
                  </p>
                </div>
                <div class="col-4">
                  <p title="Next Update Latest">
                    <i class="ti ti-calendar-check"></i>
                    {{ card.next_update_latest }}
                  </p>
                </div>
                <div class="col-4">
                  <p class="text-end" title="SC Forcast Total Commision">
                    <i class="ion-cash"></i>
                    {{ card.sc_forecast_total_commission }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </draggable>
    </ul>
  </div>
</div>
</template>

<script>
import {
  VueDraggableNext
} from "vue-draggable-next";
import {
  ref,
  onMounted,
  computed,
  watchEffect
} from 'vue';
import {
  useRouter
} from 'vue-router';
import {
  useStore
} from "vuex";

export default {
  props: {
    searchBoardQuery: String,
    filters: Object,
  },
  components: {
    draggable: VueDraggableNext,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const modifiedPipeName = ref([]);
    const opportunities = computed(() => store.getters['opportunity/getOppListData']);
    const pipelineLevels = computed(() => store.getters['opportunity/getPipelineLevels']);
    const sourcePipeId = ref(null);

    // Transform pipeline names
    watchEffect(() => {
      if (pipelineLevels.value && pipelineLevels.value.length) {
        modifiedPipeName.value = pipelineLevels.value.map(item => ({
          ...item,
          name: item.name.replace(/\[\d+\]\s*/, '') // Remove "[n]" from the name
        }));
      }
    });
    const fetchBoardOpportunities = async () => {
      const params = {
        search: props.searchBoardQuery, // Use the correct prop
      };

      await store.dispatch('opportunity/fetchOpportunitiesLists', params);
    };
    onMounted(() => {
      fetchBoardOpportunities();
    });

    // Get cards by pipe ID
    const getCardsByPipe = (pipeID) => {
      return Array.isArray(opportunities.value) ?
        opportunities.value.filter(card => card.pipeline_level === pipeID) : [];
    };

    const onDragEnd = async (event, id) => {
      // Retrieve the card ID from the dragged item
      const draggableCard = event.item;
      const cardInfo = JSON.parse(draggableCard.dataset.cardInfo);
      const targetElement = event.to;
      const draggableComponent = targetElement.__draggable_component__;

      // Retrieve the data-pipe-id from the draggable component's $attrs
      const newPipelineId = draggableComponent.$attrs['data-pipe-id'];
      const form = ref({
        id: cardInfo.id,
        pipeline_level: newPipelineId,
      });
      const response = await store.dispatch('opportunity/updateOpportunity', form.value);
      if (response.status) {
        await store.dispatch('opportunity/fetchOpportunitiesLists');
      }
    };

    return {
      modifiedPipeName,
      fetchBoardOpportunities,
      getCardsByPipe,
      onDragEnd,
    };
  },
};
</script>

<style>
.kanban-action-btns {
  a {
    background-color: green;
  }
}

.card-price {
  p {
    font-size: 10px;
  }
}
</style>
