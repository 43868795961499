<template>
  <!-- Add New Invoices -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Invoice</h4>
        <a href="#" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Client</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select :options="AsilverSel" id="asilversel" placeholder="Choose" />
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Bill To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Ship To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Project</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select :options="ServeSel" id="sevesel" placeholder="Choose" />
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Amount<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Currency</label>
                  <vue-select
                    :options="InrCurrent"
                    id="inrcurrent"
                    placeholder="Choose"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Invoice Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdateTwo"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Due Date<span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdateThree"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Payment Method</label>
                  <vue-select :options="InSelac" id="inselac" placeholder="Choose" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Status</label>
                  <vue-select :options="AcSelac" id="acselac" placeholder="Choose" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Project</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select :options="HawkSel" id="hawksel" placeholder="Choose" />
              </div>
              <div class="table-responsive">
                <table class="table table-view">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="input-table input-table-descripition">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <select>
                            <option>%</option>
                            <option>5%</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <a href="#" class="btn btn-success-light">
                          <i class="ti ti-check"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="subtotal-div mb-3">
                <ul class="mb-3">
                  <li>
                    <h5>Subtotal</h5>
                    <h6>$0.00</h6>
                  </li>
                  <li>
                    <h5>Discount 2%</h5>
                    <h6>$0.00</h6>
                  </li>
                  <li>
                    <h5>Extra Discount 0%</h5>
                    <h6>$0.00</h6>
                  </li>
                  <li>
                    <h5>Tax</h5>
                    <h6>$0.00</h6>
                  </li>
                  <li>
                    <h5>Total</h5>
                    <h6>$0.00</h6>
                  </li>
                </ul>
                <div class="row">
                  <div class="mb-3">
                    <label class="col-form-label">Signature Name</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="col-form-label">Signature Image</label>
                    <div class="upload-signature">
                      <input type="file" />
                      <a href="#">Upload Signature</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Notes<span class="text-danger"> *</span></label
                  >
                  <textarea class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Terms & Conditions<span class="text-danger"> *</span></label
                  >
                  <textarea class="form-control" rows="5"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="#" class="btn btn-light sidebar-close">Cancel</a>
            <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#create_invoices"
              >Create</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Project -->

  <!-- Edit Invoices -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Invoices</h4>
        <a href="#" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <form class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Client</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select
                  :options="EditAsilverSel"
                  id="editasilversel"
                  placeholder="NovaWave LLC"
                />
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Bill To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Ship To<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Project</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select
                  :options="EditServeSel"
                  id="editseversel"
                  placeholder="Truelysell"
                />
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Amount<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" value="2,15,000" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Currency</label>
                  <vue-select
                    :options="EditInrCurrent"
                    id="editinrcurrent"
                    placeholder="Choose"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Invoice Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdate"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Due Date<span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    <date-picker
                      v-model="startdateOne"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Payment Method</label>
                  <vue-select
                    :options="EditInSelac"
                    id="editinselac"
                    placeholder="Choose"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Status</label>
                  <vue-select
                    :options="EditAcSelac"
                    id="editacselac"
                    placeholder="Choose"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
              <div class="form-wrap">
                <div class="d-flex align-items-center justify-content-between">
                  <label class="col-form-label">Project</label>
                  <a
                    href="#"
                    class="label-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_deal"
                    ><i class="ti ti-square-rounded-plus"></i>Add New</a
                  >
                </div>
                <vue-select
                  :options="EditHawkSel"
                  id="edithawksel"
                  placeholder="Choose"
                />
              </div>
              <div class="table-responsive">
                <table class="table table-view">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="input-table input-table-descripition">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <select>
                            <option>%</option>
                            <option>5%</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" />
                        </div>
                      </td>
                      <td>
                        <a href="#" class="btn btn-success-light">
                          <i class="ti ti-check"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive">
                <table class="table table-view">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="input-table input-table-descripition">
                          <input type="text" value="Web Design" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" value="1" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" value="$200" />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <vue-select
                            :options="EditAmounSelOne"
                            id="editamounselone"
                            placeholder="%"
                          />
                        </div>
                      </td>
                      <td>
                        <div class="input-table">
                          <input type="text" value="$196" />
                        </div>
                      </td>
                      <td>
                        <a href="#" class="btn btn-danger-light">
                          <i class="ti ti-x"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="subtotal-div mb-3">
                <ul class="mb-3">
                  <li>
                    <h5>Subtotal</h5>
                    <h6>$196.00</h6>
                  </li>
                  <li>
                    <h5>Discount 2%</h5>
                    <h6>$4.00</h6>
                  </li>
                  <li>
                    <h5>Extra Discount 0%</h5>
                    <h6>$0.00</h6>
                  </li>
                  <li>
                    <h5>Tax</h5>
                    <h6>$0.00</h6>
                  </li>
                  <li>
                    <h5>Total</h5>
                    <h6>$192.00</h6>
                  </li>
                </ul>
                <div class="row">
                  <div class="mb-3">
                    <label class="col-form-label">Signature Name</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div class="mb-0">
                    <label class="col-form-label">Signature Image</label>
                    <div class="upload-signature">
                      <input type="file" />
                      <a href="#" class="text-start">
                        <img src="@/assets/img/signature.png" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Notes<span class="text-danger"> *</span></label
                  >
                  <textarea class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Terms & Conditions<span class="text-danger"> *</span></label
                  >
                  <textarea class="form-control" rows="5"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="#" class="btn btn-light sidebar-close1">Cancel</a>
            <button type="submit" class="btn btn-primary">Save Changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Invoices -->

  <!-- view Invoices -->
  <div class="toggle-popup2">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Invoices<span class="badge badge-purple-light ms-2">#274738</span></h4>
        <div class="d-flex align-items-center">
          <div class="toggle-header-popup">
            <div class="dropdown table-action">
              <a
                href="#"
                class="btn btn-dropdowns dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Download
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#"> Download </a>
                <a class="dropdown-item" href="#"> Download PDF </a>
              </div>
            </div>
          </div>
          <a href="#" class="sidebar-close toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="card">
          <div class="card-body">
            <div class="details-propsal details-propsals">
              <h4>
                Invoice Details<span class="badge badge-success ms-2 d-inline-flex"
                  >Paid</span
                >
              </h4>
              <ul>
                <li>
                  <h5>Invoice Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Expiry Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Client</h5>
                  <h6>NovaWave LLC</h6>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <div class="row">
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Bill To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Ship To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>1</td>
                <td>$200</td>
                <td>2%</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="subtotal-div">
          <ul>
            <li>
              <h5>Subtotal</h5>
              <h6>$196.00</h6>
            </li>
            <li>
              <h5>Discount 2%</h5>
              <h6>$4.00</h6>
            </li>
            <li>
              <h5>Extra Discount 0%</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Tax</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
            <li>
              <h5>Total Paid</h5>
              <h6>$92.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
          </ul>
        </div>
        <div class="terms-condition">
          <h2>Terms & Conditions</h2>
          <p class="p-0">
            The products/services listed in this invoice will be delivered/provided as per
            the specifications and schedule detailed in the invoice or as agreed upon by
            both parties in previous communications.
          </p>
        </div>
        <hr />
        <div class="details-propsal">
          <h4>Transactions</h4>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Payment</th>
                <th>Payment Mode</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>Credit</td>
                <td>13 May 2024</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- /view Invoices -->

  <!-- view Contracts -->
  <div class="toggle-popup-draft">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Invoices<span class="badge badge-purple-light ms-2">#274738</span></h4>
        <div class="d-flex align-items-center">
          <div class="toggle-header-popup">
            <div class="dropdown table-action">
              <a
                href="#"
                class="btn btn-dropdowns dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Download
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#"> Download </a>
                <a class="dropdown-item" href="#"> Download PDF </a>
              </div>
            </div>
          </div>
          <a href="#" class="sidebar-close toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="card">
          <div class="card-body">
            <div class="details-propsal details-propsals">
              <h4>
                Invoice Details<span class="badge badge-warning ms-2 d-inline-flex"
                  >Partially Paid</span
                >
              </h4>
              <ul>
                <li>
                  <h5>Invoice Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Expiry Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Client</h5>
                  <h6>NovaWave LLC</h6>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <div class="row">
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Bill To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Ship To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>1</td>
                <td>$200</td>
                <td>2%</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="subtotal-div">
          <ul>
            <li>
              <h5>Subtotal</h5>
              <h6>$196.00</h6>
            </li>
            <li>
              <h5>Discount 2%</h5>
              <h6>$4.00</h6>
            </li>
            <li>
              <h5>Extra Discount 0%</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Tax</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
            <li>
              <h5>Total Paid</h5>
              <h6>$92.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
            <li>
              <h5 class="text-danger">Amount Due</h5>
              <h6 class="text-danger">$100.00</h6>
            </li>
          </ul>
        </div>
        <div class="terms-condition">
          <h2>Terms & Conditions</h2>
          <p class="p-0">
            The products/services listed in this invoice will be delivered/provided as per
            the specifications and schedule detailed in the invoice or as agreed upon by
            both parties in previous communications.
          </p>
        </div>
        <hr />
        <div class="details-propsal">
          <h4>Transactions</h4>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Payment</th>
                <th>Payment Mode</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>Credit</td>
                <td>13 May 2024</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="radio-wrap form-wrap">
              <label class="col-form-label">Online Payment</label>
              <div class="d-flex flex-wrap">
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="public"
                    name="visible"
                    checked
                  />
                  <label for="public">Credit</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="radio-wrap form-wrap text-end">
              <label class="col-form-label">Offline Payment</label>
              <div class="d-flex flex-wrap justify-content-end">
                <div class="radio-btn">
                  <input type="radio" class="status-radio" id="bank" name="visible" />
                  <label for="bank">Bank</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-wrap">
              <label class="col-form-label"
                >Amount<span class="text-danger"> *</span></label
              >
              <div class="row">
                <div class="col-lg-9">
                  <input class="form-control" type="text" placeholder="$100" />
                </div>
                <div class="col-lg-3 pay-now">
                  <a href="#" class="btn btn-success w-100">Pay Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /view Contracts -->

  <div class="toggle-popup-declined">
    <!-- estimation -->
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Invoices<span class="badge badge-purple-light ms-2">#274738</span></h4>
        <div class="d-flex align-items-center">
          <div class="toggle-header-popup">
            <div class="dropdown table-action">
              <a
                href="#"
                class="btn btn-dropdowns dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Download
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#"> Download </a>
                <a class="dropdown-item" href="#"> Download PDF </a>
              </div>
            </div>
          </div>
          <a href="#" class="sidebar-close toggle-btn ms-2"><i class="ti ti-x"></i></a>
        </div>
      </div>
      <div class="toggle-body">
        <div class="card">
          <div class="card-body">
            <div class="details-propsal details-propsals">
              <h4>
                Invoice Details<span class="badge badge-danger ms-2 d-inline-flex"
                  >Unpaid</span
                >
              </h4>
              <ul>
                <li>
                  <h5>Invoice Date</h5>
                  <h6>24 Jan 2024</h6>
                </li>
                <li>
                  <h5>Expiry Date</h5>
                  <h6>26 Jan 2024</h6>
                </li>
                <li>
                  <h5>Client</h5>
                  <h6>NovaWave LLC</h6>
                </li>
              </ul>
            </div>
            <div class="details-propsal">
              <div class="row">
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>From</h6>
                    <h3>CRMS</h3>
                    <span>3338 Marcus Street </span>
                    <span>Birmingham, AL 35211</span>
                    <span>Phone : +1 98789 78788 </span>
                    <span>Email : info@example.com</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Bill To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="proposal-to">
                    <h6>Ship To</h6>
                    <h3>NovaWave LLC</h3>
                    <span>994 Martine Ranch Suite 900</span>
                    <span>Candacefort New Hampshire</span>
                    <span>Phone : +1 58478 74646</span>
                    <span>Email : info@example.net</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>1</td>
                <td>$200</td>
                <td>2%</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="subtotal-div">
          <ul>
            <li>
              <h5>Subtotal</h5>
              <h6>$196.00</h6>
            </li>
            <li>
              <h5>Discount 2%</h5>
              <h6>$4.00</h6>
            </li>
            <li>
              <h5>Extra Discount 0%</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Tax</h5>
              <h6>$0.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
            <li>
              <h5>Total Paid</h5>
              <h6>$92.00</h6>
            </li>
            <li>
              <h5>Total</h5>
              <h6>$192.00</h6>
            </li>
            <li>
              <h5 class="text-danger">Amount Due</h5>
              <h6 class="text-danger">$100.00</h6>
            </li>
          </ul>
        </div>
        <div class="terms-condition">
          <h2>Terms & Conditions</h2>
          <p class="p-0">
            The products/services listed in this invoice will be delivered/provided as per
            the specifications and schedule detailed in the invoice or as agreed upon by
            both parties in previous communications.
          </p>
        </div>
        <hr />
        <div class="details-propsal">
          <h4>Transactions</h4>
        </div>
        <div class="table-responsive">
          <table class="table table-view">
            <thead>
              <tr>
                <th>Payment</th>
                <th>Payment Mode</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Web Design</td>
                <td>Credit</td>
                <td>13 May 2024</td>
                <td>$196</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="radio-wrap form-wrap">
              <label class="col-form-label">Online Payment</label>
              <div class="d-flex flex-wrap">
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="public"
                    name="visible"
                    checked
                  />
                  <label for="public">Credit</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="radio-wrap form-wrap text-end">
              <label class="col-form-label">Offline Payment</label>
              <div class="d-flex flex-wrap justify-content-end">
                <div class="radio-btn">
                  <input type="radio" class="status-radio" id="bank" name="visible" />
                  <label for="bank">Bank</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-wrap">
              <label class="col-form-label"
                >Amount<span class="text-danger"> *</span></label
              >
              <div class="row">
                <div class="col-lg-9">
                  <input class="form-control" type="text" placeholder="$100" />
                </div>
                <div class="col-lg-3 pay-now">
                  <a href="#" class="btn btn-success w-100">Pay Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- estimation -->
  </div>

  <!-- Delete Invoices -->
  <div class="modal custom-modal fade" id="delete_invoices" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Invoices?</h3>
            <p class="del-info">Are you sure you want to remove invoices you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/invoices-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Invoices -->

  <!-- Create Invoices -->
  <div class="modal custom-modal fade" id="create_invoices" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-atom-2"></i>
            </div>
            <h3>Invoices Created Successfully!!!</h3>
            <p>View the details of invoices, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <a href="#" class="btn btn-primary">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Project -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="add_deal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/crm/invoices">
            <div class="form-wrap">
              <label class="col-form-label">Name</label>
              <input type="text" class="form-control" placeholder="Enter Name" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
const currentDateThree = ref(new Date());
export default {
  data() {
    return {
      editor: ClassicEditor,
      editorData: "Description",
      editorConfig: {},
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      startdateThree: currentDateThree,
      dateFormat: "dd-MM-yyyy",
      AsilverSel: ["CHoose", "NovaWave LLC", "BlueSky Industries"],
      ServeSel: ["Choose", "Truelysell", "Dreamschat", "Servebook"],
      InrCurrent: ["Choose", "INR", "Euro"],
      InSelac: ["Choose", "Active", "Inactive"],
      AcSelac: ["Choose", "Active", "Inactive"],
      HawkSel: ["Choose", "NovaWave LLC", "BlueSky Industries", "ASilver Hawkdams"],
      EditAsilverSel: ["CHoose", "NovaWave LLC", "BlueSky Industries"],
      EditServeSel: ["Choose", "Truelysell", "Dreamschat", "Servebook"],
      EditInrCurrent: ["Choose", "INR", "Euro"],
      EditInSelac: ["Choose", "Active", "Inactive"],
      EditAcSelac: ["Choose", "Active", "Inactive"],
      EditHawkSel: ["Choose", "NovaWave LLC", "BlueSky Industries", "ASilver Hawkdams"],
      EditAmounSelOne: ["%", "5%"],
    };
  },
};
</script>
