<template>
  <div class="card analytics-card">
    <div class="card-header d-flex justify-content-between card-selectsview flex-wrap">
      <h3 class="card-title">
        <i class="ti ti-grip-vertical"></i>Recently Created Campaign
      </h3>
      <div class="card-select">
        <ul>
          <li>
            <vue-select :options="Days" id="dayscomplete" placeholder="Last 30 days" />
          </li>
          <li>
            <a href="javascript:;" class="btn btn-primary edit-popup"
              ><i class="ti ti-square-rounded-plus"></i> Add Pipeline</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="campaign-card">
        <ul>
          <li class="campaign-wrap">
            <div class="campaign-info">
              <div class="campaign-name">
                <h5>Distribution</h5>
                <p>Public Relations</p>
              </div>
              <ul class="list-progress">
                <li>
                  <h6>30.5%</h6>
                  <p>Opened</p>
                </li>
                <li>
                  <h6>20.5%</h6>
                  <p>Closed</p>
                </li>
                <li>
                  <h6>30.5%</h6>
                  <p>Unsubscribe</p>
                </li>
                <li>
                  <h6>70.5%</h6>
                  <p>Delivered</p>
                </li>
                <li>
                  <h6>35.0%</h6>
                  <p>Conversation</p>
                </li>
              </ul>
            </div>
            <div class="campaign-action">
              <div class="campaign-date">
                <span class="badge badge-pill badge-status bg-danger">Bounced</span>
                <p>Due Date : 25 Sep 2023</p>
              </div>
              <ul class="project-mem">
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-14.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-15.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-16.jpg" alt="img"
                  /></a>
                </li>
                <li class="more-set"><a href="javascript:;">+03</a></li>
              </ul>
            </div>
          </li>
          <li class="campaign-wrap">
            <div class="campaign-info">
              <div class="campaign-name">
                <h5>Pricing</h5>
                <p>Social Marketing</p>
              </div>
              <ul class="list-progress">
                <li>
                  <h6>42.5%</h6>
                  <p>Opened</p>
                </li>
                <li>
                  <h6>38.2%</h6>
                  <p>Closed</p>
                </li>
                <li>
                  <h6>48.8%</h6>
                  <p>Unsubscribe</p>
                </li>
                <li>
                  <h6>62.7%</h6>
                  <p>Delivered</p>
                </li>
                <li>
                  <h6>22.5%</h6>
                  <p>Conversation</p>
                </li>
              </ul>
            </div>
            <div class="campaign-action">
              <div class="campaign-date">
                <span class="badge badge-pill badge-status bg-green">Running</span>
                <p>Due Date : 14 Oct 2023</p>
              </div>
              <ul class="project-mem">
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-14.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-15.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-16.jpg" alt="img"
                  /></a>
                </li>
                <li class="more-set"><a href="javascript:;">+04</a></li>
              </ul>
            </div>
          </li>
          <li class="campaign-wrap">
            <div class="campaign-info">
              <div class="campaign-name">
                <h5>Merchandising</h5>
                <p>Content Marketing</p>
              </div>
              <ul class="list-progress">
                <li>
                  <h6>52.5%</h6>
                  <p>Opened</p>
                </li>
                <li>
                  <h6>29.3%</h6>
                  <p>Closed</p>
                </li>
                <li>
                  <h6>62.8%</h6>
                  <p>Unsubscribe</p>
                </li>
                <li>
                  <h6>71.3%</h6>
                  <p>Delivered</p>
                </li>
                <li>
                  <h6>39.5%</h6>
                  <p>Conversation</p>
                </li>
              </ul>
            </div>
            <div class="campaign-action">
              <div class="campaign-date">
                <span class="badge badge-pill badge-status bg-info">Paused</span>
                <p>Due Date : 14 Oct 2023</p>
              </div>
              <ul class="project-mem">
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-14.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-15.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-16.jpg" alt="img"
                  /></a>
                </li>
                <li class="more-set"><a href="javascript:;">+06</a></li>
              </ul>
            </div>
          </li>
          <li class="campaign-wrap">
            <div class="campaign-info">
              <div class="campaign-name">
                <h5>Repeat Customers</h5>
                <p>Rebranding</p>
              </div>
              <ul class="list-progress">
                <li>
                  <h6>17.5%</h6>
                  <p>Opened</p>
                </li>
                <li>
                  <h6>49.5%</h6>
                  <p>Closed</p>
                </li>
                <li>
                  <h6>35.2%</h6>
                  <p>Unsubscribe</p>
                </li>
                <li>
                  <h6>54.8%</h6>
                  <p>Delivered</p>
                </li>
                <li>
                  <h6>60.5%</h6>
                  <p>Conversation</p>
                </li>
              </ul>
            </div>
            <div class="campaign-action">
              <div class="campaign-date">
                <span class="badge badge-pill badge-status bg-success">Success</span>
                <p>Due Date : 29 Oct 2023</p>
              </div>
              <ul class="project-mem">
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-14.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-15.jpg" alt="img"
                  /></a>
                </li>
                <li>
                  <a href="javascript:;"
                    ><img src="@/assets/img/profiles/avatar-16.jpg" alt="img"
                  /></a>
                </li>
                <li class="more-set"><a href="javascript:;">+02</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Days: ["Last 30 days", "Last 15 days", "Last 7 days"],
    };
  },
  mounted() {
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopups = document.getElementsByClassName("edit-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close1");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
