<template>
  <!-- Delete Contact -->
  <div class="modal custom-modal fade" id="delete_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Contacts?</h3>
            <p class="del-info">Are you sure you want to remove contact you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <router-link to="/crm/contacts-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Contact -->

  <!-- Add Note -->
  <div class="modal custom-modal fade modal-padding" id="add_notes" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New Notes</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="form-wrap">
              <label class="col-form-label"
                >Title <span class="text-danger"> *</span></label
              >
              <input class="form-control" type="text" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Note <span class="text-danger"> *</span></label
              >
              <textarea class="form-control" rows="4"></textarea>
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Attachment <span class="text-danger"> *</span></label
              >
              <div class="drag-attach">
                <input type="file" />
                <div class="img-upload"><i class="ti ti-file-broken"></i>Upload File</div>
              </div>
            </div>
            <div class="form-wrap">
              <label class="col-form-label">Uploaded Files</label>
              <div class="upload-file">
                <h6>Projectneonals teyys.xls</h6>
                <p>4.25 MB</p>
                <div class="progress">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 25%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="black-text">45%</p>
              </div>
              <div class="upload-file upload-list">
                <div>
                  <h6>tes.txt</h6>
                  <p>4.25 MB</p>
                </div>
                <a href="javascript:void(0);" class="text-danger"
                  ><i class="ti ti-trash-x"></i
                ></a>
              </div>
            </div>
            <div class="col-lg-12 text-end modal-btn">
              <a class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button class="btn btn-primary" type="submit">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Note -->

  <!-- Create Call Log -->
  <div class="modal custom-modal fade modal-padding" id="create_call" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Call Log</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Status <span class="text-danger"> *</span></label
                  >
                  <vue-select :options="Status" id="busy" placeholder="Busy" />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Follow Up Date <span class="text-danger"> *</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdate"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Note <span class="text-danger"> *</span></label
                  >
                  <textarea
                    class="form-control"
                    rows="4"
                    placeholder="Add text"
                  ></textarea>
                </div>
                <div class="form-wrap">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span> Create a followup task
                  </label>
                </div>
                <div class="text-end modal-btn">
                  <a class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                  <button class="btn btn-primary" type="submit">Confirm</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Call Log -->

  <!-- Add File -->
  <div
    class="modal custom-modal fade custom-modal-two modal-padding"
    id="new_file"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create New File</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="add-info-fieldset">
            <div class="add-details-wizard">
              <ul class="progress-bar-wizard">
                <li :class="{ active: fieldOne }">
                  <span><i class="ti ti-file"></i></span>
                  <div class="multi-step-info">
                    <h6>Basic Info</h6>
                  </div>
                </li>
                <li :class="{ active: fieldTwo }">
                  <span><i class="ti ti-circle-plus"></i></span>
                  <div class="multi-step-info">
                    <h6>Add Recipient</h6>
                  </div>
                </li>
              </ul>
            </div>
            <fieldset
              id="first-field-file"
              :style="{ display: fieldOne ? 'block' : 'none' }"
            >
              <form @submit.prevent="submitForm">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Choose Deal <span class="text-danger">*</span></label
                        >
                        <vue-select :options="Deal" id="wisozk" placeholder="Select" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Document Type <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="Document"
                          id="proposal"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Owner <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="Owner"
                          placeholder="Select"
                          id="ownerselect"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Title <span class="text-danger"> *</span></label
                        >
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Locale <span class="text-danger">*</span></label
                        >
                        <vue-select
                          :options="Locale"
                          id="localeone"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="signature-wrap">
                        <h4>Signature</h4>
                        <ul class="nav sign-item">
                          <li class="nav-item">
                            <span
                              class="mb-0"
                              data-bs-toggle="tab"
                              data-bs-target="#nosign"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="sign1"
                                name="email"
                              />
                              <label for="sign1"
                                ><span class="sign-title">No Signature</span>This document
                                does not require a signature before acceptance.</label
                              >
                            </span>
                          </li>
                          <li class="nav-item">
                            <span
                              class="active mb-0"
                              data-bs-toggle="tab"
                              data-bs-target="#use-esign"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="sign2"
                                name="email"
                                checked
                              />
                              <label for="sign2"
                                ><span class="sign-title">Use e-signature</span>This
                                document require e-signature before acceptance.</label
                              >
                            </span>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div class="tab-pane show active" id="use-esign">
                            <div class="input-block mb-0">
                              <label class="col-form-label"
                                >Document Signers
                                <span class="text-danger">*</span></label
                              >
                            </div>
                            <div class="sign-content">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-wrap">
                                    <input
                                      class="form-control"
                                      type="text"
                                      placeholder="Enter Name"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="d-flex align-items-center">
                                    <div class="float-none form-wrap me-3 w-100">
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Email Address"
                                      />
                                    </div>
                                    <div class="input-btn form-wrap">
                                      <a href="javascript:void(0);" class="add-sign"
                                        ><i class="ti ti-circle-plus"></i
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input-block mb-3">
                        <label class="col-form-label"
                          >Content <span class="text-danger"> *</span></label
                        >
                        <textarea
                          class="form-control"
                          rows="3"
                          placeholder="Add Content"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button modal-btn">
                      <button class="btn btn-light" type="reset">Reset</button>
                      <button
                        class="btn btn-primary wizard-next-btn"
                        @click="FieldsetTwo"
                        type="button"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <fieldset :style="{ display: fieldTwo ? 'block' : 'none' }">
              <form @submit.prevent="submitForm">
                <div class="contact-input-set">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="signature-wrap">
                        <h4 class="mb-2">Send the document to following signers</h4>
                        <p>In order to send the document to the signers</p>
                        <div class="input-block mb-0">
                          <label class="col-form-label"
                            >Recipients (Additional recipients)</label
                          >
                        </div>
                        <div class="sign-content">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-wrap">
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Enter Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="d-flex align-items-center">
                                <div class="float-none form-wrap me-3 w-100">
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Email Address"
                                  />
                                </div>
                                <div class="input-btn form-wrap">
                                  <a href="javascript:void(0);" class="add-sign"
                                    ><i class="ti ti-circle-plus"></i
                                  ></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Message Subject <span class="text-danger"> *</span></label
                        >
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Enter Subject"
                        />
                      </div>
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Message Text <span class="text-danger"> *</span></label
                        >
                        <textarea
                          class="form-control"
                          rows="3"
                          placeholder="Your document is ready"
                        ></textarea>
                      </div>
                      <button class="btn btn-light mb-3">Send Now</button>
                      <div class="send-success">
                        <p>
                          <i class="ti ti-circle-check"></i> Document sent successfully to
                          the selected recipients
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-12 text-end form-wizard-button modal-btn">
                      <button class="btn btn-light" type="reset">Cancel</button>
                      <button
                        class="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                      >
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add File -->

  <!-- Connect Account -->
  <div class="modal custom-modal fade" id="create_email" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Connect Account</h5>
          <button
            type="button"
            class="btn-close position-static"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="form-wrap">
            <label class="col-form-label"
              >Account type <span class="text-danger"> *</span></label
            >
            <vue-select :options="Account" id="imapone" placeholder="Gmail" />
          </div>
          <div class="form-wrap">
            <h5 class="form-title">Sync emails from</h5>
            <div class="sync-radio">
              <div class="radio-item">
                <input
                  type="radio"
                  class="status-radio"
                  id="test1"
                  name="radio-group"
                  checked=""
                />
                <label for="test1">Now</label>
              </div>
              <div class="radio-item">
                <input type="radio" class="status-radio" id="test2" name="radio-group" />
                <label for="test2">1 Month Ago</label>
              </div>
              <div class="radio-item">
                <input type="radio" class="status-radio" id="test3" name="radio-group" />
                <label for="test3">3 Month Ago</label>
              </div>
              <div class="radio-item">
                <input type="radio" class="status-radio" id="test4" name="radio-group" />
                <label for="test4">6 Month Ago</label>
              </div>
            </div>
          </div>
          <div class="col-lg-12 text-end modal-btn">
            <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
              >Cancel</a
            >
            <button
              class="btn btn-primary"
              data-bs-target="#success_mail"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Connect Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Connect Account -->

  <!-- Success Contact -->
  <div class="modal custom-modal fade" id="success_mail" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-mail-opened"></i>
            </div>
            <h3>Email Connected Successfully!!!</h3>
            <p>
              Email Account is configured with “example@example.com”. Now you can access
              email.
            </p>
            <div class="col-lg-12 text-center modal-btn">
              <router-link to="/crm/contact-details" class="btn btn-primary"
                >Go to email</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Success Contact -->

  <!-- Access -->
  <div class="modal custom-modal fade" id="access_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Access For</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-wrap icon-form">
              <span class="form-icon"><i class="ti ti-search"></i></span>
              <input type="text" class="form-control" placeholder="Search" />
            </div>
            <div class="access-wrap">
              <ul>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-19.jpg" alt="" />
                      <a href="javascript:;">Darlee Robertson</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-20.jpg" alt="" />
                      <a href="javascript:;">Sharon Roy</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-21.jpg" alt="" />
                      <a href="javascript:;">Vaughan</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-01.jpg" alt="" />
                      <a href="javascript:;">Jessica</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-16.jpg" alt="" />
                      <a href="javascript:;">Carol Thomas</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-22.jpg" alt="" />
                      <a href="javascript:;">Dawn Mercha</a>
                    </span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Access -->

  <!-- Add Compose -->
  <div class="modal custom-modal fade" id="add_compose" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Compose</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body p-0">
          <form action="#">
            <div class="form-wrap">
              <input type="email" placeholder="To" class="form-control" />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-wrap">
                  <input type="email" placeholder="Cc" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <input type="email" placeholder="Bcc" class="form-control" />
                </div>
              </div>
            </div>
            <div class="form-wrap">
              <input type="text" placeholder="Subject" class="form-control" />
            </div>
            <div class="form-wrap">
              <ckeditor
                :editor="editor"
                v-model="editorData"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="form-wrap">
              <div class="text-center">
                <button class="btn btn-primary me-2">
                  <span>Send</span><i class="fa-solid fa-paper-plane ms-1"></i>
                </button>
                <button class="btn btn-primary me-2" type="button">
                  <span>Draft</span> <i class="fa-regular fa-floppy-disk ms-1"></i>
                </button>
                <button class="btn btn-primary" type="button">
                  <span>Delete</span> <i class="fa-regular fa-trash-can ms-1"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Compose -->

  <!-- Edit Contact -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Contact</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="#">
            <div class="accordion-lists" id="list-accords">
              <!-- Basic Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-basic"
                  ><span><i class="ti ti-user-plus"></i></span>Basic Info</a
                >
                <div
                  class="accordion-collapse collapse show"
                  id="edit-basic"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="profile-pic-upload">
                          <div class="profile-pic">
                            <span
                              ><img src="@/assets/img/profiles/avatar-20.jpg" alt="img"
                            /></span>
                            <a href="javascript:void(0);" class="close-img"
                              ><i class="ti ti-x"></i
                            ></a>
                          </div>
                          <div class="upload-content">
                            <div class="upload-btn">
                              <input type="file" />
                              <span> <i class="ti ti-file-broken"></i>Upload File </span>
                            </div>
                            <p>JPG, GIF or PNG. Max size of 800K</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >First Name <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" value="Darlee" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Last Name <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" value="Robertson" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Job Title <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            value="Facility Manager"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Company Name</label>
                          <vue-select
                            :options="Companyname"
                            id="companynames"
                            placeholder="NovaWave LLC"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="d-flex justify-content-between align-items-center">
                            <label class="col-form-label"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div
                              class="status-toggle small-toggle-btn d-flex align-items-center"
                            >
                              <span class="me-2 label-text">Email Opt Out</span>
                              <input
                                type="checkbox"
                                id="user2"
                                class="check"
                                checked=""
                              />
                              <label for="user2" class="checktoggle"></label>
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            value="robertson@example.com"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Phone 1 <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" value="1234567890" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Phone 2</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Fax <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Deals</label>
                          <vue-select :options="Adams" placeholder="Collins" id="adams" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Date of Birth</label>
                          <div class="icon-form-end">
                            <date-picker
                              v-model="startdateOne"
                              class="datetimepicker form-control"
                              :editable="true"
                              :clearable="false"
                              :input-format="dateFormat"
                              value="29-02-2020"
                            />
                            <span class="form-icon"
                              ><i class="ti ti-calendar-event"></i
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Reviews </label>
                          <div class="icon-form-end">
                            <span class="form-icon"><i class="ti ti-star"></i></span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="4.2"
                              value="4.2"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Owner</label>
                          <vue-select
                            :options="Ownerone"
                            placeholder="hendry"
                            id="hendry"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Tags </label>
                          <vue3-tags-input
                            class="input-tags form-control"
                            type="text"
                            id="inputBox"
                            data-role="tagsinput"
                            name="Label"
                            value="Tag1"
                            :tags="tags"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Source <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Source"
                            id="referral"
                            placeholder="Phone Calls"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Industry <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Industry"
                            id="industryselect"
                            placeholder="Banking"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Currency <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Language <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Language"
                            id="arabic"
                            placeholder="English"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label"
                            >Description <span class="text-danger">*</span></label
                          >
                          <textarea class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->

              <!-- Address Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-address"
                  ><span><i class="ti ti-map-pin-cog"></i></span>Address Info</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="edit-address"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Street Address </label>
                          <input
                            type="text"
                            class="form-control"
                            value="22, Ave Street"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">City </label>
                          <input type="text" class="form-control" value="Denver" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">State / Province </label>
                          <input type="text" class="form-control" value="Colorado" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Country</label>
                          <vue-select :options="Country" id="uae" placeholder="USA" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Zipcode </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Address Info -->

              <!-- Social Profile -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-social"
                  ><span><i class="ti ti-social"></i></span>Social Profile</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="edit-social"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Facebook</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Skype </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Linkedin </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Twitter</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Whatsapp</label>
                          <input type="text" class="form-control" value="1234567890" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Instagram</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Social Profile -->

              <!-- Access -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#edit-access"
                  ><span><i class="ti ti-accessible"></i></span>Access</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="edit-access"
                  data-bs-parent="#list-accords"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="radio-wrap form-wrap">
                          <label class="col-form-label">Visibility</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-public"
                                name="visible"
                              />
                              <label for="edit-public">Public</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-private"
                                name="visible"
                              />
                              <label for="edit-private">Private</label>
                            </div>
                            <div
                              class="radio-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#access_view"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-people"
                                name="visible"
                              />
                              <label for="edit-people">Select People</label>
                            </div>
                          </div>
                        </div>
                        <div class="radio-wrap">
                          <label class="col-form-label">Status</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-active"
                                name="status"
                                checked=""
                              />
                              <label for="edit-active">Active</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="edit-inactive"
                                name="status"
                              />
                              <label for="edit-inactive">Inactive</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Access -->
            </div>

            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
              <a
                href="javascript:;"
                data-bs-toggle="modal"
                data-bs-target="#create_contact"
                class="btn btn-primary"
                >Create</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Contact -->

  <!-- Add New Deals -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Deals</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Deal Name <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Pipeine <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Pipeline" id="callsselect" placeholder="Choose" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Status <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Won" id="won" placeholder="Choose" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Deal Value<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Currency <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Currency" id="currencytwo" placeholder="Select" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period Value <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Contact <span class="text-danger">*</span></label
                  >
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Jack, Darlee Robertson"
                    :tags1="tags1"
                  />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Project <span class="text-danger">*</span></label
                  >
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Divine dran"
                    :tags2="tags2"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Due Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateTwo"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Expected Closing Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateThree"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Assignee <span class="text-danger">*</span></label
                  >
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="James"
                    :tags3="tags3"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Follow Up Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateFour"
                      placeholder=""
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Source <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Media" placeholder="Select" id="googleselect" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Tags <span class="text-danger">*</span></label
                  >
                  <vue3-tags-input
                    class="input-tags form-control"
                    type="text"
                    data-role="tagsinput"
                    name="Label"
                    value="Tag1"
                    :tags4="tags4"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Priority <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Priority" id="medium" placeholder="Select" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
            </div>
            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Deals -->

  <!-- Add Company -->
  <div class="toggle-popup2">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add Company</h4>
        <a href="javascript:;" class="sidebar-close2 toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="#">
            <div class="accordion-lists" id="list-accord">
              <!-- Basic Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap"
                  data-bs-toggle="collapse"
                  data-bs-target="#basic"
                  ><span><i class="ti ti-user-plus"></i></span>Basic Info</a
                >
                <div
                  class="accordion-collapse collapse show"
                  id="basic"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="profile-pic-upload">
                          <div class="profile-pic">
                            <span><i class="ti ti-photo"></i></span>
                          </div>
                          <div class="upload-content">
                            <div class="upload-btn">
                              <input type="file" />
                              <span> <i class="ti ti-file-broken"></i>Upload File </span>
                            </div>
                            <p>JPG, GIF or PNG. Max size of 800K</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Company Name</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="d-flex justify-content-between align-items-center">
                            <label class="col-form-label"
                              >Email <span class="text-danger">*</span></label
                            >
                            <div
                              class="status-toggle small-toggle-btn d-flex align-items-center"
                            >
                              <span class="me-2 label-text">Email Opt Out</span>
                              <input type="checkbox" id="mail" class="check" checked="" />
                              <label for="mail" class="checktoggle"></label>
                            </div>
                          </div>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Phone 1 <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Phone 2</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Fax <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Website <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Rating </label>
                          <div class="icon-form-end">
                            <span class="form-icon"><i class="ti ti-star"></i></span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="4.2"
                              value="4.2"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap img-select">
                          <label class="col-form-label">Owner</label>
                          <vue-select :options="Jami" id="theresa" placeholder="Choose" />
                          <span class="select-box-img avatar"
                            ><img src="@/assets/img/profiles/avatar-14.jpg" alt=""
                          /></span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Tags </label>
                          <vue3-tags-input
                            class="input-tags form-control"
                            type="text"
                            data-role="tagsinput"
                            name="Label"
                            value="Tag1"
                            :tags5="tags5"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Deals</label>
                          <vue-select
                            :options="Wisozk"
                            id="wiszokselect"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Source <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Source"
                            id="analytics"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Industry <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Industry"
                            id="insurancetwo"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Contact <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Jami"
                            id="jamietwo"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Currency <span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Language <span class="text-danger">*</span></label
                          >
                          <vue-select
                            :options="Language"
                            id="arabickuthu"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label"
                            >Description <span class="text-danger">*</span></label
                          >
                          <textarea class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->

              <!-- Address Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#address"
                  ><span><i class="ti ti-map-pin-cog"></i></span>Address Info</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="address"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label">Street Address </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">City </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">State / Province </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Country</label>
                          <vue-select
                            :options="Country"
                            id="france"
                            placeholder="Choose"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Zipcode </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Address Info -->

              <!-- Social Profile -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#social"
                  ><span><i class="ti ti-social"></i></span>Social Profile</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="social"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Facebook</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Skype </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Linkedin </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Twitter</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Whatsapp</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Instagram</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Social Profile -->

              <!-- Access -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#access"
                  ><span><i class="ti ti-accessible"></i></span>Access</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="access"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="radio-wrap form-wrap">
                          <label class="col-form-label">Visibility</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="add-public"
                                name="visible"
                              />
                              <label for="add-public">Public</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="add-private"
                                name="visible"
                              />
                              <label for="add-private">Private</label>
                            </div>
                            <div
                              class="radio-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#access_view"
                            >
                              <input
                                type="radio"
                                class="status-radio"
                                id="add-people"
                                name="visible"
                              />
                              <label for="add-people">Select People</label>
                            </div>
                          </div>
                        </div>
                        <div class="radio-wrap">
                          <label class="col-form-label">Status</label>
                          <div class="d-flex flex-wrap">
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="add-active"
                                name="status"
                                checked=""
                              />
                              <label for="add-active">Active</label>
                            </div>
                            <div class="radio-btn">
                              <input
                                type="radio"
                                class="status-radio"
                                id="add-inactive"
                                name="status"
                              />
                              <label for="add-inactive">Inactive</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Access -->
            </div>

            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close2">Cancel</a>
              <a href="javascript:;" class="btn btn-primary">Create</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Company -->
</template>

<script>
import { ref } from "vue";
import Vue3TagsInput from "vue3-tags-input";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
const currentDateThree = ref(new Date());
const currentDateFour = ref(new Date());
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "Description",
      editorConfig: {},
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      startdateThree: currentDateThree,
      startdateFour: currentDateFour,
      dateFormat: "dd-MM-yyyy",
      Status: [
        "Busy",
        "Unavailable",
        "No Answer",
        "Wrong Number",
        "Left Voice Message",
        "Moving Forward",
      ],
      Deal: ["Select", "Collins", "Wisozk", "Walter"],
      Document: ["Select", "Contract", "Proposal", "Quote"],
      Owner: ["Select", "Admin", "Jackson Daniel"],
      Locale: ["Select", "en", "es", "ru"],
      Account: ["Gmail", "Outlook", "Imap"],
      Companyname: [
        "Choose",
        "NovaWave LLC",
        "BlueSky Industries",
        "SilverHawk",
        "SummitPeak",
        "RiverStone Ventur",
      ],
      Adams: ["Choose", "Collins", "Konopelski", "Adams", "Schumm", "Wisozk"],
      Ownerone: ["Choose", "Hendry", "Guillory", "Jami", "Theresa", "Espinosa"],
      Source: [
        "Choose",
        "Phone Calls",
        "Social Media",
        "Referral Sites",
        "Web Analytics",
        "Previous Purchases",
      ],
      Industry: [
        "Choose",
        "Retail Industry",
        "Banking",
        "Hotels",
        "Financial Services",
        "Insurance",
      ],
      Language: ["Choose", "English", "Arabic", "Chinese", "Hindi"],
      Country: ["Choose", "India", "USA", "France", "UK", "UAE"],
      Pipeline: ["Choose", "Sales", "Marketing", "Calls"],
      Won: ["Choose", "Open", "Lost", "Won"],
      Currency: ["Select", "$", "€"],
      Media: ["Select", "Google", "Social Media"],
      Priority: ["Select", "Highy", "Low", "Medium"],
      Jami: ["Choose", "Hendry", "Guillory", "Jami", "Theresa", "Espinosa"],
      Wisozk: ["Choose", "Collins", "Konopelski", "Adams", "Schumm", "Wisozk"],
      tags: ["S", "M", "XL"],
      tags1: ["Jack", "Darlee Robertson"],
      tags2: ["Divine dran"],
      tags3: ["James"],
      tags4: ["Tag1"],
      tags5: ["Tag1"],
      fieldOne: true,
      fieldTwo: false,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/crm/contact-details");
    },
    FieldsetTwo() {
      this.fieldOne = false;
      this.fieldTwo = true;
    },
  },
};
</script>
