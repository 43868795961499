<template>
  <div class="modal custom-modal fade" id="add_paypal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Paypal</h5>
          <div class="d-flex align-items-center mod-toggle">
            <div class="status-toggle">
              <input type="checkbox" id="toggle" class="check" checked="" />
              <label for="toggle" class="checktoggle"></label>
            </div>
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <form action="/financial-settings/payment-gateways">
            <div class="form-wrap">
              <label class="col-form-label"
                >From Email Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >API Key <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Secret Key <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label">Status</label>
              <div class="radio-btn-items">
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="pdf"
                    name="export-type"
                    checked=""
                  />
                  <label for="pdf">Active</label>
                </div>
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="share"
                    name="export-type"
                  />
                  <label for="share">Inactive</label>
                </div>
              </div>
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
