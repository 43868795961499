<template>
  <div class="col-xl-3 col-lg-12 theiaStickySidebar">
    <!-- Settings Sidebar -->
    <div class="card">
      <div class="card-body">
        <div class="settings-sidebar">
          <h4>General Settings</h4>
          <ul>
            <li>
              <router-link to="/settings/profile">Profile</router-link>
            </li>
            <!-- <li>
              <router-link to="/settings/security">Security</router-link>
            </li> -->
            <!-- <li>
              <router-link to="/settings/notifications">Notifications</router-link>
            </li>
            <li>
              <router-link to="/settings/connected-apps">Connected Apps</router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- /Settings Sidebar -->
  </div>
</template>
