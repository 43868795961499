<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->

        <!-- Settings Menu -->
        <settings-tabs></settings-tabs>
        <!-- /Settings Menu -->

        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <!-- Prefixes -->
            <div class="card">
              <div class="card-body settings-form">
                <div class="settings-header">
                  <website-sidebar></website-sidebar>
                </div>
                <form @submit.prevent="handleSubmit" v-if="Object.keys(moduleArrays).length > 0">
                  <div class="profile-details">
                    <!-- Loop through each module in moduleArrays -->
                    <div class="mb-3" v-for="(moduleFields, moduleName) in moduleArrays" :key="moduleName">
                      <div class="settings-sub-header">
                        <div class="row">
                          <div class="col-12 bg-teal py-1">
                            <h6 class='text-white'>{{ moduleName }}</h6>
                            <p class='text-white'>Provide the basic information below</p>
                          </div>
                        </div>
                      </div>

                      <!-- Loop through fields in the module -->
                      <div class="row" v-for="(orgs, fieldIndex) in moduleFields" :key="orgs.id">
                        <!-- Column AA -->
                        <div class="col-md-4">
                          <div class="setting-title">
                            <h6>{{ orgs.field_name }}</h6>
                          </div>
                        </div>

                        <!-- Column BB -->
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <select class="form-select" v-model="fileColumnsMapping[moduleName][fieldIndex]">
                              <option value="">-----Select-----</option>
                              <option v-for="org in fileColumns" :key="org.id" :value="org.id">
                                {{ org.id }} {{ org.column_name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <!-- Column CC -->
                        <div class="col-md-4">
                          <div class="form-wrap">
                            <div v-if="orgs.field_type === 'dropdown' && orgs.dropdown_options">
                              <select class="form-select" v-model="sameValuesMapping[moduleName][fieldIndex]" v-if="(orgs.field_name === 'Responsible' || orgs.field_name === 'Assign To' || orgs.field_name === 'Sales Responsible' || orgs.field_name === 'Referred By')">
                                <option value="">-----Select-----</option>
                                <option v-for="option in resUsers" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </option>
                              </select>
                              <select class="form-select" v-model="sameValuesMapping[moduleName][fieldIndex]" v-else>
                                <option value="">-----Select-----</option>
                                <option v-for="option in orgs.dropdown_options" :key="option.value || option.id" :value="option.value || option.id">
                                  {{ option.text || option.name || option.country_name }}
                                </option>
                              </select>
                            </div>
                            <div v-else-if="orgs.field_type === 'text'">
                              <input type="text" class="form-control" v-model="sameValuesMapping[moduleName][fieldIndex]" />
                            </div>
                            <div v-else-if="orgs.field_type === 'textarea'">
                              <textarea class="form-control" v-model="sameValuesMapping[moduleName][fieldIndex]"></textarea>
                            </div>
                            <div v-else-if="orgs.field_type === 'datepicker'">
                              <div class="icon-form">
                                <date-picker placeholder="" v-model="sameValuesMapping[moduleName][fieldIndex]" class="datetimepicker form-control" :editable="true" :clearable="false" :input-format="dateFormat" />
                                <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                              </div>
                            </div>
                            <div v-else>
                              <input type="text" class="form-control" v-model="sameValuesMapping[moduleName][fieldIndex]" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Submit Button -->
                  <div class="submit-button">
                    <div class="row">
                      <div class="col-md-6">
                        <!-- <a href="javascript:;" class="btn btn-light">Cancel</a> -->
                        <button type="submit" class="btn btn-primary">Save Changes</button>
                      </div>
                      <div class="col-md-6 text-end">
                        <button type="btn" v-if="isMapped" @click="beginImport" class="btn btn-teal">Begin Import</button>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="row" v-else>
                    <div class="col"></div>
                    <div class="col-md-7 py-3 bg-light">
                     <p class="text-center "><i class="ion-information-circled"></i> Please upload and validate a file</p>
                    </div>
                    <div class="col"></div>
                  </div>
              </div>
            </div>
            <!-- /Prefixes -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute
} from "vue-router";
import router from "@/router";

export default {
  data() {
    return {
      title: "Settings",
      dateFormat: "dd-MM-yyyy",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const fileColumns = ref([]);
    const file_id = ref(route.query.fileId || localStorage.getItem('fileId') || null);

    const moduleArrays = ref({});
    const isMapped = ref(false)

    const resUsers = computed(() => store.getters['dataimport/getresUsers']);
    
    // Separate mappings for each module
    const fileColumnsMapping = ref({});
    const sameValuesMapping = ref({});

    const fetchMappingData = async (id) => {
      try {
        const data = await store.dispatch("dataimport/fetchMappingData", id);
        const foundObject = data.find(item => item.file_columns);

        // Extract the file_columns
        fileColumns.value = foundObject ? foundObject.file_columns : [];

        // Initialize mappings for each module
        data.forEach(item => {
          if (item.modules && Array.isArray(item.modules)) {
            item.modules.forEach(module => {
              moduleArrays.value[module.name] = module.module_fields;

              // Initialize fileColumnsMapping and sameValuesMapping for each module
              fileColumnsMapping.value[module.name] = [];
              sameValuesMapping.value[module.name] = [];
            });
          }
        });
      } catch (error) {
        console.error("Error fetching dataimport data:", error);
      }
    };

    const handleSubmit = async () => {
      if(isMapped.value === true){
        return;
      }
      const mappingData = [];

      Object.entries(moduleArrays.value).forEach(([moduleName, moduleFields]) => {
        moduleFields.forEach((org, index) => {
          const fileColumnId = fileColumnsMapping.value[moduleName][index];
          const sameValue = sameValuesMapping.value[moduleName][index];

          // Only add to mappingData if fileColumnId or sameValue is provided
          if (fileColumnId || sameValue) {
            mappingData.push({
              module_field_id: org.id,
              file_column_id: fileColumnId || null, // Include null if no file_column_id
              same_value_for_all: sameValue ? true : false,
              same_value: sameValue || null, // Include null if no same_value
            });
          }
        });
      });

      const payload = {
        file_id: file_id.value,
        mapping_data: mappingData,
      };

      const data = await store.dispatch("dataimport/postMappingData", payload);
      if (data === 201) {
        isMapped.value = true;
      }
    };
    const beginImport = async () => {
      const data = await store.dispatch("dataimport/beginImportData", file_id.value);
      if (data.status === 200) {
        router.push('/data-import/import-data-status')
        isMapped.value = false;
      }
    }

    onMounted(async () => {
      if (file_id.value) {
        fetchMappingData(file_id.value);
      }
      await store.dispatch('dataimport/fetchResponsibleUsers');
    });

    return {
      moduleArrays,
      fileColumns,
      fileColumnsMapping,
      sameValuesMapping,
      handleSubmit,
      isMapped,
      beginImport,
      file_id,
      resUsers
    };
  },
};
</script>
