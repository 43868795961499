<template>
  <!-- Add New Pipeline -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Pipeline</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <form action="pipeline-list" class="toggle-height">
          <div class="pro-create">
            <div class="form-wrap">
              <label class="col-form-label"
                >Pipeline Name <span class="text-danger">*</span></label
              >
              <input class="form-control" type="text" />
            </div>
            <div class="form-wrap">
              <div class="pipe-title d-flex align-items-center justify-content-between">
                <h5 class="form-title">Pipeline Stages</h5>
                <a
                  href="javascript:;"
                  class="add-stage"
                  data-bs-toggle="modal"
                  data-bs-target="#add_stage"
                  ><i class="ti ti-square-rounded-plus"></i>Add New</a
                >
              </div>
              <div class="pipeline-listing">
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Inpipeline</p>
                  <div class="action-pipeline">
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#edit_stage"
                      ><i class="ti ti-edit text-blue"></i>Edit</a
                    >
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#delete_stage"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Follow Up</p>
                  <div class="action-pipeline">
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#edit_stage"
                      ><i class="ti ti-edit text-blue"></i>Edit</a
                    >
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#delete_stage"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Schedule Service</p>
                  <div class="action-pipeline">
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#edit_stage"
                      ><i class="ti ti-edit text-blue"></i>Edit</a
                    >
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#delete_stage"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-wrap">
              <h5 class="form-title">Access</h5>
              <div class="d-flex flex-wrap access-item nav">
                <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#all">
                  <input
                    type="radio"
                    class="status-radio"
                    id="all"
                    name="status"
                    checked=""
                  />
                  <label for="all">All</label>
                </div>
                <div
                  class="radio-btn"
                  data-bs-toggle="tab"
                  data-bs-target="#select-person"
                >
                  <input type="radio" class="status-radio" id="select" name="status" />
                  <label for="select">Select Person</label>
                </div>
              </div>
              <div class="tab-content">
                <div class="tab-pane fade" id="select-person">
                  <div class="access-wrapper">
                    <div class="access-view">
                      <div class="access-img">
                        <img
                          src="@/assets/img/profiles/avatar-21.jpg"
                          alt="Image"
                        />Vaughan
                      </div>
                      <a href="javascript:;">Remove</a>
                    </div>
                    <div class="access-view">
                      <div class="access-img">
                        <img
                          src="@/assets/img/profiles/avatar-01.jpg"
                          alt="Image"
                        />Jessica
                      </div>
                      <a href="javascript:;">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add New Pipeline -->

  <!-- Edit Pipeline -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Pipeline</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <form action="pipeline-list" class="toggle-height">
          <div class="pro-create">
            <div class="form-wrap">
              <label class="col-form-label"
                >Pipeline Name <span class="text-danger">*</span></label
              >
              <input class="form-control" type="text" value="Inpipeline" />
            </div>
            <div class="form-wrap">
              <div class="pipe-title d-flex align-items-center justify-content-between">
                <h5 class="form-title">Pipeline Stages</h5>
                <a
                  href="javascript:;"
                  class="add-stage"
                  data-bs-toggle="modal"
                  data-bs-target="#add_stage"
                  ><i class="ti ti-square-rounded-plus"></i>Add New</a
                >
              </div>
              <div class="pipeline-listing">
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Inpipeline</p>
                  <div class="action-pipeline">
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#edit_stage"
                      ><i class="ti ti-edit text-blue"></i>Edit</a
                    >
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#delete_stage"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Follow Up</p>
                  <div class="action-pipeline">
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#edit_stage"
                      ><i class="ti ti-edit text-blue"></i>Edit</a
                    >
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#delete_stage"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
                <div class="pipeline-item">
                  <p><i class="ti ti-grip-vertical"></i> Schedule Service</p>
                  <div class="action-pipeline">
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#edit_stage"
                      ><i class="ti ti-edit text-blue"></i>Edit</a
                    >
                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#delete_stage"
                      ><i class="ti ti-trash text-danger"></i>Delete</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-wrap">
              <h5 class="form-title">Access</h5>
              <div class="d-flex flex-wrap access-item nav">
                <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#edit_all">
                  <input
                    type="radio"
                    class="status-radio"
                    id="all1"
                    name="status"
                    checked=""
                  />
                  <label for="all1">All</label>
                </div>
                <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#edit-person">
                  <input type="radio" class="status-radio" id="select1" name="status" />
                  <label for="select1">Select Person</label>
                </div>
              </div>
              <div class="tab-content">
                <div class="tab-pane fade" id="edit-person">
                  <div class="access-wrapper">
                    <div class="access-view">
                      <div class="access-img">
                        <img
                          src="@/assets/img/profiles/avatar-21.jpg"
                          alt="Image"
                        />Vaughan
                      </div>
                      <a href="javascript:;">Remove</a>
                    </div>
                    <div class="access-view">
                      <div class="access-img">
                        <img
                          src="@/assets/img/profiles/avatar-01.jpg"
                          alt="Image"
                        />Jessica
                      </div>
                      <a href="javascript:;">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Pipeline -->

  <!-- Delete Stage -->
  <div class="modal custom-modal fade" id="delete_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Stage?</h3>
            <p class="del-info">Are you sure you want to remove it.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="pipeline-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Stage -->

  <!-- Delete Pipeline -->
  <div class="modal custom-modal fade" id="delete_pipeline" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Pipeline?</h3>
            <p class="del-info">Are you sure you want to remove pipeline you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/pipeline-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Pipeline -->

  <!-- Create Deal -->
  <div class="modal custom-modal fade" id="create_pipeline" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-medal"></i>
            </div>
            <h3>Pipeline Created Successfully!!!</h3>
            <p>View the details of pipeline, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/pipeline-list" class="btn btn-primary"
                >View Details</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Deal -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New View</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/crm//deals-list">
            <div class="form-wrap">
              <label class="col-form-label">View Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->

  <!-- Add New Stage -->
  <div class="modal custom-modal fade" id="add_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New Stage</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="pipeline-list">
            <div class="form-wrap">
              <label class="col-form-label">Stage Name *</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Stage -->

  <!-- Edit Stage -->
  <div class="modal custom-modal fade" id="edit_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Stage</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="pipeline-list">
            <div class="form-wrap">
              <label class="col-form-label">Stage Name *</label>
              <input type="text" class="form-control" value="Inpipeline" />
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Stage -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
