<template>
  <div class="account-page">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper account-bg reset-bg">
          <div class="login-content">
            <Form @submit="onSubmit" :validation-schema="schema">
              <div class="login-user-info">
                <div class="login-logo">
                  <img src="@/assets/img/anviam.png" class="img-fluid" alt="Logo" />
                </div>
                <div class="login-heading">
                  <h4>Reset Password?</h4>
                  <p>Enter New Password & Confirm Password to get inside</p>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Email <span v-if="!isEditing" class="text-danger">*</span></label>
                  <Field name="email" as="input" v-model="form.email" type="email" class="form-control" />
                  <ErrorMessage name="email" class="text-danger" />
                  <span class="text-danger" v-if="apiErrors && apiErrors['email'] && apiErrors['email'].length">{{ apiErrors['email'][0]}}</span>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Password</label>
                  <div class="pass-group">
                    <Field name="password" :type="showPassword ? 'text' : 'password'" v-model="form.password" class="form-control" />
                    <span @click="toggleShow" class="toggle-password">
                      <i :class="{
                            'ti ti-eye': showPassword,
                            'ti ti-eye-off': !showPassword,
                          }"></i>
                    </span>
                  </div>
                    <ErrorMessage name="password" class="text-danger" />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Confirm Password</label>
                  <div class="pass-group">
                    <Field :type="showConfirmPassword ? 'text' : 'password'" name="password_confirmation" as="input" v-model="form.password_confirmation" class="form-control" />
                    <ErrorMessage name="password_confirmation" class="text-danger" />
                    <span @click="toggleShow('confirm')" class="toggle-password">
                      <i :class="{
                            'ti ti-eye': showConfirmPassword,
                            'ti ti-eye-off': !showConfirmPassword,
                          }"></i>
                    </span>
                  </div>
                </div>
                <div class="form-wrap">
                  <button type="submit" class="btn btn-primary">Reset Password</button>
                </div>
                <div class="login-form text-center">
                  <h6>
                    Return to <router-link to="/" class="hover-a">Log In</router-link>
                  </h6>
                </div>
                <div class="login-social-link">
                  <div class="copyright-text">
                    <p>Copyright &copy;2024 - CRMS</p>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import * as Yup from 'yup';

import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    toggleShow(role) {
      if(role === 'confirm'){
        this.showConfirmPassword = !this.showConfirmPassword;
      }else{
        this.showPassword = !this.showPassword;
      }
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const schema = Yup.object().shape({
      email: Yup.string()
          .required("Email is required")
          .email('The email field must be a valid email address.'),
      password: Yup.string()
          .required('Please enter the password')
          .min(8, 'Password must be at least 8 characters')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
          ),
        password_confirmation: Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'), null], 'Password must match'),
    });
    const resetToken = route.query.token;
    const form = ref({
      email: '',
      password: '',
      password_confirmation: '',
      token: resetToken,
    });
    const onSubmit = async () => {
      try {
        await store.dispatch('auth/resetPassword', form.value);
        // router.push('/');
      } catch (error) {
        console.error(error);
      }
    };
    return {
      schema,
      onSubmit,
      form,
      resetToken
    };
  },
};
</script>
