<template>
    <div class="card analytics-card">
        <div class="card-header d-flex justify-content-between align-items-center flex-wrap">
            <h3>
                <i class="ti ti-grip-vertical"></i> Recently Created Contacts
            </h3>
            <div class="card-select">
                <ul>
                    <li>
                        <vue-select 
                            :options="Days"
                            id="timecomplete"
                            placeholder="Last 30 days"
                        />
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table dataTable custom-table mb-0" id="analytic-contact">
                    <thead>
                        <tr>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Created at</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in AnalyticsContacts" :key="item.id">
                            <td>
                                <h2 class="table-avatar d-flex align-items-center"><router-link to="/crm/contact-details"
                                        class="avatar"><img class="avatar-img" :src="require(`@/assets/img/profiles/${item.lead_img}`)"
                                            alt="User Image"></router-link><router-link to="/crm/contact-details"
                                        class="profile-split d-flex flex-column">{{item.lead_name}}<span>{{item.lead_city}}
                                        </span></router-link></h2>
                            </td>
                            <td>{{item.email}}</td>
                            <td>{{item.phone}}</td>
                            <td>{{item.date}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import AnalyticsContacts from '@/assets/json/analytic-contact.json';

export default {
    data() {
        return {
            AnalyticsContacts: AnalyticsContacts,
            Days: ["Last 30 days", "Last 15 days", "Last 7 days"]
        }
    },
}
</script>