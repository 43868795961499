<template>
    <div class="card analytics-card">
        <div class="card-header d-flex justify-content-between card-selectsview flex-wrap">
            <h3 class="card-title"><i class="ti ti-grip-vertical"></i>Won Deals Stage</h3>
            <div class="card-select">
                <ul>
                    <li>
                        <vue-select 
                            :options="Sales"
                            id="wonpipelines"
                            placeholder="Sales Pipeline"
                        />
                    </li>
                    <li class="form-wrap">
                        <vue-select 
                            :options="Days"
                            id="woncomplete"
                            placeholder="Last 30 days"
                        />
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body pt-0">
            <div id="won-chart">
                <apexchart
                    type="bar"
                    height="150"
                    :options="wonChart.chart"
                    :series="wonChart.series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import { wonChart } from "./data";

export default {
    data() {
        return {
            wonChart: wonChart,
            Days: ["Last 30 days", "Last 15 days", "Last 7 days"],
            Sales: ["Sales Pipeline", "Marketing Pipeline"]
        }
    },
}
</script>