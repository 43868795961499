<template>
  <!-- Add New Campaign -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Campaign</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <form action="/campaign-archieve" class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Campaign Type <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="PublicSelect"
                    id="pubilcselect"
                    placeholder="Choose"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Deal Value<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Currency <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="SelectAmount"
                    id="selectamount"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period Value <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Target Audience <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="SmallSel"
                    id="smallsel"
                    placeholder="Small Business"
                  />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <textarea class="form-control" rows="4"></textarea>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Attachment <span class="text-danger">*</span></label
                  >
                  <div class="drag-attach">
                    <input type="file" />
                    <div class="img-upload">
                      <i class="ti ti-file-broken"></i>Upload File
                    </div>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Uploaded Files</label>
                  <div class="upload-file upload-list">
                    <div>
                      <h6>tes.txt</h6>
                      <p>4.25 MB</p>
                    </div>
                    <a href="javascript:void(0);" class="text-danger"
                      ><i class="ti ti-trash-x"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add New Campaign -->

  <!-- Edit Campaign -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Campaign</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <form action="campaign-archieve" class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" value="Enhanced brand" />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Campaign Type <span class="text-danger">*</span></label
                  >
                  <vue-select :options="RelaSel" id="relasel" placeholder="Choose" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Deal Value<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" value="$12,989" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Currency <span class="text-danger">*</span></label
                  >
                  <vue-select :options="SelAm" id="selam" placeholder="Select" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Period Value <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Target Audience <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="EditSmallSel"
                    id="editsmallsel"
                    placeholder="Small Business"
                  />
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <textarea class="form-control" rows="4"></textarea>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Attachment <span class="text-danger">*</span></label
                  >
                  <div class="drag-attach">
                    <input type="file" />
                    <div class="img-upload">
                      <i class="ti ti-file-broken"></i>Upload File
                    </div>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Uploaded Files</label>
                  <div class="upload-file upload-list">
                    <div>
                      <h6>tes.txt</h6>
                      <p>4.25 MB</p>
                    </div>
                    <a href="javascript:void(0);" class="text-danger"
                      ><i class="ti ti-trash-x"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Campaign -->

  <!-- Delete Campaign -->
  <div class="modal custom-modal fade" id="delete_campaign" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Campaign??</h3>
            <p class="del-info">Are you sure you want to remove campaign you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/campaign" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Campaign -->

  <!-- Create Deal -->
  <div class="modal custom-modal fade" id="create_pipeline" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-medal"></i>
            </div>
            <h3>Campaign Created Successfully!!!</h3>
            <p>View the details of campaign, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/campaign" class="btn btn-primary">View Details</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Deal -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New View</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-wrap">
              <label class="col-form-label">View Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->
</template>
<script>
export default {
  data() {
    return {
      PublicSelect: ["Choose", "Public Relation", "Brand", "Media"],
      SelectAmount: ["Select", "$", "€"],
      SmallSel: ["Small Business", "Corporate Companies", "Urban Apartment", "Busniess"],
      RelaSel: ["Choose", "Public Relations", "Brand", "Media", "Sales"],
      SelAm: ["Select", "$", "€"],
      EditSmallSel: [
        "Small Business",
        "Corporate Companies",
        "Urban Apartment",
        "Busniess",
      ],
    };
  },
};
</script>
