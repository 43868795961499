<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <general-sidebar></general-sidebar>

            <div class="col-xl-9 col-lg-12">
              <!-- Settings Info -->
              <div class="card">
                <div class="card-body">
                  <div class="settings-header">
                    <h4>Profile Settings</h4>
                  </div>
                  <div class="settings-form">
                    <Form @submit="saveProfile" :validation-schema="schema">
                      <div class="settings-sub-header">
                        <h6>Employee Information</h6>
                        <p>Provide the information below</p>
                      </div>
                      <div class="form-wrap">
                       <div class="profile-upload">
                            <div class="profile-upload-img">
                              <span v-if="!imagePreview"><i class="ti ti-photo"></i></span>
                              <img v-if="imagePreview" :src="imagePreview" class="img-fluid" />
                              <button v-if="imagePreview" type="button" class="profile-remove" @click="removeImage">
                                <i class="feather-x"></i>
                              </button>
                            </div>
                            <div class="profile-upload-content">
                              <label class="profile-upload-btn">
                                <i class="ti ti-file-broken"></i> Upload File
                                <input type="file" class="input-img" @change="handleImageUpload" />
                              </label>
                              <p>
                                JPG, JPEG or PNG. Max size of 2MB.
                              </p>
                            </div>
                          </div>
                      </div>
                      <div class="profile-details">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                First Name <span class="text-danger">*</span>
                              </label>
                              <Field name="first_name" as="input" type="text" class="form-control" v-model="form.first_name" />
                              <ErrorMessage name="first_name" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Last Name
                              </label>
                              <Field name="last_name" as="input" type="text" class="form-control" v-model="form.last_name" />
                              <ErrorMessage name="last_name" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                User Name
                              </label>
                              <Field name="login_name" as="input" type="text" class="form-control" v-model="form.login_name" />
                              <ErrorMessage name="login_name" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Phone Number
                              </label>
                              <Field name="phone_number" as="input" v-model="form.phone_number" type="text" class="form-control" />
                              <span class="text-danger" v-if="apiErrors && apiErrors['phone_number'] && apiErrors['phone_number'].length">{{ apiErrors['phone_number'][0]}}</span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Email
                              </label>
                              <label class="col-form-label">Email</label>
                              <Field name="email" as="input" v-model="form.email" type="email" class="form-control" disabled />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="profile-address">
                        <div class="settings-sub-header">
                          <h6>Address</h6>
                          <p>Please enter the address details</p>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Address
                              </label>
                              <Field name="address" as="input" type="text" class="form-control" v-model="form.address" />
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Country
                              </label>
                              <select id="country_id" v-model="form.country_id" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in Countries" :key="res" :value='res.id'>
                              {{ res.country_name }}
                            </option>
                          </select>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                State / Province
                              
                              </label>
                              <Field name="state_province" as="input" type="text" class="form-control" v-model="form.state" />
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                City
                              </label>
                              <Field name="city" as="input" type="text" class="form-control" v-model="form.city" />
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <div class="form-wrap">
                              <label class="col-form-label">
                                Postal Code
                              </label>
                              <Field name="postal_code" as="input" type="text" class="form-control" v-model="form.postal_code" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="submit-button">
                        <a href="javascript:;" class="btn btn-light">Cancel</a>
                        <button type="submit" class="btn btn-primary">
                          Save Changes
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <!-- /Settings Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import * as Yup from "yup";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      title: "Settings"
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const imagePreview = ref("");
    const apiErrors = ref(null);

    const Countries = computed(() => store.getters['organisation/getCountries']);
    const user = computed(() => store.getters['auth/user']);
    // const territories = computed(() => store.getters["users/getterritories"]);


    const schema = Yup.object().shape({
        first_name: Yup.string()
          .required("First name is required")
      });
   

    const form = ref({
      image: null,
      first_name: user.value.first_name || '',
      last_name: user.value.last_name || '',
      login_name: user.value.login_name || '',
      email: user.value.email || '',
      phone_number: user.value.phone_number || '',
      address: user.value.address || '',
      city: user.value.city || '',
      state: user.value.state || '',
      country_id: user.value.country_id || null,
      postal_code: user.value.postal_code || '',
    });
   
    if (user.value.image) {
      imagePreview.value = user.value.image;
    }
 
    const saveProfile = async () => {
      try {
        await store.dispatch('auth/updateProfile', form.value);
        // router.push('/');
      } catch (error) {
        apiErrors.value = error.response.data.errors;
        console.error(error);
      }
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        form.value.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result; // Show the image preview
        };
        reader.readAsDataURL(file); // Convert the file to a data URL
      }
    };

    const removeImage = () => {
      form.value.image = "";
      imagePreview.value = "";
    };

    onMounted(async () => {
      store.dispatch('organisation/fetchCountries');
    });

    return {
      schema,
      imagePreview,
      removeImage,
      form,
      saveProfile,
      handleImageUpload,
      Countries,
      apiErrors,
    };
  },
};
</script>
