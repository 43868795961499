// store/modules/user.js

import apiClient from "@/axios";

const state = {
  territories: [],
};

const getters = {
  getterritories: (state) => state.territories,
};


const mutations = {
  SET_TERRITORIES(state, territories) {
    state.territories = territories;
  },
};

const actions = {
  async fetchTerritories({ commit }) {
    try {
      const response = await apiClient.get('/dropdown/territories');
      
      commit('SET_TERRITORIES', response.data);
    } catch (error) {
      console.error('Error fetching territories:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
