// src/store/modules/org.js
import apiClient from '@/axios';

const state = {
  orgData: {},
  territories: [],
  countries: [],
  assignToOptions: [],
  resUsersOptions: [],
  orgDropdowns: [],
  orgLists: [],
};

const getters = {
  getorgLists: (state) => state.orgLists,
  getOrgData: (state) => state.orgData,
  getTerritories: (state) => state.territories,
  getCountries: (state) => state.countries,
  getfetchAssignedTo: (state) => state.assignToOptions,
  getresUsersOptions: (state) => state.resUsersOptions,
  getorgDropdowns: (state) => state.orgDropdowns,
};

const mutations = {
  SET_ORG_DATA(state, data) {
    state.orgData = data;
  },
  SET_TERRITORIES(state, data) {
    state.territories = data;
  },
  SET_COUNTRIES(state, data) {
    state.countries = data;
  },
  SET_ASSIGN_TO_USERS(state, data) {
    state.assignToOptions = data;
  },
  SET_RESPOSNIBLE_USERS(state, data) {
    state.resUsersOptions = data;
  },

  SET_DROPDOWN_OPTIONS(state, data) {
    state.orgDropdowns = data;
  },
  SET_ORG_LISTS(state, data) {
    state.orgLists = data;
  },
};

const actions = {
  async fetchOrgLists({ commit }, id) {
    try {
      const response = await apiClient.get('/organisations');
      commit('SET_ORG_LISTS', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching organization data:', error);
    }
  },
  async fetchOrgData({ commit }, id) {
    try {
      const response = await apiClient.get(`/view-organisation/${id}`);
      commit('SET_ORG_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching organization data:', error);
    }
  },
  async fetchDropdownOptions({ commit }) {
    try {
      const response = await apiClient.get('settings/organisation');
      if(response){
        commit('SET_DROPDOWN_OPTIONS', response.data.data.dropdowns);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching types data:', error);
    }
  },
  async fetchCountries({ commit }) {
    try {
      const response = await apiClient.get('/get-countries');
      if(response){
        commit('SET_COUNTRIES', response.data.data);
        return response.data.data;
      }else{
        console.error('Not geeting countriee data');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  },
  async fetchTerritories({ commit }) {
    try {
      const response = await apiClient.get('/dropdown/territories');
      if(response){
        commit('SET_TERRITORIES', response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching territories:', error);
    }
  },

  async fetchTerritoryCompanyUsers({ commit }, territoryId) {
    try {
      // const params = territoryIds.map(id => `territory=${id}`).join('&');
      const response = await apiClient.get(`/responsible-users?territory=${territoryId}`);
      const resData = response.data.data.map((res) => ({
        id: res.id,
        name: res.last_name ? `${res.first_name} ${res.last_name}` : res.first_name,
      }));
      commit('SET_RESPOSNIBLE_USERS', resData);
      return resData; // Return data for use in component
    } catch (error) {
      console.error('Error fetching users for territories:', error);
    }
  },
  async fetchAssignedToUsers({ commit }, id) {
    try {
      // const params = ids.map(id => `ids[]=${id}`).join('&');
      const response = await apiClient.get(`/dropdown/company/org/assignTo-users?territory=${id}`);
      const newResData = response.data.map((res) => ({
        id: res.id,
        name: res.last_name ? `${res.first_name} ${res.last_name}` : res.first_name,
      }));
      commit('SET_ASSIGN_TO_USERS', newResData);
      return newResData;
    } catch (error) {
      console.error('Error fetching accouts status data:', error);
    }
  },

  async saveOrgData({ commit }, formData) {
    try {
      let response;
      if (formData.id) {
        if(typeof formData.logo === 'string'){
          formData.logo = null;
        }
        response = await apiClient.post(
          `/update-organisation/${formData.id}`,
          formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        );
      } else {
        response = await apiClient.post(
          '/create-organisation',
          formData
        );
      }
      return response.data;
    } catch (error) {
      console.error('Error saving organization data:', error);
      return { success: false, errors: error.response.data.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
