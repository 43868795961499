// src/store/modules/org.js
import apiClient from '@/axios';
import router from '@/router';

const state = {
  oppListData: {},
  oppViewData: [],
  personData: [],
  pipelineLevel: [],
  oppDropdowns: [],
  oppSuppliers: [],
  orgOtionsData: [],
  winProb: [],
  apiErrors: [],
};

const getters = {
  getOppListData: (state) => state.oppListData,
  getoppViewData: (state) => state.oppViewData,
  getPersonsData: (state) => state.personData,
  getPipelineLevels: (state) => state.pipelineLevel,
  getoppDropdowns: (state) => state.oppDropdowns,
  getoppSuppliers: (state) => state.oppSuppliers,
  getorgOtionsData: (state) => state.orgOtionsData,
  getwinProb: (state) => state.winProb,
  getapiErrors: (state) => state.apiErrors
};

const mutations = {
  SET_OPPORTUNITIES_LIST_DATA(state, data) {
    state.oppListData = data;
  },
  SET_PERSON_DATA(state, data) {
    state.personData = data;
  },
  SET_PIPELINE_DATA(state, data) {
    state.pipelineLevel = data;
  },
  SET_OPP_DROPDOWN_DATA(state, data) {
    state.oppDropdowns = data;
  },
  SET_OPP_SUPPLIERS(state, data) {
    state.oppSuppliers = data;
  },

  SET_OPPORTUNITY_OPTIONS(state, data) {
    state.orgOtionsData = data;
  },
  SET_OPPORTUNITY_VIEW_DATA(state, data) {
    state.oppViewData = data;
  },
  SET_WIN_PROB(state, data) {
    state.winProb = data;
  },
  SET_UPDATE_ERRORS(state, data) {
    state.apiErrors = data;
  }
};

const actions = {
  async fetchOpportunitiesLists({ commit }, params) {
    try {
      const response = await apiClient.get('/opportunities', { params });
      commit('SET_OPPORTUNITIES_LIST_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching opportunity data:', error);
    }
  },
  async fetchOpportunity({ commit }, id) {
    try {
      const response = await apiClient.get(`/opportunity/view/${id}`);
      commit('SET_OPPORTUNITY_VIEW_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching opportunity data:', error);
    }
  },
  async getorgOptions({ commit }) {
    try {
      const response = await apiClient.get('/organisations-dropdown');
      if (response) {
        commit('SET_OPPORTUNITY_OPTIONS', response.data.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching types data:', error);
    }
  },
  async fetchPipelines({ commit }) {
    try {
      const response = await apiClient.get('/pipeline-levels');
      if (response) {
        commit('SET_PIPELINE_DATA', response.data.data);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching pipeline:', error);
    }
  },
  async fetchPersons({ commit }, orgId) {
    try {
      const response = await apiClient.get(`dropdown/organisation/${orgId}/persons`);
      if (response) {
        const modifiedResponse = response.data.data.map(person => ({
          id: person.id,
          name: (person.first_name) + ' ' + (person.last_name ? person.last_name : ''),
        }
        ))
        commit('SET_PERSON_DATA', modifiedResponse);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching persons:', error);
    }
  },

  async fetchOpportunityOptions({ commit }) {
    try {
      const response = await apiClient.get('/settings/opportunity');
      if (response) {
        commit('SET_OPP_DROPDOWN_DATA', response.data.data.dropdowns);
        commit('SET_OPP_SUPPLIERS', response.data.data.supplier);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching pipeline:', error);
    }
  },
  async fetchWinProb({ commit }, { pipelineID, selectedOppId }) {
    try {
      const response = await apiClient.get(`/opportunity/${selectedOppId}/pipeline-data/${pipelineID}`);
      if (response) {
        commit('SET_WIN_PROB', response.data.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching win probability data:', error);
    }
  },
  
  async createOpportunity({ dispatch }, form) {
    try {
      const response = await apiClient.post(
        '/opportunity/create',
        form
      );
      // if (response.status) {
      //   router.push('/crm/meter-opportunities-list')
      // }
      return response.data;
    } catch (error) {
      console.error('Error saving Opportunity data:', error);
    }
  },
  async updateOpportunity({ commit }, form) {
    try {
      const response = await apiClient.post(
        `/opportunity/update/${form.id}`,
        form,
      );
      // if(response.status){
      //   router.push('/crm/meter-opportunities-list')
      // }
      return response.data;
    } catch (error) {
      commit('SET_UPDATE_ERRORS', error.response.data.errors)
      console.error('Error saving Opportunity data:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
