// src/store/modules/org.js
import apiClient from '@/axios';
import router from '@/router';

const state = {
  dashBoard: {},
};

const getters = {
  getDashData: (state) => state.dashBoard,
};

const mutations = {
  SET_PIPELINE_DATA(state, data) {
    state.dashBoard = data;
  },

};

const actions = {
  async getDashboardData({ commit }, { search, type }) {
    try {
      // const response = await apiClient.get('/dashboard');
      const response = await apiClient.get('/dashboard', {
        params: {
          search: search, // Assuming the API expects 'search' as the query parameter
          type: type  // Assuming the API expects 'filter' for selected option value
        },
      });

      if (response.data.status) {
        commit('SET_PIPELINE_DATA', response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching pipeline:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
