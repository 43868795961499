<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Card -->
          <div class="card contracts main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Contracts"
                      />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <div class="export-dropdwon">
                            <a
                              href="javascript:void(0);"
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              ><i class="ti ti-package-export"></i>Export</a
                            >
                            <div class="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-pdf text-danger"></i>Export
                                    as PDF</a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-xls text-green"></i>Export
                                    as Excel
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add Invoice</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <invoices-filter></invoices-filter>
              <!-- /Filter -->

              <!-- Contact Grid -->
              <div class="row">
                <div
                  class="col-xxl-3 col-xl-4 col-md-6"
                  v-for="item in InvoiceGrid"
                  :key="item.id"
                >
                  <div class="contact-grid invoice-grid">
                    <div class="grid-head">
                      <div class="users-profile">
                        <span :class="item.BadgeClass">{{ item.Badge }}</span>
                      </div>
                      <div class="dropdown table-action">
                        <a
                          href="#"
                          class="action-icon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item edit-popup" href="#"
                            ><i class="ti ti-edit text-blue"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_invoices"
                            ><i class="ti ti-trash text-danger"></i> Delete</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-clipboard-copy text-green"></i> View
                            Invoices</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-checks text-success"></i> Mark as Paid</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-file text-tertiary"></i> Mark as Partially
                            Paid</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-sticker text-blue"></i> Mark ad Unpaid</a
                          >
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i class="ti ti-printer text-info"></i> Print</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="grid-body">
                      <div class="invoice-head">
                        <div class="invoice-profile">
                          <a href="javascript:void(0);" class="avatar">
                            <img
                              :src="require(`@/assets/img/priority/${item.Image}`)"
                              alt="Truelysell"
                            />
                          </a>
                          <div class="name-user">
                            <h6>
                              <a href="javascript:void(0);">{{ item.Title }}</a>
                            </h6>
                          </div>
                        </div>
                        <div class="proposals-badge">
                          <span class="badge badge-tag badge-purple-light">#1254058</span>
                        </div>
                      </div>
                      <div class="address-info">
                        <p>
                          <i class="ti ti-moneybag"></i>Total Value :
                          <span>$2,15,000</span>
                        </p>
                        <p>
                          <i class="ti ti-calendar-event"></i>Due Date :
                          <span>15 May 2024</span>
                        </p>
                        <p>
                          <i class="ti ti-calendar-stats"></i>Paid Amount :
                          <span>2,15,000</span>
                        </p>
                        <p>
                          <i class="ti ti-calendar-stats"></i>Balance Amount :
                          <span>2,15,000</span>
                        </p>
                      </div>
                    </div>
                    <div class="grid-footer d-flex justify-content-between">
                      <div class="star-user">
                        <a href="javascript:void(0);" class="avatar">
                          <img :src="require(`@/assets/img/icons/${item.Company}`)" alt="img" />
                        </a>
                        <div class="star-user-title">
                          <span>Client</span>
                          <a href="javascript:void(0);" class="start-title"
                            >NovaWave LLC</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Contact Grid -->

              <div class="load-btn text-center">
                <a href="javascript:void(0);" class="btn btn-primary"
                  >Load More Contacts<i class="ti ti-loader"></i
                ></a>
              </div>
            </div>
          </div>
          <!-- /Card -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <invoices-modal></invoices-modal>
</template>

<script>
import InvoiceGrid from "@/assets/json/invoice-grid.json";

export default {
  data() {
    return {
      InvoiceGrid: InvoiceGrid,
      title: "Invoices",
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("add-popups-draft").length > 0) {
      var addPopupsOne = document.getElementsByClassName("add-popups-draft");
      for (var l = 0; l < addPopupsOne.length; l++) {
        addPopupsOne[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup-draft")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesTwo = document.getElementsByClassName("sidebar-close");
      for (var k = 0; k < sidebarClosesTwo.length; k++) {
        sidebarClosesTwo[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup-draft")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("add-popups-declined").length > 0) {
      var addPopupsTwo = document.getElementsByClassName("add-popups-declined");
      for (var m = 0; m < addPopupsTwo.length; m++) {
        addPopupsTwo[m].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup-declined")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesOne = document.getElementsByClassName("sidebar-close");
      for (var n = 0; k < sidebarClosesOne.length; n++) {
        sidebarClosesOne[n].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup-declined")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("add-popups").length > 0) {
      var addPopupsThree = document.getElementsByClassName("add-popups");
      for (var o = 0; o < addPopupsThree.length; o++) {
        addPopupsThree[o].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesThree = document.getElementsByClassName("sidebar-close");
      for (var p = 0; p < sidebarClosesThree.length; p++) {
        sidebarClosesThree[p].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopupsFour = document.getElementsByClassName("edit-popup");
      for (var u = 0; u < addPopupsFour.length; u++) {
        addPopupsFour[u].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesFour = document.getElementsByClassName("sidebar-close1");
      for (var v = 0; v < sidebarClosesFour.length; v++) {
        sidebarClosesFour[v].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
