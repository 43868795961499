<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper meter-opp">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="contact-head">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <ul class="contact-breadcrumb">
                <li>
                  <router-link to="/crm/activities-list">
                    <i class="ti ti-arrow-narrow-left"></i>
                    Activity
                  </router-link>
                </li>
                <li>Create activity</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-body settings-form">
                <Form @submit="createActivity" :validation-schema="schema">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-wrap">
                        <label class="col-form-label">Organisation <span class="text-danger">*</span></label>
                        <div class="icon-form">
                          <Field name="organisation_id" type="text" class="form-control" placeholder="Search organisation name / postcode" v-model="searchQuery" @keydown.enter="searchOrganisations" @input="onInput" />
                          <span class="form-icon">
                            <i class="ti ti-building-community"></i>
                          </span>
                        </div>
                        <ul v-if="filteredOrganisations.length" class="dropdown-menu shadow show w-100 p-0">
                          <li v-for="organisation in filteredOrganisations" :key="organisation.id" @click="selectOrganisation(organisation)" class="dropdown-item" style="cursor: pointer;">
                            {{ organisation.name + ' ' + ( organisation.postcode ? organisation.postcode : '' ) }}
                          </li>
                        </ul>
                        <ErrorMessage name="organisation_id" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-md-12" v-if="form.organization_id">
                      <div class="form-wrap">
                        <label class="col-form-label">Person</label>
                        <multiselect v-model="selectedPerson" :options="orgPersons" track-by="id" label="name" :multiple="false" tag-placeholder="Person" placeholder="Select..."></multiselect>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-wrap">
                        <label class="col-form-label">Date <span class="text-danger">*</span></label>
                        <div class="icon-form">
                          <date-picker placeholder="" v-model="form.date" class="datetimepicker form-control" :editable="true" :clearable="false" :input-format="dateFormat" />
                          <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">Action <span class="text-danger">*</span></label>
                        <Field as="select" name="overall_status" class="form-select" v-model="form.action">
                          <option value="">
                            ----select----
                          </option>
                          <option v-for="res in Options.actv_action" :key="res.value" :value="res.value">
                            {{ res.text }}
                          </option>
                        </Field>
                        <ErrorMessage name="early_contract_start" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">Responsible</label>
                        <select id="userRes" class="form-select" v-model="form.responsible" :disabled="saleResponsibleOptions.length === 1">
                          <option v-for="res in saleResponsibleOptions" :key="res.id" :value="res.id">
                            {{ res.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-wrap">
                        <label class="col-form-label">Description</label>
                        <textarea class="form-control" rows="1" v-model="form.description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="submit-button text-end">
                    <router-link to="/crm/contracts-list" class="btn btn-light">Back</router-link>
                    <button type="submit" class="btn btn-primary">Create</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import apiClient from "@/axios";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import Multiselect from 'vue-multiselect';
import $ from 'jquery';

import {
  Form,
  Field,
  ErrorMessage,
  useForm
} from "vee-validate";
import * as Yup from "yup";
import moment from 'moment';
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      dateFormat: "dd-MM-yyyy",
      title: "Settings",
      availStatus: [
        'No', 'Yes'
      ],
      loginSmsCode: ['always']
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isKanban = ref(false);
    if (route.query.kanban) {
      isKanban.value = true;
    }

    // const responsibleUsers = ref([]);
    const apiErrors = ref(null);

    const searchQuery = ref('');
    const orgOptions = computed(() => store.getters['opportunity/getorgOtionsData']);
    const Options = computed(() => store.getters['activities/getactivityDropdowns']);
    const orgPersons = computed(() => store.getters['opportunity/getPersonsData']);
    const saleResponsibleOptions = computed(() => store.getters['organisation/getresUsersOptions']);

    const filteredOrganisations = ref([]);

    const onInput = () => {
      searchOrganisations();
    }

    const searchOrganisations = () => {
      const query = searchQuery.value.toLowerCase();
      if (query) {
        filteredOrganisations.value = orgOptions.value.filter((org) => {
          const nameMatch = org.name.toLowerCase().includes(query);
          const postcodeMatch = org.postcode ?
            org.postcode.toString().toLowerCase().includes(query) :
            false; // If postcode is null or undefined, this will prevent the error
          return nameMatch || postcodeMatch;
        });
      } else {
        filteredOrganisations.value = [];
      }
    };

    const selectOrganisation = (organisation) => {
      form.value.organization_id = organisation.id;
      searchQuery.value = organisation.name + ' ' + organisation.postcode;
      store.dispatch('opportunity/fetchPersons', organisation.id)
      store.dispatch('organisation/fetchTerritoryCompanyUsers', organisation.territory_id)
      filteredOrganisations.value = [];
    };

    const schema = Yup.object().shape({
      organisation_id: Yup.string()
        .required("Organisation is required"),
      // date_of_sale: Yup.string()
      //   .required("Order date is required")
      overall_status: Yup.string()
        .required("Overall Status is required"),
    });

    const form = ref({
      organization_id: null,
      organization_person_id: null,
      responsible: null,
      date: new Date(),
      time: '',
      description: '',
      action: null,
    });
    
    const selectedPerson = ref(null);
    const createActivity = async () => {
      // Clone the form's date to avoid direct mutation
      const selectedDate = new Date(form.value.date);

      // Extract and format hours and minutes (ensures 2-digit format)
      const hours = selectedDate.getHours().toString().padStart(2, '0');
      const minutes = selectedDate.getMinutes().toString().padStart(2, '0');

      // Set the time in H:i format (24-hour format)
      form.value.time = `${hours}:${minutes}`;

      // Dispatch the action to create activity
      try {
        await store.dispatch('activities/createActivity', form.value);
      } catch (error) {
        console.error('Error creating activity:', error);
      }
    };

    const fetchResponsibles = async (role) => {
      if (role === 'role') {
        form.value.territories = [];
      }

      const selectedRole = form.value.role;
      let selectedTerritoryIds = [];
      const selectedTer = form.value.territories;

      // Determine if multiple selections are allowed
      if (isMultiple.value && Array.isArray(selectedTer)) {
        selectedTerritoryIds = selectedTer.map(territory => territory.id);
      } else if (!isMultiple.value && selectedTer && selectedTer.id) {
        selectedTerritoryIds = [selectedTer.id];
      }

      if (selectedRole !== '') {
        try {
          const response = await apiClient.get('/dropdown/company/assigned-managers', {
            params: {
              role: selectedRole,
              territory_ids: selectedTerritoryIds.length > 0 ? selectedTerritoryIds : []
            }
          });
          responsibleUsers.value = response.data.map(user => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }));
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    };
    // Watch for changes to selectedPerson
    watch(selectedPerson, (newVal) => {
      if (newVal) {
        form.value.organization_person_id = newVal.id; // Assign only the ID
      } else {
        form.value.organization_person_id = null; // Clear the ID if nothing is selected
      }
    });
    onMounted(async () => {
      store.dispatch('opportunity/getorgOptions');
      store.dispatch('activities/fetchActivityOptions');
      store.dispatch('contract/fetchContractOptions');
    });

    return {
      schema,
      isKanban,
      form,
      orgOptions,
      Options,
      searchQuery,
      filteredOrganisations,
      searchOrganisations,
      selectOrganisation,
      createActivity,
      fetchResponsibles,
      orgPersons,
      saleResponsibleOptions,
      apiErrors,
      onInput,
      selectedPerson
    };
  },
  watch: {
    saleResponsibleOptions: {
      immediate: true,
      handler(newOptions) {
        if (newOptions.length === 1) {
          this.form.responsible = newOptions[0].id;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.meter-opp {
  .card {
    .card-body {
      min-height: 80vh;
    }
  }
}
</style>
