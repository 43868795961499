<template>
  <!-- Add Currency -->
  <div class="modal custom-modal fade" id="add_currency" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Currency</h5>
          <div class="d-flex align-items-center mod-toggle">
            <div class="status-toggle">
              <input type="checkbox" id="toggle" class="check" checked="" />
              <label for="toggle" class="checktoggle"></label>
            </div>
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <form action="/finanical-settings/currencies">
            <div class="form-wrap">
              <label class="col-form-label"
                >Currency Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Code <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Symbol <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Exchange Rate <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Currency -->

  <!-- Edit Currency -->
  <div class="modal custom-modal fade" id="edit_currency" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Tax Rate</h5>
          <div class="d-flex align-items-center mod-toggle">
            <div class="status-toggle">
              <input type="checkbox" id="toggle1" class="check" checked="" />
              <label for="toggle1" class="checktoggle"></label>
            </div>
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <form action="/finanical-settings/currencies">
            <div class="form-wrap">
              <label class="col-form-label"
                >Currency Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Euro" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Code <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="EUR" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Symbol <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="€" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Exchange Rate <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Default" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Currency -->

  <!-- Delete Account -->
  <div class="modal custom-modal fade" id="delete_currency" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Currency?</h3>
            <p class="del-info">Are you sure you want to remove it.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/finanical-settings/currencies" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
