<template>
  <div class="filter-section">
    <div class="row">
      <div class="col-xl-6">
        <div class="sortby-list">
          <ul>
            <li>
              <div class="sort-dropdown drop-down">
                <a href="javascript:void(0);" class="dropdown-toggle" data-bs-toggle="dropdown"><i
                    class="ti ti-sort-ascending-2"></i>Sort
                </a>
                <div class="dropdown-menu dropdown-menu-start">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <i class="ti ti-circle-chevron-right"></i>Ascending
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i class="ti ti-circle-chevron-right"></i>Descending
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i class="ti ti-circle-chevron-right"></i>Recently Added
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="form-wrap icon-form">
                <span class="form-icon"><i class="ti ti-calendar"></i></span>
                <input type="text" class="form-control bookingrange" placeholder="" ref="dateRangeInput" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="filter-list">
          <ul>
            <li>
              <div class="manage-dropdwon">
                <a href="javascript:void(0);" class="btn btn-purple-light" data-bs-toggle="dropdown"
                  data-bs-auto-close="false"><i class="ti ti-columns-3"></i>Manage Columns</a>
                <div class="dropdown-menu dropdown-menu-xl-end">
                  <h4>Want to manage datatables?</h4>
                  <p>
                    Please drag and drop your column to reorder your table and enable see
                    option as you want.
                  </p>
                  <ul>
                    <li>
                      <p><i class="ti ti-grip-vertical"></i>User Name</p>
                      <div class="status-toggle">
                        <input type="checkbox" id="col-name" class="check" />
                        <label for="col-name" class="checktoggle"></label>
                      </div>
                    </li>
                    <li>
                      <p><i class="ti ti-grip-vertical"></i>Rating</p>
                      <div class="status-toggle">
                        <input type="checkbox" id="col-phone" class="check" />
                        <label for="col-phone" class="checktoggle"></label>
                      </div>
                    </li>
                    <li>
                      <p><i class="ti ti-grip-vertical"></i>Content</p>
                      <div class="status-toggle">
                        <input type="checkbox" id="col-email" class="check" />
                        <label for="col-email" class="checktoggle"></label>
                      </div>
                    </li>
                    <li>
                      <p><i class="ti ti-grip-vertical"></i>Created at</p>
                      <div class="status-toggle">
                        <input type="checkbox" id="col-tag" class="check" />
                        <label for="col-tag" class="checktoggle"></label>
                      </div>
                    </li>
                    <li>
                      <p><i class="ti ti-grip-vertical"></i>Status</p>
                      <div class="status-toggle">
                        <input type="checkbox" id="col-status" class="check" />
                        <label for="col-status" class="checktoggle"></label>
                      </div>
                    </li>
                    <li>
                      <p><i class="ti ti-grip-vertical"></i>Action</p>
                      <div class="status-toggle">
                        <input type="checkbox" id="col-action" class="check" />
                        <label for="col-action" class="checktoggle"></label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="form-sorts dropdown">
                <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="false"><i
                    class="ti ti-filter-share"></i>Filter</a>
                <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
                  <div class="filter-set-view">
                    <div class="filter-set-head">
                      <h4><i class="ti ti-filter-share"></i>Filter</h4>
                    </div>
                    <div class="accordion" id="accordionExample">
                      <div class="filter-set-content">
                        <div class="filter-set-content-head">
                          <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#owner"
                            aria-expanded="false" aria-controls="owner">Owner</a>
                        </div>
                        <div class="filter-set-contents accordion-collapse collapse" id="owner"
                          data-bs-parent="#accordionExample">
                          <div class="filter-content-list">
                            <div class="form-wrap icon-form">
                              <span class="form-icon"><i class="ti ti-search"></i></span>
                              <input type="text" class="form-control" placeholder="Search User" />
                            </div>
                            <ul>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" checked />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>Darlee Robertson</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>Sharon Roy</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>Vaughan</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>Jessica</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>Carol Thomas</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="filter-set-content">
                        <div class="filter-set-content-head">
                          <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#Status"
                            aria-expanded="false" aria-controls="Status">Status</a>
                        </div>
                        <div class="filter-set-contents accordion-collapse collapse" id="Status"
                          data-bs-parent="#accordionExample">
                          <div class="filter-content-list">
                            <ul>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" checked />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>Active</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>Inactive</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="filter-set-content">
                        <div class="filter-set-content-head">
                          <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                            aria-expanded="false" aria-controls="collapseOne">Rating</a>
                        </div>
                        <div class="filter-set-contents accordion-collapse collapse" id="collapseOne"
                          data-bs-parent="#accordionExample">
                          <div class="filter-content-list">
                            <ul>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" checked />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="rating">
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <span>5.0</span>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="rating">
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star"></i>
                                  <span>4.0</span>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="rating">
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <span>3.0</span>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="rating">
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <span>2.0</span>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="rating">
                                  <i class="fa fa-star filled"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <i class="fa fa-star"></i>
                                  <span>1.0</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="filter-set-content">
                        <div class="filter-set-content-head">
                          <a href="javascript:;" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">Created Date</a>
                        </div>
                        <div class="filter-set-contents accordion-collapse collapse" id="collapseThree"
                          data-bs-parent="#accordionExample">
                          <div class="filter-content-list">
                            <ul>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" checked />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>25 Sep 2023, 01:22pm</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>29 Sep 2023, 08:12 am</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>02 Oct 2023, 10:15 am</h5>
                                </div>
                              </li>
                              <li>
                                <div class="filter-checks">
                                  <label class="checkboxs">
                                    <input type="checkbox" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                                <div class="collapse-inside-text">
                                  <h5>11 Oct 2023, 02:32 pm</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="filter-reset-btns">
                      <div class="row">
                        <div class="col-6">
                          <a href="javascript:;" class="btn btn-light">Reset</a>
                        </div>
                        <div class="col-6">
                          <router-link to="/content/testimonials" class="btn btn-primary">Filter</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
export default {
  data() {
    return {
    };
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },

};
</script>
