<template>
  <index-loader></index-loader>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <dashboard-header :title="title" />

          <div class="row">
            <div class="col-md-7">
              <div class="card">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4><i class="ti ti-grip-vertical me-1"></i>Recent Projects</h4>
                    <div class="dropdown statistic-dropdown">
                      <div class="card-select">
                        <ul>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              <i class="ti ti-calendar-check me-2"></i>Last 7 days
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 15 days
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 30 days
                              </a>
                            </div>
                          </li>
                          <li>
                            <a class="btn btn-primary add-popup" href="javascript:void(0);">
                              <i class="ti ti-square-rounded-plus me-1"></i>Add Project
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive custom-table">
                    <table class="table dataTable" id="recent-project">
                      <thead class="thead-light">
                        <tr>
                          <th>Priority</th>
                          <th>Name</th>
                          <th>Client</th>
                          <th>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in Project_Dashboard" :key="item.id">
                          <td>
                            <h2 class="table-avatar d-flex align-items-center">
                              <router-link to="/crm/project-details" class="company-img"
                                ><img
                                  class="avatar-img"
                                  :src="
                                    require(`@/assets/img/priority/${item.PriorityImage}`)
                                  "
                                  alt="User Image" /></router-link
                              ><router-link
                                to="/crm/project-details"
                                class="profile-split d-flex flex-column"
                                >{{ item.Priority }}</router-link
                              >
                            </h2>
                          </td>
                          <td>
                            <h2 class="table-avatar d-flex align-items-center">
                              <router-link to="/crm/company-details" class="company-img"
                                ><img
                                  class="avatar-img"
                                  :src="require(`@/assets/img/icons/${item.Image}`)"
                                  alt="User Image" /></router-link
                              ><router-link
                                to="/crm/company-details"
                                class="profile-split d-flex flex-column"
                                >{{ item.Name }}</router-link
                              >
                            </h2>
                          </td>
                          <td>
                            <span :class="item.Class"
                              ><span class="badge-dots"></span> {{ item.Client }}</span
                            >
                          </td>
                          <td>{{ item.DueDate }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 d-flex">
              <div class="card w-100">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4><i class="ti ti-grip-vertical me-1"></i>Projects By Stage</h4>
                    <div class="dropdown statistic-dropdown">
                      <div class="card-select">
                        <ul>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              Last 3 months
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 3 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 6 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 12 months
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="contacts-analysis">
                    <apexchart
                      type="donut"
                      :options="contactAnalysisCharts.contactCharts"
                      :series="contactAnalysisCharts.series"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 d-flex">
              <div class="card w-100">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4><i class="ti ti-grip-vertical me-1"></i>Projects By Stage</h4>
                    <div class="dropdown statistic-dropdown">
                      <div class="card-select">
                        <ul>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              Sales Pipeline
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Marketing Pipeline
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Sales Pipeline
                              </a>
                            </div>
                          </li>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              Last 3 months
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 3 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 6 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 12 months
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="project-stage">
                    <apexchart
                      type="bar"
                      height="420"
                      :options="projectStaCharts.stagesCharts"
                      :series="projectStaCharts.series"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4><i class="ti ti-grip-vertical me-1"></i>Leads By Stage</h4>
                    <div class="dropdown statistic-dropdown">
                      <div class="card-select">
                        <ul>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              Marketing Pipeline
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Marketing Pipeline
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Sales Pipeline
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Email
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Chats
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Operational
                              </a>
                            </div>
                          </li>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              Last 3 months
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 3 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 6 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 12 months
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="last-chart">
                    <apexchart
                      type="bar"
                      height="150"
                      :options="lastProCharts.lastCharts"
                      :series="lastProCharts.series"
                    ></apexchart>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="statistic-header">
                    <h4><i class="ti ti-grip-vertical me-1"></i>Won Deals Stage</h4>
                    <div class="dropdown statistic-dropdown">
                      <div class="card-select">
                        <ul>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              Marketing Pipeline
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Marketing Pipeline
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Sales Pipeline
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Email
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Chats
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Operational
                              </a>
                            </div>
                          </li>
                          <li>
                            <a
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              Last 3 months
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 3 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 6 months
                              </a>
                              <a href="javascript:void(0);" class="dropdown-item">
                                Last 12 months
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="won-chart">
                    <apexchart
                      type="bar"
                      height="150"
                      :options="wonProCharts.wonCharts"
                      :series="wonProCharts.series"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <project-dashboard-modal></project-dashboard-modal>
</template>
<script>
import Project_Dashboard from "@/assets/json/project-dashboard.json";
import {
  contactAnalysisCharts,
  projectStaCharts,
  lastProCharts,
  wonProCharts,
} from "./data";
export default {
  data() {
    return {
      title: "Project Dashboard",
      Project_Dashboard: Project_Dashboard,
      contactAnalysisCharts: contactAnalysisCharts,
      projectStaCharts: projectStaCharts,
      lastProCharts: lastProCharts,
      wonProCharts: wonProCharts,
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
