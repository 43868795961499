<template>
  <!-- Add User -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Ticket</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn">
          <i class="ti ti-x"></i>
        </a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="/support/tickets">
            <div class="accordion-lists" id="list-accord">
              <!-- Basic Info -->
              <div class="manage-user-modal">
                <div class="manage-user-modals">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Subject <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Assigned Name <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Created Date <span class="text-danger">*</span>
                        </label>
                        <div class="cal-icon cal-icon-info">
                          <date-picker
                            v-model="startdate"
                            placeholder="Select Date"
                            class="datetimepicker form-control"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Due Date <span class="text-danger">*</span>
                        </label>
                        <div class="cal-icon cal-icon-info">
                          <date-picker
                            v-model="startdateOne"
                            placeholder="Created Date"
                            class="datetimepicker form-control"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Assignee Name <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Priority <span class="text-danger">*</span>
                        </label>
                        <!-- Assuming you've registered the vue-select component correctly -->
                        <vue-select
                          :options="TickSel"
                          id="ticksel"
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Status<span class="text-danger">*</span>
                        </label>
                        <!-- Assuming you've registered the vue-select component correctly -->
                        <vue-select
                          :options="ChooseSel"
                          id="choosesel"
                          placeholder="Choose"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->
            </div>
            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->

  <!-- Edit User -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Edit Tickets</h4>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <div class="pro-create">
          <form action="/support/tickets">
            <div class="accordion-lists" id="list-accords">
              <!-- Basic Info -->
              <div class="manage-user-modal">
                <div class="manage-user-modals">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label">
                          Subject <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          value="Support for theme"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Assigned Name <span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" value="Richard" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Created Date <span class="text-danger">*</span></label
                        >
                        <div class="cal-icon cal-icon-info">
                          <date-picker
                            v-model="startdate"
                            placeholder="22 Sep 2023"
                            class="datetimepicker form-control"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Due Date <span class="text-danger">*</span></label
                        >
                        <div class="cal-icon cal-icon-info">
                          <date-picker
                            v-model="startdateOne"
                            placeholder="25 Dec 2023"
                            class="datetimepicker form-control"
                            :editable="true"
                            :clearable="false"
                            :input-format="dateFormat"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Assignee Name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          value="Darlee Robertson"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Priority <span class="text-danger">*</span></label
                        >
                        <vue-select :options="LowSel" id="lowsel" placeholder="Low" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-wrap">
                        <label class="col-form-label"
                          >Status<span class="text-danger">*</span></label
                        >
                        <vue-select :options="Closel" id="closel" placeholder="Closed" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->
            </div>

            <div class="submit-button text-end">
              <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit User -->

  <!-- Delete User -->
  <div class="modal custom-modal fade" id="delete_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Tickets</h3>
            <p class="del-info">Are you sure you want to remove it.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/user/manage-users" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete User -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      TickSel: ["Choose", "Low", "Medium", "High"],
      ChooseSel: ["Choose", "Closed", "Open", "Open", "Pending", "Resolved"],
      Lowsel: ["Low", "Medium", "High"],
      Closel: ["Closed", "Open", "Pending", "Resolved"],
    };
  },
};
</script>
