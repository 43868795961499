<template>
  <!-- PHP Mailer -->
  <div class="modal custom-modal fade" id="add_phpmail" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">PHP Mailer</h5>
          <div class="d-flex align-items-center mod-toggle">
            <div class="status-toggle">
              <input type="checkbox" id="toggle" class="check" checked="" />
              <label for="toggle" class="checktoggle"></label>
            </div>
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <form action="/system-settings/email-settings">
            <div class="form-wrap">
              <label class="col-form-label"
                >From Email Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Email Password <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >From Email Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /PHP Mailer -->

  <!-- SMTP -->
  <div class="modal custom-modal fade" id="add_smtp" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">SMTP</h5>
          <div class="d-flex align-items-center mod-toggle">
            <div class="status-toggle">
              <input type="checkbox" id="toggle1" class="check" checked="" />
              <label for="toggle1" class="checktoggle"></label>
            </div>
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <form action="/system-settings/email-settings">
            <div class="form-wrap">
              <label class="col-form-label"
                >From Email Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Email Password <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Email Host <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Port <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /SMTP -->

  <!-- Test Mail -->
  <div class="modal custom-modal fade" id="add_mail" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Test Mail</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/system-settings/email-settings">
            <div class="form-wrap">
              <label class="col-form-label"
                >Enter Email Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Test Mail -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
