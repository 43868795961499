<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input type="text" class="form-control" placeholder="Search User" />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <div class="export-dropdwon">
                            <a
                              href="javascript:void(0);"
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              ><i class="ti ti-package-export"></i>Export</a
                            >
                            <div class="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-pdf text-danger"></i>Export
                                    as PDF</a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-xls text-green"></i>Export
                                    as Excel
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add Contact
                            Messages</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <contact-messages-filter></contact-messages-filter>
              <!-- /Filter -->

              <!-- Contact Messages List -->
              <div class="table-responsive custom-table">
                <a-table
                  class="table"
                  :columns="columns"
                  :data-source="data"
                  :row-selection="rowSelection"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Star'">
                      <div class="set-star rating-select">
                        <i class="fa fa-star"></i>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Name'">
                      <h2 class="table-avatar d-flex align-items-center">
                        <router-link to="/settings/profile" class="avatar"
                          ><img
                            class="avatar-img"
                            :src="require(`@/assets/img/profiles/${record.Image}`)"
                            alt="User Image" /></router-link
                        ><router-link
                          to="/settings/profile"
                          class="profile-split d-flex flex-column"
                          >{{ record.Name }}
                          <span>{{ record.Posting }} </span></router-link
                        >
                      </h2>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="dropdown table-action">
                        <a
                          href="javascript:;"
                          class="action-icon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item edit-popup" href="javascript:void(0);"
                            ><i class="ti ti-edit text-blue"></i> Edit</a
                          ><a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_contact"
                            ><i class="ti ti-trash text-danger"></i> Delete</a
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Contact Messages List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <contact-message-modal></contact-message-modal>
</template>
<script>
const columns = [
  {
    title: "",
    dataIndex: "",
    sorter: false,
  },
  {
    title: "",
    dataIndex: "",
    key: "Star",
    sorter: false,
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Message",
    dataIndex: "Message",
    sorter: {
      compare: (a, b) => {
        a = a.Message.toLowerCase();
        b = b.Message.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created",
    dataIndex: "Created",
    sorter: {
      compare: (a, b) => {
        a = a.Created.toLowerCase();
        b = b.Created.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    key: 1,
    Name: "Darlee Robertson",
    Posting: "Facility Manager",
    Phone: "1234567890",
    Email: "robertson@example.com",
    Message: "Duis aute irure dolor in reprehenderit",
    Created: "25 Sep 2023, 12:12 pm",
    Action: "Edit Delete",
    Image: "avatar-19.jpg",
  },
  {
    key: 2,
    Name: "Sharon Roy",
    Posting: "Installer",
    Phone: "+1 989757485",
    Email: "sharon@example.com",
    Message: "Excepteur sint occaecat cupidatat",
    Created: "27 Sep 2023, 07:40 am",
    Action: "Edit Delete",
    Image: "avatar-20.jpg",
  },
  {
    key: 3,
    Name: "Vaughan",
    Posting: "Senior Manager",
    Phone: "+1 546555455",
    Email: "vaughan12@example.com",
    Message: "Lorem ipsum dolor sit consectetur",
    Created: "29 Sep 2023, 08:20 am",
    Action: "Edit Delete",
    Image: "avatar-21.jpg",
  },
  {
    key: 4,
    Name: "Jessica",
    Posting: "Test Engineer",
    Phone: "+1 454478787",
    Email: "jessica13@example.com",
    Message: "Nemo enim ipsam voluptatem quia",
    Created: "25 Sep 2023, 12:12 pm",
    Action: "Edit Delete",
    Image: "avatar-23.jpg",
  },
  {
    key: 5,
    Name: "Carol Thomas",
    Posting: "UI /UX Designer",
    Phone: "+1 124547845",
    Email: "caroltho3@example.com",
    Message: "Sed ut perspiciatis unde omnis iste",
    Created: "02 Oct 2023, 10:10 am",
    Action: "Edit Delete",
    Image: "avatar-16.jpg",
  },
  {
    key: 6,
    Name: "Dawn Mercha",
    Posting: "Technician",
    Phone: "+1 478845447",
    Email: "dawnmercha@example.com",
    Message: "Ut enim ad minim veniam, quis",
    Created: "17 Oct 2023, 04:25 pm",
    Action: "Edit Delete",
    Image: "avatar-22.jpg",
  },
  {
    key: 7,
    Name: "Rachel Hampton",
    Posting: "Software Developer",
    Phone: "+1 215544845",
    Email: "rachel@example.com",
    Message: "Duis aute irure dolor in reprehenderit",
    Created: "28 Oct 2023, 07:16 am",
    Action: "Edit Delete",
    Image: "avatar-24.jpg",
  },
  {
    key: 8,
    Name: "Jonelle Curtiss",
    Posting: "Supervisor",
    Phone: "+1 121145471",
    Email: "jonelle@example.com",
    Message: "Ut enim ad minim veniam, quis",
    Created: "08 Nov 2023, 06:10 am",
    Action: "Edit Delete",
    Image: "avatar-25.jpg",
  },
  {
    key: 9,
    Name: "Jonathan",
    Posting: "Team Lead Dev",
    Phone: "+1 321454789",
    Email: "jonathan@example.com",
    Message: "Excepteur sint occaecat cupidatat",
    Created: "15 Nov 2023, 11:50 am",
    Action: "Edit Delete",
    Image: "avatar-26.jpg",
  },
  {
    key: 10,
    Name: "Brook",
    Posting: "Team Lead Dev",
    Phone: "+1 278907145",
    Email: "brook@example.com",
    Message: "Ut enim ad minim veniam, quis",
    Created: "25 Nov 2023, 06:34 pm",
    Action: "Edit Delete",
    Image: "avatar-01.jpg",
  },
];
const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};
export default {
  data() {
    return {
      title: "Settings",
      data,
      columns,
      rowSelection,
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("edit-popup").length > 0) {
      var editPopups = document.getElementsByClassName("edit-popup");
      for (var k = 0; k < editPopups.length; k++) {
        editPopups[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses1 = document.getElementsByClassName("sidebar-close1");
      for (var l = 0; l < sidebarCloses1.length; l++) {
        sidebarCloses1[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
