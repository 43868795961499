<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input type="text" class="form-control" placeholder="Search Task" />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <a href="javascript:void(0);" class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add New Task</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <div class="filter-section">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <div class="sortby-list">
                      <ul>
                        <li>
                          <div class="sort-dropdown drop-down task-drops">
                            <a
                              href="javascript:void(0);"
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              >All Tasks
                            </a>
                            <div class="dropdown-menu dropdown-menu-start">
                              <ul>
                                <li>
                                  <router-link to="/crm/tasks">
                                    <i class="ti ti-dots-vertical"></i>All Tasks
                                  </router-link>
                                </li>
                                <li>
                                  <router-link to="/crm/tasks-important">
                                    <i class="ti ti-dots-vertical"></i>Important
                                  </router-link>
                                </li>
                                <li>
                                  <router-link to="/crm/tasks-completed">
                                    <i class="ti ti-dots-vertical"></i>Completed
                                  </router-link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="filter-list">
                      <ul class="justify-content-md-end">
                        <li class="all-read">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span>Mark
                            all as read</label
                          >
                        </li>
                        <li>
                          <div class="sort-dropdown drop-down">
                            <a
                              href="javascript:void(0);"
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              ><i class="ti ti-sort-ascending-2"></i>Sort
                            </a>
                            <div class="dropdown-menu dropdown-menu-start">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);">
                                    <i class="ti ti-dots-vertical"></i>Ascending
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <i class="ti ti-dots-vertical"></i>Descending
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <i class="ti ti-dots-vertical"></i>Recently Viewed
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <i class="ti ti-dots-vertical"></i>Recently Added
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="form-wrap icon-form mb-0">
                            <span class="form-icon"><i class="ti ti-calendar"></i></span>
                            <input
                              type="text"
                              class="form-control bookingrange"
                              ref="dateRangeInput"
                              placeholder=""
                            />
                          </div>
                        </li>
                        <li>
                          <div class="form-sorts dropdown">
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="false"
                              ><i class="ti ti-filter-share"></i>Filter</a
                            >
                            <div
                              class="filter-dropdown-menu dropdown-menu dropdown-menu-md-end"
                            >
                              <div class="filter-set-view">
                                <div class="filter-set-head">
                                  <h4><i class="ti ti-filter-share"></i>Filter</h4>
                                </div>
                                <div class="accordion" id="accordionExample">
                                  <div class="filter-set-content">
                                    <div class="filter-set-content-head">
                                      <a
                                        href="javascript:;"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="true"
                                        aria-controls="collapseTwo"
                                        >Task Name</a
                                      >
                                    </div>
                                    <div
                                      class="filter-set-contents accordion-collapse collapse show"
                                      id="collapseTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="filter-content-list">
                                        <div class="form-wrap icon-form">
                                          <span class="form-icon"
                                            ><i class="ti ti-search"></i
                                          ></span>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Task"
                                          />
                                        </div>
                                        <ul>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" checked />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Add a form to Update Task</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Add a form to Update Task</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Update orginal content</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Use only component colours</h5>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="filter-set-content">
                                    <div class="filter-set-content-head">
                                      <a
                                        href="javascript:;"
                                        class="collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#owner"
                                        aria-expanded="false"
                                        aria-controls="owner"
                                      >
                                        Task Type</a
                                      >
                                    </div>
                                    <div
                                      class="filter-set-contents accordion-collapse collapse"
                                      id="owner"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="filter-content-list">
                                        <ul>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" checked />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Meeting</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Calls</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Task</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Email</h5>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="filter-set-content">
                                    <div class="filter-set-content-head">
                                      <a
                                        href="javascript:;"
                                        class="collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapsestatus"
                                        aria-expanded="false"
                                        aria-controls="collapsestatus"
                                        >Tags</a
                                      >
                                    </div>
                                    <div
                                      class="filter-set-contents accordion-collapse collapse"
                                      id="collapsestatus"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="filter-content-list">
                                        <ul>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" checked />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Collab</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Rated</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Promotion</h5>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="filter-set-content">
                                    <div class="filter-set-content-head">
                                      <a
                                        href="javascript:;"
                                        class="collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#Status"
                                        aria-expanded="false"
                                        aria-controls="Status"
                                        >Created Date</a
                                      >
                                    </div>
                                    <div
                                      class="filter-set-contents accordion-collapse collapse"
                                      id="Status"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="filter-content-list">
                                        <div class="form-wrap icon-form">
                                          <span class="form-icon"
                                            ><i class="ti ti-search"></i
                                          ></span>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Date"
                                          />
                                        </div>
                                        <ul>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" checked />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>23 Oct 2023</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>29 Sep 2023</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>24 Oct 2023</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>25 Oct 2023</h5>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="filter-set-content">
                                    <div class="filter-set-content-head">
                                      <a
                                        href="javascript:;"
                                        class="collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                        >Created By</a
                                      >
                                    </div>
                                    <div
                                      class="filter-set-contents accordion-collapse collapse"
                                      id="collapseThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div class="filter-content-list">
                                        <ul>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" checked />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Hendry</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Monty Beer</h5>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="filter-checks">
                                              <label class="checkboxs">
                                                <input type="checkbox" />
                                                <span class="checkmarks"></span>
                                              </label>
                                            </div>
                                            <div class="collapse-inside-text">
                                              <h5>Bradtke</h5>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="filter-reset-btns">
                                  <div class="row">
                                    <div class="col-6">
                                      <a href="javascript:;" class="btn btn-light">Reset</a>
                                    </div>
                                    <div class="col-6">
                                      <router-link to="/crm/tasks" class="btn btn-primary"
                                        >Filter</router-link
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Filter -->

              <!-- Recent Task List -->
              <div class="task-wrapper">
                <a
                  href="javascript:;"
                  class="task-accordion"
                  data-bs-toggle="collapse"
                  data-bs-target="#recent"
                >
                  <h4>Recent<span>24</span></h4>
                </a>
                <div class="tasks-activity tasks collapse show" id="recent">
                  <ul>
                    <li class="task-wrap pending">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p>Add a form to Update Task</p>
                        <span class="badge badge-pill badge-status bg-green"
                          ><i class="ti ti-phone"></i>Calls</span
                        >
                        <span class="badge badge-tag bg-pending">Pending</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-purple-light"
                              >Promotion</span
                            >
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>25 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="task-wrap pending">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p>Make all strokes thinner</p>
                        <span class="badge activity-badge bg-warning"
                          ><i class="ti ti-mail"></i> Email</span
                        >
                        <span class="badge badge-tag bg-pending">Pending</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-danger-light"
                              >Rejected</span
                            >
                            <span class="badge badge-tag badge-success-light"
                              >Collab</span
                            >
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>25 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="task-wrap warning">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p>Update orginal content</p>
                        <span class="badge badge-pill badge-status bg-green"
                          ><i class="ti ti-phone"></i>Calls</span
                        >
                        <span class="badge badge-tag bg-warning">Inprogress</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-purple-light"
                              >Promotion</span
                            >
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>25 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="task-wrap warning">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p>Use only component colours</p>
                        <span class="badge activity-badge bg-blue"
                          ><i class="ti ti-subtask"></i> Task</span
                        >
                        <span class="badge badge-tag bg-warning">Inprogress</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-success-light"
                              >Collab</span
                            >
                            <span class="badge badge-tag badge-warning-light">Rated</span>
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>25 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Recent Task List -->

              <!-- Task List -->
              <div class="task-wrapper">
                <a
                  href="javascript:;"
                  class="task-accordion"
                  data-bs-toggle="collapse"
                  data-bs-target="#yesterday"
                >
                  <h4>Yesterday</h4>
                </a>
                <div class="tasks-activity tasks collapse show" id="yesterday">
                  <ul>
                    <li class="task-wrap warning">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p>Add images to the cards section</p>
                        <span class="badge badge-pill badge-status bg-green"
                          ><i class="ti ti-phone"></i>Calls</span
                        >
                        <span class="badge badge-tag bg-warning">Inprogress</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-success-light"
                              >Collab</span
                            >
                            <span class="badge badge-tag badge-warning-light">Rated</span>
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>24 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Task List -->

              <!-- Task List -->
              <div class="task-wrapper">
                <a
                  href="javascript:;"
                  class="task-accordion"
                  data-bs-toggle="collapse"
                  data-bs-target="#date-01"
                >
                  <h4>23 Oct 2023</h4>
                </a>
                <div class="tasks-activity tasks collapse show" id="date-01">
                  <ul>
                    <li class="task-wrap warning">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p>Design description banner & landing page</p>
                        <span class="badge activity-badge bg-blue"
                          ><i class="ti ti-subtask"></i> Task</span
                        >
                        <span class="badge badge-tag bg-warning">Inprogress</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-success-light"
                              >Collab</span
                            >
                            <span class="badge badge-tag badge-warning-light">Rated</span>
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>23 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="task-wrap success">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p><del>Make sure all the padding should be 24px</del></p>
                        <span class="badge badge-pill badge-status bg-green"
                          ><i class="ti ti-phone"></i>Calls</span
                        >
                        <span class="badge badge-tag bg-success">Completed</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-purple-light"
                              >Promotion</span
                            >
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>23 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Task List -->

              <!-- Task List -->
              <div class="task-wrapper">
                <a
                  href="javascript:;"
                  class="task-accordion"
                  data-bs-toggle="collapse"
                  data-bs-target="#date-02"
                >
                  <h4>22 Oct 2023</h4>
                </a>
                <div class="tasks-activity tasks collapse show" id="date-02">
                  <ul>
                    <li class="task-wrap success">
                      <div class="task-info">
                        <span class="task-icon"><i class="ti ti-grip-vertical"></i></span>
                        <div class="task-checkbox">
                          <label class="checkboxs"
                            ><input type="checkbox" /><span class="checkmarks"></span
                          ></label>
                        </div>
                        <div class="set-star rating-select">
                          <i class="fa fa-star"></i>
                        </div>
                        <p><del>Use border radius as 5px or 10 px</del></p>
                        <span class="badge badge-pill badge-status bg-purple"
                          ><i class="ti ti-user-share"></i>Meeting</span
                        >
                        <span class="badge badge-tag bg-success">Completed</span>
                      </div>
                      <div class="task-actions">
                        <ul>
                          <li class="task-time">
                            <span class="badge badge-tag badge-danger-light"
                              >Rejected</span
                            >
                            <span class="badge badge-tag badge-success-light"
                              >Collab</span
                            >
                          </li>
                          <li class="task-date">
                            <i class="ti ti-calendar-exclamation"></i>22 Oct 2023
                          </li>
                          <li class="task-owner">
                            <div class="task-user">
                              <img src="@/assets/img/profiles/avatar-14.jpg" alt="img" />
                            </div>
                            <div class="dropdown table-action">
                              <a
                                href="javascript:;"
                                class="action-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fa fa-ellipsis-v"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item edit-popup" href="javascript:;"
                                  ><i class="ti ti-edit text-blue"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="javascript:;"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_activity"
                                  ><i class="ti ti-trash text-danger"></i> Delete</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Task List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <task-content-modal></task-content-modal>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";

export default {
  data() {
    return {
      title: "Tasks",
    };
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("edit-popup").length > 0) {
      var editPopups = document.getElementsByClassName("edit-popup");
      for (var k = 0; k < editPopups.length; k++) {
        editPopups[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses1 = document.getElementsByClassName("sidebar-close1");
      for (var l = 0; l < sidebarCloses1.length; l++) {
        sidebarCloses1[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
