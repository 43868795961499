<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Search -->
          <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search opportunities" v-model="searchOppBoardQuery" @input="searchOpportunitiesBoard"
                      />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <div class="view-icons">
                    <router-link to="/crm/meter-opportunities-list"
                      ><i class="ti ti-list-tree"></i
                    ></router-link>
                    <router-link to="/crm/meter-opportunities-kanban" class="active"
                      ><i class="ti ti-grid-dots"></i
                    ></router-link>
                  </div>
                    </li>
                        <li>
                          <router-link :to="{ path: '/crm/create-meter-opportunity', query: { kanban: true } }" class="btn btn-primary"><i class="ti ti-square-rounded-plus"></i>Add Opportunities</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          <!-- /Search -->

          <!-- Leads Kanban -->
          <!-- <meter-opportunities-board :search-board-query="searchOppBoardQuery" ref="oppBoardTable"></meter-opportunities-board> -->
          <meter-opportunities-board :search-board-query="searchOppBoardQuery" ref="oppBoardTable"></meter-opportunities-board>

          <!-- /Leads Kanban -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <opportunities-list-modal></opportunities-list-modal>
</template>

<script>
export default {
  data() {
    return {
      title: "Meter Opportunities",
      searchOppBoardQuery: "",
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("edit-popup").length > 0) {
      var editPopups = document.getElementsByClassName("edit-popup");
      for (var k = 0; k < editPopups.length; k++) {
        editPopups[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses1 = document.getElementsByClassName("sidebar-close1");
      for (var l = 0; l < sidebarCloses1.length; l++) {
        sidebarCloses1[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
  methods: {
    async searchOpportunitiesBoard() {
      console.log(this.searchOppBoardQuery, 'hello');
      
      this.$refs.oppBoardTable.fetchBoardOpportunities();
    }
  },
};
</script>
