<template>
  <!-- Add Company -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Organisation</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <form @submit.prevent="handleSubmit" class="toggle-height">
          <div class="pro-create">
            <div class="accordion-lists" id="list-accord">
              <!-- Basic Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap"
                  data-bs-toggle="collapse"
                  data-bs-target="#basic"
                  ><span><i class="ti ti-user-plus"></i></span>Basic Info</a
                >
                <div
                  class="accordion-collapse collapse show"
                  id="basic"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <div class="profile-upload">
                            <div class="profile-upload-img">
                              <span><i class="ti ti-photo"></i></span>
                              <img
                                src="@/assets/img/icons/company-icon-03.svg"
                                class="preview1"
                                alt="img"
                              />
                              <button type="button" class="profile-remove">
                                <i class="ti ti-x"></i>
                              </button>
                            </div>
                            <div class="profile-upload-content">
                              <label class="profile-upload-btn">
                                <i class="ti ti-file-broken"></i> Upload File
                                <input type="file" class="input-img" />
                              </label>
                              <p>JPG, GIF or PNG. Max size of 800K</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Organisation name</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Alert text<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.alert_text"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Postcode<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.postcode"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >www<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.www"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Telephone<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.telephone"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Fax<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.fax"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">VAT no.</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.vat_no"
                          />
                        </div>
                      </div>
                      <div class="col-md-12 mb-3">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label"
                            >Comment <span class="text-danger">*</span></label
                          >
                          <textarea
                            class="form-control"
                            rows="2"
                            v-model="formData.comment"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >ERP ID <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.erp_id"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">Org URN</label>
                          <div class="icon-form-end">
                            <!-- <span class="form-icon"
                              ><i class="ti ti-star"></i
                            ></span> -->
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              v-model="formData.org_urn"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >ABC<span class="text-danger">*</span></label
                          >
                          <input type="text" class="form-control" v-model="formData.abc"/>
                        </div>
                      </div> -->
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Original Lead Source</label
                          >
                          <select
                            id="leadSource"
                            v-model="formData.original_lead_source"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option
                              v-for="source in LeadSource"
                              :key="source"
                              :value="source"
                            >
                              {{ source }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">No of Employees</label>
                          <select
                            id="leadSource"
                            v-model="formData.no_of_employees"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option
                              v-for="emp in EmployeesNumber"
                              :key="emp"
                              :value="emp"
                            >
                              {{ emp }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >SIC code <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.sic_code"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Responsible
                            <span class="text-danger">*</span></label
                          >
                          <select
                            id="responsible"
                            v-model="formData.responsible"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option
                              v-for="res in Responsible"
                              :key="res"
                              value="1"
                            >
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >SIC Description<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.sic_description"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Territory <span class="text-danger">*</span></label
                          >
                          <select
                            id="territory"
                            v-model="formData.territory"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option
                              v-for="res in Territory"
                              :key="res"
                              :value="res"
                            >
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >VAT group <span class="text-danger">*</span></label
                          >
                          <select
                            id="vatgroup"
                            v-model="formData.vat_group"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option
                              v-for="res in VatGroup"
                              :key="res"
                              :value="res"
                            >
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Type <span class="text-danger">*</span></label
                          >
                          <select
                            id="type"
                            v-model="formData.type"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option v-for="res in Type" :key="res" :value="res">
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Status <span class="text-danger">*</span></label
                          >
                          <select
                            id="status"
                            v-model="formData.status"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option
                              v-for="res in Status"
                              :key="res"
                              :value="res"
                            >
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Industry <span class="text-danger">*</span></label
                          >
                          <select
                            id="industry"
                            v-model="formData.industry"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option
                              v-for="res in Industry"
                              :key="res"
                              :value="res"
                            >
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Basic Info -->

              <!-- Company Reg No -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#social"
                  ><span><i class="ti ti-social"></i></span>For all LTD / LLP /
                  PLC</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="social"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Company Reg No:
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.company_reg_no"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Company Reg No -->

              <!-- For Registered Charities -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#Charities"
                  ><span><i class="ti ti-social"></i></span>For Registered
                  Charities</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="Charities"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Reg No: <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.charity_reg_no"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Import ID <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.charity_import_id"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Lead Source
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.charity_lead_source"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label"
                            >Duplicate Batch
                            <span class="text-danger">*</span></label
                          >
                          <select
                            id="duplicateBatch"
                            v-model="formData.charity_duplicate_batch"
                            class="form-select"
                          >
                            <option disabled value="">--</option>
                            <option
                              v-for="res in DuplicateBatch"
                              :key="res"
                              :value="res"
                            >
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /For Registered Charities -->

              <!-- Address Info -->
              <div class="user-accordion-item">
                <a
                  href="javascript:;"
                  class="accordion-wrap collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#address"
                  ><span><i class="ti ti-map-pin-cog"></i></span>Address Info</a
                >
                <div
                  class="accordion-collapse collapse"
                  id="address"
                  data-bs-parent="#list-accord"
                >
                  <div class="content-collapse">
                    <div class="row">
                      <div class="col-md-12 mb-3">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label"
                            >Address <span class="text-danger">*</span></label
                          >
                          <textarea
                            class="form-control"
                            rows="2"
                            v-model="formData.address"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">City </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.city"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <label class="col-form-label">County / State </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.state"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Post code/ZIP</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.zip"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-wrap mb-wrap">
                          <label class="col-form-label">Country</label>
                          <select
                            id="country_id"
                            v-model="formData.country_id"
                            class="form-select"
                          >
                            <option disabled value="">-----Select-----</option>
                            <option v-for="res in Country" :key="res" value="1">
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Address Info -->
              <div class="row mb-3">
                <div class="col-md-12">
                  <div class="form-wrap mb-0">
                    <label class="col-form-label"
                      >content <span class="text-danger">*</span></label
                    >
                    <textarea
                      class="form-control"
                      rows="2"
                      v-model="formData.content"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <button type="button" class="btn btn-light sidebar-close">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Create</button>

            <!-- <a href="javascript:;" class="btn btn-light sidebar-close"
              >Cancel</a
            >
            <a
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#create_contact"
              class="btn btn-primary"
              >Create</a
            > -->
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Company -->
</template>

<script>
import { ref, watch, onMounted } from "vue";
import apiClient from "@/axios"; // Adjust this path if necessary
import { useRouter } from "vue-router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Vue3TagsInput from "vue3-tags-input";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
export default {
  props: {
    editData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    // Vue3TagsInput,
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "dd-MM-yyyy",
      LeadSource: [
        "Website",
        "Referral",
        "email shot",
        "Advertising",
        "Exhibition",
        "Other",
        "Turn Energy",
      ],
      EmployeesNumber: [
        "5 to 10",
        "11 to 50",
        "51 to 100",
        "101 to 250",
        "250+",
      ],
      Responsible: [
        "Admin",
        "Abbas M",
        "Adam Ahmed",
        "Akash Dalmia",
        "Alex Smith",
        "Amar Dhanoa",
      ],
      Territory: [
        "2:Not Allocated",
        "5:UK",
        "10:India",
        "15:Himachal",
        "20:Syndicate",
      ],
      Type: [
        "Customer",
        "Prospect",
        "Partner",
        "Supplier",
        "Agent",
        "General",
        "Existing Customer",
      ],
      Status: ["A Account", "B Account", "C Account", "Lost", "Not relevant"],
      Industry: [
        "Finance",
        "Telco",
        "Trade",
        "Service",
        "Utility",
        "IT",
        "Production",
        "Healthcare",
        "Central government",
        "Local government",
      ],
      DuplicateBatch: ["Yes"],
      Country: ["Choose", "India", "USA", "France", "UK", "UAE"],
      VatGroup: ["A", "B"],
      editor: ClassicEditor,
      editorData: "Description",
      editorConfig: {},
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/crm/organisation-list");
    },
  },
  mounted() {
    if (document.getElementsByClassName("add-popups").length > 0) {
      var addPopups = document.getElementsByClassName("add-popups");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close2");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
  setup(props) {
    const router = useRouter();
    const formData = ref({
      name: "",
      postcode: "",
      telephone: "",
      fax: "",
      responsible: 1,
      logo: 101,
      comment: "",
      territory: "",
      vat_group: "",
      type: "",
      status: "",
      industry: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country_id: 1,
      alert_text: "",
      www: "",
      vat_no: "",
      erp_id: "",
      org_urn: "",
      original_lead_source: "",
      no_of_employees: "",
      sic_code: "",
      sic_description: "",
      company_reg_no: "",
      charity_reg_no: "",
      charity_import_id: "",
      charity_lead_source: "",
      charity_duplicate_batch: "",
      content: "",
    });
    const handleSubmit = async () => {
      if (props.editData && props.editData.id) {
        await handleUpdate(props.editData.id);
      } else {
        await handleCreate();
      }
    };

    const handleCreate = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Adjust the key if necessary
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await apiClient.post(
          "/create-organisation",
          formData.value,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status) {
          if (router) {
            window.location.reload();
            router.push("/crm/organisation-list");
          } else {
            console.error("Router is not defined or is null");
          }
        } else {
          console.error("Error:", response.data.error);
        }
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };

    const handleUpdate = async (id) => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await apiClient.post(
          `/update-organisation/${id}`,
          formData.value,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status) {
          window.location.reload();
          router.push("/crm/organisation-list");
        } else {
          console.error("Error:", response.data.error);
        }
      } catch (error) {
        console.error("Error during update:", error);
      }
    };

    watch(
      () => props.editData,
      (newEditData) => {
        if (newEditData) {
          formData.value = { ...formData.value, ...newEditData };
        }
      },
      { immediate: true }
    );

    return {
      formData,
      handleSubmit,
    };
  },
};
</script>
