<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input type="text" class="form-control" placeholder="Search User" />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <div class="export-dropdwon">
                            <a
                              href="javascript:void(0);"
                              class="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              ><i class="ti ti-package-export"></i>Export</a
                            >
                            <div class="dropdown-menu dropdown-menu-end">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-pdf text-danger"></i>Export
                                    as PDF</a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0);"
                                    ><i class="ti ti-file-type-xls text-green"></i>Export
                                    as Excel
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add Tickets</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <ticket-filter></ticket-filter>
              <!-- /Filter -->

              <!-- Tickets List -->
              <div class="table-responsive custom-table">
                <a-table
                  class="table"
                  :columns="columns"
                  :data-source="data"
                  :row-selection="rowSelection"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Star'">
                      <div class="set-star rating-select">
                        <i class="fa fa-star"></i>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Assigned'">
                      <h2 class="table-avatar d-flex align-items-center">
                        <a href="javascript:;" class="avatar"
                          ><img
                            class="avatar-img"
                            :src="require(`@/assets/img/profiles/${record.Image}`)"
                            alt="User Image" /></a
                        ><a href="javascript:;" class="profile-split d-flex flex-column">{{
                          record.Assigned
                        }}</a>
                      </h2>
                    </template>
                    <template v-else-if="column.key === 'Assignee'">
                      <h2 class="table-avatar d-flex align-items-center">
                        <router-link to="/settings/profile" class="avatar"
                          ><img
                            class="avatar-img"
                            :src="require(`@/assets/img/profiles/${record.Image1}`)"
                            alt="User Image" /></router-link
                        ><router-link
                          to="/settings/profile"
                          class="profile-split d-flex flex-column"
                          >{{ record.Assignee }}
                          <span>{{ record.Posting }} </span></router-link
                        >
                      </h2>
                    </template>
                    <template v-else-if="column.key === 'Priority'">
                      <div>
                        <span :class="record.PriorityClass">{{ record.Priority }}</span>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <span :class="record.StatusClass">{{ record.Status }}</span>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="dropdown table-action">
                        <a
                          href="javascript:;"
                          class="action-icon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item edit-popup" href="javascript:void(0);"
                            ><i class="ti ti-edit text-blue"></i> Edit</a
                          ><a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_contact"
                            ><i class="ti ti-trash text-danger"></i> Delete</a
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Tickets List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ticket-modal></ticket-modal>
</template>
<script>
const columns = [
  {
    title: "",
    dataIndex: "",
    sorter: false,
  },
  {
    title: "",
    dataIndex: "",
    key: "Star",
    sorter: false,
  },
  {
    title: "Ticket ID",
    dataIndex: "TicketID",
    sorter: {
      compare: (a, b) => {
        a = a.TicketID.toLowerCase();
        b = b.TicketID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Subject",
    dataIndex: "Subject",
    sorter: {
      compare: (a, b) => {
        a = a.Subject.toLowerCase();
        b = b.Subject.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "  Assigned",
    dataIndex: "  Assigned",
    key: "Assigned",
    sorter: {
      compare: (a, b) => {
        a = a.Assigned.toLowerCase();
        b = b.Assigned.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Assigned Date",
    dataIndex: "AssignedDate",
    sorter: {
      compare: (a, b) => {
        a = a.AssignedDate.toLowerCase();
        b = b.AssignedDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created On",
    dataIndex: "CreatedOn",
    sorter: {
      compare: (a, b) => {
        a = a.CreatedOn.toLowerCase();
        b = b.CreatedOn.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Due Date",
    dataIndex: "DueDate",
    sorter: {
      compare: (a, b) => {
        a = a.DueDate.toLowerCase();
        b = b.DueDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Assignee",
    dataIndex: "Assignee",
    key: "Assignee",
    sorter: {
      compare: (a, b) => {
        a = a.Assignee.toLowerCase();
        b = b.Assignee.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Last Reply",
    dataIndex: "LastReply",
    key: "LastReply",
    sorter: {
      compare: (a, b) => {
        a = a.LastReply.toLowerCase();
        b = b.LastReply.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Priority",
    dataIndex: "Priority",
    key: "Priority",
    sorter: {
      compare: (a, b) => {
        a = a.Priority.toLowerCase();
        b = b.Priority.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    key: 1,
    TicketID: "#4987",
    Subject: "Support for theme",
    Assigned: "Richard",
    AssignedDate: "22 Sep 2023",
    CreatedOn: "25 Sep 2023, 12:12 pm",
    DueDate: "25 Dec 2023",
    Assignee: "Darlee Robertson",
    Posting: "Facility Manager",
    LastReply: "27 Sep 2023",
    Priority: "Medium",
    Image: "avatar-02.jpg",
    Image1: "avatar-19.jpg",
    PriorityClass: "badge badge-tag badge-warning-light",
    StatusClass: "badge badge-pill badge-status bg-success",
    Status: "Resolved",
  },
  {
    key: 2,
    TicketID: "#4988",
    Subject: "verify your email",
    Assigned: "Elizabeth",
    AssignedDate: "23 Sep 2023",
    CreatedOn: "26 Sep 2023, 12:12 pm",
    DueDate: "30 Dec 2023",
    Assignee: "Sharon Roy",
    Posting: "Installer",
    LastReply: "30 Sep 2023",
    Priority: "Low",
    Image: "avatar-01.jpg",
    Image1: "avatar-20.jpg",
    PriorityClass: "badge badge-tag badge-purple-light",
    StatusClass: "badge badge-pill badge-status bg-info",
    Status: "Closed",
  },
  {
    key: 3,
    TicketID: "#4989",
    Subject: "Calling for help",
    Assigned: "Michel",
    AssignedDate: "24 Sep 2023",
    CreatedOn: "27 Sep 2023, 12:12 pm",
    DueDate: "25 Jan 2024",
    Assignee: "Vaughan",
    Posting: "Senior Manager",
    LastReply: "01 Oct 2023",
    Priority: "High",
    Image: "avatar-04.jpg",
    Image1: "avatar-21.jpg",
    PriorityClass: "badge badge-tag badge-danger-light",
    StatusClass: "badge badge-pill badge-status bg-warning",
    Status: "Pending",
  },
  {
    key: 4,
    TicketID: "#4990",
    Subject: "Management",
    Assigned: "Esther",
    AssignedDate: "30 Sep 2023",
    CreatedOn: "05 Oct 2023, 11:10 pm",
    DueDate: "31 Jan 2023",
    Assignee: "Jessica",
    Posting: "Test Engineer",
    LastReply: "10 Oct 2023",
    Priority: "Medium",
    Image: "avatar-03.jpg",
    Image1: "avatar-23.jpg",
    PriorityClass: "badge badge-tag badge-warning-light",
    StatusClass: "badge badge-pill badge-status bg-success",
    Status: "Resolved",
  },
  {
    key: 5,
    TicketID: "#4991",
    Subject: "Calling for help",
    Assigned: "Wilson",
    AssignedDate: "22 Sep 2023",
    CreatedOn: "25 Sep 2023, 12:12 pm",
    DueDate: "25 Dec 2023",
    Assignee: "Carol Thomas",
    Posting: "UI /UX Designer",
    LastReply: "27 Sep 2023",
    Priority: "Low",
    Image: "avatar-05.jpg",
    Image1: "avatar-16.jpg",
    PriorityClass: "badge badge-tag badge-purple-light",
    StatusClass: "badge badge-pill badge-status bg-danger",
    Status: "Open",
  },
  {
    key: 6,
    TicketID: "#4992",
    Subject: "Support for theme",
    Assigned: "Walter",
    AssignedDate: "30 Sep 2023",
    CreatedOn: "05 Oct 2023, 12:12 pm",
    DueDate: "25 Dec 2023",
    Assignee: "Dawn Mercha",
    Posting: "Technician",
    LastReply: "07 Oct 2023",
    Priority: "High",
    Image: "avatar-02.jpg",
    Image1: "avatar-19.jpg",
    PriorityClass: "badge badge-tag badge-danger-light",
    StatusClass: "badge badge-pill badge-status bg-info",
    Status: "Closed",
  },
];
const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};
export default {
  data() {
    return {
      title: "Settings",
      data,
      columns,
      rowSelection,
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("edit-popup").length > 0) {
      var editPopups = document.getElementsByClassName("edit-popup");
      for (var k = 0; k < editPopups.length; k++) {
        editPopups[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses1 = document.getElementsByClassName("sidebar-close1");
      for (var l = 0; l < sidebarCloses1.length; l++) {
        sidebarCloses1[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
