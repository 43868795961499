<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Search -->
          <div class="search-section">
            <div class="row">
              <div class="col-md-5 col-sm-4">
                <div class="form-wrap icon-form">
                  <span class="form-icon"><i class="ti ti-search"></i></span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Estimations"
                  />
                </div>
              </div>
              <div class="col-md-7 col-sm-8">
                <div class="export-list text-sm-end">
                  <ul>
                    <li>
                      <div class="export-dropdwon">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          ><i class="ti ti-package-export"></i>Export</a
                        >
                        <div class="dropdown-menu dropdown-menu-end">
                          <ul>
                            <li>
                              <a href="javascript:void(0);"
                                ><i class="ti ti-file-type-pdf text-danger"></i>Export as
                                PDF</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><i class="ti ti-file-type-xls text-green"></i>Export as
                                Excel
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="btn btn-primary add-popup"
                        ><i class="ti ti-square-rounded-plus"></i>Add Estimate</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Search -->

          <!-- Filter -->
          <div class="filter-section filter-flex">
            <div class="d-flex">
              <div class="sortby-list">
                <ul>
                  <li>
                    <div class="sort-dropdown drop-down">
                      <a
                        href="javascript:void(0);"
                        class="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        ><i class="ti ti-sort-ascending-2"></i>Sort
                      </a>
                      <div class="dropdown-menu dropdown-menu-start">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Ascending
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Descending
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i class="ti ti-circle-chevron-right"></i>Recently Added
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-calendar"></i></span>
                      <input
                        type="text"
                        class="form-control bookingrange"
                        ref="dateRangeInput"
                        placeholder="From Date - To Date"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="filter-list">
              <ul>
                <li>
                  <div class="form-sorts dropdown">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                      ><i class="ti ti-filter-share"></i>Filter</a
                    >
                    <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
                      <div class="filter-set-view">
                        <div class="filter-set-head">
                          <h4><i class="ti ti-filter-share"></i>Filter</h4>
                        </div>
                        <div class="accordion" id="accordionExample">
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseclient"
                                aria-expanded="false"
                                aria-controls="collapseclient"
                                >Client</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="collapseclient"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Client"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>NovaWave LLC</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Redwood Inc</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>HarborVie w</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>CoastalStar Co.</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>RiverStone Ventur</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#owner"
                                aria-expanded="false"
                                aria-controls="owner"
                                >Project</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="owner"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Truelysell</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Dreamschat</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Truelysell</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Servbook</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>DreamPOS</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#Status"
                                aria-expanded="false"
                                aria-controls="Status"
                                >Date of Estimation</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="Status"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Date"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>15 Oct 2023</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>19 Oct 2023</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>24 Oct 2023</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>10 Nov 2023</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>18 Nov 2023</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#Status"
                                aria-expanded="false"
                                aria-controls="Status"
                                >Expiry Date</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="Status"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Date"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>15 Sep 2027</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>10 Sep 2028</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>20 Oct 2026</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>07 Oct 2028</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>10 Oct 2026</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#Status"
                                aria-expanded="false"
                                aria-controls="Status"
                                >Estimation By</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="Status"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Date"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Darlee Robertson</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Sharon Roy</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Vaughan</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Jessica</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Carol Thomas</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#Status"
                                aria-expanded="false"
                                aria-controls="Status"
                                >Status</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="Status"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Date"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Sent</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Accepted</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Draft</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Accepted</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Declined</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="filter-reset-btns">
                          <div class="row">
                            <div class="col-6">
                              <a href="#" class="btn btn-light">Reset</a>
                            </div>
                            <div class="col-6">
                              <router-link
                                to="/crm/estimations-list"
                                class="btn btn-primary"
                                >Filter</router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="view-icons">
                    <router-link to="/crm/estimations-list"
                      ><i class="ti ti-list-tree"></i
                    ></router-link>
                    <router-link to="/crm/estimations-kanban"
                      ><i class="ti ti-grid-dots"></i
                    ></router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Filter -->

          <!-- Estimations Kanban -->
          <estimates-board></estimates-board>
          <!-- /Estimations Kanban -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <estimations-modal></estimations-modal>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";

export default {
  data() {
    return {
      title: "Estimations",
    };
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
    if (document.getElementsByClassName("add-popups").length > 0) {
      var addPopupsOne = document.getElementsByClassName("add-popups");
      for (var k = 0; k < addPopupsOne.length; k++) {
        addPopupsOne[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesOne = document.getElementsByClassName("sidebar-close2");
      for (var l = 0; l < sidebarClosesOne.length; l++) {
        sidebarClosesOne[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup2")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopupsTwo = document.getElementsByClassName("edit-popup");
      for (var m = 0; m < addPopupsTwo.length; m++) {
        addPopupsTwo[m].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarClosesTwo = document.getElementsByClassName("sidebar-close1");
      for (var n = 0; n < sidebarClosesTwo.length; n++) {
        sidebarClosesTwo[n].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
