<template>
    <div class="card analytics-card">
        <div class="card-header d-flex justify-content-between align-items-center flex-wrap">
            <h3><i class="ti ti-grip-vertical"></i>Deals By Stage</h3>
            <div class="card-select">
                <ul>
                    <li>
                        <vue-select 
                            :options="Sales"
                            id="salespipelines"
                            placeholder="Sales Pipeline"
                        />
                    </li>
                    <li class="form-wrap">
                        <vue-select 
                            :options="Days"
                            id="threecomplete"
                            placeholder="Last 30 days"
                        />
                    </li>
                </ul>
            </div>

        </div>
        <div class="card-body">
            <div id="deals-chart">
                <apexchart
                    type="bar"
                    height="275"
                    :options="dealsChatrt.chart"
                    :series="dealsChatrt.series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import { dealsChatrt } from './data'
export default {
    data() {
        return {
            dealsChatrt: dealsChatrt,
            Days: ["Last 30 days", "Last 15 days", "Last 7 days"],
            Sales: ["Sales Pipeline", "Marketing Pipeline"]
        }
    },
}
</script>