<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->

        <!-- Settings Menu -->
        <settings-tabs></settings-tabs>
        <!-- /Settings Menu -->

        <div class="row">
          <!-- <website-sidebar></website-sidebar> -->

          <div class="col-xl-12 col-lg-12">
            <div class="contact-head">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <ul class="contact-breadcrumb">
                    <li>
                      <router-link to="/users/users-list"><i class="ti ti-arrow-narrow-left"></i>Users</router-link>
                    </li>
                    <li>{{ isEditing ? 'Edit User' : 'Create New User' }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Company Settings -->
            <div class="card">
              <div class="card-body settings-form">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <h4>{{ isEditing ? 'Edit User' : 'Create User' }}</h4>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <!-- <div class="export-list text-sm-end">
                      <router-link class="btn btn-primary" to="/users/users-list">
                        Back</router-link>
                    </div> -->
                  </div>
                </div>
                <Form @submit="registerUser" :validation-schema="schema">
                  <div class="settings-sub-header">
                    <!-- <h6>User Information</h6> -->
                  </div>
                  <div class="profile-details">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-wrap">
                          <div class="profile-upload">
                            <div class="profile-upload-img">
                              <span v-if="!imagePreview"><i class="ti ti-photo"></i></span>
                              <img v-if="imagePreview" :src="imagePreview" class="img-fluid" />
                              <button v-if="imagePreview" type="button" class="profile-remove" @click="removeImage">
                                <i class="feather-x"></i>
                              </button>
                            </div>
                            <div class="profile-upload-content">
                              <label class="profile-upload-btn">
                                <i class="ti ti-file-broken"></i> Upload File
                                <input type="file" class="input-img" @change="handleImageUpload" />
                              </label>
                              <p>
                                Upload User Image.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                          <Field name="first_name" as="input" type="text" class="form-control" v-model="form.first_name" />
                          <ErrorMessage name="first_name" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Last Name</label>
                          <Field name="last_name" as="input" v-model="form.last_name" type="text" class="form-control" />
                          <!-- <ErrorMessage name="last_name" class="text-danger" /> -->
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Initials</label>
                          <Field name="initial" as="input" v-model="form.initials" type="text" class="form-control" />
                          <!-- <ErrorMessage name="initial" class="text-danger" /> -->
                          <span class="text-danger" v-if="apiErrors && apiErrors['initials'] && apiErrors['initials'].length">{{ apiErrors['initials'][0]}}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Email <span v-if="!isEditing" class="text-danger">*</span></label>
                          <Field name="email" as="input" :disabled="form.id !== null" v-model="form.email" type="email" class="form-control" />
                          <ErrorMessage name="email" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['email'] && apiErrors['email'].length">{{ apiErrors['email'][0]}}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Login Name</label>
                          <input v-model="form.login_name" type="text" class="form-control" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['login_name'] && apiErrors['login_name'].length">{{ apiErrors['login_name'][0] }}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Title</label>
                          <input v-model="form.title" type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Login by sms code</label>
                          <select id="loginBySms" class="form-select" v-model="form.login_by_sms_code">
                            <option value="no">-----No-----</option>
                            <option v-for="source in loginSmsCode" :key="source" :value="source">
                              {{ source }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Telephone</label>
                          <Field name="telephone" ref="phoneInput" as="input" v-model="form.telephone" type="text" class="form-control" />
                          <ErrorMessage name="telephone" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['telephone'] && apiErrors['telephone'].length">{{ apiErrors['telephone'][0] }}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Mobile</label>
                          <Field name="phone_number" as="input" v-model="formattedTelephone" type="text" class="form-control" />
                          <ErrorMessage name="phone_number" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['phone_number'] && apiErrors['phone_number'].length">{{ apiErrors['phone_number'][0] }}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Password <span v-if="!isEditing" class="text-danger">*</span></label>
                          <Field id="password" name="password" as="input" v-model="form.password" type="password" class="form-control" />
                          <ErrorMessage name="password" class="text-danger" />
                          <span class="text-danger" v-if="isEditing & apiErrors && apiErrors['password'] && apiErrors['password'].length">{{ apiErrors['password'][0] }}</span>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Confirm Password <span v-if="!isEditing" class="text-danger">*</span></label>
                          <Field id="password_confirmation" name="password_confirmation" as="input" v-model="form.password_confirmation" type="password" class="form-control" />
                          <ErrorMessage name="password_confirmation" class="text-danger" />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Use available status</label>
                          <select id="availStatus" class="form-select" v-model="form.use_available_status">
                            <option value="">-----Select-----</option>
                            <option v-for="source in availStatus" :key="source" :value="source">
                              {{ source }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Access level <span v-if="!isEditing" class="text-danger">*</span></label>
                          <Field as="select" name="role" class="form-select" v-model="form.role" @change="fetchResponsibles('role')">
                            <option value="">-----Select-----</option>
                            <option v-for="role in roles" :key="role.key" :value="role.key">
                              {{ role.value }}
                            </option>
                          </Field>
                          <ErrorMessage name="role" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Territories</label>
                          <multiselect v-model="form.territories" :options="territories" track-by="id" label="name" :multiple="isMultiple" tag-placeholder="Select territories" placeholder="Select..." @input="fetchResponsibles"></multiselect>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-wrap">
                          <label class="col-form-label">Assigned Manager</label>
                          <select id="userRes" class="form-select" v-model="form.organization_2nd_responsible">
                            <option value="">-----Select-----</option>
                            <option v-for="res in responsibleUsers" :key="res.name" :value="res.id">
                              {{ res.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="mx-3" v-if="permissions.length > 0">
                      <div class="row">
                        <div v-for="permission in permissions" :key="permission" class="col-12 col-xs-12 col-md-2 mb-3 form-check">
                          <input type="checkbox" class="form-check-input" :id="permission" :value="permission" v-model="form.permissions" />
                          <label class="form-check-label" :for="permission">{{ permission }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center my-3">
                      <div class="col-md-2">
                        <h4>Calendar colour :</h4>
                      </div>
                      <div class="col-md-10">
                        <div class="color-selectors">
                          <div v-for="color in colors" :key="color" class="color-field" :style="{ backgroundColor: color }" @click="handleColorSelect(color)">
                            <span v-if="form.calendar_colour === color" class="checkmark">✔</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-button text-end">
                    <router-link class="btn btn-light" to="/users/users-list">
                      Back</router-link>
                    <button type="submit" class="btn btn-primary">{{ isEditing ? 'Update' : 'Create and send invite' }}</button>
                  </div>
                </Form>
              </div>
            </div>
            <!-- /Company Settings -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import apiClient from "@/axios";
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
import Multiselect from 'vue-multiselect';
import $ from 'jquery';

import {
  Form,
  Field,
  ErrorMessage,
  useForm
} from "vee-validate";
import * as Yup from "yup";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      title: "Settings",
      availStatus: [
        'No', 'Yes'
      ],
      loginSmsCode: ['always']
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const colors = ["#a8729f", "#8479b2", "#5190b0", "#66a8a7", "#58a677", "#97ab67", "#b4a775", "#a1867f", "#ba8970", "#db919c"];
    const imagePreview = ref('');
    const responsibleUsers = ref([]);
    const isEditing = ref(false);

    if (route.query.userId) {
      // isCreatingUser.value = false;
      isEditing.value = true;
    }

    const territories = computed(() => store.getters['users/getterritories']);

    const roles = ref([]);
    const permissions = ref([]);
    const selectedterritories = ref([]);
    const apiErrors = ref(null);

    let schema;

    if (isEditing.value) {
      schema = Yup.object().shape({
        first_name: Yup.string()
          .required("First name is required"),
        // .min(3, "First name must be at least 3 characters"),
        email: Yup.string()
          .required("Email is required")
          .email('The email field must be a valid email address.'),
        role: Yup.string().required('Access level is required.'),
      });
    } else {
      schema = Yup.object().shape({
        first_name: Yup.string()
          .required("First name is required"),
        // .min(3, "First name must be at least 3 characters"),
        email: Yup.string()
          .required("Email is required")
          .email('The email field must be a valid email address.'),
        role: Yup.string().required('Access level is required.'),
        password: Yup.string()
          .required('Please enter the password')
          .min(8, 'Password must be at least 8 characters')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
          ),
        password_confirmation: Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'), null], 'Password must match'),
      });
    }

    const form = ref({
      id: null,
      image: null,
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      password: '',
      password_confirmation: '',
      role: '',
      permissions: [],
      territories: [],
      calendar_colour: "",
      initials: '',
      login_name: '',
      telephone: '',
      organization_2nd_responsible: '',
      meter_opportunity_referred_by: '',
      use_available_status: '',
      login_by_sms_code: '',
      title: '',
    });

    const isMultiple = computed(() => form.value.role === 'territory_manager');

    // Assuming form is a reactive object
    const formattedTelephone = computed({
      get() {
        // Ensure form and form.value.telephone are defined
        if (!form.value || !form.value.phone_number) {
          return ''; // Return an empty string if there's no telephone number
        }

        const number = form.value.phone_number.replace(/\D/g, '');

        // Check if the number is valid (e.g., 10 digits)
        if (number.length === 10) {
          // Format the number
          return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
        } else {
          // Return the original value if the number is not valid
          return form.value.phone_number;
        }
      },
      set(value) {
        // Clean the input value to store only digits
        const cleanedValue = value.replace(/\D/g, '');

        // If the cleaned value is empty, set form.telephone to an empty string
        form.value.phone_number = cleanedValue.length ? cleanedValue : '';
      }
    });

    const fetchUserData = async (id) => {
      try {
        const response = await apiClient.get(`/company/users/view/${id}`);

        Object.assign(form.value, response.data);
        imagePreview.value = response.data.image;
        if (response.data.permissions) {
          form.value.permissions = response.data.permissions;
        }
        if (response.data.territories) {
          if (isMultiple.value) {
            form.value.territories = territories.value.filter(territory =>
              response.data.territories.includes(territory.id)
            );
          } else {
            // If single selection is allowed, find and set the single territory
            const selectedTerritory = territories.value.find(territory =>
              response.data.territories.includes(territory.id)
            );
            form.value.territories = selectedTerritory ? selectedTerritory : null;
          }
        }

        if (response.data.calendar_colour) {
          form.value.calendar_colour = response.data.calendar_colour;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    const registerUser = async () => {
      let isValid = true;
      $('.error-message').remove();

      if (form.value.id) {
        if (form.value.password.length > 0) {
          if (form.value.password !== form.value.password_confirmation) {
            isValid = false;
            $('#password_confirmation').after('<span class="text-danger error-message">Password must match</span>');
          }
        }
      }
      if (!isValid) {
        return;
      }

      let selectedTerritoryIds = [];

      if (isMultiple.value) {
        selectedTerritoryIds = form.value.territories.map((territory) => territory.id);
      } else if (form.value.territories && form.value.territories.id) {
        selectedTerritoryIds = [form.value.territories.id];
      }

      const formData = {
        ...form.value,
        territories: selectedTerritoryIds,
      };
      try {
        if (form.value.id) {
          if(typeof formData.image === 'string'){
          formData.image = null;
        }
          await apiClient.post(`/company/users/update/${form.value.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          await apiClient.post('/company/user/register', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
        router.push('/users/users-list');
      } catch (error) {
        apiErrors.value = error.response.data.errors;
        console.error('Error saving user:', error);
      }
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        form.value.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result; // Show the image preview
        };
        reader.readAsDataURL(file); // Convert the file to a data URL
      }
    };

    const removeImage = () => {
      form.value.image = '';
      imagePreview.value = '';
    };

    const handleColorSelect = (color) => {
      form.value.calendar_colour = color;
    };
    const fetchRoles = async () => {
      try {
        const response = await apiClient.get('/company/user/roles');
        roles.value = response.data.map(role => {
          const [key, value] = Object.entries(role)[0];
          return {
            key,
            value
          };
        });
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    const fetchPermissions = async () => {
      if (form.value.role) {
        try {
          const response = await apiClient.get(
            `roles/${form.value.role}/permissions`);
          permissions.value = response.data.permissions;
          if (!form.value.permissions.length) {
            form.value.permissions = permissions.value.slice();
          }

        } catch (error) {
          console.error('Error fetching permissions:', error);
        }
      }
    };

    const fetchResponsibles = async (role) => {
      if (role === 'role') {
        form.value.territories = [];
      }

      const selectedRole = form.value.role;
      let selectedTerritoryIds = [];
      const selectedTer = form.value.territories;

      // Determine if multiple selections are allowed
      if (isMultiple.value && Array.isArray(selectedTer)) {
        selectedTerritoryIds = selectedTer.map(territory => territory.id);
      } else if (!isMultiple.value && selectedTer && selectedTer.id) {
        selectedTerritoryIds = [selectedTer.id];
      }

      if (selectedRole !== '') {
        try {
          const response = await apiClient.get('/dropdown/company/assigned-managers', {
            params: {
              role: selectedRole,
              territory_ids: selectedTerritoryIds.length > 0 ? selectedTerritoryIds : []
            }
          });
          responsibleUsers.value = response.data.map(user => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }));
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    };

    watch(() => form.value.role, (newRole) => {
      fetchPermissions(newRole);
    });

    watch(() => form.value.territories, (newTerritories) => {
      // Check if newTerritories is an array and has at least one item
      if (Array.isArray(newTerritories) && newTerritories.length > 0) {
        // Check if the first item in the array has a 'name' property
        if (newTerritories[0] && newTerritories[0].name) {
          fetchResponsibles(form.value.role); // Pass the role to fetchResponsibles
        } else {
          return; // Exit if the 'name' property is not present
        }
      } else if (newTerritories && newTerritories.name) {
        // Handle the case where newTerritories is a single object
        fetchResponsibles(form.value.role); // Pass the role to fetchResponsibles
      }
    });

    onMounted(async () => {
      const userId = route.query.userId;
      store.dispatch('users/fetchTerritories');
      // fetchTerritories();
      fetchRoles();
      if (userId) {
        fetchUserData(userId);
      }
      // fetchResponsibles();
    });

    return {
      schema,
      isEditing,
      formattedTelephone,
      imagePreview,
      roles,
      responsibleUsers,
      permissions,
      territories,
      removeImage,
      selectedterritories,
      form,
      registerUser,
      fetchResponsibles,
      colors,
      handleColorSelect,
      handleImageUpload,
      isMultiple,
      apiErrors
    };
  },
};
</script>

<style>
.profile-upload-img {
  width: 150px;
  height: 150px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-selectors {
  display: flex;
  gap: 10px;
}

.color-field {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.color-field:hover {
  border-color: #ccc;
}

.color-field .checkmark {
  font-size: 20px;
  color: white;
}

.color-field.selected {
  border-color: #000;
}
</style>
