<template>
  <!-- Add New Industry -->
  <div class="modal custom-modal fade" id="add_industry" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New Industry</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/crm-settings/industry">
            <div class="form-wrap">
              <label class="col-form-label"
                >Industry Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label">Status</label>
              <div class="d-flex flex-wrap radio-wrap">
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="add-active"
                    name="status"
                    checked=""
                  />
                  <label for="add-active">Active</label>
                </div>
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="add-inactive"
                    name="status"
                  />
                  <label for="add-inactive">Inactive</label>
                </div>
              </div>
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Industry -->

  <!-- Edit Industry -->
  <div class="modal custom-modal fade" id="edit_industry" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Industry</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/crm-settings/industry">
            <div class="form-wrap">
              <label class="col-form-label"
                >Industry Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Retail Industry" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label">Status</label>
              <div class="d-flex flex-wrap radio-wrap">
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="edit-active"
                    name="status"
                    checked=""
                  />
                  <label for="edit-active">Active</label>
                </div>
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="edit-inactive"
                    name="status"
                  />
                  <label for="edit-inactive">Inactive</label>
                </div>
              </div>
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Industry -->

  <!-- Delete Industry -->
  <div class="modal custom-modal fade" id="delete_industry" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/crm-settings/industry">
            <div class="success-message text-center">
              <div class="success-popup-icon">
                <i class="ti ti-trash-x"></i>
              </div>
              <h3>Remove Industry?</h3>
              <p class="del-info">Are you sure you want to remove it.</p>
              <div class="col-lg-12 text-center modal-btn">
                <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-danger">Yes, Delete it</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Industry -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
