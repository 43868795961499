<template>
  <div class="account-page">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">
          <div class="login-content">
            <!-- <form action="/dashboard/deals-dashboard"> -->
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="login-user-info">
                <div class="login-logo">
                  <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
                </div>
                <div class="login-heading">
                  <h4>Sign In</h4>
                  <p>Access the CRMS panel using your email and passcode.</p>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Email Address</label>
                  <div class="form-wrap-icon">
                    <Field
                      name="email"
                      type="text"
                      value="example@dreamstechnologies.com"
                      class="form-control"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                    <i class="ti ti-mail"></i>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Password</label>
                  <div class="pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      value="123456"
                      class="form-control pass-input mt-2"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': showPassword,
                          'fas fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <div class="form-wrap form-wrap-checkbox">
                  <div class="custom-control custom-checkbox">
                    <label class="check">
                      <input type="checkbox" />
                      <span class="box"></span> Remember Me
                    </label>
                  </div>
                  <div class="text-end">
                    <router-link to="/forgot-password-2" class="forgot-link"
                      >Forgot Password?</router-link
                    >
                  </div>
                </div>
                <div class="form-wrap">
                  <button type="submit" class="btn btn-primary">Sign In</button>
                </div>
                <div class="login-form">
                  <h6>
                    New on our platform?<router-link to="/register-2" class="hover-a">
                      Create an account</router-link
                    >
                  </h6>
                </div>
                <div class="form-set-login or-text">
                  <h4>OR</h4>
                </div>
                <div class="login-social-link">
                  <ul class="nav">
                    <li>
                      <a href="javascript:void(0);" class="facebook-logo">
                        <img src="@/assets/img/icons/facebook-logo.svg" alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="@/assets/img/icons/google-logo.svg" alt="Google" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="apple-logo">
                        <img src="@/assets/img/icons/apple-logo.svg" alt="Apple" />
                      </a>
                    </li>
                  </ul>
                  <div class="copyright-text">
                    <p>
                      Copyright &copy; {{ new Date().getFullYear() }} CRMS. All rights
                      reserved
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div class="login-img">
            <img
              src="@/assets/img/authentication/login-img.png"
              class="img-fluid"
              alt="Login"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { router } from "@/router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
export default {
  components: {
    /* eslint-disable vue/no-reserved-component-names */
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  setup() {
    let users = localStorage.getItem("storedData");
    if (users === null) {
      let password = [
        {
          email: "example@dreamstechnologies.com",
          password: "123456",
        },
      ];
      const jsonData = JSON.stringify(password);
      localStorage.setItem("storedData", jsonData);
    }
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });
    const onSubmit = (values) => {
      document.getElementById("email").innerHTML = "";
      document.getElementById("password").innerHTML = "";
      let data = localStorage.getItem("storedData");
      var Pdata = JSON.parse(data);
      const Eresult = Pdata.find(({ email }) => email === values.email);
      if (Eresult) {
        if (Eresult.password === values.password) {
          router.push("/dashboard");
        } else {
          document.getElementById("password").innerHTML = "Incorrect password";
        }
      } else {
        document.getElementById("email").innerHTML = "Email is not valid";
      }
    };
    return {
      schema,
      onSubmit,
      checked: ref(false),
    };
  },
};
</script>
