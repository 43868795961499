<template>
    <div class="content">
      <div class="row">
        <div class="col-md-12 p-3">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="cards p-4">
                <div class="card-body">
                  <div class="settings-header">
                    <h4>{{ title }}</h4>
                  </div>
                  <div class="row">
                   <div class="col-12" v-for="(error, index) in errorLogs" :key="index">
                   <P>{{ error }}</P>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {
  ref,
  onMounted,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRoute
} from "vue-router";
import router from "@/router";

export default {
  data() {
    return {
      title: "Data Import | View details",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const file_id = ref(route.query.fileId || null);
    const errorLogs = ref([])

    const fetchErrorsData = async (id) => {
      try {
        const data = await store.dispatch("dataimport/fetchWarningErrors", id);
        
        errorLogs.value = data;
       
      } catch (error) {
        console.error("Error fetchWarningErrors data:", error);
      }
    };

    onMounted(async () => {
      if (file_id.value) {
        fetchErrorsData(file_id.value);
      }
    });

    return {
      fetchErrorsData,
      errorLogs,
      file_id,
    };
  },
};
</script>
