<template>
  <a-table
    class="table dataTable no-footer"
    :columns="columns"
    :data-source="data"
    :row-selection="rowSelection"
    :pagination="pagination"
     @change="handleTableChange"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'Star'">
        <div class="set-star rating-select"><i class="fa fa-star"></i></div>
      </template>
      <template v-else-if="column.key === 'Lead_name'">
        <div>
          <router-link to="/crm/meter-opportunities-details" class="title-name">{{
            record.lead_name
          }}</router-link>
        </div>
      </template>
      <template v-else-if="column.key === 'Company_name'">
        <h2 class="table-avatar d-flex align-items-center">
          <router-link to="/crm/company-details" class="company-img"
            ><img
              class="avatar-img"
              :src="require(`@/assets/img/icons/${record.company_image}`)"
              alt="User Image" /></router-link
          ><router-link to="/crm/company-details" class="profile-split d-flex flex-column"
            >{{ record.company_name
            }}<span>{{ record.company_address }} </span></router-link
          >
        </h2>
      </template>
      <template v-else-if="column.key === 'Status'">
        <div>
          <span :class="record.statusclass">{{ record.status }}</span>
        </div>
      </template>
      <template v-else-if="column.key === 'Owner'">
        <div>
          <span class="title-name">{{ record.owner }}</span>
        </div>
      </template>
      <template v-else-if="column.key === 'action'">
        <div class="dropdown table-action">
          <a href="javascript:;" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"
            ><i class="fa fa-ellipsis-v"></i
          ></a>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ path: '/crm/meter-opportunities-edit', query: { territory_id: record.territory_id, org_Id: record.orgId, opp_Id: record.key }}"><i class="ti ti-edit text-blue"></i> Edit</router-link>
            <!-- <a class="dropdown-item edit-popup" href="javascript:;"
              ><i class="ti ti-edit text-blue"></i> Edit</a
            ><a
              class="dropdown-item"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#delete_contact"
              ><i class="ti ti-trash text-danger"></i> Delete</a
            ><a class="dropdown-item" href="javascript:;"
              ><i class="ti ti-clipboard-copy text-blue-light"></i> Clone</a
            > -->
          </div>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import {
  ref,
  onMounted,
  watchEffect
} from 'vue';
import {
  useRouter
} from 'vue-router';
import apiClient from '@/axios';

const columns = [{
    title: "Number",
    dataIndex: "number",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.number;
        b = b.number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. Name",
    dataIndex: "org_name",
    sorter: {
      compare: (a, b) => {
        a = a.org_name;
        b = b.org_name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "Description",
    sorter: {
      compare: (a, b) => {
        a = a.description;
        b = b.description;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Pipeline text",
    dataIndex: "pipe_line",
    sorter: {
      compare: (a, b) => {
        a = a.pipe_line;
        b = b.pipe_line;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date of Sale",
    dataIndex: "date_of_sale",
    sorter: {
      compare: (a, b) => {
        a = a.date_of_sale;
        b = b.date_of_sale;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "SC Forecast Total Commission",
    dataIndex: "total_commission",
    sorter: {
      compare: (a, b) => {
        a = a.total_commission;
        b = b.total_commission;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  props: {
    searchOppQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const router = useRouter();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchOpportunities = async () => {
      try {
        const response = await apiClient.get('/opportunities', {
          params: {
            search: props.searchOppQuery,
            page: pagination.value.current,
            pageSize: pagination.value.pageSize,
          },
        });
        if (response.data.status) {
          data.value = response.data.data.data.map((opp) => ({
            key: opp.id,
            number: opp.meter_opportunity_nr_auto,
            pipe_line: opp.pipeline.name,
            description: opp.description,
            territory_id: opp.territory_id,
            orgId: opp.organization.id,
            org_name: opp.organization.name,
            date_of_sale: opp.date_of_sale,
            total_commission: opp.sc_forecast_total_commission
          }));
          pagination.value.total = response.data.data.total;
        } else {
          console.error("Failed to fetch opportunities:", response);
        }
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      }
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchOpportunities();
    };

    const openEditModal = (id) => {
      router.push({
        path: "/crm/create-organisation",
        query: {
          orgId: id
        },
      });
    };

    watchEffect(() => {
      fetchOpportunities();
    });

    return {
      data,
      pagination,
      fetchOpportunities,
      handleTableChange,
      openEditModal,
    };
  },
};
</script>
