<template>
  <!-- Add New Project -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Project</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"><i class="ti ti-x"></i></a>
      </div>
      <div class="toggle-body">
        <form action="/project-dashboard" class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Project ID<span class="text-danger"> *</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Project Type <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="Apptypesel"
                    id="apptypesel"
                    placeholder="Choose"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Client <span class="text-danger">*</span></label
                  >
                  <vue-select :options="Hawkel" id="hawkel" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Category <span class="text-danger">*</span></label
                  >
                  <vue-select :options="ViewSelect" id="viewselect" placeholder="" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Project Timing <span class="text-danger">*</span></label
                  >
                  <vue-select :options="HouMinSel" id="houminsel" placeholder="Select" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Price <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Amount <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Total <span class="text-danger">*</span></label
                  >
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Responsible Persons <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="DashProSel"
                    id="dashprosel"
                    placeholder="Darlee Robertson"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Team Leader <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="TeamProSel"
                    id="teamprosel"
                    placeholder="Darlee Robertson"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Start Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdate"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      value="29-02-2020"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-event"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Due Date <span class="text-danger">*</span></label
                  >
                  <div class="icon-form">
                    <date-picker
                      v-model="startdateOne"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      value="29-02-2020"
                      :input-format="dateFormat"
                    />
                    <span class="form-icon"><i class="ti ti-calendar-event"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Priority</label>
                  <vue-select
                    :options="ProDashSelMe"
                    id="prodashselme"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Status</label>
                  <vue-select
                    :options="ProActivelMe"
                    id="proactivelme"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <textarea class="form-control" rows="4"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add New Project -->

  <!-- Delete Contact -->
  <div class="modal custom-modal fade" id="delete_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Contacts?</h3>
            <p class="del-info">Are you sure you want to remove contact you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/contacts-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Contact -->

  <!-- Create Contact -->
  <div class="modal custom-modal fade" id="create_contact" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-user-plus"></i>
            </div>
            <h3>Contact Created Successfully!!!</h3>
            <p>View the details of contact, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/crm/contact-details" class="btn btn-primary"
                >View Details</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Contact -->

  <!-- Add Event Modal -->
  <div id="dwnld_report" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Download Report</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="mb-3">
              <label class="form-label"
                >File Type <span class="text-danger">*</span></label
              >
              <vue-select
                :options="ExcelSelect"
                id="execlselect"
                placeholder="Download as PDF"
              />
            </div>
            <div class="mb-3">
              <h5>Filters</h5>
            </div>
            <div class="mb-3">
              <label class="form-label"
                >File Type <span class="text-danger">*</span></label
              >
              <vue-select
                :options="FieldsName"
                id="filedsname"
                placeholder="All Fields"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Position<span class="text-danger">*</span></label>
              <vue-select
                :options="InstallerSel"
                id="installersel"
                placeholder="All Position"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Source<span class="text-danger">*</span></label>
              <vue-select :options="AllSource" id="allsource" placeholder="All Source" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Select Year<span class="text-danger">*</span></label
              >
              <vue-select :options="YearSelect" id="YearSelect" placeholder="2023" />
            </div>
            <div class="col-lg-12 text-end modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <a href="javascript:;" class="btn btn-primary">Download Now</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Event Modal -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      YearSelect: ["2023", "2022", "2021"],
      AllSource: ["All Source", "Google", "Campaigns", "Referrals", "Paid Social"],
      InstallerSel: [
        "All Position",
        "Installer",
        "Senior Manager",
        "Test Engineer",
        "UI /UX Designer",
      ],
      FieldsName: [
        "All Fields",
        "Name",
        "Position",
        "Owner",
        "Location",
        "Phone",
        "Date Created",
      ],
      ExcelSelect: ["Download as PDF", "Download as Excel"],
      ProActivelMe: ["Select", "Active", "Inactive"],
      ProDashSelMe: ["Select", "High", "Low", "Medium"],
      TeamProSel: [
        "Darlee Robertson",
        "Sharon Roy",
        "Vaughan",
        "Jessica",
        "Carol Thomas",
      ],
      DashProSel: [
        "Darlee Robertson",
        "Sharon Roy",
        "Vaughan",
        "Jessica",
        "Carol Thomas",
      ],
      ViewSelect: ["Select", "HarborView", "LLC"],
      Hawkel: ["Select", "NovaWave LLC", "SilverHawk", "HarborView"],
      Apptypesel: ["Choose", "Mobile App", "Meeting"],
      HouMinSel: ["Select", "Hourly", "Minutes"],
    };
  },
};
</script>
