<template>
<a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection" @change="handleTableChange">
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'Name'">
      <h2 class="table-avatar d-flex align-items-center">
        <router-link to="/crm/company-details" class="profile-split d-flex flex-column">{{ record.name }}</router-link>
      </h2>
    </template>
    <template v-else-if="column.key === 'action'">
      <div class="dropdown table-action">
        <a href="javascript:;" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item edit-popup" href="javascript:void(0);" @click="openEditModal(record.key)"><i class="ti ti-edit text-blue"></i> Edit</a>
          <!-- <a
              class="dropdown-item"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#delete_contact"
              ><i class="ti ti-trash text-danger"></i> Delete</a
            > -->
          <!-- <a class="dropdown-item" href="javascript:;" @click="confirmDelete(record.key)">
            <i class="ti ti-trash text-danger"></i> Delete
          </a> -->
          <!-- <router-link class="dropdown-item" to="/crm/company-details"
              ><i class="ti ti-eye text-blue-light"></i> Preview</router-link
            > -->
        </div>
      </div>
    </template>
  </template>
</a-table>
<!-- <div class="modal-backdrop fade show" v-if="showDeleteModal"></div>
<div v-if="showDeleteModal" class="modal custom-modal fade show" style="display: block;" tabindex="-1" role="dialog" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0 m-0 justify-content-end">
        <button class="btn-close" @click="showDeleteModal = false" aria-label="Close">
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="success-message text-center">
          <div class="success-popup-icon">
            <i class="ti ti-trash-x"></i>
          </div>
          <h3>Delete Account</h3>
          <p class="del-info">Are you sure you want to delete?</p>
          <div class="col-lg-12 text-center modal-btn">
            <button class="btn btn-light" @click="showDeleteModal = false">Cancel</button>
            <button class="btn btn-danger" @click="deleteUser(selectedUserId)">Yes, Delete it</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
</template>

<script>
import {
  useRouter
} from 'vue-router';

import apiClient from "@/axios";
import {
  message
} from "ant-design-vue";
const columns = [{
    title: "Name",
    dataIndex: "name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created",
    dataIndex: "created",
    sorter: {
      compare: (a, b) => {
        a = a.created;
        b = b.created;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  data() {
    return {
      columns,
      selectedUserId: null,
      showDeleteModal: false,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },
  mounted() {
    this.fetchTerritories();
  },
  setup() {
    const router = useRouter();

    const openEditModal = (id) => {
      router.push({
        path: "/territory/create-territory",
        query: {
          territoryId: id
        }
      });
    };

    return {
      openEditModal,
    };
  },
  methods: {
    async fetchTerritories(page = 1, pageSize = 10) {
      try {
        const token = localStorage.getItem("authToken"); // Adjust the key if necessary
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await apiClient.get("/territories", {
          params: {
            page,
            pageSize
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status) {
          console.log(response.data.data, 'response territories');
          this.data = response.data.data.data.map((territory) => ({
            key: territory.id,
            name: territory.name,
            created: territory.created_at,
          }));
          this.pagination.total = response.data.data.total;
        } else {
          console.error("Failed to fetch territories:", response);
        }
      } catch (error) {
        console.error("Error fetching territories:", error);
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.fetchTerritories(pagination.current, pagination.pageSize);
    },
  },
};
</script>
