<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <general-sidebar></general-sidebar>

            <div class="col-xl-9 col-lg-12">
              <!-- Settings Info -->
              <div class="card">
                <div class="card-body">
                  <div class="settings-header">
                    <h4>Security Settings</h4>
                  </div>
                  <div class="settings-form">
                    <!-- General Notifications -->
                    <div class="settings-sub-header">
                      <h6>General Notifications</h6>
                      <p>Select notifications</p>
                    </div>
                    <div class="notification-wrap">
                      <ul>
                        <li>
                          <div class="security-checkbox">
                            <label class="checkboxs">
                              <input type="checkbox" checked />
                              <span class="checkmarks"></span>
                              Mobile Push Notifications
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="security-checkbox">
                            <label class="checkboxs">
                              <input type="checkbox" checked />
                              <span class="checkmarks"></span>
                              Desktop Notifications
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="security-checkbox">
                            <label class="checkboxs">
                              <input type="checkbox" checked />
                              <span class="checkmarks"></span>
                              Email Notifications
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="security-checkbox">
                            <label class="checkboxs">
                              <input type="checkbox" checked />
                              <span class="checkmarks"></span>
                              SMS Notifications
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- /General Notifications -->

                    <!-- Custom Notifications -->
                    <div class="settings-sub-header">
                      <h6>Custom Notifications</h6>
                      <p>
                        Select when you will be notified when the following changes occur
                      </p>
                    </div>
                    <div class="table-responsive notificaion-table">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Push</th>
                            <th>SMS</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody class="custom-table-data">
                          <tr>
                            <td>Legendary</td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="users4"
                                  class="check"
                                  checked
                                />
                                <label for="users4" class="checktoggle"> </label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="users5"
                                  class="check"
                                  checked
                                />
                                <label for="users5" class="checktoggle"> </label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="users6"
                                  class="check"
                                  checked
                                />
                                <label for="users6" class="checktoggle"> </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Transaction</td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input type="checkbox" id="user5" class="check" checked />
                                <label for="user5" class="checktoggle"> </label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input type="checkbox" id="user6" class="check" checked />
                                <label for="user6" class="checktoggle"> </label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input type="checkbox" id="user7" class="check" checked />
                                <label for="user7" class="checktoggle"> </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Email Verification</td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input type="checkbox" id="user8" class="check" checked />
                                <label for="user8" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input type="checkbox" id="user9" class="check" checked />
                                <label for="user9" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user10"
                                  class="check"
                                  checked
                                />
                                <label for="user10" class="checktoggle"></label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>OTP</td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user11"
                                  class="check"
                                  checked
                                />
                                <label for="user11" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user12"
                                  class="check"
                                  checked
                                />
                                <label for="user12" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user13"
                                  class="check"
                                  checked
                                />
                                <label for="user13" class="checktoggle"></label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Activity</td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user14"
                                  class="check"
                                  checked
                                />
                                <label for="user14" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user15"
                                  class="check"
                                  checked
                                />
                                <label for="user15" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user16"
                                  class="check"
                                  checked
                                />
                                <label for="user16" class="checktoggle"></label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Account</td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user17"
                                  class="check"
                                  checked
                                />
                                <label for="user17" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user18"
                                  class="check"
                                  checked
                                />
                                <label for="user18" class="checktoggle"></label>
                              </div>
                            </td>
                            <td>
                              <div class="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user19"
                                  class="check"
                                  checked
                                />
                                <label for="user19" class="checktoggle"></label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- /Custom Notifications -->
                  </div>
                </div>
              </div>
              <!-- /Settings Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal custom-modal fade" id="delete_account" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Delete Account</h3>
            <p class="del-info">Are you sure want to delete?</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/settings/security" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Settings",
    };
  },
};
</script>
