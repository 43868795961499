<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Filter -->
          <div class="filter-section filter-flex">
            <div class="sortby-list">
              <ul>
                <li>
                  <div class="form-sorts dropdown">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                      ><i class="ti ti-filter-share"></i>Filter</a
                    >
                    <div
                      class="filter-dropdown-menu dropdown-menu dropdown-menu-start"
                    >
                      <div class="filter-set-view">
                        <div class="filter-set-head">
                          <h4><i class="ti ti-filter-share"></i>Filter</h4>
                        </div>
                        <div class="accordion" id="accordionExample">
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="javascript:;"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                                >Deals Name</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse show"
                              id="collapseTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Country"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Collins</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Konopelski</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Adams</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Gutkowski</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Walter</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="javascript:;"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#owner"
                                aria-expanded="false"
                                aria-controls="owner"
                                >Owner</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="owner"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Owner"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Hendry</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Guillory</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Jami</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Theresa</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Espinosa</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="javascript:;"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#Status"
                                aria-expanded="false"
                                aria-controls="Status"
                                >Status</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="Status"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Won</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Open</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Lost</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="javascript:;"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                                >Rating</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="collapseOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="rating">
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <span>5.0</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="rating">
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star"></i>
                                      <span>4.0</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="rating">
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                      <span>3.0</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="rating">
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                      <span>2.0</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="rating">
                                      <i class="fa fa-star filled"></i>
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                      <span>1.0</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="javascript:;"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                                >Tags</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="collapseThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Promotion</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Rated</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Rejected</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Collab</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Calls</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="filter-reset-btns">
                          <div class="row">
                            <div class="col-6">
                              <a href="javascript:;" class="btn btn-light">Reset</a>
                            </div>
                            <div class="col-6">
                              <router-link
                                to="/crm/deals-kanban"
                                class="btn btn-primary"
                                >Filter</router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-wrap icon-form">
                    <span class="form-icon"><i class="ti ti-search"></i></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Deals"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div class="filter-list">
              <ul>
                <li>
                  <div class="view-icons">
                    <router-link to="/crm/deals-list"
                      ><i class="ti ti-list-tree"></i
                    ></router-link>
                    <router-link to="/crm/deals-kanban" class="active"
                      ><i class="ti ti-grid-dots"></i
                    ></router-link>
                  </div>
                </li>
                <li>
                  <a href="javascript:void(0);" class="btn btn-primary add-popup"
                    ><i class="ti ti-square-rounded-plus"></i>Add Deals</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- /Filter -->

          <!-- Deals Kanban -->
          <deals-board></deals-board>
          <!-- /Deals Kanban -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <deals-list-modal></deals-list-modal>
</template>

<script>
export default {
  data() {
    return {
      title: "Deals",
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>