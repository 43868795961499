<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="contact-head">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <ul class="contact-breadcrumb">
                <li>
                  <router-link to="/crm/organisation-list"><i class="ti ti-arrow-narrow-left"></i>Organisations</router-link>
                </li>
                <li>
                  <router-link :to="{ path: '/crm/person-list', query: { org_id: org_id } }">Persons</router-link>
                </li>
                <li>{{ isEditing ? 'Edit Person' : 'Create New Person' }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <!-- Company Settings -->
            <div class="card">
              <div class="card-body settings-form">
                <!-- <form @submit.prevent="handleSubmit" class="toggle-height"> -->
                <Form @submit="handleSubmit" :validation-schema="schema" class="toggle-height">
                  <div class="settings-sub-header">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <h4>{{ isEditing ? 'Edit Person' : 'Create Person' }}</h4>
                      </div>
                    </div>
                  </div>
                  <div class="pro-create">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">First name<span class="text-danger">*</span></label>
                          <Field name="first_name" as="input" type="text" class="form-control" v-model="formData.first_name" />
                          <ErrorMessage name="first_name" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Last name</label>
                          <Field name="last_name" as="input" type="text" class="form-control" v-model="formData.last_name" />
                          <ErrorMessage name="last_name" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Status </label>
                          <select id="perStatusOptions" v-model="formData.status" class="form-select" v-if="options && options.org_p_status">
                            <option disabled value="">
                              -----Select-----
                            </option>
                            <option v-for="res in options.org_p_status" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Support cases</label>
                          <select id="status" v-model="formData.support_cases" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in supportCases" :key="res" :value="res">
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Email<span class="text-danger">*</span></label>
                          <Field name="email" as="input" type="email" class="form-control" v-model="formData.email" />
                          <ErrorMessage name="email" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['email'] && apiErrors['email'].length">{{ apiErrors['email'][0]}}</span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap form-wrap mt-4 pt-2">
                          <input type="checkbox" class="form-check-input me-2" id="block_mass_emails" :true-value="1" :false-value="0" v-model="formData.block_mass_emails" />
                          <label class="form-check-label" for="block_mass_emails">Block mass emails</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Skype</label>
                          <input type="text" class="form-control" v-model="formData.skype" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Salutation</label>
                          <select id="status" v-model="formData.salutation" class="form-select" v-if="options && options.org_p_salutation">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in options.org_p_salutation" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Job title</label>
                          <input type="text" class="form-control" v-model="formData.job_title" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Direct telephone</label>
                          <Field name="direct_telephone" as="input" type="text" class="form-control" v-model="formData.direct_telephone" />
                          <ErrorMessage name="direct_telephone" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Mobile</label>
                          <Field name="mobile_telephone" as="input" type="text" class="form-control" v-model="formData.mobile_telephone" />
                          <ErrorMessage name="mobile_telephone" class="text-danger" />
                          <span class="text-danger" v-if="apiErrors && apiErrors['mobile_telephone'] && apiErrors['mobile_telephone'].length">{{ apiErrors['mobile_telephone'][0]}}</span>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="form-wrap mb-0">
                          <label class="col-form-label">Comment</label>
                          <textarea class="form-control" rows="3" v-model="formData.comment"></textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">job Function</label>
                          <select id="job_function" v-model="formData.job_function" class="form-select" v-if="options && options.org_p_job_functions">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in options.org_p_job_functions" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Safe toTelephone</label>
                          <select id="safe_to_telephone" v-model="formData.safe_to_telephone" class="form-select">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in safeTelephones" :key="res" :value="res">
                              {{ res }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Forename</label>
                          <input type="text" class="form-control" v-model="formData.dir_owner_forename" />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Surname</label>
                          <input type="text" class="form-control" v-model="formData.dir_owner_surname" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Director / Owner</label>
                          <select id="director_owner" v-model="formData.director_owner" class="form-select" v-if="options && options.org_p_dir_own">
                            <option value="">
                              -----Select-----
                            </option>
                            <option v-for="res in options.org_p_dir_own" :key="res.value" :value="res.value">
                              {{ res.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Due Date</label>
                          <div class="icon-form">
                            <date-picker v-model="formData.director_date_of_birth" placeholder="" class="datetimepicker form-control" :editable="true" :clearable="false" :input-format="dateFormat" />
                            <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Building No</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.dir_owner_building_no" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Building Name</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.dir_owner_building_name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Director Street</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.dir_owner_street" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Director Town</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.dir_owner_town" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Director Postcode</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.dir_owner_postcode" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Bank Account Name</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.customer_bank_account_name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Account No</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.customer_account_no" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Sort Code</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.customer_sort_code" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Direct Debit Day</label>
                          <select id="customer_direct_debit_day" v-model="formData.customer_direct_debit_day" class="form-select" v-if="options && options.org_p_direct_debit_day">
                            <option disabled value="">
                              -----Select-----
                            </option>
                            <option v-for="source in options.org_p_direct_debit_day" :key="source.value" :value="source.value">
                              {{ source.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Payment Method</label>
                          <select id="customer_payment_method" v-model="formData.customer_payment_method" class="form-select" v-if="options && options.org_p_payment_method">
                            <option disabled value="">
                              -----Select-----
                            </option>
                            <option v-for="source in options.org_p_payment_method" :key="source.value" :value="source.value">
                              {{ source.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Bank Name</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.customer_bank_name" />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Bank address</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.customer_bank_address" />
                          </div>
                        </div>
                      </div> -->
                      <div class="col-md-4">
                        <div class="form-wrap">
                          <label class="col-form-label">Bank address</label>
                          <div class="icon-form-end">
                            <input type="text" class="form-control" placeholder="" v-model="formData.customer_contact_urn" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap mb-0 mt-4" style="border: 1px solid #e1e1e1;">
                          <h4 class="p-2">Logs</h4>
                          <p class="col-form-label p-2" style="background-color: #eff1f0;">Add log entry</p>
                          <textarea class="form-control" rows="3" v-model="formData.content"></textarea>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap mt-4 pt-2">
                          <input type="checkbox" class="form-check-input me-2" id="key_accounting_system" :true-value="1" :false-value="0" v-model="formData.key_accounting_system" />
                          <label class="form-check-label" for="key_accounting_system">Key Accounting System ...</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-wrap mt-4 pt-2">
                          <input type="checkbox" class="form-check-input me-2" id="existing_dd" :true-value="1" :false-value="0" v-model="formData.existing_dd" />
                          <label class="form-check-label" for="existing_dd">Existing DD</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-3" v-if="isEditing">
                    <div class="col-md-4">
                      <div class="form-wrap mb-0" style="border: 1px solid #e1e1e1;">
                        <p class="col-form-label p-2" style="background-color: #eff1f0;">Logs</p>
                        <div class="row" v-for="logs in logs">
                          <div class="col-12">
                            <p class="p-2">{{ logs.content }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-button text-end">
                    <router-link v-if="isEditing" class="btn btn-light" :to="{ path: '/crm/person-list', query: { org_id: org_id } }">Cancel</router-link>
                    <router-link v-else class="btn btn-light" :to="{ path: '/crm/create-organisation', query: { orgId: org_id } }">Back</router-link>
                    <button type="submit" class="btn btn-primary">
                      {{ isEditing ? 'Update' : 'Create' }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed,
  onBeforeMount
} from "vue";
import {
  mapActions,
  mapGetters
} from 'vuex';
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";
import apiClient from '@/axios';

const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "dd-MM-yyyy",
      supportCases: [
        "Same language as users"
      ],
      safeTelephones: [
        "Y",
        "N"
      ],
      VatGroup: ["A", "B"],
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isEditing = ref(false);
    const org_id = computed(() => route.query.org_id);
    const apiErrors = ref(null);

    // onBeforeMount(async () => {
    //   await store.dispatch('person/fetchPersonOptions');
    // });

    const options = ref([]);

    const schema = Yup.object().shape({
      first_name: Yup.string()
        .required("First name is required")
        .min(3, "First name must be at least 3 characters"),
      email: Yup.string()
        .required("Email is required")
        .email('The email field must be a valid email address.'),
    });

    if (route.query.personId) {
      // org_id.value = route.query.org_id;
      isEditing.value = true;
    }
    const formData = ref({
      id: null,
      organization_id: route.query.org_id,
      first_name: "",
      last_name: "",
      status: "",
      support_cases: "",
      email: '',
      block_mass_emails: 0,
      skype: "",
      salutation: "",
      job_title: "",
      birthday: "",
      direct_telephone: "",
      mobile_telephone: "",
      comment: "",
      job_function: "",
      safe_to_telephone: "",
      dir_owner_forename: "",
      dir_owner_surname: '',
      director_owner: "",
      director_date_of_birth: "",
      dir_owner_building_no: "",
      dir_owner_building_name: "",
      dir_owner_street: "",
      dir_owner_town: "",
      dir_owner_postcode: "",
      customer_bank_account_name: "",
      customer_account_no: "",
      customer_sort_code: "",
      customer_direct_debit_day: "",
      customer_payment_method: "",
      customer_bank_name: "",
      customer_bank_address: "",
      customer_contact_urn: "",
      key_accounting_system: 0,
      existing_dd: 0,
      content: '',
    });

    const Territories = ref([]);
    const Responsibles = ref([]);
    const Countries = ref([]);
    const logs = ref([]);

    const fetchPersonData = async (id) => {
      try {
        const res = await apiClient.get(`/organisation/viewPerson/${id}`);
        Object.assign(formData.value, res.data.data);
        formData.value.content = '';
        logs.value = res.data.data.logs;
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    const fetchOptions = async () => {
      try {
        const response = await apiClient.get('settings/organisation-person');
        options.value = response.data.data.dropdowns;
      } catch (error) {
        console.error('Error fetching options data:', error);
      }
    };

    const handleSubmit = async () => {
      try {
        let response;
        if (formData.value.id) {
          response = await apiClient.post(
            `organisation/updatePerson/${formData.value.id}`,
            formData.value, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          router.push({
            path: "/crm/person-list",
            query: {
              org_id: route.query.org_id
            },
          });
        } else {
          response = await apiClient.post(
            '/organisation/createPerson',
            formData.value
          );
          router.push({
            path: "/crm/person-list",
            query: {
              org_id: route.query.org_id
            },
          });
        }

      } catch (error) {
        console.error('Error saving organization data:', error);
      }

      // try {
      //   const response = await store.dispatch('person/createPerson', formData.value);
      //   router.push({
      //   path: "/crm/person-list",
      //   query: {
      //     org_id: route.query.org_id
      //   },
      // });
      // } catch (error) {
      //   apiErrors.value = error.response.data.errors;
      //   console.error('Error saving person data:', error);
      // }
    };
    onMounted(async () => {
      const personId = route.query.personId;
      if (personId) {
        fetchPersonData(personId);
      }
      fetchOptions()
      //  store.dispatch('person/getoptionsData');
    });

    return {
      schema,
      formData,
      handleSubmit,
      isEditing,
      Territories,
      Responsibles,
      Countries,
      options,
      org_id,
      apiErrors,
      logs
    };
  },
};
</script>
