<template>
  <div class="card analytics-card">
    <div class="card-header d-flex justify-content-between align-items-center flex-wrap">
      <h3><i class="ti ti-grip-vertical"></i>Recently Added Companies</h3>
      <div class="card-select">
        <ul>
          <li>
            <vue-select :options="Days" id="monthcomplete" placeholder="Last 30 days" />
          </li>
          <li>
            <a href="javascript:;" class="btn btn-primary add-popup"
              ><i class="ti ti-square-rounded-plus"></i> Add Company</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table custom-table mb-0" id="analytic-company">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in AnalyticsCompany" :key="item.id">
              <td>
                <h2 class="table-avatar d-flex align-items-center">
                  <router-link to="/crm/company-details" class="company-img"
                    ><img
                      class="avatar-img"
                      :src="require(`@/assets/img/icons/${item.lead_img}`)"
                      alt="User Image" /></router-link
                  ><router-link
                    to="/crm/company-details"
                    class="profile-split d-flex flex-column"
                    >{{ item.lead_name }}</router-link
                  >
                </h2>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsCompany from "@/assets/json/analytic-company.json";
export default {
  data() {
    return {
      AnalyticsCompany: AnalyticsCompany,
      Days: ["Last 30 days", "Last 15 days", "Last 7 days"],
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
