<template>
    <div class="card analytics-card">
        <div class="card-header d-flex justify-content-between align-items-center flex-wrap">
            <h3>
                <i class="ti ti-grip-vertical"></i>Recently Created Leads
            </h3>
            <div class="card-select">
                <ul>
                    <li>
                        <vue-select 
                            :options="Days"
                            id="ninecomplete"
                            placeholder="Last 30 days"
                        />
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table custom-table mb-0" id="analytic-lead">
                    <thead>
                        <tr>
                            <th>Lead Name</th>
                            <th>Company Name</th>
                            <th>Phone</th>
                            <th>Lead Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in AnalyticsLeads" :key="item.id">
                            <td>{{item.name}}</td>
                            <td>
                                <h2 class="table-avatar d-flex align-items-center"><router-link to="/crm/meter-opportunities-details"
                                        class="company-img"><img class="avatar-img"
                                            :src="require(`@/assets/img/icons/${item.lead_img}`)" alt="User Image"></router-link><router-link
                                        to="/crm/meter-opportunities-details"
                                        class="profile-split d-flex flex-column">{{item.lead_name}}<span>{{item.lead_city}}
                                        </span></router-link></h2>
                            </td>
                            <td>{{item.phone}}</td>
                            <td><span :class="item.statusclass">{{item.status}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import AnalyticsLeads from '@/assets/json/analytic-lead.json'
export default {
    data() {
        return {
            AnalyticsLeads: AnalyticsLeads,
            Days: ["Last 30 days", "Last 15 days", "Last 7 days"]
        }
    },
}
</script>