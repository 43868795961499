<template>
  <!-- Add New Activity -->
  <div class="toggle-popup">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h4>Add New Activity</h4>
        <a href="javascript:;" class="sidebar-close toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <form action="/crm/activities-list" class="toggle-height">
          <div class="pro-create">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Title <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Activity Type <span class="text-danger">*</span></label
                  >
                  <ul class="radio-activity">
                    <li>
                      <div class="active-type">
                        <input type="radio" id="call" name="status" checked="" />
                        <label for="call"><i class="ti ti-phone"></i>Calls</label>
                      </div>
                    </li>
                    <li>
                      <div class="active-type">
                        <input type="radio" id="mail" name="status" />
                        <label for="mail"><i class="ti ti-mail"></i>Email</label>
                      </div>
                    </li>
                    <li>
                      <div class="active-type">
                        <input type="radio" id="task" name="status" />
                        <label for="task"><i class="ti ti-subtask"></i>Task</label>
                      </div>
                    </li>
                    <li>
                      <div class="active-type">
                        <input type="radio" id="shares" name="status" />
                        <label for="shares"
                          ><i class="ti ti-user-share"></i>Meeting</label
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label"
                  >Due Date <span class="text-danger">*</span></label
                >
                <div class="form-wrap icon-form">
                  <date-picker
                    v-model="startdate"
                    placeholder=""
                    class="datetimepicker form-control"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                  />
                  <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label"
                  >Time <span class="text-danger">*</span></label
                >
                <div class="form-wrap icon-form">
                  <a-time-picker
                    class="form-control datetimepicker-time"
                    placeholder="11:00 AM"
                    use12-hours
                    format="h:mm a"
                    type="text"
                  />
                  <span class="form-icon"><i class="ti ti-clock-hour-10"></i></span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-form-label"
                  >Reminder <span class="text-danger">*</span></label
                >
                <div class="form-wrap icon-form">
                  <span class="form-icon"><i class="ti ti-bell-ringing"></i></span>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center">
                  <div class="form-wrap w-100">
                    <label class="col-form-label">&nbsp;</label>
                    <vue-select
                      :options="SelectAct"
                      id="selectact"
                      placeholder="Select"
                    />
                  </div>
                  <div class="form-wrap time-text">
                    <label class="col-form-label">&nbsp;</label>
                    <p>Before Due</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Owner <span class="text-danger">*</span></label
                  >
                  <vue-select :options="SelectHen" id="selecthen" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Guests <span class="text-danger">*</span></label
                  >
                  <vue-select
                    :options="GueSel"
                    id="gusel"
                    placeholder="Darlee Robertson"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label">Deals</label>
                    <a
                      href="javascript:;"
                      class="label-add"
                      data-bs-toggle="modal"
                      data-bs-target="#add_deal"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select
                    :options="SelectColl"
                    id="selectcoll"
                    placeholder="Select"
                  />
                </div>
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label">Contacts</label>
                    <a
                      href="javascript:;"
                      class="label-add"
                      data-bs-toggle="modal"
                      data-bs-target="#add_contacts"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select :options="SelectAd" id="selectad" placeholder="Select" />
                </div>
                <div class="form-wrap">
                  <div class="d-flex align-items-center justify-content-between">
                    <label class="col-form-label">Companies</label>
                    <a
                      href="javascript:;"
                      class="label-add"
                      data-bs-toggle="modal"
                      data-bs-target="#add_company"
                      ><i class="ti ti-square-rounded-plus"></i>Add New</a
                    >
                  </div>
                  <vue-select :options="SelectLLC" id="selectllc" placeholder="Select" />
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close">Cancel</a>
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add New Activity -->

  <!-- Edit Activity -->
  <div class="toggle-popup1">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <div>
          <h4 class="mb-1">We scheduled a meeting for next week</h4>
          <p>Created At: September 28, 2023 01:21 - Hendry</p>
        </div>
        <a href="javascript:;" class="sidebar-close1 toggle-btn"
          ><i class="ti ti-x"></i
        ></a>
      </div>
      <div class="toggle-body">
        <form @submit.prevent="submitForm" class="toggle-height">
          <div class="pro-create">
            <div class="tab-activity">
              <ul class="nav">
                <li>
                  <a
                    href="javascript:;"
                    data-bs-toggle="tab"
                    data-bs-target="#activity"
                    class="active"
                    >Activity</a
                  >
                </li>
                <li>
                  <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#comments"
                    >Comments<span>1</span></a
                  >
                </li>
              </ul>
            </div>
            <div class="tab-content">
              <div class="tab-pane fade" id="comments">
                <div class="comment-wrap">
                  <h6>
                    The best way to get a project done faster is to start sooner. A goal
                    without a timeline is just a dream.The goal you set must be
                    challenging. At the same time, it should be realistic and attainable,
                    not impossible to reach.
                  </h6>
                  <p>Commented by <span>Aeron</span> on 15 Sep 2023, 11:15 pm</p>
                </div>
              </div>
              <div class="tab-pane show active" id="activity">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Title <span class="text-danger">*</span></label
                      >
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Activity Type <span class="text-danger">*</span></label
                      >
                      <ul class="radio-activity">
                        <li>
                          <div class="active-type">
                            <input type="radio" id="call1" name="status" checked="" />
                            <label for="call1"><i class="ti ti-phone"></i>Calls</label>
                          </div>
                        </li>
                        <li>
                          <div class="active-type">
                            <input type="radio" id="mail1" name="status" />
                            <label for="mail1"><i class="ti ti-mail"></i>Email</label>
                          </div>
                        </li>
                        <li>
                          <div class="active-type">
                            <input type="radio" id="task1" name="status" />
                            <label for="task1"><i class="ti ti-subtask"></i>Task</label>
                          </div>
                        </li>
                        <li>
                          <div class="active-type">
                            <input type="radio" id="shares1" name="status" />
                            <label for="shares1"
                              ><i class="ti ti-user-share"></i>Meeting</label
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="col-form-label"
                      >Due Date <span class="text-danger">*</span></label
                    >
                    <div class="form-wrap icon-form">
                      <date-picker
                        v-model="startdate"
                        placeholder=""
                        class="datetimepicker form-control"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                      <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="col-form-label"
                      >Time <span class="text-danger">*</span></label
                    >
                    <div class="form-wrap icon-form">
                      <a-time-picker
                        class="form-control datetimepicker-time"
                        placeholder="11:00 AM"
                        use12-hours
                        format="h:mm a"
                        type="time"
                      />
                      <span class="form-icon"><i class="ti ti-clock-hour-10"></i></span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="col-form-label"
                      >Reminder <span class="text-danger">*</span></label
                    >
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-bell-ringing"></i></span>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex align-items-center">
                      <div class="form-wrap w-100">
                        <label class="col-form-label">&nbsp;</label>
                        <vue-select
                          :options="SelectHours"
                          id="selecthours"
                          placeholder="Select"
                        />
                      </div>
                      <div class="form-wrap time-text">
                        <label class="col-form-label">&nbsp;</label>
                        <p>Before Due</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Owner <span class="text-danger">*</span></label
                      >
                      <vue-select
                        :options="SelectBrad"
                        id="selectbrad"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Guests <span class="text-danger">*</span></label
                      >
                      <vue-select
                        :options="DarSel"
                        id="darsel"
                        placeholder="Darlee Robertson"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Description <span class="text-danger">*</span></label
                      >
                      <ckeditor
                        :editor="editor"
                        v-model="editorData"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                    <div class="form-wrap">
                      <div class="d-flex align-items-center justify-content-between">
                        <label class="col-form-label">Deals</label>
                        <a
                          href="javascript:;"
                          class="label-add"
                          data-bs-toggle="modal"
                          data-bs-target="#add_deal"
                          ><i class="ti ti-square-rounded-plus"></i>Add New</a
                        >
                      </div>
                      <vue-select :options="SelKono" id="selkono" placeholder="Select" />
                    </div>
                    <div class="form-wrap">
                      <div class="d-flex align-items-center justify-content-between">
                        <label class="col-form-label">Contacts</label>
                        <a
                          href="javascript:;"
                          class="label-add"
                          data-bs-toggle="modal"
                          data-bs-target="#add_contacts"
                          ><i class="ti ti-square-rounded-plus"></i>Add New</a
                        >
                      </div>
                      <vue-select
                        :options="SelAdact"
                        id="seladact"
                        placegolder="Select"
                      />
                    </div>
                    <div class="form-wrap">
                      <div class="d-flex align-items-center justify-content-between">
                        <label class="col-form-label">Companies</label>
                        <a
                          href="javascript:;"
                          class="label-add"
                          data-bs-toggle="modal"
                          data-bs-target="#add_company"
                          ><i class="ti ti-square-rounded-plus"></i>Add New</a
                        >
                      </div>
                      <vue-select
                        :options="SelLLCact"
                        id="selllcact"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-button text-end">
            <a href="javascript:;" class="btn btn-light sidebar-close1">Cancel</a>
            <button type="submit" class="btn btn-primary">Save Changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Activity -->

  <!-- Delete Activity -->
  <div class="modal custom-modal fade" id="delete_activity" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Activity?</h3>
            <p class="del-info">Are you sure you want to remove activity you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <router-link to="/crm/activities-list" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Activity -->

  <!-- Add Contacts -->
  <div class="modal custom-modal fade" id="add_contacts" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Contacts</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-wrap icon-form">
              <span class="form-icon"><i class="ti ti-search"></i></span>
              <input type="text" class="form-control" placeholder="Search" />
            </div>
            <div class="access-wrap">
              <ul>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-19.jpg" alt="" />
                      <a href="javascript:;">Darlee Robertson</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-20.jpg" alt="" />
                      <a href="javascript:;">Sharon Roy</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-21.jpg" alt="" />
                      <a href="javascript:;">Vaughan</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-01.jpg" alt="" />
                      <a href="javascript:;">Jessica</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-16.jpg" alt="" />
                      <a href="javascript:;">Carol Thomas</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/profiles/avatar-22.jpg" alt="" />
                      <a href="javascript:;">Dawn Mercha</a>
                    </span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Contacts -->

  <!-- Add Deals -->
  <div class="modal custom-modal fade" id="add_deal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Deals</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/crm/activities-list">
            <div class="form-wrap icon-form">
              <span class="form-icon"><i class="ti ti-search"></i></span>
              <input type="text" class="form-control" placeholder="Search" />
            </div>
            <div class="access-wrap">
              <ul>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <a href="javascript:;">Collins</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <a href="javascript:;">Konopelski</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <a href="javascript:;">Adams</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <a href="javascript:;">Schumm</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <a href="javascript:;">Wisozk</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <a href="javascript:;">Dawn Mercha</a>
                    </span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Deals -->

  <!-- Add Company -->
  <div class="modal custom-modal fade" id="add_company" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Company</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-wrap icon-form">
              <span class="form-icon"><i class="ti ti-search"></i></span>
              <input type="text" class="form-control" placeholder="Search" />
            </div>
            <div class="access-wrap">
              <ul>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/icons/company-icon-01.svg" alt="" />
                      <a href="javascript:;">NovaWave LLC</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/icons/company-icon-02.svg" alt="" />
                      <a href="javascript:;">BlueSky Industries</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/icons/company-icon-03.svg" alt="" />
                      <a href="javascript:;">SilverHawk</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/icons/company-icon-04.svg" alt="" />
                      <a href="javascript:;">SummitPeak</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/icons/company-icon-05.svg" alt="" />
                      <a href="javascript:;">RiverStone Ventur</a>
                    </span>
                  </label>
                </li>
                <li class="select-people-checkbox">
                  <label class="checkboxs">
                    <input type="checkbox" />
                    <span class="checkmarks"></span>
                    <span class="people-profile">
                      <img src="@/assets/img/icons/company-icon-06.svg" alt="" />
                      <a href="javascript:;">Bright Bridge Grp</a>
                    </span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-btn text-end">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-primary">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Company -->
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      editor: ClassicEditor,
      editorData: "Description",
      editorConfig: {},
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      SelectAct: ["Select", "Minutes", "Hours"],
      SelectHen: ["Select", "Hendry", "Bradtke"],
      SelectColl: ["Select", "Collins", "Konopelski", "Adams"],
      SelectAd: ["Select", "COllins", "Konopelski", "Adams"],
      SelectLLC: ["Select", "NovaWave LLC", "SilverHawk", "HarborView"],
      SelectHours: ["Select", "Minutes", "Hours"],
      SelectBrad: ["Select", "Hendry", "Bradtke", "Sally"],
      SelKono: ["Select", "Collins", "Konopelski", "Adams"],
      SelAdact: ["Select", "Collins", "Konopelski", "Admas"],
      SelLLCact: ["Select", "NovoWave LLC", "SilverHawk", "HarbooView"],
      GueSel: ["Darlee Robertson", "Sharon Roy", "Vaughan", "Jessica", "Carol Thomas"],
      DarSel: ["Darlee Robertson", "Sharon Roy", "Vaughan", "Jessica", "Carol Thomas"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/crm/activities-list");
    },
  },
};
</script>
