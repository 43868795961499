<template>
    <layouts-header></layouts-header>
    <layouts-sidebar></layouts-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
        <div class="content">

            <div class="row">
                <div class="col-md-12">

                    <!-- Page Header -->
                    <page-header :title="title" />
                    <!-- /Page Header -->

                    <!-- Campaign Status -->
                    <div class="row">
                        <div class="col-xl-3 col-lg-6">
                            <div class="campaign-box bg-danger-light">
                                <div class="campaign-img">
                                    <span>
                                        <i class="ti ti-brand-campaignmonitor"></i>
                                    </span>
                                    <p>Campaign</p>
                                </div>
                                <h2>474</h2>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="campaign-box bg-warning-light">
                                <div class="campaign-img">
                                    <span>
                                        <i class="ti ti-send"></i>
                                    </span>
                                    <p>Sent</p>
                                </div>
                                <h2>454</h2>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="campaign-box bg-purple-light">
                                <div class="campaign-img">
                                    <span>
                                        <i class="ti ti-brand-feedly"></i>
                                    </span>
                                    <p>Opened</p>
                                </div>
                                <h2>658</h2>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6">
                            <div class="campaign-box bg-success-light">
                                <div class="campaign-img">
                                    <span>
                                        <i class="ti ti-brand-pocket"></i>
                                    </span>
                                    <p>Completed</p>
                                </div>
                                <h2>747</h2>
                            </div>
                        </div>
                    </div>
                    <!-- /Campaign Status -->

                    <!-- Campaign Tab -->
                    <div class="campaign-tab">
                        <ul class="nav">
                            <li>
                                <router-link to="/crm/campaign">Active Campaign<span>24</span></router-link>
                            </li>
                            <li>
                                <router-link to="/crm/campaign-complete">Completed Campaign</router-link>
                            </li>
                            <li>
                                <router-link to="/crm/campaign-archieve">Archived Campaign</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Campaign Tab -->

                    <div class="card main-card">
                        <div class="card-body">

                            <!-- Search -->
                            <div class="search-section">
                                <div class="row">
                                    <div class="col-md-5 col-sm-4">
                                        <div class="form-wrap icon-form">
                                            <span class="form-icon"><i class="ti ti-search"></i></span>
                                            <input type="text" class="form-control" placeholder="Search Campaign">
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-sm-8">
                                        <div class="export-list text-sm-end">
                                            <ul>
                                                <li>
                                                    <div class="export-dropdwon">
                                                        <a href="javascript:void(0);" class="dropdown-toggle"
                                                            data-bs-toggle="dropdown"><i
                                                                class="ti ti-package-export"></i>Export</a>
                                                        <div class="dropdown-menu  dropdown-menu-end">
                                                            <ul>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i
                                                                            class="ti ti-file-type-pdf text-danger"></i>Export
                                                                        as PDF</a>
                                                                </li>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i
                                                                            class="ti ti-file-type-xls text-green"></i>Export
                                                                        as Excel </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" class="btn btn-primary add-popup"><i
                                                            class="ti ti-square-rounded-plus"></i>Add New Campaign</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Search -->

                            <!-- Filter -->
                            <div class="filter-section filter-flex">
                                <div class="sortby-list">
                                    <ul>
                                        <li>
                                            <div class="sort-dropdown drop-down">
                                                <a href="javascript:void(0);" class="dropdown-toggle"
                                                    data-bs-toggle="dropdown"><i class="ti ti-sort-ascending-2"></i>Sort
                                                </a>
                                                <div class="dropdown-menu  dropdown-menu-start">
                                                    <ul>
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <i class="ti ti-circle-chevron-right"></i>Ascending
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <i class="ti ti-circle-chevron-right"></i>Descending
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <i class="ti ti-circle-chevron-right"></i>Recently
                                                                Viewed
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <i class="ti ti-circle-chevron-right"></i>Recently Added
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-wrap icon-form">
                                                <span class="form-icon"><i class="ti ti-calendar"></i></span>
                                                <input type="text" class="form-control bookingrange"
                                                    ref="dateRangeInput" placeholder="">
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="filter-list">
                                    <ul>
                                        <li>
                                            <div class="manage-dropdwon">
                                                <a href="javascript:void(0);" class="btn btn-purple-light"
                                                    data-bs-toggle="dropdown" data-bs-auto-close="false"><i
                                                        class="ti ti-columns-3"></i>Manage Columns</a>
                                                <div class="dropdown-menu  dropdown-menu-xl-end">
                                                    <h4>Want to manage datatables?</h4>
                                                    <p>Please drag and drop your column to reorder your table and enable
                                                        see option as you want.</p>
                                                    <ul>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Name</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-name" class="check">
                                                                <label for="col-name" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Type</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-phone" class="check">
                                                                <label for="col-phone" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Progress</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-email" class="check">
                                                                <label for="col-email" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Members</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-tag" class="check">
                                                                <label for="col-tag" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Start Date</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-loc" class="check">
                                                                <label for="col-loc" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>End Date</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-rate" class="check">
                                                                <label for="col-rate" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Status</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-owner" class="check">
                                                                <label for="col-owner" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Created</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-contact" class="check"
                                                                    checked="">
                                                                <label for="col-contact" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p><i class="ti ti-grip-vertical"></i>Action</p>
                                                            <div class="status-toggle">
                                                                <input type="checkbox" id="col-action" class="check">
                                                                <label for="col-action" class="checktoggle"></label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-sorts dropdown">
                                                <a href="javascript:void(0);" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="false"><i
                                                        class="ti ti-filter-share"></i>Filter</a>
                                                <div class="filter-dropdown-menu dropdown-menu  dropdown-menu-xl-end">
                                                    <div class="filter-set-view">
                                                        <div class="filter-set-head">
                                                            <h4><i class="ti ti-filter-share"></i>Filter</h4>
                                                        </div>
                                                        <div class="accordion" id="accordionExample">
                                                            <div class="filter-set-content">
                                                                <div class="filter-set-content-head">
                                                                    <a href="javascript:;" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseTwo"
                                                                        aria-expanded="true"
                                                                        aria-controls="collapseTwo">Name</a>
                                                                </div>
                                                                <div class="filter-set-contents accordion-collapse collapse show"
                                                                    id="collapseTwo" data-bs-parent="#accordionExample">
                                                                    <div class="filter-content-list">
                                                                        <div class="form-wrap icon-form">
                                                                            <span class="form-icon"><i
                                                                                    class="ti ti-search"></i></span>
                                                                            <input type="text" class="form-control"
                                                                                placeholder="Search Name">
                                                                        </div>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox" checked>
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Distribution</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Merchandising</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Pricing</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Increased sales</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Brand recognition</h5>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="filter-set-content">
                                                                <div class="filter-set-content-head">
                                                                    <a href="javascript:;" class="collapsed"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target="#owner" aria-expanded="false"
                                                                        aria-controls="owner">Type</a>
                                                                </div>
                                                                <div class="filter-set-contents accordion-collapse collapse"
                                                                    id="owner" data-bs-parent="#accordionExample">
                                                                    <div class="filter-content-list">
                                                                        <ul>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox" checked>
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Public Relations</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Content Marketing</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Brand</h5>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="filter-set-content">
                                                                <div class="filter-set-content-head">
                                                                    <a href="javascript:;" class="collapsed"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target="#Status" aria-expanded="false"
                                                                        aria-controls="Status">Start Date </a>
                                                                </div>
                                                                <div class="filter-set-contents accordion-collapse collapse"
                                                                    id="Status" data-bs-parent="#accordionExample">
                                                                    <div class="filter-content-list">
                                                                        <div class="form-wrap icon-form">
                                                                            <span class="form-icon"><i
                                                                                    class="ti ti-search"></i></span>
                                                                            <input type="text" class="form-control"
                                                                                placeholder="Search Date">
                                                                        </div>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox" checked>
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>25 Sep 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>29 Sep 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>05 Oct 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>14 Oct 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="filter-set-content">
                                                                <div class="filter-set-content-head">
                                                                    <a href="javascript:;" class="collapsed"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseThree"
                                                                        aria-expanded="false"
                                                                        aria-controls="collapseThree">End Date</a>
                                                                </div>
                                                                <div class="filter-set-contents accordion-collapse collapse"
                                                                    id="collapseThree"
                                                                    data-bs-parent="#accordionExample">
                                                                    <div class="filter-content-list">
                                                                        <ul>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox" checked>
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>19 Oct 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>23 Nov 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>14 Dec 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>09 Dec 2023</h5>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="filter-set-content">
                                                                <div class="filter-set-content-head">
                                                                    <a href="javascript:;" class="collapsed"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target="#collapsestatus"
                                                                        aria-expanded="false"
                                                                        aria-controls="collapsestatus">Status</a>
                                                                </div>
                                                                <div class="filter-set-contents accordion-collapse collapse"
                                                                    id="collapsestatus"
                                                                    data-bs-parent="#accordionExample">
                                                                    <div class="filter-content-list">
                                                                        <ul>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox" checked>
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Success</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Pending</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Bounced</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="filter-checks">
                                                                                    <label class="checkboxs">
                                                                                        <input type="checkbox">
                                                                                        <span class="checkmarks"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="collapse-inside-text">
                                                                                    <h5>Paused</h5>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="filter-reset-btns">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <a href="javascript:;" class="btn btn-light">Reset</a>
                                                                </div>
                                                                <div class="col-6">
                                                                    <router-link to="/crm/campaign-complete"
                                                                        class="btn btn-primary">Filter</router-link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- /Filter -->

                            <!-- Campaign List -->
                            <div class="table-responsive custom-table">
                                <campaign-active></campaign-active>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="datatable-length"></div>
                                </div>
                                <div class="col-md-6">
                                    <div class="datatable-paginate"></div>
                                </div>
                            </div>
                            <!-- /Campaign List -->

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <!-- /Page Wrapper -->
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";

export default {
    data() {
        return {
            title: "Campaign",
        }
    },
    setup() {
        const dateRangeInput = ref(null);

        // Move the function declaration outside of the onMounted callback
        function booking_range(start, end) {
            return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
        }

        onMounted(() => {
            if (dateRangeInput.value) {
                const start = moment().subtract(6, "days");
                const end = moment();

                new DateRangePicker(
                    dateRangeInput.value,
                    {
                        startDate: start,
                        endDate: end,
                        ranges: {
                            Today: [moment(), moment()],
                            Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                            "Last 7 Days": [moment().subtract(6, "days"), moment()],
                            "Last 30 Days": [moment().subtract(29, "days"), moment()],
                            "This Month": [moment().startOf("month"), moment().endOf("month")],
                            "Last Month": [
                                moment().subtract(1, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                            ],
                        },
                    },
                    booking_range
                );

                booking_range(start, end);
            }
        });

        return {
            dateRangeInput,
        };
    },

}
</script>