<template>
  <div class="main-wrapper">
    <div class="account-content">
      <div class="login-wrapper account-bg reset-bg">
        <div class="login-content">
          <form action="/success">
            <div class="login-user-info">
              <div class="login-logo success-login-logo">
                <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
              </div>
              <div class="login-heading text-center">
                <i class="ti ti-circle-check-filled"></i>
                <h4>Success</h4>
                <p class="verfy-mail-content mb-0">Your Passwrod Reset Successfully!</p>
              </div>
              <div class="form-wrap">
                <router-link to="/" class="btn btn-primary">Back to Login</router-link>
              </div>
              <div class="login-social-link">
                <div class="copyright-text">
                  <p>Copyright &copy;2024 - CRMS</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
