<template>
  <!-- Add Custom Fields -->
  <div class="modal custom-modal fade" id="add_fields" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Custom Fields</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/app-settings/custom-fields">
            <div class="form-wrap">
              <label class="col-form-label"
                >Custom Fields For <span class="text-danger">*</span></label
              >
              <vue-select :options="ChooSel" id="choosel" placeholder="Choose" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Label <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Type <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label">Default Value</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Required <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Status <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Custom Fields -->

  <!-- Edit Custom Fields -->
  <div class="modal custom-modal fade" id="edit_fields" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Custom Fields</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/app-settings/custom-fields">
            <div class="form-wrap">
              <label class="col-form-label"
                >Custom Fields For <span class="text-danger">*</span></label
              >
              <vue-select :options="EditChoosel" id="editchoosel" placeholder="Choose" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Label <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Name" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Type <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Text" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label">Default Value</label>
              <input type="text" class="form-control" value="Name" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Required <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Required" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Status <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Active" />
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Custom Fields -->

  <!-- Delete Fields -->
  <div class="modal custom-modal fade" id="delete_fields" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form action="/app-settings/custom-fields">
            <div class="success-message text-center">
              <div class="success-popup-icon">
                <i class="ti ti-trash-x"></i>
              </div>
              <h3>Remove Field?</h3>
              <p class="del-info">Are you sure you want to remove it.</p>
              <div class="col-lg-12 text-center modal-btn">
                <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-danger">Yes, Delete it</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Fields -->
</template>
<script>
export default {
  data() {
    return {
      ChooSel: ["Choose", "Expense", "Transaction"],
      EditChoosel: ["Choose", "Expense", "Transaction"],
    };
  },
};
</script>
