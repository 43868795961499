<template>
  <div class="kanban-wrapper">
    <div
      class="kanban-list-items estimations"
      v-for="(module, color) in modules"
      :key="color"
    >
      <div class="kanban-list-head">
        <div class="d-flex justify-content-between align-items-center">
          <div :class="module.DotClass">
            <h5>{{ module.Title }}</h5>
          </div>
          <div class="kanban-action-btns d-flex align-items-center">
            <a href="javascript:void(0);" class="plus-btn"
              ><i class="ti ti-plus"></i
            ></a>
          </div>
        </div>
      </div>
      <span :class="module.LineClass"></span>
      <ul class="kanban-drag-wrap">
        <draggable
          :list="module.Cards"
          :group="{ name: 'kanban', pull: true, put: true }"
          @end="onDragEnd(color)"
        >
          <li v-for="Card in module.Cards" :key="Card.id">
            <div class="kanban-card">
              <div class="kanban-card-head">
                <div class="kanban-card-title">
                  <div class="kanban-card-left">
                    <a href="javascript:void(0);" class="avatar">
                      <img :src="require(`@/assets/img/priority/${Card.Logo}`)" alt="" />
                    </a>
                    <div class="kanban-avatar">
                      <h6>
                        <a href="javascript:void(0);">{{Card.Title}}</a>
                      </h6>
                      <p>{{Card.Development}}</p>
                    </div>
                  </div>
                  <div class="kanban-card-right">
                    <div class="dropdown table-action">
                      <a
                        href="#"
                        class="action-icon"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right"> 
                        <a class="dropdown-item edit-popup" href="#"
                          ><i class="ti ti-edit text-blue"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_estimations"
                          ><i class="ti ti-trash text-danger"></i> Delete</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="ti ti-clipboard-copy text-violet"></i> View
                          Estimation</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="ti ti-checks text-green"></i> Mark as
                          Accpeted</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="ti ti-file"></i> Mark as Draft</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="ti ti-sticker text-blue"></i> Mark as
                          Declined</a
                        >
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="ti ti-printer text-info"></i> Print</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kanban-card-body">
                <div class="kanban-card-content">
                  <p>
                    {{ Card.Content }}
                  </p>
                </div>
                <ul>
                  <li>
                    <i class="ti ti-forbid-2"></i>
                    Estimate ID : <span> {{ Card.EstimateID }}</span>
                  </li>
                  <li>
                    <i class="ti ti-report-money"></i>
                    Amount : <span> {{Card.Amount}}</span>
                  </li>
                  <li>
                    <i class="ti ti-calendar-exclamation"></i>
                    Date : <span> {{Card.Date}}</span>
                  </li>
                  <li>
                    <i class="ti ti-calendar-time"></i>
                    Expiry Date : <span> {{Card.ExpiryDate}}</span>
                  </li>
                </ul>
              </div>
              <div
                class="kanban-card-footer d-flex align-items-center justify-content-between"
              >
                <div class="card-user-info d-flex align-items-center">
                  <a href="javascript:void(0);" class="avatar"
                    ><img :src="require(`@/assets/img/profiles/${Card.Avatar}`)" alt=""
                  /></a>
                  <a href="javascript:void(0);" class="user-name"
                    >{{Card.Name}}</a
                  >
                </div>
                <a href="javascript:void(0);" class="company-logo">
                  <img :src="require(`@/assets/img/icons/${Card.Company}`)" alt="" />
                </a>
              </div>
            </div>
          </li>
        </draggable>
      </ul>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
export default {
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      modules: {
        Draft: {
          DotClass: "kanban-title-head dot-warning",
          Title: "Draft",
          LineClass: "bar-design bg-warning",
          Cards: [
            {
              Logo: "truellysell.svg",
              Title: "Truelysell",
              Development: "Mobile App",
              Content: "TruelySell provides a multiple on-demand service based bootstrap html template.",
              EstimateID: "#EST00023",
              Amount: "$02,15,000",
              Date: "24 Oct 2023",
              ExpiryDate: "15 Sep 2027",
              Avatar: "avatar-21.jpg",
              Name: "Darlee Robertson",
              Company: "company-icon-03.svg"
            },
            {
              Logo: "project-01.svg",
              Title: "Kofejob",
              Development: "Meeting",
              Content: "Kofejob is a freelancers marketplace where you can post projects & get instant help.",
              EstimateID: "#EST00020",
              Amount: "$01,23,000",
              Date: "15 Oct 2023",
              ExpiryDate: "05 Nov 2026",
              Avatar: "avatar-22.jpg",
              Name: "Dawn Mercha",
              Company: "company-icon-07.svg"
            },
          ],
        },
        Sent: {
          DotClass: "kanban-title-head dot-purple",
          Title: "Sent",
          LineClass: "bar-design bg-purple",
          Cards: [
            {
              Logo: "truellysel.svg",
              Title: "Truelysell",
              Development: "Web App",
              Content: "Truelysell is a fully responsive, lightweight, and easy customizable template",
              EstimateID: "#EST00025",
              Amount: "$02,15,000",
              Date: "15 Oct 2023",
              ExpiryDate: "15 Sep 2027",
              Avatar: "avatar-19.jpg",
              Name: "Darlee Robertson",
              Company: "company-icon-01.svg"
            },
            {
              Logo: "project-02.svg",
              Title: "Doccure",
              Development: "Meeting",
              Content: "Doctor Appointment Booking Template is a appointment management template",
              EstimateID: "#EST00019",
              Amount: "$01,23,000",
              Date: "15 Oct 2023",
              ExpiryDate: "05 Nov 2026",
              Avatar: "avatar-23.jpg",
              Name: "Rachel Hampton",
              Company: "company-icon-08.svg"
            },
          ],
        },
        Accepted: {
          DotClass: "kanban-title-head dot-success",
          Title: "Accepted",
          LineClass: "bar-design bg-success",
          Cards: [
            {
              Logo: "dreamchat.svg",
              Title: "Dreamchat",
              Development: "Meeting",
              Content: "The template of Dreamschat is developed with the help of Bootstrap & latest technologies",
              EstimateID: "#EST00024",
              Amount: "$1,45,000",
              Date: "19 Oct 2023",
              ExpiryDate: "10 Sep 2028",
              Avatar: "avatar-20.jpg",
              Name: "Sharon Roy",
              Company: "company-icon-02.svg"
            },
            {
              Logo: "servbook.svg",
              Title: "Servbook",
              Development: "Meeting",
              Content: "Servbook is a fully responsive, lightweight, and easy customizable template",
              EstimateID: "#EST00022",
              Amount: "$4,80,380",
              Date: "10 Nov 2023",
              ExpiryDate: "07 Oct 2028 ",
              Avatar: "avatar-01.jpg",
              Name: "Jessica",
              Company: "company-icon-04.svg"
            },
          ],
        },
        Declined: {
          DotClass: "kanban-title-head dot-danger",
          Title: "Declined",
          LineClass: "bar-design bg-danger",
          Cards: [
            {
              Logo: "dream-pos.svg",
              Title: "DreamPOS",
              Development: "Mobile App",
              Content: "A point of sale admin template can help organize and track inventory in a business.",
              EstimateID: "#EST00021",
              Amount: "$2,12,000",
              Date: "18 Nov 2023",
              ExpiryDate: "10 Oct 2026",
              Avatar: "avatar-16.jpg",
              Name: "Carol Thomas",
              Company: "company-icon-05.svg"
            },
            {
              Logo: "dream-pos.svg",
              Title: "Dreamsports",
              Development: "Meeting",
              Content: "DreamSports is a template to elevate your badminton business build with latest technologies",
              EstimateID: "#EST00017",
              Amount: "$4,18,000",
              Date: "22 Dec 2023",
              ExpiryDate: "20 Nov 2027",
              Avatar: "avatar-25.jpg",
              Name: "Jonathan",
              Company: "company-icon-10.svg"
            },
          ],
        },
      },
    };
  },
  methods: {
    onDragEnd() {},
    onModuleDragEnd() {},
  },
};
</script>