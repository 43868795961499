<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="page-title">
          <h4>Buttons Group</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Basic button group</h5>
            </div>
            <div class="card-body btn-group-showcase">
              <div class="row g-3">
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-primary" type="button">Left</button>
                    <button class="btn btn-primary" type="button">Middle</button>
                    <button class="btn btn-primary" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-secondary" type="button">Left</button>
                    <button class="btn btn-secondary" type="button">Middle</button>
                    <button class="btn btn-secondary" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-success" type="button">Left</button>
                    <button class="btn btn-success" type="button">Middle</button>
                    <button class="btn btn-success" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-info" type="button">Left</button>
                    <button class="btn btn-info" type="button">Middle</button>
                    <button class="btn btn-info" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-warning" type="button">Left</button>
                    <button class="btn btn-warning" type="button">Middle</button>
                    <button class="btn btn-warning" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-danger" type="button">Left</button>
                    <button class="btn btn-danger" type="button">Middle</button>
                    <button class="btn btn-danger" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-light" type="button">Left</button>
                    <button class="btn btn-light" type="button">Middle</button>
                    <button class="btn btn-light" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12 button-group-mb-sm">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-dark" type="button">Left</button>
                    <button class="btn btn-dark" type="button">Middle</button>
                    <button class="btn btn-dark" type="button">Right</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Outline button group</h5>
            </div>
            <div class="card-body btn-group-showcase">
              <div class="row g-3">
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-primary" type="button">Left</button>
                    <button class="btn btn-outline-primary" type="button">Middle</button>
                    <button class="btn btn-outline-primary" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-secondary" type="button">Left</button>
                    <button class="btn btn-outline-secondary" type="button">
                      Middle
                    </button>
                    <button class="btn btn-outline-secondary" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-success" type="button">Left</button>
                    <button class="btn btn-outline-success" type="button">Middle</button>
                    <button class="btn btn-outline-success" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-info" type="button">Left</button>
                    <button class="btn btn-outline-info" type="button">Middle</button>
                    <button class="btn btn-outline-info" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-warning" type="button">Left</button>
                    <button class="btn btn-outline-warning" type="button">Middle</button>
                    <button class="btn btn-outline-warning" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-danger" type="button">Left</button>
                    <button class="btn btn-outline-danger" type="button">Middle</button>
                    <button class="btn btn-outline-danger" type="button">Right</button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-light txt-dark" type="button">
                      Left
                    </button>
                    <button class="btn btn-outline-light txt-dark" type="button">
                      Middle
                    </button>
                    <button class="btn btn-outline-light txt-dark" type="button">
                      Right
                    </button>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6 col-sm-12 button-group-mb-sm">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-dark" type="button">Left</button>
                    <button class="btn btn-outline-dark" type="button">Middle</button>
                    <button class="btn btn-outline-dark" type="button">Right</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Edges button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row g-3">
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-primary" type="button">Left</button>
                      <button class="btn btn-primary" type="button">Middle</button>
                      <button class="btn btn-primary" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-secondary" type="button">Left</button>
                      <button class="btn btn-secondary" type="button">Middle</button>
                      <button class="btn btn-secondary" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-success" type="button">Left</button>
                      <button class="btn btn-success" type="button">Middle</button>
                      <button class="btn btn-success" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-info" type="button">Left</button>
                      <button class="btn btn-info" type="button">Middle</button>
                      <button class="btn btn-info" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-warning" type="button">Left</button>
                      <button class="btn btn-warning" type="button">Middle</button>
                      <button class="btn btn-warning" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-danger" type="button">Left</button>
                      <button class="btn btn-danger" type="button">Middle</button>
                      <button class="btn btn-danger" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-light" type="button">Left</button>
                      <button class="btn btn-light" type="button">Middle</button>
                      <button class="btn btn-light" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12 button-group-mb-sm">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-dark" type="button">Left</button>
                      <button class="btn btn-dark" type="button">Middle</button>
                      <button class="btn btn-dark" type="button">Right</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Outline Edges button</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row g-3">
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-primary" type="button">Left</button>
                      <button class="btn btn-outline-primary" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-primary" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-secondary" type="button">
                        Left
                      </button>
                      <button class="btn btn-outline-secondary" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-secondary" type="button">
                        Right
                      </button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-success" type="button">Left</button>
                      <button class="btn btn-outline-success" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-success" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-info" type="button">Left</button>
                      <button class="btn btn-outline-info" type="button">Middle</button>
                      <button class="btn btn-outline-info" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-warning" type="button">Left</button>
                      <button class="btn btn-outline-warning" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-warning" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-danger" type="button">Left</button>
                      <button class="btn btn-outline-danger" type="button">Middle</button>
                      <button class="btn btn-outline-danger" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-light txt-dark" type="button">
                        Left
                      </button>
                      <button class="btn btn-outline-light txt-dark" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-light txt-dark" type="button">
                        Right
                      </button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12 button-group-mb-sm">
                    <div
                      class="btn-group btn-group-pill"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-dark" type="button">Left</button>
                      <button class="btn btn-outline-dark" type="button">Middle</button>
                      <button class="btn btn-outline-dark" type="button">Right</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Flat button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row g-3">
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-primary" type="button">Left</button>
                      <button class="btn btn-primary" type="button">Middle</button>
                      <button class="btn btn-primary" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-secondary" type="button">Left</button>
                      <button class="btn btn-secondary" type="button">Middle</button>
                      <button class="btn btn-secondary" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-success" type="button">Left</button>
                      <button class="btn btn-success" type="button">Middle</button>
                      <button class="btn btn-success" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-info" type="button">Left</button>
                      <button class="btn btn-info" type="button">Middle</button>
                      <button class="btn btn-info" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-warning" type="button">Left</button>
                      <button class="btn btn-warning" type="button">Middle</button>
                      <button class="btn btn-warning" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-danger" type="button">Left</button>
                      <button class="btn btn-danger" type="button">Middle</button>
                      <button class="btn btn-danger" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-light" type="button">Left</button>
                      <button class="btn btn-light" type="button">Middle</button>
                      <button class="btn btn-light" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-dark" type="button">Left</button>
                      <button class="btn btn-dark" type="button">Middle</button>
                      <button class="btn btn-dark" type="button">Right</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Outline flat button</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row g-3">
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-primary" type="button">Left</button>
                      <button class="btn btn-outline-primary" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-primary" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-secondary" type="button">
                        Left
                      </button>
                      <button class="btn btn-outline-secondary" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-secondary" type="button">
                        Right
                      </button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-success" type="button">Left</button>
                      <button class="btn btn-outline-success" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-success" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-info" type="button">Left</button>
                      <button class="btn btn-outline-info" type="button">Middle</button>
                      <button class="btn btn-outline-info" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-warning" type="button">Left</button>
                      <button class="btn btn-outline-warning" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-warning" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-danger" type="button">Left</button>
                      <button class="btn btn-outline-danger" type="button">Middle</button>
                      <button class="btn btn-outline-danger" type="button">Right</button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-light txt-dark" type="button">
                        Left
                      </button>
                      <button class="btn btn-outline-light txt-dark" type="button">
                        Middle
                      </button>
                      <button class="btn btn-outline-light txt-dark" type="button">
                        Right
                      </button>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-6 col-sm-12 button-group-mb-sm">
                    <div
                      class="btn-group btn-group-square"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button class="btn btn-outline-dark" type="button">Left</button>
                      <button class="btn btn-outline-dark" type="button">Middle</button>
                      <button class="btn btn-outline-dark" type="button">Right</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Default Buttons</h5>
            </div>
            <div class="card-body">
              <div class="btn-group">
                <button type="button" class="btn btn-primary">Left</button>
                <button type="button" class="btn btn-primary">Middle</button>
                <button type="button" class="btn btn-primary">Right</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Default Buttons</h5>
            </div>
            <div class="card-body">
              <div class="btn-group">
                <button type="button" class="btn btn-outline-primary">Left</button>
                <button type="button" class="btn btn-outline-primary">Middle</button>
                <button type="button" class="btn btn-outline-primary">Right</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Default Buttons</h5>
            </div>
            <div class="card-body">
              <div class="btn-group">
                <button type="button" class="btn btn-soft-danger btn-outline-danger">
                  Left
                </button>
                <button type="button" class="btn btn-soft-danger btn-outline-danger">
                  Middle
                </button>
                <button type="button" class="btn btn-soft-danger btn-outline-danger">
                  Right
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Default Buttons</h5>
            </div>
            <div class="card-body">
              <div class="btn-group">
                <button type="button" class="btn btn-soft-danger">Left</button>
                <button type="button" class="btn btn-soft-danger">Middle</button>
                <button type="button" class="btn btn-soft-danger">Right</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="card-title">Mixed styles</div>
            </div>
            <div class="card-body">
              <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                <button type="button" class="btn btn-danger">Left</button>
                <button type="button" class="btn btn-warning">Middle</button>
                <button type="button" class="btn btn-success">Right</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="card-title">Nesting</div>
            </div>
            <div class="card-body">
              <div
                class="btn-group"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <button type="button" class="btn btn-primary">1</button>
                <button type="button" class="btn btn-primary">2</button>
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                  </ul>
                </div>
              </div>
              <div class="btn-group me-2">
                <button class="btn btn-primary"><i class="fa fa-bold"></i></button>
                <button class="btn btn-secondary"><i class="fa fa fa-italic"></i></button>
                <button class="btn btn-success"><i class="fa fa-file-image"></i></button>
                <button class="btn btn-info"><i class="fa fa-paperclip"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="card-title">Checkbox button groups</div>
            </div>
            <div class="card-body">
              <div
                class="btn-group"
                role="group"
                aria-label="Basic checkbox toggle button group"
              >
                <input type="checkbox" class="btn-check" id="btncheck1" />
                <label class="btn btn-outline-primary" for="btncheck1">Checkbox 1</label>

                <input type="checkbox" class="btn-check" id="btncheck2" />
                <label class="btn btn-outline-primary" for="btncheck2">Checkbox 2</label>

                <input type="checkbox" class="btn-check" id="btncheck3" />
                <label class="btn btn-outline-primary" for="btncheck3">Checkbox 3</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="card-title">Radio button groups</div>
            </div>
            <div class="card-body radio-button-group">
              <div
                class="btn-group"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  checked
                />
                <label class="btn btn-outline-primary" for="btnradio1">Radio 1</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" />
                <label class="btn btn-outline-primary" for="btnradio2">Radio 2</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" />
                <label class="btn btn-outline-primary" for="btnradio3">Radio 3</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Sizing</h5>
            </div>
            <div class="card-body">
              <div
                class="btn-group btn-group-lg mb-3"
                role="group"
                aria-label="Large button group"
              >
                <button type="button" class="btn btn-outline-primary">Left</button>
                <button type="button" class="btn btn-outline-primary">Middle</button>
                <button type="button" class="btn btn-outline-primary">Right</button>
              </div>
              <br />
              <div class="btn-group mb-3" role="group" aria-label="Default button group">
                <button type="button" class="btn btn-outline-primary">Left</button>
                <button type="button" class="btn btn-outline-primary">Middle</button>
                <button type="button" class="btn btn-outline-primary">Right</button>
              </div>
              <br />
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Small button group"
              >
                <button type="button" class="btn btn-outline-primary">Left</button>
                <button type="button" class="btn btn-outline-primary">Middle</button>
                <button type="button" class="btn btn-outline-primary">Right</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header justify-content-between">
              <div class="card-title">Button toolbar</div>
            </div>
            <div class="card-body">
              <div
                class="btn-toolbar mb-4"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div class="btn-group me-2 my-1" role="group" aria-label="First group">
                  <button type="button" class="btn btn-primary">1</button>
                  <button type="button" class="btn btn-primary">2</button>
                  <button type="button" class="btn btn-primary">3</button>
                  <button type="button" class="btn btn-primary">4</button>
                </div>
                <div class="btn-group me-2 my-1" role="group" aria-label="Second group">
                  <button type="button" class="btn btn-secondary">5</button>
                  <button type="button" class="btn btn-secondary">6</button>
                  <button type="button" class="btn btn-secondary">7</button>
                </div>
                <div class="btn-group my-1" role="group" aria-label="Third group">
                  <button type="button" class="btn btn-info">8</button>
                </div>
              </div>
              <div
                class="btn-toolbar mb-3"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div class="btn-group me-2 my-1" role="group" aria-label="First group">
                  <button type="button" class="btn btn-outline-secondary">1</button>
                  <button type="button" class="btn btn-outline-secondary">2</button>
                  <button type="button" class="btn btn-outline-secondary">3</button>
                  <button type="button" class="btn btn-outline-secondary">4</button>
                </div>
                <div class="input-group">
                  <div class="input-group-text" id="btnGroupAddon">@</div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Input group example"
                    aria-label="Input group example"
                    aria-describedby="btnGroupAddon"
                  />
                </div>
              </div>
              <div
                class="btn-toolbar justify-content-between"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div class="btn-group my-1" role="group" aria-label="First group">
                  <button type="button" class="btn btn-outline-secondary">1</button>
                  <button type="button" class="btn btn-outline-secondary">2</button>
                  <button type="button" class="btn btn-outline-secondary">3</button>
                  <button type="button" class="btn btn-outline-secondary">4</button>
                </div>
                <div class="input-group">
                  <div class="input-group-text" id="btnGroupAddon2">@</div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Input group example"
                    aria-label="Input group example"
                    aria-describedby="btnGroupAddon2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Vertical</h5>
            </div>
            <div class="card-body">
              <div class="row gap-2">
                <div class="col-sm-3">
                  <div
                    class="btn-group-vertical"
                    role="group"
                    aria-label="Vertical button group"
                  >
                    <button type="button" class="btn btn-primary">Button</button>
                    <div class="btn-group" role="group">
                      <button
                        type="button"
                        class="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Dropdown
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                      </ul>
                    </div>
                    <div class="btn-group dropstart" role="group">
                      <button
                        type="button"
                        class="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Dropdown
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                      </ul>
                    </div>
                    <div class="btn-group dropend" role="group">
                      <button
                        type="button"
                        class="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Dropdown
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                      </ul>
                    </div>
                    <div class="btn-group dropup" role="group">
                      <button
                        type="button"
                        class="btn btn-primary dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Dropdown
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0);">Dropdown link</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="btn-group-vertical"
                    role="group"
                    aria-label="Vertical button group"
                  >
                    <button type="button" class="btn btn-primary">Button</button>
                    <button type="button" class="btn btn-primary">Button</button>
                    <button type="button" class="btn btn-primary">Button</button>
                    <button type="button" class="btn btn-primary">Button</button>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="btn-group-vertical"
                    role="group"
                    aria-label="Vertical radio toggle button group"
                  >
                    <input
                      type="radio"
                      class="btn-check"
                      name="vbtn-radio"
                      id="vbtn-radio1"
                      checked
                    />
                    <label class="btn btn-outline-primary" for="vbtn-radio1"
                      >Radio 1</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      name="vbtn-radio"
                      id="vbtn-radio2"
                    />
                    <label class="btn btn-outline-primary" for="vbtn-radio2"
                      >Radio 2</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      name="vbtn-radio"
                      id="vbtn-radio3"
                    />
                    <label class="btn btn-outline-primary" for="vbtn-radio3"
                      >Radio 3</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
