<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Card -->

          <!-- Search -->
          <div class="search-section">
            <div class="row">
              <div class="col-md-5 col-sm-4">
                <div class="form-wrap icon-form">
                  <span class="form-icon"><i class="ti ti-search"></i></span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Contracts"
                  />
                </div>
              </div>
              <div class="col-md-7 col-sm-8">
                <div class="export-list text-sm-end">
                  <ul>
                    <li>
                      <div class="export-dropdwon">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          ><i class="ti ti-package-export"></i>Export</a
                        >
                        <div class="dropdown-menu dropdown-menu-end">
                          <ul>
                            <li>
                              <a href="javascript:void(0);"
                                ><i class="ti ti-file-type-pdf text-danger"></i
                                >Export as PDF</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><i class="ti ti-file-type-xls text-green"></i
                                >Export as Excel
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="btn btn-primary add-popup"
                        ><i class="ti ti-square-rounded-plus"></i>Add
                        contracts</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Search -->

          <!-- Filter -->
          <div class="filter-section filter-flex">
            <div class="sortby-list">
              <ul>
                <li>
                  <div class="sort-dropdown drop-down">
                    <a
                      href="javascript:void(0);"
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      ><i class="ti ti-sort-ascending-2"></i>Sort
                    </a>
                    <div class="dropdown-menu dropdown-menu-start">
                      <ul>
                        <li>
                          <a href="javascript:void(0);">
                            <i class="ti ti-circle-chevron-right"></i>Ascending
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i class="ti ti-circle-chevron-right"></i>Descending
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i class="ti ti-circle-chevron-right"></i>Recently
                            Viewed
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i class="ti ti-circle-chevron-right"></i>Recently
                            Added
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-wrap icon-form">
                    <span class="form-icon"
                      ><i class="ti ti-calendar"></i
                    ></span>
                    <input
                      type="text"
                      class="form-control bookingrange"
                      ref="dateRangeInput"
                      placeholder=""
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div class="filter-list">
              <ul class="justify-content-md-end">
                <li>
                  <div class="form-sorts dropdown">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                      ><i class="ti ti-filter-share"></i>Filter</a
                    >
                    <div
                      class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end"
                    >
                      <div class="filter-set-view">
                        <div class="filter-set-head">
                          <h4><i class="ti ti-filter-share"></i>Filter</h4>
                        </div>
                        <div class="accordion" id="accordionExample">
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                                >Contracts ID</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse show"
                              id="collapseTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search ID"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>#1493024</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>#1493023</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>#1493022</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>#1493021</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>#1493020</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwos"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                                >Subject</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="collapseTwos"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Name"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>SEO Contracts</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Web Design</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Logo & Branding</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Development</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Logo</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseclient"
                                aria-expanded="false"
                                aria-controls="collapseclient"
                                >Customer</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="collapseclient"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Client"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>NovaWave LLC</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Redwood Inc</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>HarborVie w</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>CoastalStar Co.</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>RiverStone Ventur</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#Status"
                                aria-expanded="false"
                                aria-controls="Status"
                                >Contract Type</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="Status"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <div class="form-wrap icon-form">
                                  <span class="form-icon"
                                    ><i class="ti ti-search"></i
                                  ></span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Date"
                                  />
                                </div>
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" checked />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Contracts under Seal</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Implied Contracts</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Executory Contracts</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Voidable Contracts</h5>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                      </label>
                                    </div>
                                    <div class="collapse-inside-text">
                                      <h5>Unilateral Contracts</h5>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="filter-reset-btns">
                          <div class="row">
                            <div class="col-6">
                              <a href="#" class="btn btn-light">Reset</a>
                            </div>
                            <div class="col-6">
                              <router-link to="/crm/contracts-list" class="btn btn-primary"
                                >Filter</router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="view-icons">
                    <router-link to="/crm/contracts-list"><i class="ti ti-list-tree"></i></router-link>
                    <router-link to="/crm/contracts-grid" class="active"
                      ><i class="ti ti-grid-dots"></i
                    ></router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Filter -->

          <!-- Contact Grid -->
          <div class="row">
            <div class="col-xxl-3 col-xl-4 col-md-6" v-for="item in ContractsGrid" :key="item.id">
              <div class="contact-grid contracts-grid">
                <div class="grid-head">
                  <div class="users-profile">
                    <span class="badge badge-tag badge-danger-light"
                      >#1493016</span
                    >
                  </div>
                  <div class="dropdown table-action">
                    <a
                      href="#"
                      class="action-icon"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-v"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item edit-popup" href="#"
                        ><i class="ti ti-edit text-blue"></i> Edit</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_contracts"
                        ><i class="ti ti-trash text-danger"></i> Delete</a
                      >
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><i class="ti ti-copy text-blue-light"></i> Clone</a
                      >
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><i class="ti ti-clipboard-copy text-blue-light"></i>
                        View Contract</a
                      >
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><i class="ti ti-checks text-blue-light"></i> Mark as
                        Signed</a
                      >
                      <a class="dropdown-item" href="javascript:void(0);"
                        ><i class="ti ti-printer text-blue-light"></i> Print</a
                      >
                    </div>
                  </div>
                </div>
                <div class="grid-body">
                  <div class="contracts-head">
                    <div class="contracts-title">
                      <h4>{{ item.Title }}</h4>
                      <p>{{item.Content}}</p>
                    </div>
                  </div>
                  <div class="address-info">
                    <p>
                      <i class="ti ti-calendar-event"></i>Date : 25 May 2024
                    </p>
                    <p>
                      <i class="ti ti-calendar-stats"></i>Open till : 31 Jun
                      2024
                    </p>
                  </div>
                  <div class="star-user">
                    <a href="javascript:void(0);" class="avatar">
                      <img
                        :src="require(`@/assets/img/icons/${item.Image}`)"
                        alt="img"
                      />
                    </a>
                    <div class="star-user-title">
                      <span>Customer</span>
                      <a href="javascript:void(0);" class="start-title"
                        >{{item.Company}}</a
                      >
                    </div>
                  </div>
                </div>
                <div class="grid-footer d-flex justify-content-between">
                  <div>
                    <span class="badge badge-tag badge-purple">
                      <i class="ti ti-moneybag"></i> Value : $2,04,214</span
                    >
                  </div>
                  <a href="javascript:void(0);" class="contracts-file">
                    <i class="ti ti-file-dots"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contact Grid -->

          <div class="load-btn text-center pb-4">
            <a href="javascript:void(0);" class="btn btn-primary"
              >Load More Contacts<i class="ti ti-loader"></i
            ></a>
          </div>

          <!-- /Card -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <contracts-modal></contracts-modal>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
import ContractsGrid from '@/assets/json/contracts-grid.json'

export default {
    data() {
        return {
            ContractsGrid: ContractsGrid,
            title: "Contracts"
        }
    },
    setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days"),
              ],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },
}
</script>