<template>
  <div class="filter-section filter-flex">
    <div class="sortby-list">
      <ul>
        <li>
          <div class="sort-dropdown drop-down">
            <a
              href="javascript:void(0);"
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              ><i class="ti ti-sort-ascending-2"></i>Sort
            </a>
            <div class="dropdown-menu dropdown-menu-start">
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Ascending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Descending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Added
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div class="form-wrap icon-form">
            <span class="form-icon"><i class="ti ti-calendar"></i></span>
            <input
              type="text"
              class="form-control bookingrange"
              placeholder=""
              ref="dateRangeInput2"
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="filter-list">
      <ul>
        <li>
          <div class="manage-dropdwon">
            <a
              href="javascript:void(0);"
              class="btn btn-purple-light"
              data-bs-toggle="dropdown"
              data-bs-auto-close="false"
              ><i class="ti ti-columns-3"></i>Manage Columns</a
            >
            <div class="dropdown-menu dropdown-menu-md-end">
              <h4>Want to manage datatables?</h4>
              <p>
                Please drag and drop your column to reorder your table and enable see
                option as you want.
              </p>
              <ul>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Name</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-name" class="check" />
                    <label for="col-name" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Phone</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-phone" class="check" />
                    <label for="col-phone" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Email</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-email" class="check" />
                    <label for="col-email" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Location</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-loc" class="check" />
                    <label for="col-loc" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Owner</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-owner" class="check" />
                    <label for="col-owner" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Source</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-tag" class="check" />
                    <label for="col-tag" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Won Deals</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-contact" class="check" checked="" />
                    <label for="col-contact" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Lost Deals</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-status" class="check" />
                    <label for="col-status" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Date Created</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-action" class="check" />
                    <label for="col-action" class="checktoggle"></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div class="form-sorts dropdown">
            <a
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
              data-bs-auto-close="false"
              ><i class="ti ti-filter-share"></i>Filter</a
            >
            <div class="filter-dropdown-menu dropdown-menu dropdown-menu-md-end">
              <div class="filter-set-view">
                <div class="filter-set-head">
                  <h4><i class="ti ti-filter-share"></i>Filter</h4>
                </div>
                <div class="accordion" id="accordionExample">
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="javascript:;"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                        >Location</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse show"
                      id="collapseTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <div class="form-wrap icon-form">
                          <span class="form-icon"><i class="ti ti-search"></i></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Location"
                          />
                        </div>
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>India</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>USA</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>France</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>United Kingdom</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>UAE</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Italy</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Japan</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Germany</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="javascript:;"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#owner"
                        aria-expanded="false"
                        aria-controls="owner"
                        >Owner</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="owner"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <div class="form-wrap icon-form">
                          <span class="form-icon"><i class="ti ti-search"></i></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Owner"
                          />
                        </div>
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Hendry</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Guillory</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Jami</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Theresa</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Espinosa</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="javascript:;"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#Status"
                        aria-expanded="false"
                        aria-controls="Status"
                        >Source</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="Status"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Paid Social</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Google</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Campaigns</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="javascript:;"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        >Date</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="collapseThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>25 Sep 2023, 01:22 pm</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>29 Sep 2023, 04:15 pm</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>04 Oct 2023, 10:18 am</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>17 Oct 2023, 03:31 pm</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>24 Oct 2023, 09:14 pm</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-reset-btns">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="btn btn-light">Reset</a>
                    </div>
                    <div class="col-6">
                      <router-link to="/report/contact-reports" class="btn btn-primary"
                        >Filter</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
export default {
  data() {
    return {
    };
  },

  setup() {
    const dateRangeInput2 = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput2.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput2.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput2,
    };
  },
};
</script>
