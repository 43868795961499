<template>
  <div class="filter-section filter-flex">
    <div class="sortby-list">
      <ul>
        <li>
          <div class="sort-dropdown drop-down">
            <a
              href="javascript:void(0);"
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              ><i class="ti ti-sort-ascending-2"></i>Sort
            </a>
            <div class="dropdown-menu dropdown-menu-start">
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Ascending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Descending
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="ti ti-circle-chevron-right"></i>Recently Added
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="filter-list">
      <ul>
        <li>
          <div class="manage-dropdwon">
            <a
              href="javascript:void(0);"
              class="btn btn-purple-light"
              data-bs-toggle="dropdown"
              data-bs-auto-close="false"
              ><i class="ti ti-columns-3"></i>Manage Columns</a
            >
            <div class="dropdown-menu dropdown-menu-xl-end">
              <h4>Want to manage datatables?</h4>
              <p>
                Please drag and drop your column to reorder your table and enable see
                option as you want.
              </p>
              <ul>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Country Code</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-code" class="check" />
                    <label for="col-code" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Country ID</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-id" class="check" />
                    <label for="col-id" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Country Name</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-country" class="check" />
                    <label for="col-country" class="checktoggle"></label>
                  </div>
                </li>
                <li>
                  <p><i class="ti ti-grip-vertical"></i>Action</p>
                  <div class="status-toggle">
                    <input type="checkbox" id="col-action" class="check" />
                    <label for="col-action" class="checktoggle"></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div class="form-sorts dropdown">
            <a
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
              data-bs-auto-close="false"
              ><i class="ti ti-filter-share"></i>Filter</a
            >
            <div class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end">
              <div class="filter-set-view">
                <div class="filter-set-head">
                  <h4><i class="ti ti-filter-share"></i>Filter</h4>
                </div>
                <div class="accordion" id="accordionExample">
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="javascript:;"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                        >Country Code</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse show"
                      id="collapseTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <div class="form-wrap icon-form">
                          <span class="form-icon"><i class="ti ti-search"></i></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Country"
                          />
                        </div>
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>AS</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>AD</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>AO</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>AI</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>AQ</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="javascript:;"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#Country"
                        aria-expanded="false"
                        aria-controls="Country"
                        >Country ID</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="Country"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>684</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>376</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="filter-set-content">
                    <div class="filter-set-content-head">
                      <a
                        href="javascript:;"
                        class="collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        >Country Name</a
                      >
                    </div>
                    <div
                      class="filter-set-contents accordion-collapse collapse"
                      id="collapseThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="filter-content-list">
                        <ul>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" checked />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>American Samoa</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Andorra</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Angalo</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Anguilla</h5>
                            </div>
                          </li>
                          <li>
                            <div class="filter-checks">
                              <label class="checkboxs">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </div>
                            <div class="collapse-inside-text">
                              <h5>Antarctica</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-reset-btns">
                  <div class="row">
                    <div class="col-6">
                      <a href="javascript:;" class="btn btn-light">Reset</a>
                    </div>
                    <div class="col-6">
                      <router-link to="/pages/countries" class="btn btn-primary"
                        >Filter</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>
