<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body pb-0">
              <!-- Search -->
              <div class="search-section mb-0 border-0">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Membership"
                      />
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-8">
                    <div class="export-list text-sm-end">
                      <ul>
                        <li>
                          <a href="javascript:void(0);" class="btn btn-primary add-popup"
                            ><i class="ti ti-square-rounded-plus"></i>Add Membership</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->
            </div>
          </div>
          <div class="membership-plans">
            <div class="plan-selected">
              <h4>Monthly</h4>
              <div class="status-toggle">
                <input id="two_factor" class="check" type="checkbox" />
                <label for="two_factor" class="checktoggle">checkbox</label>
              </div>
              <h4>Annually</h4>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6">
                <div class="membership-plan-grid">
                  <div class="plan-price-head">
                    <span class="plan-type">Basic</span>
                    <h4>$50 <span>/ month</span></h4>
                  </div>
                  <div class="Plan-body">
                    <div class="plans-features">
                      <ul>
                        <li>
                          <span><i class="ti ti-check"></i></span>10 Contacts
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>10 Leads
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>20 Companies
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>50 Compaigns
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>100 Projects
                        </li>
                        <li>
                          <span class="bg-danger"><i class="ti ti-x"></i></span>Deals
                        </li>
                        <li>
                          <span class="bg-danger"><i class="ti ti-x"></i></span>Tasks
                        </li>
                        <li>
                          <span class="bg-danger"><i class="ti ti-x"></i></span>Pipelines
                        </li>
                      </ul>
                    </div>
                    <div class="plan-btn text-center">
                      <a href="javascript:void(0);" class="btn btn-primary">Choose</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="membership-plan-grid active">
                  <div class="plan-price-head">
                    <span class="plan-type">Business</span>
                    <h4>$200 <span>/ month</span></h4>
                  </div>
                  <div class="Plan-body">
                    <div class="plans-features">
                      <ul>
                        <li>
                          <span><i class="ti ti-check"></i></span>20 Contacts
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>20 Leads
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>50 Companies
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Unlimited Compaigns
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Unlimited Projects
                        </li>
                        <li>
                          <span class="bg-danger"><i class="ti ti-x"></i></span>Deals
                        </li>
                        <li>
                          <span class="bg-danger"><i class="ti ti-x"></i></span>Tasks
                        </li>
                        <li>
                          <span class="bg-danger"><i class="ti ti-x"></i></span>Pipelines
                        </li>
                      </ul>
                    </div>
                    <div class="plan-btn text-center">
                      <a href="javascript:void(0);" class="btn btn-primary">Choose</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="membership-plan-grid">
                  <div class="plan-price-head">
                    <span class="plan-type">Enterprise</span>
                    <h4>$400 <span>/ month</span></h4>
                  </div>
                  <div class="Plan-body">
                    <div class="plans-features">
                      <ul>
                        <li>
                          <span><i class="ti ti-check"></i></span>Unlimited Contacts
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Unlimited Leads
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Unlimited Companies
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Unlimited Compaigns
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Unlimited Projects
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Deals
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Tasks
                        </li>
                        <li>
                          <span><i class="ti ti-check"></i></span>Pipelines
                        </li>
                      </ul>
                    </div>
                    <div class="plan-btn text-center">
                      <a href="javascript:void(0);" class="btn btn-primary">Choose</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <membership-modal></membership-modal>
</template>
<script>
export default {
  data() {
    return {
      title: "Membership Plans",
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
