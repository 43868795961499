<template>
  <!-- Add Testimonial -->
  <div class="modal custom-modal fade" id="add_testimonials" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Testimonial</h5>
          <div class="d-flex align-items-center mod-toggle">
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <form action="testimonials">
            <div class="form-wrap">
              <div class="profile-upload">
                <div class="profile-upload-img">
                  <span><i class="ti ti-photo"></i></span>
                  <img
                    src="@/assets/img/profiles/avatar-20.jpg"
                    alt="img"
                    class="preview1"
                  />
                  <button type="button" class="profile-remove">
                    <i class="ti ti-x"></i>
                  </button>
                </div>
                <div class="profile-upload-content">
                  <label class="profile-upload-btn">
                    <i class="ti ti-file-broken"></i> Upload File
                    <input type="file" class="input-img" />
                  </label>
                  <p>JPG, GIF or PNG. Max size of 800K</p>
                </div>
              </div>
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >User Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Designation <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Ratings <span class="text-danger">*</span></label
              >
              <vue-select :options="ChoNumSel" id="chonumsel" placeholder="Choose(1-5)" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Status <span class="text-danger">*</span></label
              >
              <div class="radio-wrap">
                <div class="d-flex align-items-center">
                  <div class="radio-btn">
                    <input
                      type="radio"
                      class="status-radio"
                      id="active"
                      name="status"
                      checked=""
                    />
                    <label for="active">Active</label>
                  </div>
                  <div class="radio-btn">
                    <input
                      type="radio"
                      class="status-radio"
                      id="inactive"
                      name="status"
                    />
                    <label for="inactive">Inactive</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Testimonial  -->

  <!-- Edit Testimonial -->
  <div class="modal custom-modal fade" id="edit_testimonials" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Testimonial</h5>
          <div class="d-flex align-items-center mod-toggle">
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="ti ti-x"></i>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <form action="testimonials">
            <div class="form-wrap">
              <div class="profile-upload">
                <div class="profile-upload-img">
                  <span><i class="ti ti-photo"></i></span>
                  <img
                    src="@/assets/img/profiles/avatar-20.jpg"
                    alt="img"
                    class="preview1"
                  />
                  <button type="button" class="profile-remove">
                    <i class="ti ti-x"></i>
                  </button>
                </div>
                <div class="profile-upload-content">
                  <label class="profile-upload-btn">
                    <i class="ti ti-file-broken"></i> Upload File
                    <input type="file" class="input-img" />
                  </label>
                  <p>JPG, GIF or PNG. Max size of 800K</p>
                </div>
              </div>
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >User Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Darlee Robertson" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Designation <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Facility Manager" />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Ratings <span class="text-danger">*</span></label
              >
              <vue-select
                :options="EditChoNumSel"
                id="editchonumsel"
                placeholder="Choose(1-5)"
              />
            </div>
            <div class="form-wrap">
              <label class="col-form-label"
                >Status <span class="text-danger">*</span></label
              >
              <div class="radio-wrap">
                <div class="d-flex align-items-center">
                  <div class="radio-btn">
                    <input
                      type="radio"
                      class="status-radio"
                      id="active1"
                      name="status"
                      checked=""
                    />
                    <label for="active1">Active</label>
                  </div>
                  <div class="radio-btn">
                    <input
                      type="radio"
                      class="status-radio"
                      id="inactive1"
                      name="status"
                    />
                    <label for="inactive1">Inactive</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Testimonial  -->

  <!-- Delete Testimonial -->
  <div class="modal custom-modal fade" id="delete_testimonials" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <i class="ti ti-trash-x"></i>
            </div>
            <h3>Remove Testimonial?</h3>
            <p class="del-info">Are you sure you want to remove contact you selected.</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="javascript:;" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/content/testimonials" class="btn btn-danger"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Testimonial -->
</template>
<script>
export default {
  data() {
    return {
      ChoNumSel: ["Choose(1-5)", "1", "2", "3", "4", "5"],
      EditChoNumSel: ["Choose(1-5)", "1", "2", "3", "4", "5"],
    };
  },
};
</script>
