<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <!-- Prefixes -->
              <div class="card">
                <div class="card-body">
                  <div class="settings-header">
                    <website-sidebar></website-sidebar>
                  </div>
                  <form @submit.prevent="createMode" v-if="createModules.length > 0">
                    <div class="row align-items-center py-4">
                      <div class="col"></div>
                      <div class="col-md-7">
                        <div class="row mb-3" v-for="mode in createModules" :key="mode.id">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-12">
                                <h6 class="bg-teal text-capitalize px-2 py-3">{{ mode.name }}</h6>
                              </div>
                            </div>
                            <div class="storage-wrap prefer-wrap d-block">
                              <div class="row align-items-center">
                                <div class="col-md-4">
                                  <p>Uniquely identify by</p>
                                </div>
                                <div class="col-md-8">
                                  <select v-if="mode.name === 'organisations'" class="form-select" v-model="selectedValue" disabled>
                                    <option value="">-----Select-----</option>
                                    <option v-for="val in identifyBy" :key="val.id" :value="val.id">
                                      {{ val.value }}
                                    </option>
                                  </select>
                                  <select v-if="mode.name === 'opportunities'" class="form-select" v-model="selectedOppValue" disabled>
                                    <option value="">-----Select-----</option>
                                    <option v-for="val in identifyOppBy" :key="val.id" :value="val.id">
                                      {{ val.value }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="row align-items-center my-3">
                                <div class="col-md-4">
                                  <p>Import mode </p>
                                </div>
                                <div class="col-md-8">
                                  <p class="form-control">Update existing, create new </p>
                                </div>
                              </div>
                              <div class="setting-gateway justify-content-center">
                                <div class="form-wrap d-flex mb-0">
                                  <div class="status-toggle">
                                    <input
                                      type="checkbox"
                                      :id="'checkbox-' + mode.id"
                                      class="check"
                                      v-model="formData.create_mode_data[mode.id].ignore_blank_fields"
                                      :true-value="1"
                                      :false-value="0"
                                    />
                                    <label :for="'checkbox-' + mode.id" class="checktoggle"></label>
                                  </div>
                                  <div class="storage-icon ms-2">
                                    <h6>Ignore blank data fields</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col"></div>
                    </div>
                    <div class="submit-button text-end">
                      <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                  </form>
                  <div class="row" v-else>
                    <div class="col"></div>
                    <div class="col-md-7 py-3 bg-light">
                      <p class="text-center "><i class="ion-information-circled"></i> Please upload and validate a file</p>
                    </div>
                    <div class="col"></div>
                  </div>
                </div>
              </div>
              <!-- /Prefixes -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      title: "Settings",
      selectedValue: 1,
      selectedName: 1,
      selectedOppValue: 1,
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const importName = ref([
      { id: 1, value: 'Full Name' },
      { id: 2, value: 'Separate first and last name' }
    ]);
    const identifyBy = ref([
      { id: 1, value: 'Organisation name + Postcode' },
      { id: 2, value: 'Organisation System ID' },
      { id: 3, value: 'ERP ID' },
      { id: 4, value: 'Organisation name + Postcode + Territory' },
      { id: 5, value: 'ERP ID + Territory' }
    ]);
    const identifyOppBy = ref([
      { id: 1, value: 'Meter Opportunity System ID' },
      { id: 2, value: 'Organisation + Meter Opportunity number' },
      { id: 3, value: 'Meter Opportunity number' }
    ]);
    const createModules = ref([]);
    const file_id = ref(route.query.fileId || localStorage.getItem('fileId') || null);

    const formData = ref({
      id: file_id,
      create_mode_data: {} // This will store the selected IDs
    });

    const fetchCreateData = async (id) => {
      try {
        const data = await store.dispatch("dataimport/fetchCreateMode", id);
        createModules.value = data.file_modules;

        // Initialize `create_mode_data` with default values
        formData.value.create_mode_data = data.file_modules.reduce((acc, module) => {

          acc[module.id] = {
            module_id: module.id,
            ignore_blank_fields: module.ignore_blank_fields != null ? module.ignore_blank_fields : 0 // Set default value here
          };
          return acc;
        }, {});

      } catch (error) {
        console.error("Error fetching dataimport data:", error);
      }
    };

    const createMode = async () => {
      try {
        const response = await store.dispatch("dataimport/createMode", formData.value);

        if (response.status === 200) {
          fetchCreateData(formData.value.id)
          message.success(response.data.message);
        }
      } catch (error) {
        console.error("create mode failed:", error);
      }
    };

    onMounted(() => {
      if (file_id.value) {
        fetchCreateData(file_id.value);
      }
    });

    return {
      formData,
      identifyBy,
      identifyOppBy,
      importName,
      createMode,
      createModules
    };
  }
};
</script>

