// src/store/modules/org.js
import apiClient from '@/axios';
import router from '@/router';

const state = {
  activitesData: {},
  contractOptions: [],
  activityViewData: [],
  activityDropdowns: [],
};

const getters = {
  getactivityDropdowns: (state) => state.activityDropdowns,
  getactivitesData: (state) => state.activitesData,
  getcontractOptions: (state) => state.contractOptions,
  getactivityViewData: (state) => state.activityViewData,
};

const mutations = {
  SET_ACTIVITY_DROPDOWNS_DATA(state, data) {
    state.activityDropdowns = data;
  },
  SET_ACTIVITIES_DATA(state, data) {
    state.activitesData = data;
  },
  SET_CONTRACTS_OPTIONS(state, data) {
    state.contractOptions = data;
  },
  SET_ACTIVITY_VIEW_DATA(state, data) {
    state.activityViewData = data;
  },
};

const actions = {
  async fetchActivityOptions({ commit }) {
    try {
      const response = await apiClient.get('/settings/activity');
      if (response.data.status) {
        commit('SET_ACTIVITY_DROPDOWNS_DATA', response.data.data.dropdowns);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching pipeline:', error);
    }
  },
  async fetchActivities({ commit }, params) {
    try {
      const response = await apiClient.get('/activities', { params });
      commit('SET_ACTIVITIES_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching contracts data:', error);
    }
  },
  async fetchActivityData({ commit }, id) {
    try {
      const response = await apiClient.get(`/activity/view/${id}`);
      commit('SET_ACTIVITY_VIEW_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching contract data:', error);
    }
  },
  async fetchContractOptions({ commit }) {
    try {
      const response = await apiClient.get('/settings/contract');
      if (response) {
        commit('SET_CONTRACTS_OPTIONS', response.data.data.dropdowns);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching pipeline:', error);
    }
  },
  async createActivity({ dispatch }, form) {
    try {
      const response = await apiClient.post(
        '/activity/create',
        form
      );
      if (response.status) {
        router.push('/crm/activities-list')
      }
      return response.data;
    } catch (error) {
      console.error('Error saving Activities data:', error);
    }
  },
  async updateActivity({ commit }, form) {
    try {
      const response = await apiClient.post(
        `/activity/update/${form.id}`,
        form,
      );
      // if(response.status){
      //   router.push('/crm/meter-opportunities-list')
      // }
      return response.data;
    } catch (error) {
      console.error('Error updating contract data:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
