<template>
    <ul>
        <li>
            <router-link to="/crm/activity-calls" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-original-title="Calls"><i class="ti ti-phone"></i></router-link>
        </li>
        <li>
            <router-link to="/crm/activity-mail" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-original-title="Mail"><i class="ti ti-mail"></i></router-link>
        </li>
        <li>
            <router-link to="/crm/activity-task" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-original-title="Task"><i class="ti ti-subtask"></i></router-link>
        </li>
        <li>
            <router-link to="/crm/activity-meeting" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-original-title="Meeting"><i class="ti ti-user-share"></i></router-link>
        </li>
    </ul>
</template>