<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card main-card">
            <div class="card-body">
              <!-- Search -->
              <div class="search-section">
                <div class="row">
                  <div class="col-md-5 col-sm-4">
                    <div class="form-wrap icon-form">
                      <span class="form-icon"><i class="ti ti-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Pipeline"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Search -->

              <!-- Filter -->
              <div class="filter-section filter-flex">
                <div class="sortby-list">
                  <ul>
                    <li>
                      <div class="sort-dropdown drop-down">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          ><i class="ti ti-sort-ascending-2"></i>Sort
                        </a>
                        <div class="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Ascending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Descending
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Recently Viewed
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i class="ti ti-circle-chevron-right"></i>Recently Added
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="export-dropdwon">
                        <a
                          href="javascript:void(0);"
                          class="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          ><i class="ti ti-package-export"></i>Export</a
                        >
                        <div class="dropdown-menu dropdown-menu-end">
                          <ul>
                            <li>
                              <a href="javascript:void(0);"
                                ><i class="ti ti-file-type-pdf text-danger"></i>Export as
                                PDF</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><i class="ti ti-file-type-xls text-green"></i>Export as
                                Excel
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="filter-list">
                  <ul>
                    <li>
                      <div class="manage-dropdwon">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-purple-light"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          ><i class="ti ti-columns-3"></i>Manage Columns</a
                        >
                        <div class="dropdown-menu dropdown-menu-xl-end">
                          <h4>Want to manage datatables?</h4>
                          <p>
                            Please drag and drop your column to reorder your table and
                            enable see option as you want.
                          </p>
                          <ul>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Pipeline Name</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-name" class="check" />
                                <label for="col-name" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Deal Value</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-phone" class="check" />
                                <label for="col-phone" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>No of Deals</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-email" class="check" />
                                <label for="col-email" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Stages</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-tag" class="check" />
                                <label for="col-tag" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Created Date</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-day" class="check" />
                                <label for="col-day" class="checktoggle"></label>
                              </div>
                            </li>
                            <li>
                              <p><i class="ti ti-grip-vertical"></i>Action</p>
                              <div class="status-toggle">
                                <input type="checkbox" id="col-action" class="check" />
                                <label for="col-action" class="checktoggle"></label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form-sorts dropdown">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          ><i class="ti ti-filter-share"></i>Filter</a
                        >
                        <div
                          class="filter-dropdown-menu dropdown-menu dropdown-menu-xl-end"
                        >
                          <div class="filter-set-view">
                            <div class="filter-set-head">
                              <h4><i class="ti ti-filter-share"></i>Filter</h4>
                            </div>
                            <div class="accordion" id="accordionExample">
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    >Pipeline Name</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse show"
                                  id="collapseTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Pipeline"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Sales</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Marketing</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Calls</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Email</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Chats</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#owner"
                                    aria-expanded="false"
                                    aria-controls="owner"
                                    >Stages</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="owner"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <div class="form-wrap icon-form">
                                      <span class="form-icon"
                                        ><i class="ti ti-search"></i
                                      ></span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search Stages"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Win</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>In Pipeline</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Conversation</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Schedule Service</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Lost</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#Status"
                                    aria-expanded="false"
                                    aria-controls="Status"
                                    >Status</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="Status"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Active</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>Inactive</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="filter-set-content">
                                <div class="filter-set-content-head">
                                  <a
                                    href="javascript:;"
                                    class="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                    >Created Date</a
                                  >
                                </div>
                                <div
                                  class="filter-set-contents accordion-collapse collapse"
                                  id="collapseThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="filter-content-list">
                                    <ul>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" checked />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>17 Nov 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>23 Nov 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>14 Dec 2023</h5>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="filter-checks">
                                          <label class="checkboxs">
                                            <input type="checkbox" />
                                            <span class="checkmarks"></span>
                                          </label>
                                        </div>
                                        <div class="collapse-inside-text">
                                          <h5>09 Dec 2023</h5>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="filter-reset-btns">
                              <div class="row">
                                <div class="col-6">
                                  <a href="javascript:;" class="btn btn-light">Reset</a>
                                </div>
                                <div class="col-6">
                                  <router-link
                                    to="/crm/pipeline-list"
                                    class="btn btn-primary"
                                    >Filter</router-link
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="btn btn-primary add-popup"
                        ><i class="ti ti-square-rounded-plus"></i>Add Pipeline</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Filter -->

              <!-- Pipeline List -->
              <div class="table-responsive custom-table">
                <pipeline-table></pipeline-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Pipeline List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <pipeline-modal></pipeline-modal>
</template>

<script>
export default {
  data() {
    return {
      title: "Pipeline",
    };
  },
  mounted() {
    if (document.getElementsByClassName("add-popup").length > 0) {
      var addPopups = document.getElementsByClassName("add-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }

    if (document.getElementsByClassName("edit-popup").length > 0) {
      var editPopups = document.getElementsByClassName("edit-popup");
      for (var k = 0; k < editPopups.length; k++) {
        editPopups[k].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses1 = document.getElementsByClassName("sidebar-close1");
      for (var l = 0; l < sidebarCloses1.length; l++) {
        sidebarCloses1[l].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
