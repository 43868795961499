<template>
    <div class="card analytics-card">
        <div class="card-header d-flex justify-content-between align-items-center flex-wrap">
            <h3><i class="ti ti-grip-vertical"></i>Recently Created Deals</h3>
            <div class="card-select">
                <ul>
                    <li>
                        <vue-select 
                            :options="Days"
                            id="sixcomplete"
                            placeholder="Last 30 days"
                        />
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-nowrap custom-table mb-0" id="analytic-deal">
                    <thead>
                        <tr>
                            <th>Deal Name</th>
                            <th>Stage</th>
                            <th>Deal Value</th>
                            <th>Probability</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in AnalyticsDeals" :key="item.id">
                            <td>{{item.name}}</td>
                            <td>{{item.stage}}</td>
                            <td>{{item.value}}</td>
                            <td>{{item.probability}}</td>
                            <td><span :class="item.statusclass"> {{item.status}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import AnalyticsDeals from '@/assets/json/analytic-deal.json'
export default {
    data() {
        return {
            AnalyticsDeals: AnalyticsDeals,
            Days: ["Last 30 days", "Last 15 days", "Last 7 days"]
        }
    },
}
</script>