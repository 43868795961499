// src/store/modules/org.js
import apiClient from '@/axios';
import router from '@/router';

const state = {
  contractsList: {},
  contractOptions: [],
  contractViewData: [],
};

const getters = {
  getcontractsList: (state) => state.contractsList,
  getcontractOptions: (state) => state.contractOptions,
  getcontractViewData: (state) => state.contractViewData,
};

const mutations = {
  SET_CONTRACTS_DATA(state, data) {
    state.contractsList = data;
  },
  SET_CONTRACTS_OPTIONS(state, data) {
    state.contractOptions = data;
  },
  SET_CONTRACT_VIEW_DATA(state, data) {
    state.contractViewData = data;
  },
};

const actions = {
  async fetchContracts({ commit }, params) {
    try {
      const response = await apiClient.get('/contracts', { params });
      commit('SET_CONTRACTS_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching contracts data:', error);
    }
  },
  async fetchContractData({ commit }, id) {
    try {
      const response = await apiClient.get(`/contract/view/${id}`);
      commit('SET_CONTRACT_VIEW_DATA', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching contract data:', error);
    }
  },
  async fetchContractOptions({ commit }) {
    try {
      const response = await apiClient.get('/settings/contract');
      if (response) {
        commit('SET_CONTRACTS_OPTIONS', response.data.data.dropdowns);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching pipeline:', error);
    }
  },
  async createContract({ dispatch }, form) {
    try {
      const response = await apiClient.post(
        '/contract/create',
        form
      );
      if (response.status) {
        router.push('/crm/contracts-list')
      }
      return response.data;
    } catch (error) {
      console.error('Error saving Opportcontractunity data:', error);
    }
  },
  async updateContract({ commit }, form) {
    try {
      const response = await apiClient.post(
        `/contract/update/${form.id}`,
        form,
      );
      // if(response.status){
      //   router.push('/crm/meter-opportunities-list')
      // }
      return response.data;
    } catch (error) {
      console.error('Error updating contract data:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
