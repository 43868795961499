<template>
  <div class="account-page">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper account-bg register-bg">
          <div class="login-content">
            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <div class="login-user-info">
                <div class="login-logo">
                  <img
                    src="@/assets/img/anviam.png"
                    class="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div class="login-heading">
                  <h4>Register</h4>
                  <p>Create new CRMS account</p>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Name</label>
                  <div class="form-wrap-icon">
                    <input type="text" class="form-control" />
                    <i class="ti ti-user"></i>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Email Address</label>
                  <div class="form-wrap-icon">
                    <Field
                      name="email"
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                    <i class="ti ti-mail"></i>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Password</label>
                  <div class="pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control pass-input mt-2"
                      placeholder="Password"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'ti ti-eye': showPassword,
                          'ti ti-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Confirm Password</label>
                  <div class="pass-group">
                    <Field
                      name="confirmpassword"
                      :type="showPassword1 ? 'text' : 'password'"
                      class="form-control pass-input mt-2"
                      placeholder="Confirm Password"
                      :class="{ 'is-invalid': errors.confirmpassword }"
                    />
                    <span @click="toggleShow1" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': showPassword1,
                          'fas fa-eye-slash': !showPassword1,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">
                      {{ errors.confirmpassword }}
                    </div>
                    <div
                      class="emailshow text-danger"
                      id="confirmpassword"
                    ></div>
                  </div>
                </div>
                <div class="form-wrap form-wrap-checkbox">
                  <div class="custom-control custom-checkbox">
                    <label class="check">
                      <input type="checkbox" />
                      <span class="box"></span>
                      I agree to the
                      <a href="javascript:void(0);" class="forgot-link ms-1"
                        >Terms & Privacy</a
                      >
                    </label>
                  </div>
                </div>
                <div class="form-wrap">
                  <button type="submit" class="btn btn-primary">Sign Up</button>
                </div>
                <div class="login-form">
                  <h6>
                    Already have an account?<router-link to="/" class="hover-a">
                      Sign In Instead</router-link
                    >
                  </h6>
                </div>
                <div class="form-set-login or-text">
                  <h4>OR</h4>
                </div>
                <div class="login-social-link">
                  <ul class="nav">
                    <li>
                      <a href="javascript:void(0);" class="facebook-logo">
                        <img
                          src="@/assets/img/icons/facebook-logo.svg"
                          alt="Facebook"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img
                          src="@/assets/img/icons/google-logo.svg"
                          alt="Google"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="apple-logo">
                        <img
                          src="@/assets/img/icons/apple-logo.svg"
                          alt="Apple"
                        />
                      </a>
                    </li>
                  </ul>
                  <div class="copyright-text">
                    <p>
                      Copyright &copy; {{ new Date().getFullYear() }} DreamsPOS.
                      All rights reserved
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { router } from "@/router";
export default {
  data() {
    return {
      showPassword: false,
      showPassword1: false,
      password: null,
    };
  },
  components: {
    /* eslint-disable vue/no-reserved-component-names */
    Form,
    Field,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel1() {
      return this.showPassword1 ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const showPassword = ref(false);
    // eslint-disable-next-line no-unused-vars
    const emailError = ref("");
    // eslint-disable-next-line no-unused-vars
    const passwordError = ref("");

    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confirmpassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Confirm password is required"),
    });

    const onSubmit = (values) => {
      if (values.password === values.confirmpassword) {
        let Rawdata = localStorage.getItem("storedData");
        let Pdata = [];
        Pdata = JSON.parse(Rawdata);
        const Eresult = Pdata.find(({ email }) => email == values.email);
        if (Eresult) {
          document.getElementById("email").innerHTML =
            "This email are already exist";
        } else {
          Pdata.push(values);
          const jsonData = JSON.stringify(Pdata);
          router.push("/");
          localStorage.setItem("storedData", jsonData);
        }
      } else {
        document.getElementById("confirmpassword").innerHTML =
          "Password not matching";
      }
    };

    return {
      schema,
      onSubmit,
    };
  },
};
</script>
