<template>
  <div id="app">
    <div class="main-wrapper">
      <Loader :isVisible="isLoading" />
      <router-view />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Loader from '../src/views/layouts/index-loader.vue';
export default {
  components: { Loader },
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.isLoading);

    return { isLoading };
  }
}
</script>
